import { FormSection, NumberInputField, SelectField } from "@smartrent/ui";
import { useField } from "formik";

import { StyleSheet, View } from "react-native";
import { useCallback } from "react";

import { humanize } from "@/lib/helpers";

import {
  InputSample,
  InputSampleState,
  InputSampleStateOptions,
} from "../types";

interface FormikInputSamplesProps {
  name: string;
}

interface FormikInputSampleProps {
  value: InputSample;
  index: number;
  setSample: (arg0: InputSample) => void;
  error: any;
}

interface InputSamleStateOption {
  value: InputSampleState;
  label: string;
}

const FormikInputSample = ({
  value,
  index,
  setSample,
  error,
}: FormikInputSampleProps) => {
  return (
    <FormSection title={`Sample ${index + 1}`} style={styles.sampleContainer}>
      <View style={styles.sampleContainer}>
        <NumberInputField
          style={styles.sampleTextInput}
          label={"Low"}
          textInputProps={{
            value: value.low,
            onChangeText: (num) => {
              setSample({ ...value, low: num || 0 });
            },
          }}
          error={error?.low || ""}
        />
        <NumberInputField
          style={styles.sampleTextInput}
          label={"High"}
          textInputProps={{
            value: value.high,
            onChangeText: (num) => {
              setSample({ ...value, high: num || 0 });
            },
          }}
          error={error?.high || ""}
        />
        <SelectField<InputSamleStateOption>
          style={styles.sampleSelectInput}
          label={"State"}
          options={InputSampleStateOptions}
          onChange={function (selectValue): void {
            setSample({
              ...value,
              state: selectValue?.value as InputSampleState,
            });
          }}
          name={"state"}
          value={{ label: humanize(value.state), value: value.state }}
          required
          error={error?.state || ""}
        />
      </View>
    </FormSection>
  );
};

export const FormikInputSamples = ({ name }: FormikInputSamplesProps) => {
  const [{ value }, { error }, { setValue }] = useField<InputSample[]>(name);

  const setSample = useCallback(
    (index: number, input: InputSample) => {
      if (value[index] == input) return;
      const updatedValue = value.slice();
      updatedValue[index] = input;
      setValue(updatedValue);
    },
    [value, setValue]
  );

  return (
    <View style={styles.root}>
      {value.map((sample, i) => {
        return (
          <View key={i}>
            <FormikInputSample
              value={sample}
              index={i}
              setSample={(input: InputSample) => {
                setSample(i, input);
              }}
              error={error ? error[i] : undefined}
            />
          </View>
        );
      })}
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    flexDirection: "column",
  },
  sampleContainer: {
    flexDirection: "column",
    flexWrap: "wrap",
    flexGrow: 1,
    paddingBottom: 8,
  },
  sampleTextInput: {},
  sampleSelectInput: {},
});
