import { Controller } from "@/modules/controller/types";
import { SipAccountsTable } from "@/modules/sip-account/Table";

interface ControllerReadersDisplayProps {
  controller: Controller;
}

export const ControllerSipAccountsDisplay = ({
  controller,
}: ControllerReadersDisplayProps) => {
  return <SipAccountsTable filters={{ controller_id: controller.id }} />;
};
