import { Controller } from "@/modules/controller/types";
import { Door } from "@/modules/door/types";
import { Hub } from "@/modules/hub/types";
import { Panel } from "@/modules/panel/types";

export enum Messages {
  // Controllers
  fetch_config = "config:fetch",
  config_changed = "config:changed",
  controller_status = "controller:status",
  controller_reply = "controller:reply",

  // Panels
  panel_status = "panel:status",

  // Doors
  door_changed = "door:changed",

  // Debug
  debug_send_request = "debug:send-request",
  debug_reply = "debug:reply",

  // Hubs
  hub_reply = "hub:reply",
  hub_status = "hub:status",

  // Encoding
  encoding_updated = "encoding_updated",
}

export const controllerChannelTopic = (controller: Controller) =>
  `controllers:${controller.id}`;
export const hubChannelTopic = (hub: Hub) => `hubs:${hub.id}`;
export const doorChannelTopic = (door_id: Door["id"]) => `doors:${door_id}`;
export const panelChannelTopic = (panel: Panel) => `panels:${panel.id}`;
export const siteEncodingTopic = (site_id: number) =>
  `site_encoding:${site_id}`;
export const generalEncodingTopic = () => "site_encoding:none";
