import {
  Button,
  HStack,
  LoadingCard,
  StatusDot,
  Typography,
  VStack,
} from "@smartrent/ui";

import { ScrollView, StyleSheet, View } from "react-native";

import { ControllerQueries } from "@/modules/controller/queries";

import { ControllerNameAndStatusIndicator } from "@/modules/controller/components/ControllerStatusIndicator";

import { ErrorPage } from "@/components/ErrorPage";

import { Credential, CredentialController } from "../types";

import { CredentialQueries } from "../queries";
interface CredentialSyncFormProps {
  initialValues?: Credential;
  params: {
    site_id: number;
    member_id: number;
  };
}

interface CredentialSyncButtonProps {
  credential: Credential;
  credentialController: CredentialController;
}

export const CredentialSyncForm: React.FC<
  React.PropsWithChildren<CredentialSyncFormProps>
> = ({ initialValues, params }) => {
  const [syncCredential] = CredentialQueries.useSyncCredentialMutation();
  if (!initialValues?.id) {
    return <ErrorPage title={"Error Loading Credential"} />;
  }

  const CredentialSyncButton = ({
    credential,
    credentialController,
  }: CredentialSyncButtonProps) => {
    const { data: controller, isLoading } = ControllerQueries.useQuery({
      id: credentialController?.controller_id,
    });

    if (isLoading) return <LoadingCard />;
    if (!controller) return <ErrorPage title="Error Loading Controller" />;

    return (
      <View style={styles.syncButtonContainer}>
        <View style={styles.statusContainer}>
          <View style={styles.controllerStatusContainer}>
            <ControllerNameAndStatusIndicator controller={controller} />
          </View>
          <HStack style={styles.syncStatusContainer}>
            <Typography>
              {credentialController?.synced_at ? "Synced" : "Unsynced"}
            </Typography>
            <View style={styles.statusDotContainer}>
              <StatusDot
                color={credentialController?.synced_at ? "success" : "error"}
              />
            </View>
          </HStack>
        </View>
        <Button
          style={styles.syncButton}
          disabled={!controller.online}
          onPress={() => {
            syncCredential({ credential: initialValues, credentialController });
          }}
        >
          {controller.online
            ? `Force Sync`
            : "Unable To Sync - Controller Offline"}
        </Button>
      </View>
    );
  };

  return (
    <ScrollView>
      <VStack>
        {initialValues?.controllers?.map((credentialController, index) => {
          return (
            <CredentialSyncButton
              key={index}
              credential={initialValues}
              credentialController={credentialController}
            />
          );
        })}
        <View style={styles.syncButtonContainer}>
          <Button
            style={styles.syncButton}
            onPress={() => {
              syncCredential({ credential: initialValues });
            }}
          >
            Force Sync To All Controllers
          </Button>
        </View>
      </VStack>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  syncButton: {
    flexGrow: 1,
  },
  statusContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  syncButtonContainer: {
    marginBottom: 16,
    padding: 4,
    alignContent: "center",
  },
  syncStatusContainer: {
    alignContent: "space-between",
  },
  controllerStatusContainer: {},
  statusDotContainer: {
    padding: 8,
  },
});
