import { useContext, useEffect } from "react";

import { Paths } from "@/lib/path";
import { PageCtx } from "@/context/PageContext";
import { Site } from "@/modules/site/types";
import { Controller } from "@/modules/controller/types";
import { ControllerLogsTable } from "@/modules/controller/components/ControllerLogsTable";

interface ControllerLogsPageProps {
  site: Site;
  controller: Controller;
}

export const ControllerLogsPage = ({
  site,
  controller,
}: ControllerLogsPageProps) => {
  const { addBreadcrumb, breadCrumbs } = useContext(PageCtx);

  useEffect(() => {
    addBreadcrumb({
      label: `Logs`,
      to: Paths.controllerLogsPath(site.id, controller.id),
    });
  }, [breadCrumbs, site.id, controller.id, addBreadcrumb]);

  return <ControllerLogsTable controller={controller} site={site} />;
};
