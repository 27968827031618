import React, { useEffect } from "react";
import { StyleSheet } from "react-native";
import { useLocation, Link } from "react-router-dom";

import { HStack, Typography, useTheme } from "@smartrent/ui";

import { LinkData } from "./NavDrawer";

const isLinkActive = (pathname: string, url: string): boolean => {
  switch (url) {
    case "/admin":
      return pathname == url;
    case "/superadmin":
      return pathname == url;
    default:
      return pathname.includes(url);
  }
};

interface NavItemProps extends LinkData {
  close?: VoidFunction;
  setNavigationState: VoidFunction;
}

export const NavItem: React.FC<React.PropsWithChildren<NavItemProps>> = ({
  url,
  label,
  icon: Icon,
  close,
  setNavigationState,
}) => {
  const { pathname } = useLocation();
  const { colors } = useTheme();

  const isActive: boolean = isLinkActive(pathname, url);
  const color = isActive ? colors.white : colors.gray200;

  useEffect(() => {
    isActive && setNavigationState();
  }, [setNavigationState, isActive]);

  return (
    <Link
      to={url}
      onClick={close}
      style={
        // eslint-disable-next-line react-native/no-inline-styles -- :thinking:
        {
          backgroundColor: isActive ? colors.gray800 : "inherit",
          padding: "4px 0",
        }
      }
    >
      <HStack spacing={8} align="center" style={styles.container}>
        {Icon ? <Icon size={16} color={color} /> : null}
        <Typography type="body" style={[{ color }, styles.text]}>
          {label}
        </Typography>
      </HStack>
    </Link>
  );
};

const styles = StyleSheet.create({
  container: {
    flexGrow: 0,
    paddingVertical: 4,
    paddingRight: 16,
    paddingLeft: 40,
  },
  text: { lineHeight: 16 },
});
