import { FormikTextInputField } from "@smartrent/ui";
import { Form } from "formik";
import * as yup from "yup";
import { View } from "react-native";

import { FormProps, BaseForm } from "../base/Form";

import { Organization } from "./types";
import { OrganizationQueries } from "./queries";

const validationSchema = yup.object().shape({
  name: yup.string().required("Required").max(40),
});

export const OrganizationForm = ({
  initialValues,
  params,
}: FormProps<Organization>) => {
  return (
    <BaseForm<Organization>
      initialValues={{ ...initialValues }}
      validationSchema={validationSchema}
      QueryClient={OrganizationQueries}
      params={params}
    >
      {() => (
        <Form>
          <View>
            <FormikTextInputField
              name="name"
              label="Organization Name"
              required
            />
          </View>
        </Form>
      )}
    </BaseForm>
  );
};
