import {
  Link,
  ListQueryResponse,
  Stack,
  Table,
  Typography,
  useTableQuery,
} from "@smartrent/ui";

import { ControllerQueries } from "@/modules/controller/queries";
import {
  Controller,
  ControllerFirmwareHistory,
} from "@/modules/controller/types";
import {
  DATE_FORMAT_SHORT,
  TIME_FORMAT,
  formatDateTime,
} from "@/lib/formatters";
import { Site } from "@/modules/site/types";

interface ControllerFirmwareHistoryDisplayProps {
  controller: Controller;
  site: Site;
}

export const ControllerFirmwareHistoryDisplay = ({
  controller,
  site,
}: ControllerFirmwareHistoryDisplayProps) => {
  const tableProps = useTableQuery<
    any,
    ControllerFirmwareHistory,
    ListQueryResponse<ControllerFirmwareHistory>
  >({
    fetch: ControllerQueries.fetchFirmwareHistory,
    getQueryKey: ({ filters, page, pageSize, sortColumn, sortDirection }) => [
      "controller-firmware-history",
      {},
      {
        ...filters,
        page,
        per_page: pageSize,
        sort: sortColumn || "inserted_at",
        dir: sortDirection,
        controllerId: controller.id,
      },
    ],

    columns: [
      {
        name: "model",
        header: "Model",
        sortable: false,
        render: ({ row }) => <Typography>{row.model}</Typography>,
      },
      {
        name: "version",
        header: "Firmware",
        sortable: true,
        render: ({ row }) => <Typography>{row.version}</Typography>,
      },
      {
        name: "inserted_at",
        header: "Last Updated",
        sortable: true,
        render: ({ row }) => {
          return (
            <Stack wrap>
              <Typography numberOfLines={2} type="body">
                {formatDateTime(
                  `${row.inserted_at}Z`,
                  site?.timezone,
                  DATE_FORMAT_SHORT
                )}
              </Typography>
              <Typography numberOfLines={2} type="body" color="textSecondary">
                {formatDateTime(
                  `${row.inserted_at}Z`,
                  site?.timezone,
                  TIME_FORMAT
                )}
              </Typography>
            </Stack>
          );
        },
      },
      {
        name: "release_notes_url",
        header: "Release Notes",
        render: ({ row }) => {
          if (!row.release_notes_url) return null;

          return (
            <Typography type="body">
              <Link href={row.release_notes_url} target="_blank">
                Download
              </Link>
            </Typography>
          );
        },
      },
    ],
    defaultPageSize: 10,
  });

  return (
    <Table<ControllerFirmwareHistory>
      title={"Firmware History"}
      noRecordsText={"No Firmware History Found"}
      isRowPressable={() => false}
      {...tableProps}
    />
  );
};
