import { useSubmitForm } from "@smartrent/forms";
import { forwardRef } from "react";
import { Platform } from "react-native";

/**
 * Custom SubmitFormButton for @smartrent/forms Form component.
 *
 * This component allows triggering form submission from outside the Form Context.
 * On the web, it uses the useSubmitForm hook; on mobile, it listens for a "name=submit" button.
 *
 * For more details on onClick behavior, see:
 * @see https://github.com/smartrent/js-shared/blob/main/packages/forms/src/components/Form/Form.tsx#L136-L146
 */

export const SubmitFormButton = forwardRef<HTMLButtonElement>((props, ref) => {
  const submitForm = useSubmitForm();

  return (
    <button
      hidden
      name="submit"
      ref={ref}
      onClick={Platform.OS === "web" ? (e) => e : submitForm}
    >
      this is a hidden submit button for clicking submit button outside of
      @smartrent/form's FormContext.Provider
    </button>
  );
});
