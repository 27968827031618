import { BaseDrawer } from "../base/Drawer";

import { InputForm } from "./Form";
import { Input } from "./types";

interface InputDrawerProps {
  initialValues?: Input;
  params?: any;
}

export const InputDrawer: React.FC<
  React.PropsWithChildren<InputDrawerProps>
> = ({ initialValues, params }) => {
  return (
    <BaseDrawer
      title={initialValues?.id ? initialValues?.name : "New Input"}
      subtitle={initialValues?.id ? "Edit Input" : null}
    >
      <InputForm initialValues={{ ...initialValues }} params={params} />
    </BaseDrawer>
  );
};
