import { Chip, ChipProps, HStack, Tooltip, useTheme } from "@smartrent/ui";
import { View } from "react-native";

export const PendingDeleteChip: React.FC<
  React.PropsWithChildren<Partial<ChipProps>>
> = (chipProps) => {
  const { colors } = useTheme();

  return (
    <HStack spacing={8} align="center">
      <Tooltip
        name="pending_delete"
        title="Run a Sync to finish deleting"
        placement="top"
      >
        <View>
          <Chip
            textProps={{ numberOfLines: 1 }}
            variation="outlined"
            color="warning"
            size="small"
            corners="badge"
            textStyle={{ color: colors.warning800 }}
            {...chipProps}
          >
            Pending Delete
          </Chip>
        </View>
      </Tooltip>
    </HStack>
  );
};
