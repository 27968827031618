import {
  VStack,
  FormikMultiSelectField,
  FormikTimeInputField,
} from "@smartrent/ui";
import { Form } from "formik";
import * as yup from "yup";
import { yupTime } from "@smartrent/utils";

import { BaseForm } from "../base/Form";

import {
  ScheduleInterval,
  ScheduleIntervalDayMaskOptions,
  ValidScheduleIntervals,
} from "./types";
import { ScheduleIntervalQueries } from "./queries";

yup.addMethod(yup.string, "time", yupTime);

const validationSchema = yup.object({
  days_list: yup
    .array()
    .required("Required")
    .of(yup.string().oneOf(ValidScheduleIntervals)),
  start_time: yup.string().time().required("Required"),
  end_time: yup.string().time().required("Required"),
  schedule_id: yup.number().required(),
});

interface ApiApplicationFormProps {
  initialValues?: Partial<ScheduleInterval>;
  params: { schedule_id: number };
}

export const ScheduleForm = ({
  initialValues,
  params,
}: ApiApplicationFormProps) => {
  return (
    <BaseForm<ScheduleInterval>
      initialValues={{
        ...initialValues,
        days_list: initialValues?.days_list || [],
        schedule_id: params.schedule_id,
      }}
      validationSchema={validationSchema}
      QueryClient={ScheduleIntervalQueries}
      params={params}
    >
      {() => (
        <Form>
          <VStack spacing={16}>
            <FormikMultiSelectField
              name="days_list"
              label="Days"
              required
              options={ScheduleIntervalDayMaskOptions}
            />
            <FormikTimeInputField
              name="start_time"
              label="Start Time"
              required
            />
            <FormikTimeInputField name="end_time" label="End Time" required />
          </VStack>
        </Form>
      )}
    </BaseForm>
  );
};
