import { Member } from "@/modules/member/types";
import { Site } from "@/modules/site/types";
import { MemberDoorsTable } from "@/modules/member/components/MemberDoorsTable";

interface DoorsDisplayProps {
  member: Member;
  site: Site;
}
export const DoorsDisplay = ({ site, member }: DoorsDisplayProps) => {
  return (
    <MemberDoorsTable
      filters={{ member_id: member.id }}
      site={site}
      member={member}
    />
  );
};
