import { FormikMultiSelectField } from "@smartrent/ui";

import { AccessLevelQueries } from "@/modules/access-level/queries";
import { AccessLevel } from "@/modules/access-level/types";

interface AccessLevelSelectFieldProps {
  assistiveText?: React.ReactNode;
  site_id: number;
  name: string;
  label: string;
  filterParams?: any;
  initialValues?: AccessLevel[];
}

export const AccessLevelMultiSelectField: React.FC<
  React.PropsWithChildren<AccessLevelSelectFieldProps>
> = ({ assistiveText, site_id, label, name, filterParams, initialValues }) => {
  const selectQueryProps = AccessLevelQueries.select({
    filterParams: { site_id, ...filterParams },
  });

  const options = buildOptions(initialValues, selectQueryProps.options);
  return (
    <FormikMultiSelectField<AccessLevel>
      name={name}
      label={label}
      assistiveText={assistiveText}
      filtering={true}
      getOptionLabel={(al) => `${al.name}`}
      getOptionValue={(al) => al.id}
      onTextInputChange={selectQueryProps.onTextInputChange}
      options={options}
    />
  );
};

const buildOptions = (
  initialValues?: AccessLevel[],
  options?: AccessLevel[]
) => {
  const newInitialValues = initialValues || [];
  const newOptions = options || [];

  const combinedValues = newInitialValues.concat(newOptions);

  const deduped = [...new Map(combinedValues.map((m) => [m.id, m])).values()];
  return deduped;
};
