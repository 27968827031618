import { Box, FlatListItemSeparator, HStack, Typography } from "@smartrent/ui";
import { StyleSheet, View } from "react-native";

import { Controller, ControllerEncoder } from "@/modules/controller/types";
import { ErrorPage } from "@/components/ErrorPage";
import { Site, SystemTypes } from "@/modules/site/types";

interface ControllerEncodersDisplayProps {
  controller: Controller;
  site: Site;
}

const ControllerEncoderRow = ({
  encoder,
  site,
}: {
  encoder: ControllerEncoder;
  site: Site;
}) => {
  return (
    <Box style={styles.box}>
      <HStack spacing={12} style={styles.row}>
        {encoderFields(site).map((field) => (
          <View style={styles.column} key={field}>
            <Typography type="title4">{field}</Typography>
            <Typography type="body">{encoder[field]}</Typography>
          </View>
        ))}
      </HStack>
    </Box>
  );
};

const encoderFields = (site: Site) => {
  switch (site.system_type) {
    case SystemTypes.Salto:
      return ["id", "type", "ip_address", "description"];
    case SystemTypes.Schlage:
      return ["FriendlyName", "DeviceType", "SerialNumberShort"];
    default:
      return [];
  }
};

export const ControllerEncodersDisplay = ({
  controller,
  site,
}: ControllerEncodersDisplayProps) => {
  if (!controller.metadata?.encoders) {
    return <ErrorPage title="No Encoders Found" />;
  }
  return (
    <View>
      {controller.metadata?.encoders.map((encoder, id) => {
        return (
          <View key={id}>
            {id !== 0 && <FlatListItemSeparator />}
            <ControllerEncoderRow encoder={encoder} site={site} />
          </View>
        );
      })}
    </View>
  );
};

const styles = StyleSheet.create({
  box: { flexGrow: 1 },
  row: {
    flexDirection: "row",
    justifyContent: "space-around",
    flex: 1,
  },
  column: {
    alignItems: "center",
    flexDirection: "column",
  },
});
