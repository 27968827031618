import * as yup from "yup";

import { FormCheckbox, FormSelectField } from "@smartrent/forms";
import { VStack } from "@smartrent/ui";

import {
  KeypadModeOptions,
  LedDriveMode,
  ValidKeypadModes,
  WiegandLedDriveModeOptions,
  WiegandLedDriveModes,
} from "../types";

// Supports Mercury controller's Wiegand fields.
// Manages error messages for form submission and OSDP reader form switching.
export const readerWiegandValidationSchema = yup.object().shape({
  dt_fmt_wiegand_pulses: yup.boolean().required().label("Wiegand Pulses"),
  keypad_mode: yup
    .string()
    .required()
    .notOneOf([""], "Keypad Mode is a required field")
    .oneOf(ValidKeypadModes)
    .label("Keypad Mode"),

  led_drive_mode: yup
    .string()
    .required()
    .notOneOf(
      ["", LedDriveMode.osdp_reader_with_lcd_and_keypad],
      "Led Drive Mode is a required field"
    )
    .oneOf(WiegandLedDriveModes)
    .label("Led Drive Mode"),
  dt_fmt: yup.array().of(yup.string()).required().label("Data Format"),
});

export const ReaderWiegandFields: React.VFC = () => {
  return (
    <VStack spacing={16}>
      {/* Note: This is a custom value we use for encoding/decoding the api payload */}
      <FormCheckbox name={"dt_fmt_wiegand_pulses"} label={"Wiegand Pulses"} />
      <VStack spacing={16}>
        <FormSelectField
          required
          name={"led_drive_mode"}
          label={"Led drive mode"}
          options={WiegandLedDriveModeOptions}
          closeOnChange={true}
        />
        <FormSelectField
          required
          name={"keypad_mode"}
          label={"Keypad mode"}
          options={KeypadModeOptions}
          closeOnChange={true}
        />
      </VStack>
    </VStack>
  );
};
