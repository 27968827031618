import { BaseDrawer } from "../base/Drawer";

import { Operator } from "./types";
import { OperatorForm } from "./Form";

interface OperatorDrawerProps {
  initialValues: Operator;
  params: { site_id: number };
}

export const OperatorDrawer: React.FC<
  React.PropsWithChildren<OperatorDrawerProps>
> = ({ initialValues, params }) => {
  return (
    <BaseDrawer title="New Operator">
      <OperatorForm initialValues={initialValues} params={params} />
    </BaseDrawer>
  );
};
