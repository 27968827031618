import { useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
import { useHistory, useParams } from "react-router-dom";

import {
  FilterProps,
  FormikSelectField,
  FormikTextInputField,
  ListQueryResponse,
  StatusDot,
  Table,
  Typography,
  useTableQuery,
} from "@smartrent/ui";

import {
  StatusDisplay,
  DeviceStatus,
  SyncStatus,
} from "@/components/alloy-access/StatusDisplay";

import { booleanOptions } from "@/pages/dev/badmagic/workspaces/shared";

import { useAppDrawer } from "@/components/layout/AppDrawer";

import { usePermissions } from "@/context/PolicyContext";

import { useDialog } from "@/context/dialog";

import { PathActions, Paths } from "@/lib/path";

import { TableFilters } from "@/modules/base/table/types";

import { DateTimeTypography } from "@/components/DateTimeTypography";

import { Hub } from "../hub/types";
import { HubQueries } from "../hub/queries";

import { SiteQueries } from "../site/queries";

import { SystemTypes } from "../site/types";

import {
  createActionsColumn,
  CreateButton,
  useTableUtils,
} from "../base/table/utils";

import { SiteFirmwareQueries } from "../site-firmware/queries";
import { SiteFirmware, UnsetSiteFirmware } from "../site-firmware/types";

import {
  Controller,
  ControllerModelOptions,
  ControllerProtocols,
  HumanReadableControllerModels,
} from "./types";
import { ControllerQueries } from "./queries";

interface ControllersTableProps {
  filters: Partial<TableFilters & Controller>;
}
export const ControllersTable = ({ filters }: ControllersTableProps) => {
  const { site_id } = useParams<{ site_id: string }>();
  const { data: controller_result } = ControllerQueries.useList(filters);
  const { data: site } = SiteQueries.useQuery({ id: Number(site_id) });
  const { data: activeSiteFirmware } =
    SiteFirmwareQueries.useListActiveSiteFirmware(site_id);

  const [firmwareVersionByModel, setFirmwareVersionByModel] = useState(
    buildFirmwareVersionByModel(activeSiteFirmware)
  );
  useEffect(() => {
    setFirmwareVersionByModel(buildFirmwareVersionByModel(activeSiteFirmware));
  }, [activeSiteFirmware]);

  const hubSelectProps = HubQueries.tableSelectProps({
    defaultParams: { site_id: site_id },
  });

  const drawer = useAppDrawer();
  const params = useParams();
  const history = useHistory();

  const { confirm } = useDialog();
  const [doDelete] = ControllerQueries.useDeleteMutation();

  const { canView, canCreate, canDelete, canUpdate } =
    usePermissions(ControllerQueries);

  const { numberOfLines } = useTableUtils();

  const tableProps = useTableQuery<
    any,
    Controller,
    ListQueryResponse<Controller>
  >({
    fetch: ControllerQueries.fetch,
    getQueryKey: ({
      filters: tableFilters,
      page,
      pageSize,
      sortColumn,
      sortDirection,
    }) => [
      ControllerQueries.queryKey,
      {},
      {
        ...filters,
        page,
        per_page: pageSize,
        sort: sortColumn,
        dir: sortDirection,
        ...tableFilters,
      },
    ],
    columns: [
      {
        name: "name",
        header: "Controller Name",
        render: ({ row }) => {
          return (
            <Typography numberOfLines={numberOfLines}>{row.name}</Typography>
          );
        },
        sortable: true,
        filter: (props: FilterProps) => {
          return <FormikTextInputField {...props} />;
        },
      },
      {
        name: "online",
        header: "Online",
        render: ({ row }) => {
          if (row.protocol == ControllerProtocols.whoo) {
            // For now, Whoo online status is fetched on-demand in the controller page
            return null;
          } else {
            return (
              <StatusDisplay
                status={row.online ? DeviceStatus.Online : DeviceStatus.Offline}
              />
            );
          }
        },
        sortable: true,
        filter: (props: FilterProps) => {
          return <FormikSelectField {...props} options={booleanOptions} />;
        },
      },
      {
        name: "model",
        header: "Model",
        render: ({ row }) => {
          return (
            <Typography numberOfLines={numberOfLines} type="body">
              {HumanReadableControllerModels[row.model as string]}
            </Typography>
          );
        },
        sortable: true,
        filter: (props: FilterProps) => {
          return (
            <FormikSelectField {...props} options={ControllerModelOptions} />
          );
        },
      },
      {
        name: "sync",
        header: "Sync Status",
        render: ({ row }) => {
          return (
            <StatusDisplay
              status={row.synced ? SyncStatus.Synced : SyncStatus.NotSynced}
            />
          );
        },
        sortable: false,
      },
      {
        name: "firmware_version",
        header: "Firmware",
        render: ({ row }) => {
          return (
            <>
              {shouldRenderFirmwareWarning(firmwareVersionByModel, row) && (
                <View style={styles.offsetRight}>
                  <StatusDot color="warning" />
                </View>
              )}
              <Typography type="body">{row.firmware_version}</Typography>
            </>
          );
        },
      },
      {
        name: "inserted_at",
        header: "Created At",
        render: ({ row }) => {
          return (
            <DateTimeTypography
              dateTime={row.inserted_at}
              timezone={site?.timezone}
            />
          );
        },
      },
      {
        name: "hub_id",
        header: "Hub",
        hidden: true,
        filterType: {
          type: "selectField",
          getOptionValue: (option: Hub) => {
            return option.id.toString();
          },
          getOptionLabel: (option: Hub) => {
            return `${option.serial} ${option.name ? `(${option.name})` : ""}`;
          },
          ...hubSelectProps,
        },
      },
      createActionsColumn<Controller>({
        shouldAdjustForPressIcon: canView,
        canDelete: canDelete && site?.system_type !== SystemTypes.Schlage,
        onDelete: async (row) => {
          const confirmed = await confirm({
            title: "Delete",
            description: "Are you sure you want to delete this Controller?",
            confirmText: "Delete",
            confirmType: "destructive",
            cancelText: "Cancel",
          });
          if (confirmed) {
            doDelete({ id: row.id });
          }
        },
        canUpdate,
        onUpdate: async (row) =>
          drawer.push(ControllerQueries.queryKey, {
            initialValues: row,
            params,
          }),
      }),
    ],
    defaultPageSize: 10,
  });
  return (
    <Table<Controller>
      title={"Controllers"}
      noRecordsText={`No Controllers Found`}
      action={
        <CreateButton
          // We only allow one salto controller per site.
          canCreate={
            (canCreate && site?.system_type !== SystemTypes.Salto) ||
            controller_result?.total_records === 0
          }
          onCreate={() => drawer.push(ControllerQueries.queryKey, { params })}
          text={"Add Controller"}
        />
      }
      onRowPress={
        canView
          ? (row) =>
              history.push(
                Paths.GetPath(ControllerQueries.queryKey, PathActions.View, {
                  ...filters,
                  ...row,
                })
              )
          : undefined
      }
      {...tableProps}
    />
  );
};

const styles = StyleSheet.create({
  offsetRight: { paddingRight: 8 },
});

function buildFirmwareVersionByModel(
  activeSiteFirmware: (SiteFirmware | UnsetSiteFirmware)[] | undefined
): Map<string, string> {
  if (!activeSiteFirmware || activeSiteFirmware.length == 0) {
    return new Map();
  }

  return activeSiteFirmware?.reduce((map, firmware) => {
    if ("version" in firmware) {
      map.set(firmware.model, firmware.version);
      return map;
    }

    return map;
  }, new Map<string, string>());
}

/**
 * @returns true if there is a set firmware for the controller's model that
 * differ's from the controller's version
 */
function shouldRenderFirmwareWarning(
  firmwareVersionByModel: Map<string, string>,
  controller: Controller
): boolean {
  if (!controller.model || !controller.firmware_version) return false;

  const setFirmwareVersion = firmwareVersionByModel.get(controller.model);

  return (
    !!setFirmwareVersion && setFirmwareVersion != controller.firmware_version
  );
}
