import { VStack, FormikTextInputField, FormikSelectField } from "@smartrent/ui";
import { Form } from "formik";
import * as yup from "yup";

import { FormProps, BaseForm } from "../base/Form";

import {
  Site,
  SiteTimezoneOptions,
  SystemTypeOptions,
  ValidSiteTimeZones,
  ValidSystemTypes,
} from "./types";
import { SiteQueries } from "./queries";

const validationSchema = yup.object().shape({
  name: yup.string().required("Required").max(40),
  system_type: yup.string().required("Required").oneOf(ValidSystemTypes),
  timezone: yup.string().required("Required").oneOf(ValidSiteTimeZones),
});

export const SiteForm = ({ initialValues, params }: FormProps<Site>) => {
  return (
    <BaseForm<Site>
      initialValues={initialValues}
      validationSchema={validationSchema}
      QueryClient={SiteQueries}
      params={params}
    >
      {() => (
        <Form>
          <VStack spacing={16}>
            <FormikTextInputField name="name" label="Site Name" required />
            <FormikSelectField
              disabled={!!initialValues?.id}
              name="system_type"
              label="System Type"
              options={SystemTypeOptions}
              required
            />
            <FormikSelectField
              name="timezone"
              label="Time Zone"
              options={SiteTimezoneOptions}
              required
            />
          </VStack>
        </Form>
      )}
    </BaseForm>
  );
};
