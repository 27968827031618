import { Image } from "react-native";

import { Link } from "react-router-dom";

import darkLogo from "@/assets/images/logo-dark.svg";

interface LogoProps {
  width?: string | number | undefined;
  size?: number;
}

export const Logo: React.FC<React.PropsWithChildren<LogoProps>> = ({
  width = "auto",
  size = 100,
}) => {
  return (
    <Link to="/">
      <Image
        style={{ width, height: size }}
        resizeMode="contain"
        source={darkLogo}
      />
    </Link>
  );
};
