import { StyleSheet } from "react-native";
import {
  Button,
  VStack,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from "@smartrent/ui";

import { useCallback } from "react";

import { Credential } from "../types";

import { CredentialQueries } from "../queries";

interface CredentialLostOrReturnedDialogProps {
  visible: boolean;
  credential: Credential | null;
  onClose: () => void;
}

export const CredentialLostOrReturnedDialog: React.FC<
  React.PropsWithChildren<CredentialLostOrReturnedDialogProps>
> = ({ visible, credential, onClose }) => {
  if (!credential) {
    return null;
  }

  return (
    <Dialog visible={visible} onClose={onClose} titleID="LostOrReturnedDialog">
      <DialogContent>
        <VStack spacing={20} style={styles.vStackWrapper}>
          <DialogTitle type="title3" style={styles.dialogTitle}>
            Delete Credential
          </DialogTitle>
          <Typography>Mark the credential as returned or lost.</Typography>
          <ReturnedButton credential={credential} onSuccess={onClose} />
          <LostButton credential={credential} onSuccess={onClose} />
        </VStack>
      </DialogContent>
    </Dialog>
  );
};

const styles = StyleSheet.create({
  vStackWrapper: {
    paddingVertical: 16,
    paddingHorizontal: 24,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  dialogTitle: {
    display: "flex",
    justifyContent: "center",
  },
});

interface CredentialButtonProps {
  credential: Credential;
  onSuccess: () => void;
}

const ReturnedButton: React.FC<
  React.PropsWithChildren<CredentialButtonProps>
> = ({ credential, onSuccess }) => {
  const [deleteCredential] = CredentialQueries.useDeleteMutation();

  const reportReturned = useCallback(
    async (credential: Credential) => {
      await deleteCredential(credential);
      onSuccess();
    },
    [deleteCredential, onSuccess]
  );

  return <Button onPress={(e) => reportReturned(credential)}>Returned</Button>;
};

const LostButton: React.FC<React.PropsWithChildren<CredentialButtonProps>> = ({
  credential,
  onSuccess,
}) => {
  const [lostCredential] = CredentialQueries.useLostCredentialMutation();

  const reportLost = useCallback(
    async (credential: any) => {
      await lostCredential(credential);
      onSuccess();
    },
    [onSuccess, lostCredential]
  );

  return (
    <Button onPress={(e) => reportLost(credential)} variation="outlined">
      Lost
    </Button>
  );
};
