import { useContext, useEffect } from "react";
import { View } from "react-native";

import { Paths } from "@/lib/path";
import { PageCtx } from "@/context/PageContext";
import { Site } from "@/modules/site/types";

import { SchedulesTable } from "@/modules/schedule/Table";

import { ScheduleNavigation } from "./Navigation";

interface SchedulesListPageProps {
  site: Site;
}

export const SchedulesListPage = ({ site }: SchedulesListPageProps) => {
  const { addBreadcrumb, breadCrumbs } = useContext(PageCtx);

  useEffect(() => {
    addBreadcrumb({
      label: `Schedules`,
      to: Paths.schedulesListPath(site.id),
    });
  }, [breadCrumbs, addBreadcrumb, site.id]);

  return (
    <View>
      <View>
        <ScheduleNavigation />
      </View>
      <SchedulesTable filters={{}} site={site} />
    </View>
  );
};
