import {
  ListQueryResponse,
  Pill,
  Table,
  Typography,
  useTableQuery,
} from "@smartrent/ui";
import { StyleSheet, View } from "react-native";

import { useParams } from "react-router-dom";

import { useAppDrawer } from "@/components/layout/AppDrawer";

import { useDialog } from "@/context/dialog";

import { usePermissions } from "@/context/PolicyContext";

import { TableFilters } from "@/modules/base/table/types";

import { Schedule } from "../schedule/types";
import { Site } from "../site/types";

import { CreateButton, createActionsColumn } from "../base/table/utils";

import { HumanReadableDayMask, ScheduleInterval } from "./types";
import { ScheduleIntervalQueries } from "./queries";

interface SchedulesTableProps {
  filters: Partial<TableFilters & ScheduleInterval>;
  schedule: Partial<Schedule>;
  site: Site;
}

export const ScheduleIntervalsTable = ({
  filters,
  schedule,
  site,
}: SchedulesTableProps) => {
  const drawer = useAppDrawer();
  const params = useParams();
  const { confirm } = useDialog();

  const { queryKey, fetch, useDeleteMutation } = ScheduleIntervalQueries;
  const { canCreate, canDelete, canUpdate } = usePermissions({ queryKey });

  const [deleteScheduleInterval] = useDeleteMutation();

  const tableProps = useTableQuery<
    any,
    ScheduleInterval,
    ListQueryResponse<ScheduleInterval>
  >({
    fetch,
    getQueryKey: ({
      filters: tableFilters,
      page,
      pageSize,
      sortColumn,
      sortDirection,
    }) => [
      queryKey,
      {},
      {
        ...filters,
        page,
        per_page: pageSize,
        sort: sortColumn,
        dir: sortDirection,
        ...tableFilters,
      },
    ],

    columns: [
      {
        name: "name",
        header: "Active Time",
        render: ({ row }) => {
          return (
            <View style={styles.activeTimeContainer}>
              <View style={styles.dayPillContainer}>
                {row.days_list?.map((day, index) => (
                  <Pill style={styles.dayPill} onPress={undefined} key={index}>
                    {HumanReadableDayMask[day]}
                  </Pill>
                ))}
              </View>
              <Typography style={styles.timeRangeText}>
                {row.start_time} - {row.end_time}
              </Typography>
            </View>
          );
        },
      },
      createActionsColumn<ScheduleInterval>({
        maxWidth: 80,
        canUpdate,
        onUpdate: async (row) =>
          drawer.push(queryKey, { initialValues: row, params }),
        canDelete,
        onDelete: async (row) => {
          const confirmed = await confirm({
            title: "Delete",
            description: `Are you sure you want to delete this Entry?`,
            confirmText: "Delete",
            confirmType: "destructive",
            cancelText: "Cancel",
          });

          if (confirmed) {
            deleteScheduleInterval({ id: row.id });
          }
        },
      }),
    ],
    defaultPageSize: 10,
  });

  return (
    <Table<ScheduleInterval>
      title={`${schedule.name} Intervals`}
      action={
        <CreateButton
          canCreate={canCreate && schedule.id != site.always_schedule_id}
          onCreate={() => drawer.push(queryKey, { params })}
          text={"Add Interval"}
        />
      }
      {...tableProps}
    />
  );
};

const styles = StyleSheet.create({
  activeTimeContainer: {},
  dayPillContainer: {
    flexDirection: "row",
  },
  dayPill: {
    marginTop: 4,
    marginLeft: 4,
  },
  timeRangeText: {
    marginTop: 4,
    marginLeft: 4,
  },
});
