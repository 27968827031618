import { PaginatedResponse } from "@smartrent/hooks";
import { FormikSelectField, useFormikSelectQuery } from "@smartrent/ui";

import { apiClient } from "@/lib/api";

import { API_BASE_PATH } from "@/modules/base/queries";
import { MemberQueries } from "@/modules/member/queries";
import { Member } from "@/modules/member/types";

interface MemberSelectFieldProps {
  assistiveText?: string;
  clearable?: boolean;
  filterParams?: any;
  initialValue?: number;
  label: string;
  name: string;
  required?: boolean;
  site_id: number;
}

export const MemberSelectField: React.FC<
  React.PropsWithChildren<MemberSelectFieldProps>
> = ({
  site_id,
  label,
  name,
  filterParams,
  initialValue,
  assistiveText,
  required,
}) => {
  const selectQueryProps = useFormikSelectQuery(
    (inputValue) => [
      MemberQueries.queryKey,
      { search: inputValue, site_id: site_id, ...filterParams },
    ],
    async ({ queryKey, pageParam = 1 }) => {
      const [, filters] = queryKey;
      return apiClient
        .get<PaginatedResponse<Member>>(
          `${API_BASE_PATH}/${MemberQueries.queryKey}`,
          {
            params: {
              page: pageParam,
              ...filters,
            },
          }
        )
        .then((response) => response.data);
    },
    {
      keepPreviousData: true,
    },
    /**
     * NOTE: (kjb) Have to do this wonky thing to check for an initial value as
     * js-shared currently fires off the `getInitialValue` function even if no initial value is passed
     */
    Object.assign(
      {
        inputDebounceInterval: 250,
      },
      initialValue
        ? {
            getInitialValue: async () => {
              return apiClient
                .get<Member>(
                  `${API_BASE_PATH}/${MemberQueries.queryKey}/${initialValue}`
                )
                .then((r) => r.data);
            },
            getInitialQueryKey: () => [
              "initialMemberValue",
              { site_id: site_id, initialValue: initialValue },
            ],
          }
        : {}
    )
  );

  return (
    <FormikSelectField<Member>
      name={name}
      label={label}
      getOptionValue={(option) => option.id}
      getOptionLabel={(option) => `${option.last_name}, ${option.first_name}`}
      required={required}
      assistiveText={assistiveText}
      {...selectQueryProps}
    />
  );
};
