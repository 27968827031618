import { useContext, useEffect } from "react";

import { FirmwareTable } from "@/modules/firmware/Table";
import { PageCtx } from "@/context/PageContext";
import { Paths } from "@/lib/path";

export const FirmwareListPage: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const { addBreadcrumb, breadCrumbs } = useContext(PageCtx);

  useEffect(() => {
    addBreadcrumb({
      label: `Firmware`,
      to: Paths.firmwareListPath(),
    });
  }, [breadCrumbs, addBreadcrumb]);

  return <FirmwareTable filters={{}} />;
};
