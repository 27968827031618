import { Typography, StatusDot, TypographyProps } from "@smartrent/ui";
import { View, StyleSheet, StyleProp, ViewStyle } from "react-native";

import { toTitleCase } from "../../lib/helpers";

export enum DeviceStatus {
  Online = "online",
  Offline = "offline",
  Pending = "pending",
}

export enum SyncStatus {
  Synced = "synced",
  NotSynced = "not synced",
}

enum IssueStatus {
  Info = "info",
  Warn = "warn",
  Alert = "alert",
}

const statusColor = (status: DeviceStatus | SyncStatus | IssueStatus) => {
  switch (status) {
    case DeviceStatus.Online:
      return "success";
    case DeviceStatus.Offline:
      return "error";
    case DeviceStatus.Pending:
      return "warning";
    case SyncStatus.Synced:
      return "success";
    case SyncStatus.NotSynced:
      return "error";
    case IssueStatus.Info:
      return "info";
    case IssueStatus.Warn:
      return "warning";
    case IssueStatus.Alert:
      return "error";
  }
  return "grayscale";
};
export const StatusDisplay = ({
  status,
  message,
  style,
  numberOfLines,
}: {
  status: DeviceStatus | SyncStatus | IssueStatus;
  message?: string;
  style?: StyleProp<ViewStyle>;
  numberOfLines?: TypographyProps["numberOfLines"];
}) => {
  return (
    <View style={[styles.root, style]}>
      <View style={styles.offsetRight}>
        <StatusDot color={statusColor(status)} />
      </View>
      <Typography numberOfLines={numberOfLines}>
        {toTitleCase(message || status)}
      </Typography>
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    flexShrink: 1,
    flexDirection: "row",
    flexAlign: "center",
    alignItems: "center",
  },
  offsetRight: { paddingRight: 8 },
});
