import { useContext, useEffect } from "react";

import { Site } from "@/modules/site/types";
import { OperatorsTable } from "@/modules/operators/Table";
import { PageCtx } from "@/context/PageContext";
import { Paths } from "@/lib/path";

interface OperatorsListPageProps {
  site: Site;
}

export const OperatorsListPage = ({ site }: OperatorsListPageProps) => {
  const { addBreadcrumb, breadCrumbs } = useContext(PageCtx);

  useEffect(() => {
    addBreadcrumb({
      label: `Operators`,
      to: Paths.cardsListPath(site.id),
    });
  }, [breadCrumbs, addBreadcrumb, site.id]);

  return <OperatorsTable filters={{ site_id: site.id }} />;
};
