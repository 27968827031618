import { useWindowDimensionsQuery } from "@smartrent/hooks";
import { Grid } from "@smartrent/ui";

export const ResponsiveGrid: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const { small, medium } = useWindowDimensionsQuery();

  return (
    <Grid numColumns={small ? 1 : medium ? 2 : 4} gap={8}>
      {children}
    </Grid>
  );
};
