import { Site } from "@/modules/site/types";
import { UserActivityLogsTable } from "@/modules/user-activity-logs/UserActivityLogTable";

interface UserActivityLogsListPage {
  site: Site;
}

export const UserActivityLogsListPage: React.FC<
  React.PropsWithChildren<UserActivityLogsListPage>
> = ({ site }) => {
  return <UserActivityLogsTable filters={{ site_id: site.id }} site={site} />;
};
