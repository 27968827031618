import { BaseDrawer } from "../base/Drawer";

import { ScheduleForm } from "./Form";
import { Schedule } from "./types";

interface ScheduleDrawerProps {
  initialValues?: Schedule;
  params: { site_id: number };
}

export const ScheduleDrawer: React.FC<
  React.PropsWithChildren<ScheduleDrawerProps>
> = ({ initialValues, params }) => {
  return (
    <BaseDrawer
      title={initialValues?.id ? initialValues?.name : "New Schedule"}
      subtitle={initialValues?.id ? "Edit Schedule" : null}
    >
      <ScheduleForm initialValues={initialValues} params={params} />
    </BaseDrawer>
  );
};
