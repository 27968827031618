import { VStack } from "@smartrent/ui";
import { Form, FormikProps } from "formik";
import * as yup from "yup";
import { View } from "react-native";

import { startOfDay, startOfTomorrow } from "date-fns";

import { toTitleCase } from "@/lib/helpers";
import { FormikDateTimePicker } from "@/components/form/FormikDateTimePicker";
import { FormikButtonGroup } from "@/components/form/FormikButtonGroup";

import { Site } from "@/modules/site/types";

import { BaseForm } from "../../base/Form";

import {
  CredentialDurationOptions,
  CredentialType,
  Credential,
  CredentialDurationSelectOptions,
} from "../types";
import { CredentialQueries } from "../queries";

interface CredentialFormProps {
  initialValues?: Credential;
  params: {
    site_id: number;
    member_id: number;
  };
  site: Site;
}

export const SaltoCredentialForm: React.FC<
  React.PropsWithChildren<CredentialFormProps>
> = ({ initialValues, params, site }) => {
  const credentialTypeOptions = [
    {
      label: toTitleCase(CredentialType.card),
      value: CredentialType.card,
    },
    {
      label: toTitleCase(CredentialType.mobile),
      value: CredentialType.mobile,
    },
  ];

  const validationSchema = yup.object().shape({
    member_id: yup.number().required(),
    type: yup
      .string()
      .required()
      .oneOf([CredentialType.card, CredentialType.mobile]),
    start_at: yup
      .date()
      .nullable()
      .when("duration_type", {
        is: CredentialDurationOptions.temporary,
        then: yup.date().required("You must provide a start date and time"),
      }),
    end_at: yup
      .date()
      .nullable()
      .when("duration_type", {
        is: CredentialDurationOptions.temporary,
        then: yup.date().required("You must provide an end date and time"),
      })
      .min(yup.ref("start_at"), "End Date can't be before Start Date"),
  });

  return (
    <BaseForm<Credential>
      initialValues={{
        type: initialValues?.type || CredentialType.card,
        start_at:
          initialValues?.start_at || startOfDay(new Date()).toISOString(),
        end_at: initialValues?.end_at || startOfTomorrow().toISOString(),
        member_id: params.member_id,
        duration_type: CredentialDurationOptions.permanent,
        ...initialValues,
      }}
      validationSchema={validationSchema}
      QueryClient={CredentialQueries}
      params={params}
    >
      {({ values, setFieldValue }: FormikProps<Credential>) => {
        return (
          <Form>
            <VStack spacing={16}>
              <FormikButtonGroup<CredentialType>
                options={credentialTypeOptions}
                name="type"
                onChange={() => {
                  // If you remove this, the card format component explodes.
                  setFieldValue("format", "");
                }}
              />

              <FormikButtonGroup<CredentialDurationOptions>
                options={CredentialDurationSelectOptions}
                name="duration_type"
                onChange={() => {
                  // If you remove this, the card format component explodes.
                  setFieldValue("start_at", null);
                  setFieldValue("end_at", null);
                }}
              />
              {values.duration_type == CredentialDurationOptions.temporary && (
                <View>
                  <FormikDateTimePicker
                    labelPrefix="Start"
                    name="start_at"
                    required
                  />
                  <FormikDateTimePicker
                    labelPrefix="End"
                    name="end_at"
                    required
                  />
                </View>
              )}
            </VStack>
          </Form>
        );
      }}
    </BaseForm>
  );
};
