import { HStack, Skeleton, Typography, useTheme } from "@smartrent/ui";
import { StyleSheet } from "react-native";

import { useContext } from "react";

import { OrganizationQueries } from "@/modules/organization/queries";

import { AuthContext } from "@/context/Auth";

import { SystemOverviewButton } from "./SystemOverviewButton";
import { SignOutButton } from "./SignOutButton";

export const OrganizationBanner: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const { user } = useContext(AuthContext);
  const { data: organization, isLoading: orgLoading } =
    OrganizationQueries.useQuery({
      id: user?.organization_id,
    });
  const { colors } = useTheme();

  return (
    <HStack
      align="center"
      style={[
        styles.root,
        {
          borderBottomColor: colors.border,
        },
      ]}
    >
      <HStack align="center" style={styles.content}>
        {orgLoading ? (
          <Skeleton width={100} textType={"title3"} />
        ) : (
          <Typography type={"title3"} style={{ color: colors.textPrimary }}>
            {organization?.name}
          </Typography>
        )}
      </HStack>

      <HStack spacing={12} align="center" style={styles.content}>
        <SystemOverviewButton />
        <SignOutButton />
      </HStack>
    </HStack>
  );
};

const styles = StyleSheet.create({
  root: {
    padding: 8,
    justifyContent: "space-between",
    flexWrap: "wrap",
    borderBottomColor: "gray",
    borderBottomWidth: 1,
  },
  content: { margin: 8 },
});
