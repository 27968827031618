import { useEffect, useState } from "react";
import {
  AssistiveText,
  Button,
  Chip,
  HStack,
  ListItemContainer,
  ListItemLabelValue,
  Panel,
  PanelBody,
  PanelHeader,
  PanelHeaderTitle,
  PanelHeaderTitleWithStatusBadge,
  Typography,
  VStack,
  useTheme,
  useToast,
} from "@smartrent/ui";
import { View, StyleSheet, TouchableOpacity } from "react-native";

import { PencilSolid } from "@smartrent/icons";

import { humanizeCamelCase } from "@/lib/helpers";
import {
  Controller,
  ControllerProtocols,
  HumanReadableControllerModels,
} from "@/modules/controller/types";
import { QueryKeys } from "@/types";
import { useAppDrawer } from "@/components/layout/AppDrawer";
import { Site, SystemTypes } from "@/modules/site/types";
import { HubNameAndStatusIndicator } from "@/modules/hub/components/HubStatusIndicator";
import { Hub } from "@/modules/hub/types";
import { SiteFirmwareQueries } from "@/modules/site-firmware/queries";
import { ControllerQueries } from "@/modules/controller/queries";
import { SiteFirmware } from "@/modules/site-firmware/types";
import { LoadingIndicator } from "@/components/LoadingIndicator";
import { AccessLevel } from "@/modules/access-level/types";
import { Paths } from "@/lib/path";

interface ControllerInformationPanelProps {
  controller: Controller;
  site: Site;
}

const InformationItem = ({
  label,
  value,
  rightDetail,
}: {
  label: string;
  value?: string | number | JSX.Element;
  rightDetail?: React.ReactNode;
}) => {
  if (!value) return null;
  return (
    <ListItemContainer style={styles.informationRow} rightDetail={rightDetail}>
      <ListItemLabelValue
        label={label}
        value={value ? (value as string) : "-"}
        style={styles.height100}
      />
    </ListItemContainer>
  );
};

export const ControllerInformationPanel = ({
  controller,
  site,
}: ControllerInformationPanelProps) => {
  const [version, setVersion] = useState("");
  const [build, setBuild] = useState("");
  const [isOnline, setIsOnline] = useState(controller.online);
  const [onlineDetails, setOnlineDetails] = useState<string>();
  const setToast = useToast();

  // This entire useEffect block is only relevant to Whoo controllers
  useEffect(() => {
    if (controller.protocol != ControllerProtocols.whoo) return;

    const abortController = new AbortController();
    ControllerQueries.fetchWhooConfig(site.id, controller.id, {
      signal: abortController.signal,
    })
      .then((result) => {
        if (result) {
          setVersion(result.version);
          setBuild(`${result.build}`);
          setIsOnline(result.is_online);
          setOnlineDetails(result.online_details);
        } else {
          setVersion("Failed to fetch");
          setBuild("Failed to fetch");
          setOnlineDetails("Failed to fetch");
        }
      })
      .catch((error) => {
        if (error.message !== "canceled") {
          setToast({
            status: "error",
            message: error.message,
            title: "Error Fetching Whoo Config",
          });
        }
      });

    return () => {
      abortController.abort();
    };
  }, [controller, site, setToast]);

  const { colors } = useTheme();
  const drawer = useAppDrawer();

  const PanelActions = () => {
    if (site.system_type === SystemTypes.Schlage) {
      return null;
    }

    return (
      <View style={styles.panelHeaderActionsContainer}>
        <TouchableOpacity
          activeOpacity={0.7}
          style={styles.panelHeaderAction}
          onPress={() => {
            drawer.push(QueryKeys.Controllers, {
              initialValues: controller,
              params: { site_id: site.id },
            });
          }}
        >
          <PencilSolid color={colors.gray600} />
        </TouchableOpacity>
      </View>
    );
  };

  return (
    <Panel>
      <PanelHeader EndAdornment={PanelActions()}>
        <OnlineStatusBadge
          controller={controller}
          version={version}
          isOnline={isOnline}
        />
      </PanelHeader>

      <PanelBody>
        <VStack>
          <InformationItem
            label="Model"
            value={
              controller.model
                ? HumanReadableControllerModels[controller.model]
                : ""
            }
          />
          {controller.protocol !== ControllerProtocols.whoo && (
            <InformationItem
              label="Hub"
              value={<HubNameAndStatusIndicator hub={controller.hub as Hub} />}
            />
          )}
          <InformationItem
            label="Serial Number"
            value={controller.serial_number}
          />
          {!controller.whoo_intercom && (
            // We fetch this directly from Whoo for Whoo intercoms
            <FirmwareInformationItem controller={controller} site={site} />
          )}
          <InformationItem label="OEM Code" value={controller.oem_code} />
          <InformationItem label="SCP ID" value={controller.scp_id} />
          <InformationItem label="MAC address" value={controller.mac_address} />
          <InformationItem label="TLS Status" value={controller.tls_status} />
          <InformationItem
            label="Pairing Code"
            value={
              controller.whoo_intercom
                ? `${
                    parseInt(controller.whoo_intercom?.remote_entrance_id) +
                    10000
                  }`
                : ""
            }
          />
          {controller.protocol == ControllerProtocols.whoo ? (
            <>
              <InformationItem
                label="Directory Access Levels"
                value={
                  <DirectoryAccessLevels
                    accessLevels={controller.displayed_access_levels}
                  />
                }
              />

              <View style={styles.assistiveTextContainer}>
                <AssistiveText>
                  <Typography color="gray600" variation="regular.body.small">
                    Selected access levels won't automatically activate PIN
                    codes for members within those levels. To activate PIN codes
                    for the intercom follow the steps here:{" "}
                    <Typography
                      color="primary"
                      variation="regular.body.small"
                      href="https://docs.alloyaccess.com/doors/alloy-intercom-guide#configure-pin-access-for-members"
                      hrefAttrs={{
                        target: "_blank",
                        rel: "noreferrer",
                      }}
                    >
                      Intercom Pin Code Activation
                    </Typography>
                  </Typography>
                </AssistiveText>
              </View>

              <WhooInformationItems
                build={build}
                version={version}
                onlineDetails={onlineDetails}
              />
            </>
          ) : null}
        </VStack>
      </PanelBody>
    </Panel>
  );
};

const DirectoryAccessLevels = ({
  accessLevels,
}: {
  accessLevels: AccessLevel[];
}) => {
  return (
    <HStack wrap={true} spacing={4} align="start">
      {accessLevels.map((accessLevel: AccessLevel, index: number) => {
        return (
          <View key={accessLevel.id} style={styles.pill}>
            <Chip
              corners="pill"
              href={Paths.accessLevelShowPath(
                accessLevel.site_id,
                accessLevel.id
              )}
            >
              {accessLevel?.name || "-"}
            </Chip>
          </View>
        );
      })}
    </HStack>
  );
};

const OnlineStatusBadge = ({
  controller,
  version,
  isOnline,
}: {
  controller: Controller;
  version?: string;
  isOnline?: boolean;
}) => {
  if (controller.protocol == ControllerProtocols.whoo && !version) {
    return <PanelHeaderTitle title={`${controller.name}`} />;
  } else {
    return (
      <PanelHeaderTitleWithStatusBadge
        title={`${controller.name}`}
        status={isOnline ? "success" : "error"}
        statusLabel={isOnline ? "online" : "offline"}
      />
    );
  }
};

const WhooInformationItems = ({
  build,
  version,
  onlineDetails,
}: {
  build?: string;
  version?: string;
  onlineDetails?: string;
}) => {
  if (build || version || onlineDetails) {
    return (
      <>
        <InformationItem label="Firmware Version" value={version} />
        <InformationItem label="Firmware Build" value={build} />
        <InformationItem
          label="Connectivity"
          value={humanizeCamelCase(onlineDetails)}
        />
      </>
    );
  } else {
    return <LoadingIndicator />;
  }
};

const FirmwareInformationItem = ({
  controller,
  site,
}: {
  controller: Controller;
  site: Site;
}) => {
  const [isUpdatingFirmware, setIsUpdatingFirmware] = useState(false);
  const [newFirmware, setNewFirmware] = useState<SiteFirmware>();
  const { data: activeSiteFirmware } =
    SiteFirmwareQueries.useListActiveSiteFirmware(site.id);

  useEffect(() => {
    if (controller.protocol == "MPL") {
      const firmwareVersionForControllerModel = activeSiteFirmware?.find(
        (siteFirmware) => siteFirmware.model == controller.model
      );

      if (
        firmwareVersionForControllerModel &&
        "version" in firmwareVersionForControllerModel &&
        firmwareVersionForControllerModel.version != controller.firmware_version
      ) {
        setNewFirmware(firmwareVersionForControllerModel);
      }
    }
  }, [activeSiteFirmware, controller]);

  const [updateControllerFirmware] =
    ControllerQueries.useUpdateControllerFirmwareMutation();

  return (
    <InformationItem
      label="Firmware Version"
      value={controller.firmware_version}
      rightDetail={
        newFirmware && !isUpdatingFirmware ? (
          <Button
            onPress={() => {
              // We want to hide this button after clicking, only visible again on a reload of the page
              setIsUpdatingFirmware(true);

              updateControllerFirmware({
                controllerId: controller.id,
                firmwareId: newFirmware.firmware_id,
              });
            }}
            variation="outlined"
            size="x-small"
          >
            {`Update ${newFirmware.version}`}
          </Button>
        ) : null
      }
    />
  );
};

const styles = StyleSheet.create({
  assistiveTextContainer: {
    paddingLeft: 16,
    paddingRight: 16,
  },
  height100: {
    height: "100%",
  },
  informationRow: {
    justifyContent: "space-between",
    flexDirection: "row",
  },
  panelHeaderActionsContainer: {
    flexDirection: "row",
  },
  panelHeaderAction: {
    paddingLeft: 8,
  },
  pill: { paddingBottom: 4 },
});
