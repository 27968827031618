import { Refresh } from "@smartrent/icons";

import {
  FilterProps,
  FormikTextInputField,
  Tooltip,
  ListQueryResponse,
  Table,
  Typography,
  useTableQuery,
} from "@smartrent/ui";

import { useCallback } from "react";

import { View } from "react-native";

import {
  DATE_TIME_FORMAT,
  DATE_FORMAT,
  formatDateTime,
} from "@/lib/formatters";

import {
  StatusDisplay,
  SyncStatus,
} from "@/components/alloy-access/StatusDisplay";

import { usePermissions } from "@/context/PolicyContext";

import { TableFilters } from "@/modules/base/table/types";

import { ControllerQueries } from "../controller/queries";
import { Site } from "../site/types";

import { ResponsiveButton } from "../base/table/ResponsiveButton";

import { Door, SchlageFirmware } from "./types";
import { DoorQueries } from "./queries";
import { BatteryStatus } from "./components/BatteryStatus";

interface SchlageDoorsTableProps {
  filters: Partial<TableFilters & Door>;
  site: Site;
}

const Firmware = ({
  firmware,
}: {
  firmware: SchlageFirmware | null | undefined;
}) => {
  const lock = firmware && firmware.lock;

  if (!lock) {
    return <Typography> NA </Typography>;
  }
  const firmwareString = `lock: ${firmware.lock}, main: ${firmware.main}, mainBl: ${firmware.mainBl}, ble: ${firmware.ble}`;
  return (
    <View>
      <View>
        <Tooltip name="schlage_firmware" title={firmwareString}>
          <View>
            <Typography> {firmware.lock} </Typography>
          </View>
        </Tooltip>
      </View>
    </View>
  );
};

export const SchlageDoorsTable = ({
  filters,
  site,
}: SchlageDoorsTableProps) => {
  const { canView } = usePermissions(DoorQueries);
  const { data: controllers } = ControllerQueries.useList({
    site_id: site.id,
  });

  const [startSync] = ControllerQueries.useStartSyncMutation();

  const syncAction = useCallback(() => {
    if (controllers && controllers.records.length > 0) {
      const controller = controllers.records[0];
      startSync({ controller_id: controller.id, force: false });
    }
  }, [controllers, startSync]);

  const tableProps = useTableQuery<any, Door, ListQueryResponse<Door>>({
    fetch: DoorQueries.fetch,
    getQueryKey: ({
      filters: tableFilters,
      page,
      pageSize,
      sortColumn,
      sortDirection,
    }) => [
      DoorQueries.queryKey,
      {},
      {
        ...filters,
        page,
        per_page: pageSize,
        sort: sortColumn,
        dir: sortDirection,
        ...tableFilters,
        site_id: site.id,
      },
    ],
    columns: [
      {
        name: "name",
        header: "Name",
        render: function NameRow({ row }) {
          return <Typography>{row.name}</Typography>;
        },
        sortable: true,
        filter: (props: FilterProps) => {
          return <FormikTextInputField {...props} />;
        },
      },
      {
        name: "serial_number",
        header: "Serial Number",
        render: function SerialRow({ row }) {
          const cleaned_serial_number = (row.serial_number || "NA").replace(
            /^0+/,
            ""
          );
          return <Typography>{cleaned_serial_number}</Typography>;
        },
        sortable: true,
        filter: (props: FilterProps) => {
          return <FormikTextInputField {...props} />;
        },
      },
      {
        name: "firmware",
        header: "Firmware Version",
        render: ({ row }) => <Firmware firmware={row.firmware} />,
      },
      {
        name: "battery",
        header: "Battery",
        render: ({ row }) => (
          <BatteryStatus
            battery={row.battery}
            batteryUpdated={formatDateTime(
              row.battery_updated,
              site?.timezone,
              DATE_FORMAT
            )}
          />
        ),
      },
      {
        name: "synced_at",
        header: "Synced",
        render: function SyncedRow({ row }) {
          let statusText = "Needs Sync";
          let status = SyncStatus.NotSynced;
          if (row.synced_at != null) {
            const synced_at = `${row.synced_at}Z`;
            statusText = `Synced at \n${formatDateTime(
              synced_at,
              site?.timezone,
              DATE_TIME_FORMAT
            )}`;
            status = SyncStatus.Synced;
          }
          return <StatusDisplay status={status} message={statusText} />;
        },
      },
    ],
    defaultPageSize: 10,
  });
  return (
    <Table<Door>
      title="Doors"
      noRecordsText="No Doors Found"
      action={
        canView ? (
          <ResponsiveButton
            icon={Refresh}
            onPress={syncAction}
            text="Refresh Doors"
          />
        ) : undefined
      }
      {...tableProps}
    />
  );
};
