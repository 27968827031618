import { Form, Formik, FormikHelpers } from "formik";
import * as yup from "yup";
import { FormikSelectField, FormikSubmit, VStack } from "@smartrent/ui";

import {
  SMRTPConfig,
  TimeZoneOptions,
  ValidTimeZoneOptions,
} from "@/modules/controller/configurations/smrtp";

interface Props {
  handleSubmit: (
    values: SMRTPConfig,
    helpers: FormikHelpers<SMRTPConfig>
  ) => Promise<void>;
  config: SMRTPConfig;
}

const validationSchema = yup.object().shape({
  timezone: yup.string().oneOf(ValidTimeZoneOptions).label("Time Zone"),
});

export const SmrtpControllerConfigurationForm = ({
  config,
  handleSubmit,
}: Props) => {
  return (
    <Formik<SMRTPConfig>
      initialValues={{
        timezone: config?.timezone,
      }}
      enableReinitialize={true}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ dirty }) => {
        return (
          <Form>
            <VStack spacing={16}>
              <FormikSelectField
                name="timezone"
                label={"Timezone"}
                options={TimeZoneOptions}
              />

              {dirty && <FormikSubmit label="Set" />}
            </VStack>
          </Form>
        );
      }}
    </Formik>
  );
};
