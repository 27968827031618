import { Route, Switch, useRouteMatch } from "react-router-dom";

import { useContext, useEffect } from "react";

import { Site } from "@/modules/site/types";

import { Paths } from "@/lib/path";

import { PageCtx } from "@/context/PageContext";

import { SchedulesListPage } from "./SchedulesListPage";
import { ScheduleShowPage } from "./ScheduleShowPage";

interface SchedulesRouterProps {
  site: Site;
}

export const SchedulesRouter = ({ site }: SchedulesRouterProps) => {
  const { path } = useRouteMatch();
  const { addBreadcrumb } = useContext(PageCtx);

  useEffect(() => {
    addBreadcrumb({
      label: `Schedules`,
      to: Paths.schedulesListPath(site.id),
    });
  }, [addBreadcrumb, site.id]);

  return (
    <Switch>
      <Route exact path={`${path}`}>
        <SchedulesListPage site={site} />
      </Route>
      <Route exact path={`${path}/:schedule_id`}>
        <ScheduleShowPage site={site} />
      </Route>
    </Switch>
  );
};
