import { StyleSheet, View } from "react-native";
import { BatteryLow, BatteryMid, BatteryFull } from "@smartrent/icons";
import { useTheme, Typography, Tooltip } from "@smartrent/ui";

interface BatteryLevelProps {
  battery: string | number | null | undefined;
  batteryUpdated: string;
}

const batteryLevelByVolts = (battery: number) => {
  if (battery >= 4.6) {
    return "Normal";
  }
  if (battery < 4.6 && battery >= 4.3) {
    return "Low";
  }
  return "Critical";
};

const batteryComponents = {
  Normal: BatteryFull,
  Low: BatteryMid,
  Critical: BatteryLow,
};

const NullBattery: React.VFC = () => {
  const lastUpdated = "Unknown time since battery was last updated";

  return (
    <View style={styles.batteryContainer}>
      <View>
        <Tooltip name="battery_updated" title={lastUpdated}>
          <View>
            <Typography> NA </Typography>
          </View>
        </Tooltip>
      </View>
    </View>
  );
};

export const BatteryStatus: React.FC<
  React.PropsWithChildren<BatteryLevelProps>
> = ({ battery, batteryUpdated }) => {
  const { colors } = useTheme();

  if (!battery) {
    return <NullBattery />;
  }

  const lastUpdated =
    batteryUpdated != ""
      ? `Last updated: ${batteryUpdated}`
      : "Unknown time since battery was last updated";

  const batteryLevel = batteryLevelByVolts(Number(battery));
  const iconColor = batteryLevel == "Normal" ? colors.black : colors.error;
  const IconComponent = batteryComponents[batteryLevel];

  return (
    <View style={styles.batteryContainer}>
      <View>
        <Tooltip name="battery_updated" title={lastUpdated}>
          <View>
            <IconComponent color={iconColor} />
          </View>
        </Tooltip>
      </View>
      <Typography> {batteryLevel} </Typography>
    </View>
  );
};

const styles = StyleSheet.create({
  batteryContainer: {
    flexDirection: "row",
    marginLeft: "-6px",
  },
});
