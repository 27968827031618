import { useContext, useEffect } from "react";
import { Panel, PanelBody, PanelHeader, PanelHeaderTitle } from "@smartrent/ui";

import { FirmwareSettings } from "@/modules/site/components/FirmwareSettings";
import { Site } from "@/modules/site/types";

import { PageCtx } from "@/context/PageContext";
import { Paths } from "@/lib/path";

interface FirmwareSiteSettingsPageProps {
  site: Site;
}

export const FirmwareSiteSettingsPage = ({
  site,
}: FirmwareSiteSettingsPageProps) => {
  const { addBreadcrumb, breadCrumbs } = useContext(PageCtx);

  useEffect(() => {
    addBreadcrumb({
      label: `Firmware Settings`,
      to: Paths.siteSettingsGeneralPath(site.id),
    });
    addBreadcrumb({
      label: `Site Settings`,
      to: Paths.siteSettingsPath(site.id),
    });
  }, [breadCrumbs, addBreadcrumb, site.id]);

  return (
    <Panel>
      <PanelHeader>
        <PanelHeaderTitle title={`Firmware Settings`} />
      </PanelHeader>
      <PanelBody>
        <FirmwareSettings site={site} />
      </PanelBody>
    </Panel>
  );
};
