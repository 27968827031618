import { Form, Formik, FormikHelpers } from "formik";
import * as yup from "yup";
import { FormikSubmit, FormikTextInputField, VStack } from "@smartrent/ui";

import { SHIPConfig } from "@/modules/controller/configurations/ship";

interface Props {
  handleSubmit: (
    values: SHIPConfig,
    helpers: FormikHelpers<SHIPConfig>
  ) => Promise<void>;
  config: SHIPConfig;
}

const validationSchema = yup.object().shape({
  host: yup.string().required().label("Host"),
  port: yup.number().required().label("Port"),
});

export const ShipControllerConfigurationForm = ({
  config,
  handleSubmit,
}: Props) => {
  return (
    <Formik<SHIPConfig>
      initialValues={{
        host: config?.host,
        port: config?.port,
      }}
      enableReinitialize={true}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ dirty }) => {
        return (
          <Form>
            <VStack spacing={16}>
              <FormikTextInputField name="host" label={"Host"} />
              <FormikTextInputField name="port" label={"Port"} />
              {dirty && <FormikSubmit label="Set" />}
            </VStack>
          </Form>
        );
      }}
    </Formik>
  );
};
