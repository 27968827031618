export enum SMRTPCommand {
  factory_reset = "factory_reset",
}

export const SMRTPCommandParams: {
  [key in SMRTPCommand]: {
    label: string;
    form?: (params: any) => JSX.Element | null;
  };
} = {
  [SMRTPCommand.factory_reset]: {
    label: "Factory Reset",
  },
};

const SMRTPCommandList = Object.keys(SMRTPCommandParams);
export const SMRTPCommandOptions: Array<{ label: string; value: string }> =
  SMRTPCommandList.map((command: string) => {
    return {
      label: SMRTPCommandParams[command as SMRTPCommand].label,
      value: command,
    };
  }).filter((val) => !!val) as Array<{ label: string; value: string }>;
