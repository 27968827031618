import { Route, Switch, useRouteMatch } from "react-router-dom";

import { useCallback, useContext, useEffect } from "react";

import { View, StyleSheet } from "react-native";

import { CloudUpload, Community, FlagSolid } from "@smartrent/icons";

import { LinkData } from "@/components/alloy-access/NavDrawer";
import { User } from "@/modules/user/types";

import squares from "@/assets/images/squares.svg";

import { NavigationLinksCtx } from "@/context/NavigationLinksContext";

import { Paths } from "@/lib/path";

import { OrganizationRouter } from "./organization";
import { FeatureFlagRouter } from "./feature-flag";
import { FirmwareRouter } from "./firmware";

interface SuperAdminRouterProps {
  user: User;
}

export const SuperAdminRouter: React.FC<
  React.PropsWithChildren<SuperAdminRouterProps>
> = () => {
  const { path } = useRouteMatch();
  const { setNavigationLinks } = useContext(NavigationLinksCtx);

  const NavLinks = useCallback(() => {
    return [
      {
        label: "Super Admin",
        icon: squares,
        items: [
          {
            url: `/superadmin`,
            label: "Organizations",
            icon: Community,
          },
          {
            url: Paths.featureFlagListPath(),
            label: "Feature Flags",
            icon: FlagSolid,
          },
          {
            url: Paths.firmwareListPath(),
            label: "Firmware",
            icon: CloudUpload,
          },
        ] as LinkData[],
      },
    ];
  }, []);

  useEffect(() => {
    setNavigationLinks(NavLinks());
  }, [setNavigationLinks, NavLinks]);

  return (
    <View style={styles.root}>
      <Switch>
        <Route path={`${path}/organizations`}>
          <OrganizationRouter />
        </Route>
        <Route path={`${path}/feature-flags`}>
          <FeatureFlagRouter />
        </Route>
        <Route path={`${path}/firmware`}>
          <FirmwareRouter />
        </Route>
        <Route exact path={`${path}`}>
          <OrganizationRouter />
        </Route>
      </Switch>
    </View>
  );
};
const styles = StyleSheet.create({
  root: {
    padding: 16,
  },
});
