import { useTheme } from "@smartrent/ui";
import { View, StyleSheet, TouchableOpacity } from "react-native";
import { PencilSolid, TrashX } from "@smartrent/icons";
import { useHistory } from "react-router-dom";

import { Site, SystemTypes } from "@/modules/site/types";
import { QueryKeys } from "@/types";
import { useAppDrawer } from "@/components/layout/AppDrawer";
import { useDialog } from "@/context/dialog";
import { PathActions, Paths } from "@/lib/path";
import { DoorQueries } from "@/modules/door/queries";
import { ElevatorQueries } from "@/modules/elevator/queries";
import { usePermissions } from "@/context/PolicyContext";
import { Elevator } from "@/modules/elevator/types";

interface ElevatorInformationPanelActionsProps {
  site: Site;
  elevator: Elevator;
}

export const ElevatorInformationPanelActions = ({
  site,
  elevator,
}: ElevatorInformationPanelActionsProps) => {
  const { colors } = useTheme();
  const drawer = useAppDrawer();
  const { confirm } = useDialog();
  const [doDelete] = ElevatorQueries.useDeleteMutation();
  const history = useHistory();

  const { canDelete, canUpdate } = usePermissions(DoorQueries);

  const canEditDoor = canUpdate && site.system_type === SystemTypes.Alloy;
  const canDeleteDoor = canDelete && site.system_type === SystemTypes.Alloy;

  return (
    <View style={styles.panelHeaderActionsContainer}>
      {canEditDoor ? (
        <TouchableOpacity
          activeOpacity={0.7}
          style={styles.panelHeaderAction}
          onPress={() =>
            drawer.push(ElevatorQueries.queryKey, {
              initialValues: elevator,
              params: { site_id: site.id },
            })
          }
        >
          <PencilSolid color={colors.gray600} />
        </TouchableOpacity>
      ) : (
        <View />
      )}
      {canDeleteDoor ? (
        <TouchableOpacity
          activeOpacity={0.7}
          style={styles.panelHeaderAction}
          onPress={async () => {
            const confirmed = await confirm({
              title: "Delete",
              description: "Are you sure you want to delete this Elevator?",
              confirmText: "Delete",
              confirmType: "destructive",
              cancelText: "Cancel",
            });
            if (confirmed) {
              doDelete({ id: elevator.id });
              history.push(
                Paths.GetPath(QueryKeys.Elevators, PathActions.List, {
                  site_id: site.id,
                })
              );
            }
          }}
        >
          <TrashX color={colors.gray600} />
        </TouchableOpacity>
      ) : (
        <View />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  panelHeaderActionsContainer: {
    flexDirection: "row",
  },
  panelHeaderAction: {
    paddingLeft: 8,
    paddingRight: 8,
  },
});
