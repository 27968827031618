import { useContext, useEffect } from "react";
import { Panel, PanelBody, PanelHeader, PanelHeaderTitle } from "@smartrent/ui";

import { useParams } from "react-router-dom";

import { Site } from "@/modules/site/types";
import { SiteForm } from "@/modules/site/Form";
import { PageCtx } from "@/context/PageContext";
import { Paths } from "@/lib/path";

interface GeneralSiteSettingsPageProps {
  site: Site;
}

export const GeneralSiteSettingsPage = ({
  site,
}: GeneralSiteSettingsPageProps) => {
  const { addBreadcrumb, breadCrumbs } = useContext(PageCtx);
  const params = useParams();

  useEffect(() => {
    addBreadcrumb({
      label: `General Site Settings`,
      to: Paths.siteSettingsGeneralPath(site.id),
    });
    addBreadcrumb({
      label: `Site Settings`,
      to: Paths.siteSettingsPath(site.id),
    });
  }, [breadCrumbs, addBreadcrumb, site.id]);

  return (
    <Panel>
      <PanelHeader>
        <PanelHeaderTitle title={`General Site Settings`} />
      </PanelHeader>
      <PanelBody>
        <SiteForm initialValues={site} params={params} />
      </PanelBody>
    </Panel>
  );
};
