import { ReactNode } from "react";
import { StyleSheet, ScrollView, ScrollViewProps } from "react-native";

import { DrawerHeader } from "@smartrent/ui";

interface BaseDrawerProps extends ScrollViewProps {
  title: ReactNode;
  subtitle?: ReactNode;
  maxWidth?: number;
}

export const BaseDrawer: React.FC<React.PropsWithChildren<BaseDrawerProps>> = ({
  title,
  subtitle,
  maxWidth,
  children,
  contentContainerStyle,
  ...scrollViewProps
}) => {
  return (
    <ScrollView
      contentContainerStyle={[styles.contentContainer, contentContainerStyle]}
      {...scrollViewProps}
    >
      <DrawerHeader title={title} subtitle={subtitle} />
      {children}
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  contentContainer: { flexGrow: 1, justifyContent: "space-between" },
});
