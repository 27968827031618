import { Route, Switch, useRouteMatch } from "react-router-dom";

import { Site } from "@/modules/site/types";

import { OperatorsListPage } from "./OperatorsListPage";

interface OperatorsRouterProps {
  site: Site;
}

export const OperatorsRouter = ({ site }: OperatorsRouterProps) => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}`}>
        <OperatorsListPage site={site} />
      </Route>
    </Switch>
  );
};
