import {
  VStack,
  FormikTextInputField,
  FormikSelectField,
  LoadingCard,
  FormikCheckbox,
  Typography,
} from "@smartrent/ui";
import { Form, FormikProps } from "formik";
import * as yup from "yup";

import { AccessLevelMultiSelectField } from "@/modules/access-level/components/AccessLevelSelectField";
import { HubSelectField } from "@/modules/hub/components/HubSelectField";

import { ErrorPage } from "@/components/ErrorPage";

import { BaseForm } from "../base/Form";

import { SiteQueries } from "../site/queries";

import { SystemTypes } from "../site/types";

import {
  ValidControllerModels,
  Controller,
  HublessModels,
  ControllerModels,
  HubAndControllerControllers,
  ControllerModelOptions,
} from "./types";
import { ControllerQueries } from "./queries";

interface ControllerFormProps {
  initialValues?: Controller;
  params: {
    site_id: number;
  };
}

export const ControllerForm = ({
  initialValues,
  params,
}: ControllerFormProps) => {
  const { data: site, isLoading } = SiteQueries.useQuery({
    id: Number(params.site_id),
  });

  const SaltoControllerForm = () => {
    const validationSchema = yup.object().shape({
      name: yup.string().max(40).required().label("Name"),
      connection_string: yup
        .string()
        .max(128)
        .required()
        .label("Connection String"),
      hub_id: yup.number().required().label("Hub"),
    });

    return (
      <BaseForm<Controller>
        initialValues={{
          model: ControllerModels.SALTO,
          name: initialValues?.name,
          connection_string: initialValues?.connection_string,
          hub_id: initialValues?.hub_id,
          site_id: initialValues?.site_id || params.site_id,
          ...initialValues,
        }}
        validationSchema={validationSchema}
        QueryClient={ControllerQueries}
        params={params}
      >
        {({ values }: FormikProps<Controller>) => {
          return (
            <Form>
              <VStack spacing={16}>
                <FormikTextInputField name="name" label="Name" required />
                <FormikTextInputField
                  name="connection_string"
                  label={"Connection String"}
                  required
                />
                {!initialValues?.id && (
                  <HubSelectField
                    name="hub_id"
                    label="Hub"
                    site_id={params?.site_id}
                    filterParams={{ virtual: false }}
                  />
                )}
              </VStack>
            </Form>
          );
        }}
      </BaseForm>
    );
  };

  const GeneralControllerForm = () => {
    const validationSchema = yup.object().shape({
      name: yup.string().required().max(40).label("Name"),
      model: yup
        .string()
        .required()
        .oneOf(ValidControllerModels)
        .label("Model"),
      connection_string: yup.lazy((conn, values) => {
        if (values.parent?.model === ControllerModels.WHOO) {
          return yup.string();
        } else {
          return yup
            .string()
            .required()
            .max(128)
            .when("model", {
              is: ControllerModels.WARBLER,
              then: yup.string().required().label("Serial"),
              otherwise: yup.string().required().label("Connection String"),
            });
        }
      }),
      hub_id: yup.number().when("model", {
        is: (val: ControllerModels) => !HublessModels.includes(val),
        then: yup.number().required().label("Hub"),
      }),
      displayed_access_level_ids: yup.array().when("model", {
        is: ControllerModels.WHOO,
        then: yup
          .array()
          .of(yup.number())
          .optional()
          .label("Directory Access Levels"),
      }),
    });

    return (
      <BaseForm<any>
        initialValues={{
          displayed_access_levels: initialValues?.displayed_access_levels,
          displayed_access_level_ids:
            initialValues?.displayed_access_levels?.map(
              (access_level) => access_level.id
            ),
          model: initialValues?.model,
          name: initialValues?.name,
          connection_string: initialValues?.connection_string,
          hub_id: initialValues?.hub_id,
          site_id: initialValues?.site_id || params.site_id,
          ...initialValues,
        }}
        validationSchema={validationSchema}
        QueryClient={ControllerQueries}
        params={params}
      >
        {({ values }: FormikProps<Controller>) => {
          const showHubSelectField =
            !HublessModels.includes(values?.model as ControllerModels) &&
            !HubAndControllerControllers.includes(
              values?.model as ControllerModels
            );

          const showConnectionStringField =
            values?.model !== ControllerModels.WHOO;

          return (
            <Form>
              <VStack spacing={16}>
                <FormikSelectField
                  name="model"
                  label="Model"
                  options={ControllerModelOptions}
                  required
                  disabled={!!initialValues?.id}
                />
                <FormikTextInputField name="name" label="Name" required />
                {showConnectionStringField && (
                  <FormikTextInputField
                    name="connection_string"
                    label={showHubSelectField ? "Connection String" : "Serial"}
                    required
                    disabled={!!initialValues?.id}
                    transformValue={(value) => value?.trim()}
                  />
                )}
                {showHubSelectField && !initialValues?.id && (
                  <HubSelectField
                    name="hub_id"
                    label="Hub"
                    site_id={params?.site_id}
                    filterParams={{ virtual: false }}
                  />
                )}
                {values.model === ControllerModels.WHOO && (
                  <AccessLevelMultiSelectField
                    name="displayed_access_level_ids"
                    label="Directory Access Levels"
                    assistiveText={
                      <Typography
                        color="gray600"
                        variation="regular.body.small"
                      >
                        Selected access levels won't automatically activate PIN
                        codes for members within those levels. To activate PIN
                        codes for the intercom follow the steps here:{" "}
                        <Typography
                          color="primary"
                          variation="regular.body.small"
                          href="https://docs.alloyaccess.com/doors/alloy-intercom-guide#configure-pin-access-for-members"
                          hrefAttrs={{
                            target: "_blank",
                            rel: "noreferrer",
                          }}
                        >
                          Intercom Pin Code Activation
                        </Typography>
                      </Typography>
                    }
                    initialValues={initialValues?.displayed_access_levels}
                    site_id={params.site_id}
                  />
                )}
                {values.model === ControllerModels.LP1501 &&
                  !initialValues?.id && (
                    <FormikCheckbox
                      name="downstream_sio_support"
                      label="Downstream SIO Support"
                    />
                  )}
              </VStack>
            </Form>
          );
        }}
      </BaseForm>
    );
  };

  if (isLoading) return <LoadingCard />;
  if (!site) return <ErrorPage title="Failed to load site" />;

  switch (site?.system_type) {
    case SystemTypes.Salto:
      return <SaltoControllerForm />;
    default:
      return <GeneralControllerForm />;
  }
};
