import { useContext, useEffect } from "react";

import { Site } from "@/modules/site/types";
import { SiteImportsTable } from "@/modules/site-import/Table";
import { Paths } from "@/lib/path";
import { PageCtx } from "@/context/PageContext";

interface SiteImportsListPageProps {
  site: Site;
}

export const SiteImportsListPage: React.FC<
  React.PropsWithChildren<SiteImportsListPageProps>
> = ({ site }) => {
  const { addBreadcrumb, breadCrumbs } = useContext(PageCtx);

  useEffect(() => {
    addBreadcrumb({
      label: `Site Imports`,
      to: Paths.importsListPage(site.id),
    });
  }, [breadCrumbs, addBreadcrumb, site.id]);

  return <SiteImportsTable site={site} filters={{ site_id: site.id }} />;
};
