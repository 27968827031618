import { Route, Switch, useRouteMatch } from "react-router-dom";
import { View, StyleSheet, ScrollView } from "react-native";
import { useWindowDimensionsQuery } from "@smartrent/hooks";

import { LoginPage } from "./LogIn";
import { AcceptInvitationPage } from "./AcceptInvitationPage";
import { ForgotPasswordPage } from "./ForgotPasswordPage";
import { ResetPasswordPage } from "./ResetPasswordPage";

export const SessionRouter = () => {
  const { path } = useRouteMatch();
  const { large: desktop } = useWindowDimensionsQuery();

  return (
    <Switch>
      <Route
        exact
        path={`${path}session/invitation/:invitationToken/:base64Email`}
      >
        <ScrollView contentContainerStyle={styles.root}>
          <AcceptInvitationPage />
        </ScrollView>
      </Route>
      <Route exact path={`${path}session/forgot-password`}>
        <ScrollView contentContainerStyle={styles.root}>
          <View style={[styles.root, desktop && styles.rootDesktop]}>
            <ForgotPasswordPage />
          </View>
        </ScrollView>
      </Route>
      <Route
        exact
        path={`${path}session/reset-password/:resetToken/:base64Email`}
      >
        <ScrollView contentContainerStyle={styles.root}>
          <View style={[styles.root, desktop && styles.rootDesktop]}>
            <ResetPasswordPage />
          </View>
        </ScrollView>
      </Route>
      <Route>
        <ScrollView contentContainerStyle={styles.root}>
          <View style={[styles.root, desktop && styles.rootDesktop]}>
            <LoginPage />
          </View>
        </ScrollView>
      </Route>
    </Switch>
  );
};

const styles = StyleSheet.create({
  root: {
    flexGrow: 1,
    justifyContent: "center",
  },
  rootDesktop: {
    flex: 1,
    flexGrow: 1,
  },
});
