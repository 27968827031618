import { useContext } from "react";

import {
  Panel,
  PanelHeader,
  PanelBody,
  PanelHeaderTitle,
  LoadingCard,
} from "@smartrent/ui";

import { useParams } from "react-router-dom";

import { AuthContext } from "@/context/Auth";
import { ErrorPage } from "@/components/ErrorPage";
import { OrganizationQueries } from "@/modules/organization/queries";
import { SiteForm } from "@/modules/site/Form";

export const SiteAddPage = () => {
  const { user } = useContext(AuthContext);
  const { data: organization, isLoading } = OrganizationQueries.useQuery({
    id: user?.organization_id,
  });
  const params = useParams();

  if (isLoading) return <LoadingCard />;
  if (!organization) return <ErrorPage title={"Failed To Load Organization"} />;

  return (
    <Panel>
      <PanelHeader>
        <PanelHeaderTitle title="Create Site" />
      </PanelHeader>
      <PanelBody>
        <SiteForm initialValues={{}} params={params} />
      </PanelBody>
    </Panel>
  );
};
