import { StyleSheet, View } from "react-native";
import { useHistory } from "react-router-dom";

import { Button, FormikTextInputField, useToast } from "@smartrent/ui";

import { useCallback } from "react";
import { Form, Formik, FormikHelpers } from "formik";
import * as yup from "yup";

import { Logo } from "@/components/alloy-access/Logo";

import { AuthQueries } from "@/modules/auth/queries";
import { ForgotPasswordRequest } from "@/modules/auth/types";

export const ForgotPasswordPage = () => {
  return (
    <View style={styles.root}>
      <View style={styles.container}>
        <Logo />
        <ForgotPassword />
      </View>
    </View>
  );
};

const initialValues = {
  email: "",
};

const forgotPasswordValidationSchema = yup.object().shape({
  email: yup.string().required("Required"),
});

const ForgotPassword: React.FC<React.PropsWithChildren<unknown>> = () => {
  const history = useHistory();
  const setToast = useToast();

  const [forgotPasswordRequest] = AuthQueries.useForgotPasswordRequestMutation({
    onSuccess: () => {
      history.push("/");
      setToast({
        message:
          "An email will be sent to reset your password if a registered user is found. Please check your inbox to reset your password.",
        status: "success",
        title: "Success",
      });
    },
  });

  const handleSubmit = useCallback(
    async (
      values: ForgotPasswordRequest,
      _helpers: FormikHelpers<ForgotPasswordRequest>
    ) => {
      forgotPasswordRequest(values).catch(() => {
        /* No-op since the mutation handles this for us */
      });
    },
    [forgotPasswordRequest]
  );

  return (
    <Formik<ForgotPasswordRequest>
      initialValues={initialValues}
      validationSchema={forgotPasswordValidationSchema}
      onSubmit={handleSubmit}
    >
      {({ submitForm, isValid, isSubmitting }) => (
        <Form>
          <FormikTextInputField
            name="email"
            label="Email Address"
            required
            submitOnEnter
            style={styles.marginBottom16}
          />

          <Button
            disabled={!isValid && !isSubmitting}
            onPress={submitForm}
            style={styles.submit}
          >
            Send Reset Email
          </Button>
        </Form>
      )}
    </Formik>
  );
};

const styles = StyleSheet.create({
  root: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  container: {
    padding: 16,
    minWidth: 350,
  },
  submit: {
    flexGrow: 0,
  },
  marginBottom16: {
    marginBottom: 16,
  },
});
