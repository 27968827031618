import { StyleSheet } from "react-native";
import {
  Typography,
  ColumnOptions,
  useTableQuery,
  ListQueryResponse,
  Table,
} from "@smartrent/ui";

import { useParams } from "react-router-dom";

import { useDialog } from "@/context/dialog";

import {
  StatusDisplay,
  DeviceStatus,
} from "@/components/alloy-access/StatusDisplay";

import { DomLink } from "@/components/alloy-access/Link";

import { Paths } from "@/lib/path";

import { useAppDrawer } from "@/components/layout/AppDrawer";

import { usePermissions } from "@/context/PolicyContext";

import { TableFilters } from "@/modules/base/table/types";

import { QueryKeys } from "@/types";

import { ControllerQueries } from "../controller/queries";

import {
  ControllerProtocols,
  ControllersThatCanEditReaders,
  ControllersThatCanDeleteReaders,
} from "../controller/types";

import { createActionsColumn, useTableUtils } from "../base/table/utils";

import { ReaderQueries } from "./queries";
import { Reader } from "./types";

interface ReaderTableFilters extends TableFilters {
  controller_id?: number;
  panel_id?: number;
}
interface ReadersTableProps {
  title?: string;
  filters: ReaderTableFilters;
}

export const ReadersTable = ({ filters }: ReadersTableProps) => {
  const drawer = useAppDrawer();
  const { confirm } = useDialog();
  const { canUpdate, canDelete } = usePermissions(ReaderQueries);

  const [deleteReaderMutation] = ReaderQueries.useDeleteMutation();

  const { site_id } = useParams<{ site_id: string }>();
  const { data: controller } = ControllerQueries.useQuery({
    id: filters?.controller_id,
  });

  const { relativeWidths } = useTableUtils();

  const fields: ColumnOptions<Reader>[] = [
    {
      name: "name",
      header: "Name",
      style: styles.column,
      render: ({ row }) => {
        return <Typography type="body">{row.name}</Typography>;
      },
    },
    {
      name: "panel_id",
      header: "Panel",
      style: styles.column,
      relativeWidth: relativeWidths.columnDouble,
      render: ({ row }) => {
        return (
          <StatusDisplay
            status={
              row.panel.online ? DeviceStatus.Online : DeviceStatus.Offline
            }
            message={row.panel?.name}
          />
        );
      },
    },
    {
      name: "door",
      header: "Door",
      style: styles.column,
      render: ({ row }) => {
        if (!row.door) return null;
        return (
          <DomLink href={Paths.doorViewPath(site_id, row.door.id)}>
            {row.door.name}
          </DomLink>
        );
      },
    },
  ];

  const tableProps = useTableQuery<any, Reader, ListQueryResponse<Reader>>({
    fetch: ReaderQueries.fetch,
    getQueryKey: ({
      filters: tableFilters,
      page,
      pageSize,
      sortColumn,
      sortDirection,
    }) => [
      ReaderQueries.queryKey,
      {},
      {
        ...filters,
        page,
        per_page: pageSize,
        sort: sortColumn,
        dir: sortDirection,
        ...tableFilters,
      },
    ],
    columns: [
      ...fields,
      createActionsColumn<Reader>({
        canUpdate:
          canUpdate &&
          ControllersThatCanEditReaders.includes(
            controller?.protocol as ControllerProtocols
          ),
        onUpdate: async (row) =>
          controller?.model === "warbler"
            ? drawer.push(QueryKeys.ReadersWarbler, { reader: row })
            : drawer.push(QueryKeys.Readers, { initialValues: row }),

        canDelete:
          canDelete &&
          ControllersThatCanDeleteReaders.includes(
            controller?.protocol as ControllerProtocols
          ),
        onDelete: async (row) => {
          const confirmed = await confirm({
            title: "Delete",
            description: "Are you sure you want to delete this Reader?",
            confirmText: "Delete",
            confirmType: "destructive",
            cancelText: "Cancel",
          });

          if (confirmed) {
            deleteReaderMutation({
              id: row.id,
            });
          }
        },
      }),
    ],
    defaultPageSize: 10,
  });

  return (
    <Table<Reader>
      title={"Readers"}
      noRecordsText={"No Readers Found"}
      {...tableProps}
    />
  );
};

const styles = StyleSheet.create({
  column: {
    paddingRight: 16,
  },
});
