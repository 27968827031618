import { AccessLevelQueries } from "../access-level/queries";
import { AccessLevel } from "../access-level/types";

import { BaseDrawer } from "../base/Drawer";

import { AccessLevelScheduleForm } from "./Form";

interface AccessLevelScheduleDrawerProps {
  initialValues?: AccessLevel;
  params: {
    site_id: number;
    access_level_id: number;
  };
}

export const AccessLevelScheduleDrawer: React.FC<
  React.PropsWithChildren<AccessLevelScheduleDrawerProps>
> = ({ initialValues, params }) => {
  const { data: access_level } = AccessLevelQueries.useQuery({
    id: params.access_level_id,
  });

  return (
    <BaseDrawer title={access_level?.name} subtitle={"Add Door"}>
      <AccessLevelScheduleForm initialValues={initialValues} params={params} />
    </BaseDrawer>
  );
};
