import { LoadingCard } from "@smartrent/ui";

import { useContext, useEffect } from "react";

import { useParams } from "react-router-dom";

import { View, StyleSheet } from "react-native";

import { ErrorPage } from "@/components/ErrorPage";
import { PageCtx } from "@/context/PageContext";
import { Paths } from "@/lib/path";
import { ScheduleQueries } from "@/modules/schedule/queries";
import { Site } from "@/modules/site/types";

import { ScheduleIntervalsTable } from "@/modules/schedule-intervals/Table";
import { ScheduleHolidayGroupsTable } from "@/modules/schedule-holiday-groups/Table";

interface ScheduleShowPageProps {
  site: Site;
}

export const ScheduleShowPage = ({ site }: ScheduleShowPageProps) => {
  const { schedule_id } = useParams<{ schedule_id: string }>();
  const { addBreadcrumb, breadCrumbs } = useContext(PageCtx);

  const { data: schedule, isLoading } = ScheduleQueries.useQuery({
    id: parseInt(schedule_id as string),
  });

  useEffect(() => {
    if (schedule) {
      addBreadcrumb({
        label: `${schedule?.name}`,
        to: Paths.scheduleViewPage(site.id, schedule_id),
      });
    }
  }, [breadCrumbs, schedule, addBreadcrumb, site.id, schedule_id]);

  if (isLoading) {
    return <LoadingCard />;
  }
  if (!schedule) {
    return <ErrorPage title={"Error retrieving schedule"} />;
  }
  return (
    <View style={styles.root}>
      <View style={styles.intervalsContainer}>
        <ScheduleIntervalsTable
          filters={{ schedule_id: schedule.id }}
          schedule={schedule}
          site={site}
        />
      </View>
      <View style={styles.holidaysContainer}>
        <ScheduleHolidayGroupsTable
          filters={{ schedule_id: schedule.id }}
          site={site}
          schedule={schedule}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    flexWrap: "wrap",
  },
  intervalsContainer: {
    flexGrow: 1,
  },
  holidaysContainer: {
    marginTop: 50,
  },
});
