import { Route, Switch, useRouteMatch } from "react-router-dom";

import { useContext, useEffect } from "react";

import { Site } from "@/modules/site/types";

import { PageCtx } from "@/context/PageContext";

import { Paths } from "@/lib/path";

import { HolidayGroupListPage } from "./HolidayGroupListPage";
import { HolidayGroupViewPage } from "./HolidayGroupViewPage";

interface HolidayScheduleRouterProps {
  site: Site;
}

export const HolidaySchedulesRouter = ({
  site,
}: HolidayScheduleRouterProps) => {
  const { path } = useRouteMatch();
  const { addBreadcrumb } = useContext(PageCtx);

  useEffect(() => {
    addBreadcrumb({
      label: `Exceptions Groups`,
      to: Paths.holidayGroupListPath(site.id),
    });
  }, [addBreadcrumb, site.id]);

  return (
    <Switch>
      <Route exact path={`${path}`}>
        <HolidayGroupListPage site={site} />
      </Route>
      <Route exact path={`${path}/:holiday_schedule_group_id`}>
        <HolidayGroupViewPage site={site} />
      </Route>
    </Switch>
  );
};
