import {
  Panel,
  PanelHeader,
  FlatList,
  ListItemContainer,
  ListItemLoadingContainer,
  ListItemLoadingValue,
  MessageCard,
  Button,
  ListItemValue,
  PanelHeaderTitle,
} from "@smartrent/ui";

import { StyleSheet } from "react-native";

import { useHistory, useParams } from "react-router-dom";

import { Panel as PanelType } from "@/modules/panel/types";

import { useCascadingOptionsQuery } from "@/modules/panel/queries";
import { Controller } from "@/modules/controller/types";

interface CascadingPanelsListPropsProps {
  controller_id: Controller["id"];
}

export const CascadingPanelsList: React.FC<
  React.PropsWithChildren<CascadingPanelsListPropsProps>
> = ({ controller_id }) => {
  const { site_id } = useParams<{ site_id: string }>();
  const history = useHistory();

  const { data: cascadingPanelsData, isLoading } = useCascadingOptionsQuery({
    controller_id,
    order_field: "order_out",
  });

  const { data: cascadingPanelsDataIn, isLoading: isLoadingIn } =
    useCascadingOptionsQuery({
      controller_id,
      order_field: "order_in",
    });

  if (!cascadingPanelsData?.total_records) return null;

  return (
    <Panel>
      <PanelHeader
        EndAdornment={
          <Button
            onPress={() =>
              history.push(`/sites/${site_id}/controllers/${controller_id}`)
            }
          >
            Edit Cascade Positions
          </Button>
        }
      >
        <PanelHeaderTitle title={"Cascade Positions"} />
      </PanelHeader>

      <FlatList<PanelType>
        keyExtractor={(item, index) => `item-${index}-${item.id}`}
        data={[
          ...(cascadingPanelsData?.records || []),
          ...(cascadingPanelsDataIn?.records || []),
        ]}
        LoadingItemComponent={() => (
          <ListItemLoadingContainer>
            <ListItemLoadingValue />
          </ListItemLoadingContainer>
        )}
        ItemComponent={({ item }) => (
          <ListItemContainer>
            <ListItemValue valueStyle={styles.value}>
              {item?.name || ""}
            </ListItemValue>
          </ListItemContainer>
        )}
        ListEmptyComponent={() => (
          <MessageCard heading="No Panels Found" message="" />
        )}
        isLoading={isLoading || isLoadingIn}
        totalRecordsLoaded={0}
        totalRecords={0}
        totalPages={0}
      />
    </Panel>
  );
};

const styles = StyleSheet.create({
  value: { fontSize: 18, fontWeight: "600", lineHeight: 24 },
});
