import {
  ListQueryResponse,
  Table,
  Typography,
  useTableQuery,
} from "@smartrent/ui";

import { useParams } from "react-router-dom";

import { useAppDrawer } from "@/components/layout/AppDrawer";

import { usePermissions } from "@/context/PolicyContext";

import { TableFilters } from "@/modules/base/table/types";

import { createActionsColumn, useTableUtils } from "../base/table/utils";

import { SipAccount } from "./types";
import { SipAccountQueries } from "./queries";

interface SipAccountsTableProps {
  filters: Partial<TableFilters & SipAccount>;
}
export const SipAccountsTable = ({ filters }: SipAccountsTableProps) => {
  // update
  const drawer = useAppDrawer();
  const params = useParams();
  const { canUpdate } = usePermissions(SipAccountQueries);

  const { relativeWidths } = useTableUtils();

  const tableProps = useTableQuery<
    any,
    SipAccount,
    ListQueryResponse<SipAccount>
  >({
    fetch: SipAccountQueries.fetch,
    getQueryKey: ({
      filters: tableFilters,
      page,
      pageSize,
      sortColumn,
      sortDirection,
    }) => [
      SipAccountQueries.queryKey,
      {},
      {
        ...filters,
        page,
        per_page: pageSize,
        sort: sortColumn,
        dir: sortDirection,
        ...tableFilters,
      },
    ],
    columns: [
      {
        name: "account_number",
        header: "Account Number",
        relativeWidth: relativeWidths.md,
        render: ({ row }) => {
          return <Typography>{row.account_number}</Typography>;
        },
      },
      {
        name: "user_name",
        header: "Username",
        relativeWidth: relativeWidths.md,
        render: ({ row }) => {
          return <Typography>{row.user_name}</Typography>;
        },
      },
      {
        name: "register_name",
        header: "Register Name",
        relativeWidth: relativeWidths.md,
        render: ({ row }) => {
          return <Typography>{row.register_name}</Typography>;
        },
      },
      {
        name: "account_active",
        header: "Active",
        relativeWidth: relativeWidths.md,
        render: ({ row }) => {
          return <Typography>{row.account_active ? "Yes" : "No"}</Typography>;
        },
      },
      createActionsColumn<SipAccount>({
        canUpdate,
        onUpdate: async (row) =>
          drawer.push(SipAccountQueries.queryKey, {
            initialValues: row,
            params,
          }),
      }),
    ],
    defaultPageSize: 10,
  });
  return (
    <Table<SipAccount>
      title={"Sip Accounts"}
      noRecordsText={`No Sip Accounts Found`}
      {...tableProps}
    />
  );
};
