import { FormikSelectField } from "@smartrent/ui";

import { ControllerQueries } from "@/modules/controller/queries";
import { Controller } from "@/modules/controller/types";

interface ControllerSelectFieldProps {
  site_id: number;
  name: string;
  label: string;
  filterParams?: any;
  initialValue?: string;
}

export const ControllerSelectField: React.FC<
  React.PropsWithChildren<ControllerSelectFieldProps>
> = ({ site_id, label, name, filterParams, initialValue }) => {
  const selectQueryProps = ControllerQueries.select({
    filterParams: { site_id, ...filterParams },
    initialValue,
  });

  return (
    <FormikSelectField<Controller>
      name={name}
      label={label}
      getOptionValue={(option) => option.id.toString()}
      getOptionLabel={(option) => `${option.name}`}
      required
      {...selectQueryProps}
    />
  );
};
