import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
} from "react";
import { isPast, subMinutes } from "date-fns";
import decodeJwt from "jwt-decode";
import { useInterval } from "@smartrent/hooks";
import { useQueryClient } from "@tanstack/react-query";

import { User } from "@/modules/user/types";
import { AuthQueries } from "@/modules/auth/queries";
import { useStorage } from "@/hooks/storage";

const AUTH_STORAGE_KEY = "authState";
const FIFTEEN_SECONDS_IN_MS = 15_000;

// Start attempting to refresh within 2 minutes of the token's expire time
function shouldRefresh(expireTime: Date): boolean {
  return isPast(subMinutes(expireTime, 2));
}

function decodeToken(token: string): Record<string, any> {
  const payload = decodeJwt<Record<string, any>>(token);

  if (typeof payload !== "object" || !payload || !("exp" in payload)) {
    throw new Error("Invalid token");
  }

  return payload;
}

function getTokenExpiration(tokenPayload: any): Date {
  return new Date(tokenPayload.exp * 1000);
}

interface AuthState {
  accessToken: string | null;
  refreshToken: string | null;
  user: User | null;
}
interface AuthActions {
  logout: () => void;
  refresh: (force: boolean) => Promise<void>;
  setState: Dispatch<SetStateAction<AuthState | undefined>>;
}

const initialState = {
  accessToken: null,
  refreshToken: null,
  user: null,
};

export const AuthContext = createContext<AuthActions & AuthState>({
  logout: () => undefined,
  refresh: () => Promise.resolve(),
  setState: () => undefined,
  ...initialState,
});

export const AuthProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [doRefresh] = AuthQueries.useRefreshTokenMutation();
  const queryClient = useQueryClient();

  const [state, setState, clearValue] = useStorage<AuthState>(
    AUTH_STORAGE_KEY,
    initialState
  );

  const logout = useCallback(() => {
    queryClient.clear();
    setState(initialState);
    clearValue();
  }, [clearValue, queryClient, setState]);

  const refresh = useCallback(async () => {
    if (!state.accessToken || !state.refreshToken || !state.user) {
      return;
    }

    const tokenPayload = decodeToken(state.accessToken);
    // we only want to refresh the access token if it is already expired or
    // will be expiring soon
    const accessTokenExp = getTokenExpiration(tokenPayload);
    if (!shouldRefresh(accessTokenExp)) {
      return;
    }

    // don't bother trying to use an expired refresh token
    const refreshTokenExp = getTokenExpiration(decodeToken(state.refreshToken));
    if (isPast(refreshTokenExp)) {
      return logout();
    }

    try {
      if (!("fingerprintHash" in tokenPayload)) {
        throw new Error("Invalid token: no fingerprint hash");
      }

      const response = await doRefresh({
        fingerprint_hash: tokenPayload.fingerprintHash,
        refresh_token: state.refreshToken,
      });
      if (!response) {
        return logout();
      }
      setState({
        accessToken: response.data.access_token,
        refreshToken: response.data.refresh_token,
        user: response.data.user,
      });
    } catch (err: any) {
      if (err.status === 409) {
        // do nothing. This was caused by multiple requests going through at the same time.
      } else if (err.status === 500) {
        // do nothing. This was not an auth issue.
      } else {
        return logout();
      }
    }
  }, [
    logout,
    doRefresh,
    state.accessToken,
    state.refreshToken,
    state.user,
    setState,
  ]);

  // useInterval takes ms, tokenRefreshTTL is in seconds.
  // We want to refresh more often than the ttl, so we with a ttl of 30 seconds, we want to try every 15 seconds.
  useInterval(refresh, FIFTEEN_SECONDS_IN_MS);

  // see if we need to refresh the access token when the component first mounts
  useEffect(() => {
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only run on first render
  }, []);

  const isValidAccessToken =
    state.accessToken &&
    !isPast(getTokenExpiration(decodeToken(state.accessToken)));

  return (
    <AuthContext.Provider
      value={{
        logout: logout,
        refresh: refresh,
        setState: setState,
        ...(isValidAccessToken ? state : initialState),
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

interface AuthSwitchProps {
  /**
   * The component to render if the user is authenticated.
   */
  authenticated?:
    | React.ComponentType<React.PropsWithChildren<unknown>>
    | React.ElementType;

  /**
   * The component to render if the user is **not** authenticated.
   */
  unauthenticated?:
    | React.ComponentType<React.PropsWithChildren<unknown>>
    | React.ElementType;
}

export const AuthSwitch: React.FC<React.PropsWithChildren<AuthSwitchProps>> = ({
  authenticated,
  unauthenticated,
}) => {
  const { accessToken } = useContext(AuthContext);

  let ComponentOrElement: React.ComponentType<any> | React.ElementType;

  if (authenticated && accessToken) {
    ComponentOrElement = authenticated;
  } else if (unauthenticated && !accessToken) {
    ComponentOrElement = unauthenticated;
  } else {
    return null;
  }

  if (React.isValidElement(ComponentOrElement)) {
    return ComponentOrElement;
  }

  return <ComponentOrElement />;
};
