import { BaseDrawer } from "../base/Drawer";

import { ApiApplication } from "./types";
import { ApiApplicationForm } from "./Form";

interface ApiApplicationDrawerProps {
  initialValues?: ApiApplication;
  params: {
    site_id: number;
  };
}

export const ApiApplicationDrawer: React.FC<
  React.PropsWithChildren<ApiApplicationDrawerProps>
> = ({ initialValues, params }) => {
  return (
    <BaseDrawer
      title={initialValues?.id ? initialValues.name : "New API Client"}
      subtitle={initialValues?.id ? "Edit API Client" : null}
    >
      <ApiApplicationForm initialValues={initialValues} params={params} />
    </BaseDrawer>
  );
};
