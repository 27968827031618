import { VStack, FormikTextInputField } from "@smartrent/ui";
import { Form } from "formik";
import * as yup from "yup";

import { BaseForm } from "../base/Form";

import { HolidayScheduleGroup } from "./types";
import { HolidayScheduleGroupQueries } from "./queries";

interface HolidayScheduleGroupProps {
  initialValues?: Partial<HolidayScheduleGroup>;
  params: { site_id: number };
}

const validationSchema = yup.object().shape({
  name: yup.string().required("Required").max(40),
});

const assistiveText =
  "Exception groups allow you to create groups of one or more exceptions (ex. Federal Holidays). These can be assigned to different Schedules to support custom overrides for different doors.";

export const HolidayGroupForm = ({
  initialValues,
  params,
}: HolidayScheduleGroupProps) => {
  return (
    <BaseForm<HolidayScheduleGroup>
      initialValues={{
        ...initialValues,
        site_id: params.site_id,
      }}
      QueryClient={HolidayScheduleGroupQueries}
      validationSchema={validationSchema}
      params={params}
    >
      {() => (
        <Form>
          <VStack spacing={16}>
            <FormikTextInputField
              name="name"
              label="Name"
              assistiveText={assistiveText}
              required
            />
          </VStack>
        </Form>
      )}
    </BaseForm>
  );
};
