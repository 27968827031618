import { BaseDrawer } from "../base/Drawer";
import { ScheduleQueries } from "../schedule/queries";

import { ScheduleHolidayGroupsForm } from "./Form";

interface ScheduleHolidayGroupsDrawerProps {
  params: {
    site_id: number;
    schedule_id: number;
  };
}

export const ScheduleHolidayGroupsDrawer: React.FC<
  React.PropsWithChildren<ScheduleHolidayGroupsDrawerProps>
> = ({ params }) => {
  const { data: schedule, isLoading } = ScheduleQueries.useQuery({
    id: params.schedule_id,
  });

  return (
    <BaseDrawer
      title="Modify Exceptions"
      subtitle={!isLoading && schedule?.name ? schedule.name : null}
    >
      <ScheduleHolidayGroupsForm params={params} />
    </BaseDrawer>
  );
};
