import { BaseDrawer } from "../base/Drawer";

import { OrganizationForm } from "./Form";
import { Organization } from "./types";

interface OrganizationDrawerProps {
  initialValues?: Organization;
  params?: any;
}

export const OrganizationDrawer: React.FC<
  React.PropsWithChildren<OrganizationDrawerProps>
> = ({ initialValues, params }) => {
  return (
    <BaseDrawer
      title={initialValues?.id ? initialValues?.name : "New Organization"}
      subtitle={initialValues?.id ? "Edit Organization" : null}
    >
      <OrganizationForm initialValues={{ ...initialValues }} params={params} />
    </BaseDrawer>
  );
};
