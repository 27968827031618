import { StyleSheet } from "react-native";
import {
  Typography,
  ColumnOptions,
  useTableQuery,
  ListQueryResponse,
  Table,
} from "@smartrent/ui";

import { useParams } from "react-router-dom";

import {
  StatusDisplay,
  DeviceStatus,
} from "@/components/alloy-access/StatusDisplay";

import { DomLink } from "@/components/alloy-access/Link";

import { Paths } from "@/lib/path";

import { useAppDrawer } from "@/components/layout/AppDrawer";

import { usePermissions } from "@/context/PolicyContext";

import { TableFilters } from "@/modules/base/table/types";

import { ControllerQueries } from "../controller/queries";

import {
  ControllerProtocols,
  ControllersThatCanEditInputs,
} from "../controller/types";

import { createActionsColumn } from "../base/table/utils";

import { Panel } from "../panel/types";

import { PanelQueries } from "../panel/queries";

import { DoorQueries } from "../door/queries";

import { Door } from "../door/types";

import { InputQueries } from "./queries";
import { Input } from "./types";

interface InputTableFilters extends TableFilters {
  controller_id?: number;
  panel_id?: number;
  door_ids?: string;
}
interface InputsTableProps {
  title?: string;
  filters: InputTableFilters;
}

export const InputsTable = ({ filters }: InputsTableProps) => {
  const drawer = useAppDrawer();
  const params = useParams();
  const { canUpdate } = usePermissions(InputQueries);

  const { site_id } = useParams<{ site_id: string }>();
  const { data: controller } = ControllerQueries.useQuery({
    id: filters?.controller_id,
  });

  const panelSelectProps = PanelQueries.tableSelectProps({
    defaultParams: { site_id, controller_id: filters?.controller_id },
  });

  const doorSelectProps = DoorQueries.tableSelectProps({
    defaultParams: {
      site_id,
      controller_id: filters?.controller_id,
      include_deleted: false,
      has_assigned_input: true,
    },
  });

  const fields: ColumnOptions<Input>[] = [
    {
      name: "name",
      header: "Name",
      style: styles.column,
      filterType: {
        type: "textInputField",
      },
      render: ({ row }) => {
        return <Typography type="body">{row.name}</Typography>;
      },
    },
    {
      name: "panel_ids",
      header: "Panel",
      style: styles.column,
      relativeWidth: 300,
      filterType: {
        type: "multiSelectField",
        getOptionValue: (option: Panel) => option.id.toString(),
        getOptionLabel: (option: Panel) => `${option.name}`,
        ...panelSelectProps,
      },
      render: ({ row }) => {
        return (
          <StatusDisplay
            status={
              row.panel?.online ? DeviceStatus.Online : DeviceStatus.Offline
            }
            message={row.panel?.name}
          />
        );
      },
    },
    {
      name: "door_ids",
      header: "Door",
      style: styles.column,
      filterType: {
        type: "multiSelectField",
        getOptionValue: (option: Door) => option.id.toString(),
        getOptionLabel: (option: Door) => `${option.name}`,
        ...doorSelectProps,
      },
      render: ({ row }) => {
        if (row.rex1) {
          return (
            <DomLink href={Paths.doorViewPath(site_id, row.rex1.id)}>
              {row.rex1.name} (Rex1)
            </DomLink>
          );
        }
        if (row.rex2) {
          return (
            <DomLink href={Paths.doorViewPath(site_id, row.rex2.id)}>
              {row.rex2.name} (Rex2)
            </DomLink>
          );
        }
        if (row.dsm1) {
          return (
            <DomLink href={Paths.doorViewPath(site_id, row.dsm1.id)}>
              {row.dsm1.name} (Dsm1)
            </DomLink>
          );
        }
        return null;
      },
    },
  ];

  const tableProps = useTableQuery<any, Input, ListQueryResponse<Input>>({
    fetch: InputQueries.fetch,
    getQueryKey: ({
      filters: tableFilters,
      page,
      pageSize,
      sortColumn,
      sortDirection,
    }) => [
      InputQueries.queryKey,
      {},
      {
        ...filters,
        page,
        per_page: pageSize,
        sort: sortColumn,
        dir: sortDirection,
        ...tableFilters,
      },
    ],
    columns: [
      ...fields,
      createActionsColumn<Input>({
        canUpdate:
          canUpdate &&
          ControllersThatCanEditInputs.includes(
            controller?.protocol as ControllerProtocols
          ),
        onUpdate: async (row) =>
          drawer.push(InputQueries.queryKey, { initialValues: row, params }),
      }),
    ],
    // currently, our system supports multiple doors per input, so
    // to fix any duplicate key issues, we need to add the index to the key
    keyExtractor: (value, index) =>
      `${InputQueries.queryKey}-${value.id}-${index}`,
    defaultPageSize: 10,
  });

  return (
    <Table<Input>
      title={"Inputs"}
      noRecordsText={"No Inputs Found"}
      {...tableProps}
    />
  );
};

const styles = StyleSheet.create({
  column: {
    paddingRight: 16,
  },
});
