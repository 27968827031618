import {
  TabContent,
  TabProvider,
  TabsNavigation,
  useQueryStringField,
  useTheme,
} from "@smartrent/ui";

import { useContext, useEffect } from "react";
import { StyleSheet, View } from "react-native";
import { useHistory } from "react-router-dom";

import { PageCtx } from "@/context/PageContext";
import { Paths } from "@/lib/path";
import { SaltoDoorsTable } from "@/modules/door/SaltoDoorsTable";
import { SchlageDoorsTable } from "@/modules/door/SchlageDoorsTable";
import { DoorsTable } from "@/modules/door/Table";
import { ElevatorsTable } from "@/modules/elevator/Table";
import { Site, SystemTypes } from "@/modules/site/types";

interface DoorsListPageProps {
  site: Site;
}

export const DoorsListPage = ({ site }: DoorsListPageProps) => {
  const { colors } = useTheme();
  const activeTab = useQueryStringField("tab");
  const history = useHistory();
  const { addBreadcrumb } = useContext(PageCtx);

  useEffect(() => {
    addBreadcrumb({
      label: activeTab === "elevators" ? "Elevators" : "Doors",
      to:
        activeTab === "elevators"
          ? Paths.elevatorsListPath(site.id)
          : Paths.doorsListPath(site.id),
    });
  }, [activeTab, addBreadcrumb, site.id]);

  if (site.system_type == SystemTypes.Schlage) {
    return <SchlageDoorsTable filters={{}} site={site} />;
  } else if (site.system_type == SystemTypes.Salto) {
    return <SaltoDoorsTable filters={{}} site={site} />;
  } else {
    return (
      <TabProvider>
        <View style={[styles.tabContainer, { borderColor: colors.border }]}>
          <TabsNavigation
            align="flex-start"
            initialActiveTabId={activeTab || "doors"}
            items={[
              {
                id: "doors",
                label: "Doors",
                onPress: () =>
                  history.replace({
                    pathname: window.location.pathname,
                    search: "?tab=doors",
                  }),
              },
              {
                id: "elevators",
                label: "Elevators",
                onPress: () =>
                  history.replace({
                    pathname: window.location.pathname,
                    search: "?tab=elevators",
                  }),
              },
            ]}
          />
        </View>

        <TabContent id="doors">
          <DoorsTable
            filters={{}}
            defaultSortColumn="mode"
            defaultSortDirection="desc"
            site={site}
          />
        </TabContent>
        <TabContent id="elevators">
          <ElevatorsTable
            filters={{}}
            defaultSortColumn="mode"
            defaultSortDirection="desc"
            site={site}
          />
        </TabContent>
      </TabProvider>
    );
  }
};
const styles = StyleSheet.create({
  tabContainer: {
    borderWidth: 1,
    borderRadius: 4,
    borderStyle: "solid",
    paddingBottom: 8,
  },
});
