import { StyleSheet, TouchableOpacity, View } from "react-native";
import {
  FlatListItemSeparator,
  ListItemContainer,
  ListItemLabelValue,
  LoadingCard,
  VStack,
} from "@smartrent/ui";

import { useHistory } from "react-router-dom";

import { DATE_TIME_FORMAT, formatDateTime } from "@/lib/formatters";
import { ErrorPage } from "@/components/ErrorPage";

import { Paths } from "@/lib/path";

import { SiteQueries } from "../site/queries";

import { Site } from "../site/types";

import { BaseDrawer } from "../base/Drawer";

import { Event } from "./types";
import { EventQueries } from "./queries";
import { getEventDetails } from "./event-dictionary";

interface EventDrawerProps {
  initialValues?: Event;
  params: {
    site_id: number;
  };
}

interface EventDetailsProps {
  event: Event;
  site?: Site;
}

const EventDetail = ({
  label,
  value,
  link,
}: {
  label: string;
  value: string;
  link?: string;
}) => {
  const history = useHistory();

  if (!link) {
    return (
      <View>
        <ListItemContainer>
          <ListItemLabelValue label={label} value={value} />
        </ListItemContainer>
        <FlatListItemSeparator />
      </View>
    );
  }
  return (
    <TouchableOpacity
      activeOpacity={0.7}
      onPress={() => link && history.push(link)}
    >
      <ListItemContainer>
        <ListItemLabelValue label={label} value={value} />
      </ListItemContainer>
      <FlatListItemSeparator />
    </TouchableOpacity>
  );
};

const EventDetails = ({ event, site }: EventDetailsProps) => {
  const details = getEventDetails(event);
  return (
    <VStack spacing={8}>
      <EventDetail label={details.title} value={details.subtitle} />
      <EventDetail
        label="Happened At"
        value={formatDateTime(
          `${event.happened_at}` as string,
          site?.timezone || "",
          DATE_TIME_FORMAT
        )}
      />
      {event.member ? (
        <EventDetail
          label="Member"
          value={`${event.member.first_name} ${event.member.last_name}`}
          link={Paths.memberViewPage(event.site_id, event.member.id)}
        />
      ) : null}
      {event.user ? (
        <EventDetail
          label="User"
          value={`${event.user.first_name} ${event.user.last_name}`}
        />
      ) : null}
      {event.hub ? (
        <EventDetail
          label="Hub"
          value={event.hub.name || ""}
          link={Paths.hubViewPath(event.site_id, event.hub.id)}
        />
      ) : null}
      {event.controller ? (
        <EventDetail
          label="Controller"
          value={event.controller.name || ""}
          link={Paths.controllerViewPath(event.site_id, event.controller.id)}
        />
      ) : null}
      {event.door ? (
        <EventDetail
          label="Door"
          value={event.door.name || ""}
          link={Paths.doorViewPath(event.site_id, event.door.id)}
        />
      ) : null}
      {details.title === "Unknown Event" ? (
        <EventDetail label="Raw Event" value={JSON.stringify(event, null, 2)} />
      ) : null}
    </VStack>
  );
};

export const EventDrawer: React.FC<
  React.PropsWithChildren<EventDrawerProps>
> = ({ initialValues, params }) => {
  const { data: event, isLoading } = EventQueries.useQuery({
    id: Number(initialValues?.id),
  });
  const { data: site, isLoading: siteIsLoading } = SiteQueries.useQuery({
    id: Number(params.site_id),
  });

  if (isLoading || siteIsLoading) return <LoadingCard />;
  if (!event) return <ErrorPage title="Failed to fetch event" />;
  if (!site) return <ErrorPage title="Failed to fetch site" />;

  return (
    <BaseDrawer title={"Event Details"} contentContainerStyle={styles.drawer}>
      <EventDetails event={event} site={site as Site} />
    </BaseDrawer>
  );
};

const styles = StyleSheet.create({
  drawer: { justifyContent: "flex-start" },
});
