import { Typography } from "@smartrent/ui";

import { View, StyleSheet } from "react-native";

import { DomLink } from "@/components/alloy-access/Link";
import { Paths } from "@/lib/path";
import { Site } from "@/modules/site/types";
import { Door } from "@/modules/door/types";

export const UnlockedScheduleListItem = ({
  door,
  site,
}: {
  door: Door;
  site: Site;
}) => {
  const UnlockedScheduleText = () =>
    !door.unlocked_schedule ? (
      <Typography> - </Typography>
    ) : (
      <DomLink
        href={Paths.schedulesViewPath(
          site.id,
          door.unlocked_schedule_id as number
        )}
      >
        {door.unlocked_schedule?.name || "-"}
      </DomLink>
    );

  return (
    <View style={styles.hubRow}>
      <View style={styles.hubTitleContainer}>
        <Typography color="primary" type="title5">
          Unlocked Schedule
        </Typography>
      </View>
      <View style={styles.hubNameContainer}>
        <UnlockedScheduleText />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  hubRow: {
    flexDirection: "column",
    flex: 1,
    paddingVertical: 16,
    paddingLeft: 16,
  },
  hubTitleContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  hubNameContainer: {
    flexDirection: "row",
  },
});
