import * as yup from "yup";

import { FormCheckbox, FormSelectField } from "@smartrent/forms";
import { VStack } from "@smartrent/ui";

import {
  KeypadModeOptions,
  OsdpAddressOptions,
  OsdpBaudRateOptions,
  ValidKeypadModes,
  ValidOsdpAddressOptions,
  ValidOsdpBaudRates,
} from "../types";

// OSDP fields we need to support Mercury controllers
export const readerOsdpValidationSchema = yup.object().shape({
  // 'Lazy' for accurate error messages; FormSelectField sets empty fields to "".
  osdp_flags_address: yup.lazy((value) =>
    value === ""
      ? yup.string().required().label("OSDP Address")
      : yup
          .number()
          .required()
          .oneOf(ValidOsdpAddressOptions)
          .label("OSDP Address")
  ),
  osdp_flags_baud: yup.lazy((value) =>
    value === ""
      ? yup.string().required().label("OSDP Baud Rate")
      : yup
          .number()
          .required()
          .oneOf(ValidOsdpBaudRates)
          .label("OSDP Baud Rate")
  ),
  osdp_flags_auto_discovery_disabled: yup
    .boolean()
    .required()
    .default(false)
    .label("OSDP Auto Discovery"),
  osdp_flags_secure: yup
    .boolean()
    .required()
    .default(false)
    .label("OSDP Secure"),
  osdp_flags_tracing: yup
    .boolean()
    .required()
    .default(false)
    .label("OSDP Tracing"),
  keypad_mode: yup
    .string()
    .notOneOf([""], "Keypad Mode is a required field")
    .oneOf(ValidKeypadModes)
    .label("Keypad Mode"),
  dt_fmt: yup.array().of(yup.string()).required().label("Data Format"),
});

// TODO: Add OSDP doc link here to explain the options better
export const ReaderOsdpFields: React.VFC = () => {
  return (
    <VStack spacing={16}>
      <VStack spacing={8}>
        <FormCheckbox
          name={"osdp_flags_auto_discovery_disabled"}
          label={"OSDP Auto Discovery Disabled"}
        />
        <FormCheckbox name={"osdp_flags_secure"} label={"OSDP Secure"} />
        <FormCheckbox name={"osdp_flags_tracing"} label={"OSDP tracing"} />
      </VStack>
      <VStack spacing={16}>
        <FormSelectField
          required
          name={"osdp_flags_address"}
          label={"OSDP Address"}
          options={OsdpAddressOptions}
        />
        <FormSelectField
          required
          name={"osdp_flags_baud"}
          label={"OSDP Baud Rate"}
          options={OsdpBaudRateOptions}
        />

        <FormSelectField
          required
          name={"keypad_mode"}
          label={"Keypad mode"}
          options={KeypadModeOptions}
        />
      </VStack>
    </VStack>
  );
};
