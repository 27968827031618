import { useEffect } from "react";

import {
  VStack,
  FormikSelectField,
  FormikNumberInputField,
  useToast,
  Typography,
  Link,
} from "@smartrent/ui";

import { Form } from "formik";
import * as yup from "yup";

import { BaseForm } from "@/modules/base/Form";

import {
  getErrorMessage,
  InternalAPIAxiosErrorResponse,
} from "@/lib/axios-helpers";

import { ReaderQueries } from "../queries";
import {
  LEDColorOptions,
  Reader,
  SubmitKeyOptions,
  ValidLedColors,
  ValidSubmitKeys,
} from "../types";

interface FormWithAddressesProps {
  reader: Reader;
}

const validationSchema = yup.object().shape({
  address: yup.number().required(),
  led_color: yup.string().required().oneOf(ValidLedColors),
  submit_key: yup.string().required().oneOf(ValidSubmitKeys),
  pin_digits: yup.number().required().min(4).max(8),
});

export const FormWithoutAddresses: React.FC<
  React.PropsWithChildren<FormWithAddressesProps>
> = ({ reader }) => {
  const setToast = useToast();
  const { data, error, isLoading, refetch } =
    ReaderQueries.useListAvailableAddresses(reader);

  const showStartScanToast = () =>
    setToast({
      message:
        "Scanning for available OSDP addresses, this could take a minute",
      title: "Starting Scan",
      status: "knowledge",
    });

  useEffect(showStartScanToast, [setToast]);

  useEffect(() => {
    if (error) {
      setToast({
        message: `Scanning for available OSDP addresses failed with: ${getErrorMessage(error as InternalAPIAxiosErrorResponse)}`,
        title: "Scan Failed",
        status: "error",
      });
    }
  }, [error, setToast]);

  return (
    <BaseForm<Reader>
      initialValues={reader}
      validationSchema={validationSchema}
      QueryClient={ReaderQueries}
    >
      {() => (
        <>
          {error && (
            <Typography>
              Scan failed,{" "}
              <Link
                onPress={() => {
                  showStartScanToast();
                  refetch();
                }}
              >
                click to try again
              </Link>
            </Typography>
          )}
          <Form>
            <VStack spacing={16}>
              <FormikSelectField
                name={"address"}
                label={"Address"}
                options={data || []}
                isLoading={isLoading}
                // disabled={isLoading}
              />
              <FormikSelectField
                name={"led_color"}
                label={"LED Color"}
                options={LEDColorOptions}
              />
              <FormikSelectField
                name={"submit_key"}
                label={"Submit Key"}
                options={SubmitKeyOptions}
              />
              <FormikNumberInputField
                name={"pin_digits"}
                label={"Pin Digits"}
              />
            </VStack>
          </Form>
        </>
      )}
    </BaseForm>
  );
};
