import { Button, useTheme } from "@smartrent/ui";
import { View, ViewStyle, StyleProp } from "react-native";

import { useCallback, useContext } from "react";

import { useHistory } from "react-router-dom";

import { AuthContext } from "@/context/Auth";
interface SignOutButtonProps {
  style?: StyleProp<ViewStyle>;
}
export const SignOutButton: React.FC<
  React.PropsWithChildren<SignOutButtonProps>
> = ({ style }) => {
  const history = useHistory();
  const { logout } = useContext(AuthContext);
  const { colors } = useTheme();

  const onPress = useCallback(() => {
    logout();
    history.push(`/`);
  }, [history, logout]);

  return (
    <View style={style}>
      <Button
        onPress={onPress}
        size="small"
        style={{ backgroundColor: colors.gray100 }}
        textStyle={{ color: colors.gray600 }}
      >
        SIGN OUT
      </Button>
    </View>
  );
};
