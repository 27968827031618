import { BaseDrawer } from "../base/Drawer";

import { PanelForm } from "./Form";
import { Panel } from "./types";

interface PanelDrawerProps {
  initialValues?: Panel;
  params: {
    controller_id: number;
    site_id: number;
  };
}

export const PanelDrawer: React.FC<
  React.PropsWithChildren<PanelDrawerProps>
> = ({ initialValues, params }) => {
  return (
    <BaseDrawer
      title={initialValues?.id ? initialValues?.name : "New Panel"}
      subtitle={initialValues?.id ? "Edit Panel" : null}
    >
      <PanelForm initialValues={{ ...initialValues }} params={params} />
    </BaseDrawer>
  );
};
