import {
  VStack,
  FormikTextInputField,
  FormikPhoneInputField,
} from "@smartrent/ui";
import { Form } from "formik";
import { useCallback } from "react";
import * as yup from "yup";

import { BaseForm } from "../base/Form";

import { Operator } from "./types";
import { OperatorQueries } from "./queries";

interface OperatorFormProps {
  initialValues: Partial<Operator>;
  params: { site_id: number };
}

export const OperatorForm = ({ initialValues, params }: OperatorFormProps) => {
  const validationSchema = useCallback(() => {
    return yup.object().shape({
      first_name: yup.string().required("Required").max(40),
      last_name: yup.string().required("Required").max(40),
      email_address: yup.string().required("Required").email(),
      phone_number: yup.string().required("Required").phone(),
    });
  }, []);

  return (
    <BaseForm<Operator>
      initialValues={{
        site_id: params.site_id,
        ...initialValues,
      }}
      validationSchema={validationSchema()}
      QueryClient={OperatorQueries}
      params={params}
    >
      {() => {
        return (
          <Form>
            <VStack spacing={8}>
              <FormikTextInputField
                name="first_name"
                label="First Name"
                required
              />
              <FormikTextInputField
                name="last_name"
                label="Last Name"
                required
              />
              <FormikPhoneInputField
                name="phone_number"
                label="Phone Number"
                required
              />
              <FormikTextInputField
                name="email_address"
                label="Email"
                required
              />
            </VStack>
          </Form>
        );
      }}
    </BaseForm>
  );
};
