import { useParams } from "react-router-dom";

import {
  FormikTextInputField,
  Typography,
  FilterProps,
  FormikSelectField,
  Table,
  ListQueryResponse,
  useTableQuery,
  TypographyProps,
} from "@smartrent/ui";

import { useAppDrawer } from "@/components/layout/AppDrawer";

import { useDialog } from "@/context/dialog";

import { usePermissions } from "@/context/PolicyContext";

import { TableFilters } from "@/modules/base/table/types";

import { truncateString } from "@/lib/helpers";

import { CreateButton, createActionsColumn } from "../base/table/utils";

import { User, getRoleName, userRolesOptions } from "./types";
import { UserQueries } from "./queries";

interface UsersTableProps {
  title?: string;
  filters: Partial<TableFilters & User>;
  numberOfLines?: TypographyProps["numberOfLines"];
}

export const UsersTable = ({ filters }: UsersTableProps) => {
  const drawer = useAppDrawer();
  const params = useParams();
  const { confirm } = useDialog();
  const { canCreate, canDelete, canUpdate } = usePermissions(UserQueries);

  const [deleteUserMutation] = UserQueries.useDeleteMutation();

  const tableProps = useTableQuery<any, User, ListQueryResponse<User>>({
    fetch: UserQueries.fetch,
    getQueryKey: ({
      filters: tableFilters,
      page,
      pageSize,
      sortColumn,
      sortDirection,
    }) => [
      UserQueries.queryKey,
      {},
      {
        ...filters,
        page,
        per_page: pageSize,
        sort: sortColumn,
        dir: sortDirection,
        ...tableFilters,
      },
    ],

    columns: [
      {
        name: "first_name",
        header: "First Name",
        render: ({ row }) => {
          return <Typography>{row.first_name}</Typography>;
        },
        sortable: true,
        filter: (props: FilterProps) => {
          return <FormikTextInputField {...props} />;
        },
      },
      {
        name: "last_name",
        header: "Last Name",
        render: ({ row }) => {
          return <Typography>{row.last_name}</Typography>;
        },
        sortable: true,
        filter: (props: FilterProps) => {
          return <FormikTextInputField {...props} />;
        },
      },
      {
        name: "email",
        header: "Email",
        render: ({ row }) => {
          return <Typography>{truncateString(row.email, 25)}</Typography>;
        },
        sortable: false,
        filter: (props: FilterProps) => {
          return <FormikTextInputField {...props} />;
        },
      },
      {
        name: "role",
        header: "Role",
        render: ({ row }) => {
          return <Typography>{getRoleName(row.role)}</Typography>;
        },
        sortable: true,
        filter: (props: FilterProps) => {
          return <FormikSelectField options={userRolesOptions()} {...props} />;
        },
      },
      {
        name: "status",
        header: "Status",
        render: ({ row }) => {
          return (
            <Typography>
              {row.invitation_accepted_at ? "Active" : "Invited"}
            </Typography>
          );
        },
        sortable: true,
        filter: (props: FilterProps) => {
          return (
            <FormikSelectField
              options={[
                { label: "Active", value: "active" },
                { label: "Invited", value: "invited" },
              ]}
              {...props}
            />
          );
        },
      },
      createActionsColumn<User>({
        canDelete,
        onDelete: async (row) => {
          const confirmed = await confirm({
            title: "Delete",
            description: "Are you sure you want to delete this User?",
            confirmText: "Delete",
            confirmType: "destructive",
            cancelText: "Cancel",
          });
          if (confirmed) {
            deleteUserMutation({ id: row.id });
          }
        },
        canUpdate,
        onUpdate: async (row) =>
          drawer.push(UserQueries.queryKey, { initialValues: row, params }),
      }),
    ],
    defaultPageSize: 10,
  });

  return (
    <Table<User>
      title={"Users"}
      noRecordsText={`No Users Found`}
      action={
        <CreateButton
          canCreate={canCreate}
          onCreate={() => drawer.push(UserQueries.queryKey, { params })}
          text={"Add User"}
        />
      }
      {...tableProps}
    />
  );
};
