import ReactDOM from "react-dom/client";
import { Platform } from "react-native";

// import "./lib/sentry";
import App from "./App";

import "./lib/yupMethods";

// Prevent Android's soft keyboard from resizing the viewport when it opens, which
// breaks layouts pretty hard.
if (Platform.OS === "web") {
  const isAndroid = navigator.userAgent.toLowerCase().includes("android");

  if (isAndroid) {
    const metaViewport = document.createElement("meta");
    metaViewport.name = "viewport";
    metaViewport.content = `width=device-width, height=${window.innerHeight}, initial-scale=1.0, minimum-scale=1.0`;
    document.getElementsByTagName("head")[0].appendChild(metaViewport);
  }
}

const container = document.getElementById("react-root");
const root = ReactDOM.createRoot(container!);
root.render(<App />);
