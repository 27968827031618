import { BaseDrawer } from "../base/Drawer";

import { HolidayForm } from "./Form";
import { HolidaySchedule } from "./types";

interface HolidayScheduleDrawerProps {
  initialValues?: HolidaySchedule;
  params: { site_id: number; holiday_schedule_group_id: number };
}

export const HolidayScheduleDrawer: React.FC<
  React.PropsWithChildren<HolidayScheduleDrawerProps>
> = ({ initialValues, params }) => {
  return (
    <BaseDrawer
      title={initialValues?.id ? initialValues.name : "New Exception"}
      subtitle={initialValues?.id ? "Edit Exception" : null}
    >
      <HolidayForm initialValues={initialValues} params={params} />
    </BaseDrawer>
  );
};
