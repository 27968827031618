import { useState, useEffect } from "react";
import { get } from "lodash-es";
import axios from "axios";
import { Label, TextInput, Button } from "badmagic";
import { StyleSheet, View } from "react-native";
import { Plus } from "@smartrent/icons";

import { ProfileProps } from "../../types";

export function CreatePassBearerProfile({
  workspaceConfig,
  setProfiles,
  profiles,
  setError,
  setSuccess,
  setActiveProfile,
}: ProfileProps) {
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [token, setToken] = useState("");
  const [collapsed, setCollapsed] = useState(true);

  // Auto-collapse if we have profiles or if a new profile is added
  useEffect(() => {
    setCollapsed(!!profiles?.length);
  }, [profiles]);

  return (
    <View style={styles.root}>
      {collapsed ? (
        <Button
          onClick={() => setCollapsed(!collapsed)}
          title="Create Pass Bearer Auth Profile"
        >
          <div className="flex items-center justify-center">
            <Plus color="#eee" size={20} />
            <div className="ml-2">Create Pass Bearer Profile</div>
          </div>
        </Button>
      ) : null}
      {!collapsed ? (
        <View style={styles.profileFormContainer}>
          <Label>Create Pass Bearer Profile</Label>
          <TextInput
            type="text"
            value={name}
            placeholder="Profile Name"
            className="mb-1"
            onChange={(e) => setName(e.currentTarget.value)}
          />
          <TextInput
            type="text"
            value={phoneNumber}
            placeholder="Phone Number"
            className="mb-1"
            onChange={(e) => setPhoneNumber(e.currentTarget.value)}
          />
          <TextInput
            type="token"
            value={token}
            placeholder="Token"
            className="mb-1"
            onChange={(e) => setToken(e.currentTarget.value)}
          />
          <div className="flex mt-4">
            <button
              className="bg-red-500 hover:bg-red-700 text-white font-semibold py-2 px-4 rounded"
              onClick={() => {
                setError("");
                setSuccess("");
                setCollapsed(!collapsed);
              }}
            >
              Cancel
            </button>
            <Button
              className="ml-2"
              onClick={async () => {
                setError("");
                setSuccess("");

                if (!phoneNumber) {
                  setError("Please specify a phone number");
                  return;
                } else if (!token) {
                  setError("Please specify a token");
                  return;
                } else if (!name) {
                  setError("Please give your profile a name");
                  return;
                }

                setSuccess("Logging in, one moment");

                await axios({
                  url: `${workspaceConfig.baseUrl}${workspaceConfig.loginPath}`,
                  method: "POST",
                  data: {
                    phone_number: phoneNumber,
                    sms_token: token,
                  },
                })
                  .then((response) => {
                    const accessToken = get(
                      response,
                      `${workspaceConfig.pathToTokens}.access_token`
                    );
                    const refreshToken = get(
                      response,
                      `${workspaceConfig.pathToTokens}.refresh_token`
                    );

                    if (accessToken && refreshToken) {
                      setSuccess("Profile created and logged in");

                      const newProfiles = [...profiles];
                      const newProfile = {
                        name,
                        accessToken,
                        refreshToken,
                        phone_number: phoneNumber,
                        ...(process.env.NODE_ENV === "development" && {
                          sms_token: token,
                        }),
                      };

                      // Checks if the profile already existed in `auth-profiles`
                      // If the profile already exists, it replaces the pre-existing profile with the new profile
                      // If it didn't exist, it appends to the array
                      const profileIndex = newProfiles.findIndex((profile) => {
                        return profile.name === newProfile.name;
                      });
                      if (profileIndex !== -1) {
                        newProfiles.splice(profileIndex, 1, newProfile); // mutates array; replaces old profile with new
                      } else {
                        newProfiles.push(newProfile); // appends
                      }

                      setProfiles(newProfiles);
                      setActiveProfile(newProfile);
                      setCollapsed(true);
                    } else {
                      setError(
                        "Unable to fetch access_token and refresh_token from that endpoint, please contact Engineering."
                      );
                    }
                  })
                  .catch((err) => {
                    console.error(err);
                    if (err?.response?.data) {
                      setError(
                        `[${err?.response?.status}] - ${JSON.stringify(
                          err.response.data
                        )}`
                      );
                    } else {
                      setError(err.message);
                    }
                  });
              }}
            >
              Save Profile
            </Button>
          </div>
        </View>
      ) : null}
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    marginTop: "1rem",
    marginBottom: "1rem",
    marginLeft: "1rem",
  },
  profileFormContainer: {
    borderStyle: "dashed",
    borderWidth: 1,
    borderColor: "white",
    padding: 8,
  },
});
