import { truncateString } from "@/lib/helpers";

import { BaseDrawer } from "../base/Drawer";

import { User } from "./types";
import { UserForm } from "./Form";

interface UserDrawerProps {
  initialValues?: User;
  params: any;
}

export const UserDrawer: React.FC<React.PropsWithChildren<UserDrawerProps>> = ({
  initialValues,
  params,
}) => {
  return (
    <BaseDrawer
      title={
        initialValues?.id
          ? truncateString(
              `${initialValues?.first_name} ${initialValues?.last_name}`,
              15
            )
          : "New User"
      }
      subtitle={initialValues?.id ? "Edit User" : null}
    >
      <UserForm initialValues={{ ...initialValues }} params={params} />
    </BaseDrawer>
  );
};
