import { useContext } from "react";
import { View, StyleSheet, TouchableOpacity, ScrollView } from "react-native";

import { Drawer, useTheme } from "@smartrent/ui";
import { useWindowDimensionsQuery } from "@smartrent/hooks";
import { Close } from "@smartrent/icons";

import { NavigationLinksCtx } from "@/context/NavigationLinksContext";

import { NavGroup } from "./NavGroup";
import { Logo } from "./Logo";

export const NavPopover = ({
  visible,
  close,
}: {
  visible: boolean;
  close: VoidFunction;
}) => {
  const { colors } = useTheme();
  const { navigationLinks } = useContext(NavigationLinksCtx);
  const { isDesktop } = useWindowDimensionsQuery({
    isDesktop: ({ width }) => width >= 1200,
  });

  return (
    <Drawer
      anchor="left"
      open={!isDesktop && visible}
      onClose={close}
      maxWidth={250}
    >
      <ScrollView
        showsVerticalScrollIndicator={false}
        style={[styles.popoverContainer, { backgroundColor: colors.gray900 }]}
      >
        <View style={styles.popoverHeader}>
          <View style={styles.popoverCloseButtonContainer}>
            <TouchableOpacity onPress={close}>
              <Close color={colors.primary} />
            </TouchableOpacity>
          </View>
          <View style={styles.popoverLogoContainer}>
            <Logo size={20} />
          </View>
        </View>
        {navigationLinks.map((ngd, index) => (
          <NavGroup {...ngd} close={close} key={`${ngd.label}-${index}`} />
        ))}
      </ScrollView>
    </Drawer>
  );
};

const styles = StyleSheet.create({
  popoverContainer: {
    flexGrow: 1,
  },
  popoverHeader: {
    flexDirection: "row",
  },
  popoverCloseButtonContainer: {
    alignSelf: "flex-start",
    padding: 13,
  },
  popoverLogoContainer: {
    alignSelf: "center",
    flexGrow: 1,
    padding: 13,
  },
});
