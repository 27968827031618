import {
  FormikMultiSelectField,
  FormikNumberInputField,
  FormikTextInputField,
  HStack,
} from "@smartrent/ui";

import { useField } from "formik";

import { View, StyleSheet } from "react-native";

import { useEffect } from "react";

import { humanize } from "@/lib/helpers";

import { DoorAcrSelectField } from "@/modules/door/components/DoorSelectField";
import { MemberSelectField } from "@/modules/member/components/MemberSelectField";
import { CredentialSelectField } from "@/modules/credential/components/CredentialSelectField";
import { CredentialQueries } from "@/modules/credential/queries";
import { CredentialType } from "@/modules/credential/types";
import { CardFormatQueries } from "@/modules/card-format/queries";

import { CardFormatSelectField } from "@/modules/card-format/components/CardFormatSelectField";

import { StrSpecOptions } from "./str_spec";

/*
  When adding a new MPL command, you need to:

  1) add one line in AA Web to lib/alloy_access/cloud_bridge_api/mpl/debug.ex
  2) Add the MPL command to this enum
  3) Add the MPL command to MPLCommandParams (this is where you define the params you need with the command)
*/
export enum MPLCommand {
  custom = "custom",
  read_card_record = "read_card_record",
  sio_report = "sio_report",
  access_database_record = "access_database_record",
  acr_mode = "acr_mode",
  action_specification = "action_specification",
  attach_scp = "attach_scp",
  card_delete = "card_delete",
  configure_access_database = "configure_access_database",
  configure_access_level = "configure_access_level",
  configure_card_format = "configure_card_format",
  configure_door_acr = "configure_door_acr",
  configure_ds_time = "configure_ds_time",
  configure_elevator_acr = "configure_elevator_acr",
  configure_monitor_point = "configure_monitor_point",
  configure_msp1 = "configure_msp1",
  configure_scp = "configure_scp",
  configure_sio = "configure_sio",
  create_channel = "create_channel",
  create_scp = "create_scp",
  elevator_access_level_configuration = "elevator_access_level_configuration",
  elevator_access_level_spec = "elevator_access_level_spec",
  input_spec = "input_spec",
  modify_oem_code = "modify_oem_code",
  output_spec = "output_spec",
  procedure_control = "procedure_control",
  reader_spec = "reader_spec",
  remove_actions = "remove_actions",
  reset_scp = "reset_scp",
  scp_firmware_download = "scp_firmware_download",
  scp_report = "scp_report",
  scp_structure_status_read = "scp_structure_status_read",
  set_time = "set_time",
  simulate_card_read = "simulate_card_read",
  simulate_key_press = "simulate_key_press",
  sio_firmware_download = "sio_firmware_download",
  temp_acr_mode = "temp_acr_mode",
  time_zone_control = "time_zone_control",
  time_zone_spec = "time_zone_spec",
  trgger_variable_control = "trgger_variable_control",
  trigger_specification = "trigger_specification",
}

export enum CommandParamType {
  number = "number",
}

export const MPLCommandParams: {
  [key in MPLCommand]: {
    label: string;
    commandId: number | null;
    form?: (params: any) => JSX.Element | null;
    params?: Array<{
      name: string;
      type?: CommandParamType;
      label?: string;
      default?: string | number | Array<string> | Array<number>;
      component?: (params: any) => JSX.Element | null;
    }>;
  };
} = {
  [MPLCommand.custom]: {
    label: humanize(MPLCommand.custom),
    commandId: null,
    params: [],
    form: (params: any) => (
      <View>
        <View style={styles.custom_button}>
          <FormikTextInputField
            key="command-1"
            name="params.command"
            label="Command"
          />
        </View>
      </View>
    ),
  },
  [MPLCommand.read_card_record]: {
    label: humanize(MPLCommand.read_card_record),
    commandId: 1709,

    params: [
      {
        name: "nFirst",
        type: CommandParamType.number,
        label: "",
        default: "-1",
      },
      {
        name: "nCount",
        type: CommandParamType.number,
        label: "",
        default: "1",
      },
    ],
  },
  [MPLCommand.sio_report]: {
    label: humanize(MPLCommand.sio_report),
    commandId: 404,

    params: [
      { name: "first", type: CommandParamType.number, label: "" },
      { name: "count", type: CommandParamType.number, label: "" },
    ],
  },
  [MPLCommand.access_database_record]: {
    label: humanize(MPLCommand.access_database_record),
    commandId: 8304,
  },
  [MPLCommand.acr_mode]: {
    label: humanize(MPLCommand.acr_mode),
    commandId: 308,
  },
  [MPLCommand.action_specification]: {
    label: humanize(MPLCommand.action_specification),
    commandId: 118,
  },
  [MPLCommand.attach_scp]: {
    label: humanize(MPLCommand.attach_scp),
    commandId: 207,

    params: [
      {
        name: "channel_id",
        type: CommandParamType.number,
        label: "",
        default: 0,
      },
    ],
  },
  [MPLCommand.card_delete]: {
    label: humanize(MPLCommand.card_delete),
    commandId: 3305,
  },
  [MPLCommand.configure_access_database]: {
    label: humanize(MPLCommand.configure_access_database),
    commandId: 1105,
  },
  [MPLCommand.configure_access_level]: {
    label: humanize(MPLCommand.configure_access_level),
    commandId: 116,
  },
  [MPLCommand.configure_card_format]: {
    label: humanize(MPLCommand.configure_card_format),
    commandId: 1102,
  },
  [MPLCommand.configure_door_acr]: {
    label: humanize(MPLCommand.configure_door_acr),
    commandId: 115,
  },
  [MPLCommand.configure_ds_time]: {
    label: humanize(MPLCommand.configure_ds_time),
    commandId: 1116,
  },
  [MPLCommand.configure_elevator_acr]: {
    label: humanize(MPLCommand.configure_elevator_acr),
    commandId: 115,
  },
  [MPLCommand.configure_monitor_point]: {
    label: humanize(MPLCommand.configure_monitor_point),
    commandId: 113,
  },
  [MPLCommand.configure_msp1]: {
    label: humanize(MPLCommand.configure_msp1),
    commandId: 108,
  },
  [MPLCommand.configure_scp]: {
    label: humanize(MPLCommand.configure_scp),
    commandId: 1107,
  },
  [MPLCommand.configure_sio]: {
    label: humanize(MPLCommand.configure_sio),
    commandId: 109,
  },
  [MPLCommand.create_channel]: {
    label: humanize(MPLCommand.create_channel),
    commandId: 12,
  },
  [MPLCommand.create_scp]: {
    label: humanize(MPLCommand.create_scp),
    commandId: 1013,
  },
  [MPLCommand.elevator_access_level_configuration]: {
    label: humanize(MPLCommand.elevator_access_level_configuration),
    commandId: 502,
  },
  [MPLCommand.elevator_access_level_spec]: {
    label: humanize(MPLCommand.elevator_access_level_spec),
    commandId: 501,
  },
  [MPLCommand.input_spec]: {
    label: humanize(MPLCommand.input_spec),
    commandId: 110,
  },
  [MPLCommand.modify_oem_code]: {
    label: humanize(MPLCommand.modify_oem_code),
    commandId: 330,
  },
  [MPLCommand.output_spec]: {
    label: humanize(MPLCommand.output_spec),
    commandId: 111,
  },
  [MPLCommand.procedure_control]: {
    label: humanize(MPLCommand.procedure_control),
    commandId: 312,
  },
  [MPLCommand.reader_spec]: {
    label: humanize(MPLCommand.reader_spec),
    commandId: 112,
  },
  [MPLCommand.remove_actions]: {
    label: humanize(MPLCommand.remove_actions),
    commandId: 119,
  },
  [MPLCommand.reset_scp]: {
    label: humanize(MPLCommand.reset_scp),
    commandId: 301,
    params: [],
  },
  [MPLCommand.scp_firmware_download]: {
    label: humanize(MPLCommand.scp_firmware_download),
    commandId: 206,
  },
  [MPLCommand.scp_report]: {
    label: humanize(MPLCommand.scp_report),
    commandId: 401,
    params: [],
  },
  [MPLCommand.scp_structure_status_read]: {
    label: humanize(MPLCommand.scp_structure_status_read),
    commandId: 1853,
    params: [
      {
        name: "structures",
        component: function Form(params: any) {
          // This is broken until https://github.com/smartrent/js-shared/issues/1522 is resolved.
          // return <FormikMultiSelectField options={StrSpecOptions} {...params} />
          // Until then, we can hack around the bug like this:
          const [, , { setValue: setStructures }] =
            useField("params.structures");
          const [{ value: structures }] = useField("structures");

          useEffect(() => {
            setStructures(structures || []);
            // eslint-disable-next-line react-hooks/exhaustive-deps -- Creates an inf loop
          }, [structures]);

          return (
            <FormikMultiSelectField
              required={true}
              options={StrSpecOptions}
              {...params}
              name={"structures"}
            />
          );
        },
        default: [],
      },
    ],
  },
  [MPLCommand.set_time]: {
    label: humanize(MPLCommand.set_time),
    commandId: 302,
  },
  [MPLCommand.simulate_card_read]: {
    label: humanize(MPLCommand.simulate_card_read),
    commandId: 331,
    form: function Form(helpers) {
      const [, , { setValue: setCardId }] = useField("params.card_id");
      const [, , { setValue: setCardFormat }] = useField("params.card_format");
      const [, , { setValue: setFacilityCode }] = useField(
        "params.facility_code"
      );
      const [{ value: credential_id }] = useField("credential_id");

      const { data: credential } = CredentialQueries.useQuery({
        id: credential_id,
      });

      const { data: card_format } = CardFormatQueries.useQuery({
        id: credential?.card_format_id,
      });

      useEffect(() => {
        if (!credential) return;
        setCardId(credential?.number);
        // eslint-disable-next-line react-hooks/exhaustive-deps -- Creates an inf loop
      }, [credential]);

      useEffect(() => {
        if (!card_format) return;
        setCardFormat(card_format?.format_number);
        setFacilityCode(`${card_format?.facility_code}`);
        // eslint-disable-next-line react-hooks/exhaustive-deps -- Creates an inf loop
      }, [card_format]);

      return (
        <View>
          <HStack>
            <View style={styles.inputContainer}>
              <DoorAcrSelectField
                site_id={helpers.values.site_id}
                controller_id={helpers.values.controller_id}
                label="Door"
                name={"params.acr_id"}
                filterParams={{}}
              />
            </View>
            <View style={styles.inputContainer}>
              <FormikTextInputField name={"params.card_id"} label={"Card ID"} />
            </View>
            <View style={styles.inputContainer}>
              <FormikNumberInputField
                name={"params.card_format"}
                label={"Card Format"}
              />
            </View>
            <View style={styles.inputContainer}>
              <CardFormatSelectField
                name={"params.facility_code"}
                label={"Facility Code"}
                site_id={helpers.values.site_id}
                lookup_key={"facility_code"}
              />
            </View>
          </HStack>
          <HStack>
            <View style={styles.inputContainer}>
              <MemberSelectField
                site_id={helpers.values.site_id}
                name="member_id"
                label="Member"
                filterParams={{}}
              />
            </View>
            {helpers.values.member_id ? (
              <View style={styles.inputContainer}>
                <CredentialSelectField
                  member_id={helpers.values.member_id}
                  name="credential_id"
                  label="Credential"
                  filterParams={{ type: CredentialType.card }}
                />
              </View>
            ) : null}
          </HStack>
        </View>
      );
    },
    params: [],
  },
  [MPLCommand.simulate_key_press]: {
    label: humanize(MPLCommand.simulate_key_press),
    commandId: 339,
    form: function Form(helpers) {
      const [, , { setValue: setKeys }] = useField("params.keys");
      const [{ value: pin }] = useField("pin");

      useEffect(() => {
        if (!pin) return;
        setKeys(`${pin}#`);
        // eslint-disable-next-line react-hooks/exhaustive-deps -- Creates an inf loop
      }, [pin]);

      return (
        <View>
          <HStack>
            <View style={styles.inputContainer}>
              <DoorAcrSelectField
                site_id={helpers.values.site_id}
                controller_id={helpers.values.controller_id}
                label="Door"
                name={"params.acr_id"}
                filterParams={{}}
              />
            </View>
            <View style={styles.inputContainer}>
              <FormikTextInputField name="params.keys" label="Pin" />
            </View>
          </HStack>
          <HStack>
            <View style={styles.inputContainer}>
              <MemberSelectField
                site_id={helpers.values.site_id}
                name="member_id"
                label="Member"
                filterParams={{}}
              />
            </View>
            {helpers.values.member_id ? (
              <View style={styles.inputContainer}>
                <CredentialSelectField
                  member_id={helpers.values.member_id}
                  name="pin"
                  label="Credential"
                  filterParams={{ type: CredentialType.pin }}
                  lookup_key="pin"
                />
              </View>
            ) : null}
          </HStack>
        </View>
      );
    },
    params: [],
  },
  [MPLCommand.sio_firmware_download]: {
    label: humanize(MPLCommand.sio_firmware_download),
    commandId: 328,
  },
  [MPLCommand.temp_acr_mode]: {
    label: humanize(MPLCommand.temp_acr_mode),
    commandId: 334,
  },
  [MPLCommand.time_zone_control]: {
    label: humanize(MPLCommand.time_zone_control),
    commandId: 314,
  },
  [MPLCommand.time_zone_spec]: {
    label: humanize(MPLCommand.time_zone_spec),
    commandId: 2103,
  },
  [MPLCommand.trgger_variable_control]: {
    label: humanize(MPLCommand.trgger_variable_control),
    commandId: 313,
  },
  [MPLCommand.trigger_specification]: {
    label: humanize(MPLCommand.trigger_specification),
    commandId: 117,
  },
};

const MPLCommandList = Object.keys(MPLCommandParams);
export const MPLCommandOptions: Array<{ label: string; value: string }> =
  MPLCommandList.map((command: string) => {
    if (
      !MPLCommandParams[command as MPLCommand].params &&
      !MPLCommandParams[command as MPLCommand].form
    ) {
      return null;
    }
    const commandId = MPLCommandParams[command as MPLCommand].commandId;
    const commadIdString = commandId ? `[${commandId}]` : "";
    return {
      label: `${
        MPLCommandParams[command as MPLCommand].label
      } ${commadIdString}`,
      value: command,
    };
  }).filter((val) => !!val) as Array<{ label: string; value: string }>;

const styles = StyleSheet.create({
  inputContainer: {
    padding: 4,
  },
  custom_button: {
    flexGrow: 4,
  },
});
