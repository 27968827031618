import {
  FilterProps,
  FormikSelectField,
  FormikTextInputField,
  ListQueryResponse,
  Table,
  Typography,
  useTableQuery,
} from "@smartrent/ui";
import { useHistory, useParams } from "react-router-dom";

import { Paths } from "@/lib/path";
import {
  DeviceStatus,
  StatusDisplay,
} from "@/components/alloy-access/StatusDisplay";

import { booleanOptions } from "@/pages/dev/badmagic/workspaces/shared";

import { useAppDrawer } from "@/components/layout/AppDrawer";
import { useDialog } from "@/context/dialog";
import { usePermissions } from "@/context/PolicyContext";

import { TableFilters } from "@/modules/base/table/types";

import { createActionsColumn, CreateButton } from "../base/table/utils";

import { HubQueries } from "./queries";
import { Hub } from "./types";

interface HubsTableProps {
  filters: Partial<TableFilters & Hub>;
}

export const HubsTable = ({ filters }: HubsTableProps) => {
  const drawer = useAppDrawer();
  const params = useParams();
  const history = useHistory();

  const { confirm } = useDialog();
  const [doDelete] = HubQueries.useDeleteMutation();

  const { canView, canCreate, canDelete, canUpdate } =
    usePermissions(HubQueries);

  const tableProps = useTableQuery<any, Hub, ListQueryResponse<Hub>>({
    fetch: HubQueries.fetch,
    getQueryKey: ({
      filters: tableFilters,
      page,
      pageSize,
      sortColumn,
      sortDirection,
    }) => [
      HubQueries.queryKey,
      {},
      {
        ...filters,
        page,
        per_page: pageSize,
        sort: sortColumn,
        dir: sortDirection,
        ...tableFilters,
      },
    ],
    columns: [
      {
        name: "name",
        header: "Name",
        render: ({ row }) => {
          return (
            <Typography>
              {row.name}
              {row.virtual ? "*" : ""}
            </Typography>
          );
        },
        sortable: true,
        filter: (props: FilterProps) => {
          return <FormikTextInputField {...props} />;
        },
      },
      {
        name: "serial_like",
        header: "Serial",
        render: ({ row }) => {
          return <Typography type="body">{row.serial}</Typography>;
        },
        sortable: true,
        filter: (props: FilterProps) => {
          return <FormikTextInputField {...props} />;
        },
      },
      {
        name: "online",
        header: "Online",
        render: ({ row }) => {
          return (
            <StatusDisplay
              status={row.online ? DeviceStatus.Online : DeviceStatus.Offline}
            />
          );
        },
        sortable: true,
        filter: (props: FilterProps) => {
          return <FormikSelectField {...props} options={booleanOptions} />;
        },
      },
      createActionsColumn<Hub>({
        shouldAdjustForPressIcon: canView,
        canDelete: (row) => canDelete && !row.virtual,
        onDelete: async (row) => {
          const confirmed = await confirm({
            title: "Delete",
            description: "Are you sure you want to delete this Hub?",
            confirmText: "Delete",
            confirmType: "destructive",
            cancelText: "Cancel",
          });
          if (confirmed) {
            doDelete({ id: row.id });
          }
        },
        canUpdate,
        onUpdate: async (row) =>
          drawer.push(HubQueries.queryKey, { initialValues: row, params }),
      }),
    ],
    defaultPageSize: 10,
  });

  return (
    <Table<Hub>
      title={"Hubs"}
      noRecordsText={`No Hubs Found`}
      action={
        <CreateButton
          canCreate={canCreate}
          onCreate={() => drawer.push(HubQueries.queryKey, { params })}
          text={"Add Hub"}
        />
      }
      onRowPress={
        canView
          ? (row) => history.push(Paths.hubViewPath(row.site_id, row.id))
          : undefined
      }
      {...tableProps}
    />
  );
};
