import { LoadingCard, Typography } from "@smartrent/ui";

import { StyleSheet, View } from "react-native";

import { ErrorPage } from "@/components/ErrorPage";

import { FormProps } from "../base/Form";

import { SiteQueries } from "../site/queries";

import { SiteImport, SiteImportStatus } from "./types";
import { NewImportForm } from "./components/NewImportForm";
import { ExistingImportForm } from "./components/ExistingImportForm";

export const SiteImportForm = ({
  initialValues,
  params,
}: FormProps<SiteImport>) => {
  const { data: site, isLoading } = SiteQueries.useQuery({
    id: params.site_id,
  });

  if (isLoading) return <LoadingCard />;
  if (!site) return <ErrorPage title="Failed to load site." />;

  if (!initialValues?.id) {
    return (
      <NewImportForm
        initialValues={initialValues}
        site={site}
        params={params}
      />
    );
  }
  if (initialValues?.status === SiteImportStatus.new) {
    return (
      <ExistingImportForm
        initialValues={initialValues}
        site={site}
        params={params}
      />
    );
  }
  return (
    <View style={styles.importCompleteMessage}>
      <Typography>This import has completed.</Typography>
    </View>
  );
};

const styles = StyleSheet.create({
  importCompleteMessage: {
    padding: 16,
  },
});
