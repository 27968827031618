import React, { useContext } from "react";
import { HStack, useTheme } from "@smartrent/ui";
import { View, StyleSheet, TouchableOpacity } from "react-native";

import { useModalState, useWindowDimensionsQuery } from "@smartrent/hooks";
import { Hamburger, IconProps } from "@smartrent/icons";

import { NineSquareButton } from "@smartrent/central-station-ui";

import { NavigationLinksCtx } from "@/context/NavigationLinksContext";
import { NavigationStateProvider } from "@/context/NavigationStateContext";

import { fetchApps, onPressApp } from "@/modules/nine-square-button/queries";

import { NavGroup } from "./NavGroup";
import { NavPopover } from "./NavPopover";
import { Logo } from "./Logo";

export interface LinkGroupData {
  label: string;
  icon: any;
  items: LinkData[];
}

export interface LinkData {
  label: string;
  url: string;
  icon?: React.ComponentType<React.PropsWithChildren<IconProps>>;
}

interface NavDrawerProps {
  isDesktop: boolean;
  toggleDrawer: VoidFunction;
  navigationLinks: LinkGroupData[];
  isPopoverVisible: boolean;
  closeDrawer: VoidFunction;
}

const NavDrawer = React.memo(
  ({
    isDesktop,
    toggleDrawer,
    navigationLinks,
    isPopoverVisible,
    closeDrawer,
  }: NavDrawerProps) => {
    const { colors } = useTheme();
    return (
      <NavigationStateProvider>
        <View
          style={[
            isDesktop && styles.desktopRoot,
            { backgroundColor: colors.gray900 },
          ]}
        >
          {isDesktop && (
            <View style={styles.logoContainer}>
              {process.env.API_BASE_URL !== "https://api.alloyaccess.com" ? (
                <HStack spacing={16} align="center">
                  <NineSquareButton
                    fetchApps={fetchApps}
                    onPressApp={onPressApp}
                    onPressViewAll={onPressApp}
                  />
                  <Logo width={160} size={20} />
                </HStack>
              ) : (
                <Logo size={20} />
              )}
            </View>
          )}
          <View
            style={[
              styles.headerContainer,
              isDesktop && styles.desktopHeaderContainer,
            ]}
          >
            {!isDesktop && (
              <>
                <View style={styles.hamburgerButtonContainer}>
                  <TouchableOpacity
                    style={styles.hamburgerButton}
                    onPress={toggleDrawer}
                  >
                    <Hamburger color={colors.primary} />
                  </TouchableOpacity>
                </View>

                {process.env.API_BASE_URL !== "https://api.alloyaccess.com" ? (
                  <View style={styles.hamburgerButtonContainer}>
                    <NineSquareButton
                      fetchApps={fetchApps}
                      onPressApp={onPressApp}
                      onPressViewAll={onPressApp}
                    />
                  </View>
                ) : null}
                <View style={styles.mobileLogoContainer}>
                  <Logo size={20} />
                </View>
              </>
            )}

            {isDesktop && (
              <View style={styles.desktopNavContainer}>
                {navigationLinks.map((ngd: LinkGroupData, index: number) => (
                  <NavGroup {...ngd} key={`${ngd.label}-${index}`} />
                ))}
              </View>
            )}
          </View>
          <NavPopover visible={isPopoverVisible} close={closeDrawer} />
        </View>
      </NavigationStateProvider>
    );
  }
);

export const NavDrawerContainer = () => {
  const {
    onToggle: toggleDrawer,
    visible: isPopoverVisible,
    onClose: closeDrawer,
  } = useModalState();
  const { navigationLinks } = useContext(NavigationLinksCtx);
  const { isDesktop } = useWindowDimensionsQuery({
    isDesktop: ({ width }) => width >= 1200,
  });
  return (
    <NavDrawer
      {...{
        isDesktop,
        toggleDrawer,
        navigationLinks,
        isPopoverVisible,
        closeDrawer,
      }}
    />
  );
};

const styles = StyleSheet.create({
  desktopRoot: {
    minHeight: "100vh",
    minWidth: 245,
    maxWidth: 245,
  },
  headerContainer: {
    flexDirection: "row",
  },
  desktopHeaderContainer: {
    flexDirection: "row",
  },
  hamburgerButtonContainer: {
    alignSelf: "center",
    flexDirection: "row",
  },
  hamburgerButton: {
    flexGrow: 1,
    padding: 14,
  },
  logoContainer: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 24,
    paddingBottom: 48,
  },
  mobileLogoContainer: {
    alignSelf: "center",
    flexGrow: 1,
  },
  desktopNavContainer: {
    flexGrow: 1,
  },
});
