import { Chip, ChipProps } from "@smartrent/ui";

import {
  CheckCircleSolid,
  ExclamationCircleSolid,
  IconProps,
} from "@smartrent/icons";

import { useHistory } from "react-router-dom";

import { useCallback, useContext, useMemo } from "react";

import { PageCtx } from "@/context/PageContext";
import { pluralizeLabel } from "@/lib/helpers";

export const SystemOverviewButton = ({ style }: Partial<ChipProps>) => {
  const history = useHistory();
  const { systemIssues, site } = useContext(PageCtx);

  const onPress = useCallback(
    () => history.push(`/sites/${site?.id}/system-health`),
    [history, site?.id]
  );

  const Icon = useMemo(
    () => (systemIssues?.length ? ExclamationCircleSolid : CheckCircleSolid),
    [systemIssues?.length]
  );

  if (!systemIssues || !site) {
    return null;
  }

  return (
    <Chip
      style={style}
      onPress={onPress}
      iconLeft={(props: IconProps) => <Icon {...props} size={32} />}
      color={systemIssues?.length ? "error" : "success"}
      size={"medium"}
      variation="plain"
    >
      {systemIssues?.length
        ? `${systemIssues.length} ${pluralizeLabel(
            "Issue",
            systemIssues.length
          )}`
        : "No Issues"}
    </Chip>
  );
};
