import { BaseDrawer } from "../base/Drawer";

import { AccessLevelForm } from "./Form";
import { AccessLevel } from "./types";

interface AccessLevelDrawerProps {
  initialValues?: AccessLevel;
  params: { site_id: number };
}

export const AccessLevelDrawer: React.FC<
  React.PropsWithChildren<AccessLevelDrawerProps>
> = ({ initialValues, params }) => {
  return (
    <BaseDrawer
      title={initialValues?.id ? `${initialValues.name}` : "New Access Level"}
      subtitle={initialValues?.id ? "Edit Access Level" : null}
    >
      <AccessLevelForm initialValues={initialValues} params={params} />
    </BaseDrawer>
  );
};
