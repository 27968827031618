import { useCallback } from "react";
import { StyleSheet, View } from "react-native";
import { useHistory, useParams } from "react-router-dom";

import {
  Link,
  TextThemeColor,
  Typography,
  useTheme,
  useToast,
} from "@smartrent/ui";

import { Logo } from "@/components/alloy-access/Logo";

import { AuthQueries } from "@/modules/auth/queries";
import {
  PasswordForm,
  PasswordParams,
} from "@/modules/user/components/PasswordForm";

export const AcceptInvitationPage = () => {
  const { invitationToken, base64Email } = useParams<{
    base64Email: string;
    invitationToken: string;
  }>();
  const { colors } = useTheme();

  const history = useHistory();
  const setToast = useToast();

  const [acceptInvite] = AuthQueries.useAcceptInviteMutation({
    onSuccess: () => {
      history.push("/");
      setToast({
        message: "Your account has been activated. You may now log in.",
        status: "success",
        title: "Success",
      });
    },
  });

  const handleSubmit = useCallback(
    async (values: PasswordParams) => {
      acceptInvite(
        Object.assign({ invitation_token: invitationToken }, values)
      ).catch(() => {
        /* No-op since the mutation handles this for us */
      });
    },
    [acceptInvite, invitationToken]
  );

  return (
    <View style={styles.root}>
      <View style={styles.container}>
        <View style={styles.logoContainer}>
          <Logo size={28.5} />
        </View>

        <Typography
          type="title3"
          color={colors.gray700 as TextThemeColor}
          style={styles.centeredHeader}
        >
          Create Account
        </Typography>

        <PasswordForm
          email={atob(base64Email)}
          handleSubmit={handleSubmit}
          submitText="Create Account"
        />
      </View>

      <Typography type="bodySmall" style={styles.marginTop20}>
        Already have an account?{" "}
        <Link type="bodySmall" href="/">
          Sign In
        </Link>
      </Typography>
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    alignItems: "center",
    justifyContent: "center",
    padding: 16,
  },
  container: {
    minWidth: 350,
  },
  logoContainer: {
    marginBottom: 45,
    minWidth: 325,
  },
  centeredHeader: {
    textAlign: "center",
    marginBottom: 40,
  },
  marginTop20: {
    marginTop: 20,
  },
});
