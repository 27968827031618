import { ListItemContainer, ListItemLabelValue } from "@smartrent/ui";

export const InformationItem = ({
  label,
  value,
}: {
  label: string;
  value?: string | number;
}) => {
  return (
    <ListItemContainer>
      <ListItemLabelValue
        label={label}
        value={value ? (value as string) : "-"}
      />
    </ListItemContainer>
  );
};
