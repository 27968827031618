import { BaseDrawer } from "../base/Drawer";

import { Hub } from "./types";
import { HubForm } from "./Form";

interface HubDrawerProps {
  initialValues?: Hub;
  params: {
    site_id: number;
  };
}

export const HubDrawer: React.FC<React.PropsWithChildren<HubDrawerProps>> = ({
  initialValues,
  params,
}) => {
  return (
    <BaseDrawer
      title={initialValues?.id ? initialValues?.name : "New Cloud Bridge"}
      subtitle={initialValues?.id ? "Edit Cloud Bridge" : null}
    >
      <HubForm initialValues={initialValues} params={params} />
    </BaseDrawer>
  );
};
