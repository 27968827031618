import {
  Typography,
  TypographyProps,
  Stack,
  DirectionStackProps,
} from "@smartrent/ui";

import {
  formatDateTime,
  TIME_FORMAT,
  DATE_FORMAT_SHORT,
} from "@/lib/formatters";

interface DateTimeTypographyProps extends Partial<TypographyProps> {
  dateTime?: string;
  timezone?: string;
  wrap?: DirectionStackProps["wrap"];
  disabled?: boolean;
  dayFormat?: string;
  timeDisplayColor?: TypographyProps["color"];
}

export const DateTimeTypography: React.FC<
  React.PropsWithChildren<DateTimeTypographyProps>
> = ({
  dateTime,
  timezone = "",
  wrap = true,
  numberOfLines = 2,
  dayFormat = DATE_FORMAT_SHORT,
  disabled,
  timeDisplayColor,
  ...props
}) => {
  const color = disabled ? "textDisabled" : "textPrimary";

  return (
    <Stack wrap={wrap}>
      <Typography numberOfLines={numberOfLines} color={color} {...props}>
        {formatDateTime(`${dateTime}`, timezone, dayFormat)}
      </Typography>
      <Typography
        numberOfLines={numberOfLines}
        color={timeDisplayColor || color}
        {...props}
      >
        {formatDateTime(`${dateTime}`, timezone, TIME_FORMAT)}
      </Typography>
    </Stack>
  );
};
