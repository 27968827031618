import { Route, Switch, useRouteMatch, useParams } from "react-router-dom";

import { LoadingCard } from "@smartrent/ui";

import { ErrorPage } from "@/components/ErrorPage";
import { Site } from "@/modules/site/types";
import { HubQueries } from "@/modules/hub/queries";

import { HubsListPage } from "./HubsListPage";
import { HubsShowPage } from "./HubsShowPage";

interface HubsRouterProps {
  site: Site;
}

const HubSubRouter = ({ site }: HubsRouterProps) => {
  const { path } = useRouteMatch();
  const { hub_id } = useParams<{ hub_id: string }>();
  const { data: hub, isLoading } = HubQueries.useQuery({
    id: parseInt(hub_id as string),
  });

  if (isLoading) return <LoadingCard />;
  if (!hub) return <ErrorPage title={"Failed to load hub"} />;

  return (
    <Switch>
      <Route exact path={`${path}`}>
        <HubsShowPage hub={hub} site={site} />
      </Route>
    </Switch>
  );
};

export const HubsRouter = ({ site }: HubsRouterProps) => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}`}>
        <HubsListPage site={site} />
      </Route>
      <Route exact path={`${path}/:hub_id`}>
        <HubSubRouter site={site} />
      </Route>
    </Switch>
  );
};
