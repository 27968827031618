import {
  VStack,
  FormikTextInputField,
  FormikSelectField,
  FormikFileUploadField,
} from "@smartrent/ui";
import { Form } from "formik";
import * as yup from "yup";

import { FormProps, BaseForm } from "../base/Form";
import { MplControllerModelOptions } from "../controller/types";

import { FirmwareQueries } from "./queries";
import { Firmware } from "./types";

const ONE_HUNDRED_MB = 100 * 1024;

const createValidationSchema = yup.object().shape({
  model: yup.string().required("Required").max(40),
  version: yup.string().required("Required").max(40),
  firmware_url: yup.string().required("Please Upload a Firmware File"),
  release_notes_url: yup.string(),
});

const editValidationSchema = yup.object().shape({
  model: yup.string().required().label("Model"),
  release_notes_url: yup
    .string()
    .required("No release notes file provided")
    .nullable(),
});

export const FirmwareForm = ({
  initialValues,
  params,
}: FormProps<Firmware>) => {
  return (
    <BaseForm<Firmware>
      initialValues={{
        ...initialValues,
      }}
      validationSchema={
        initialValues?.id ? editValidationSchema : createValidationSchema
      }
      QueryClient={FirmwareQueries}
      params={params}
      saveText="Upload"
    >
      {() => (
        <Form>
          <VStack spacing={16}>
            <FormikSelectField
              name="model"
              disabled={!!initialValues?.id}
              label="Model"
              options={MplControllerModelOptions}
              required
            />
            <FormikTextInputField
              name="version"
              disabled={!!initialValues?.id}
              label="Version"
              required
            />
            {!initialValues?.id && (
              <FormikFileUploadField
                name="firmware_url"
                accept={[".aax", ".crc"]}
                label="Firmware File"
                maxSize={ONE_HUNDRED_MB}
                required
              />
            )}
            <FormikFileUploadField
              name="release_notes_url"
              accept={[".pdf", ".txt", ".doc", ".docx"]}
              label="Release Notes"
              required={!!initialValues?.id}
            />
          </VStack>
        </Form>
      )}
    </BaseForm>
  );
};
