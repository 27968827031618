import { View, StyleSheet } from "react-native";

import { useContext, useEffect } from "react";

import { VStack } from "@smartrent/ui";

import { Site } from "@/modules/site/types";
import { Controller } from "@/modules/controller/types";

import { PageCtx } from "@/context/PageContext";
import { Paths } from "@/lib/path";

import { ControllerInformationPanel } from "./shared/InformationPanel";
import { ControllerDebugPanel } from "./debug/DebugPanel";

interface ControllerDebugPageProps {
  site: Site;
  controller: Controller;
}

export const ControllerDebugPage = ({
  site,
  controller,
}: ControllerDebugPageProps) => {
  const { addBreadcrumb, breadCrumbs } = useContext(PageCtx);

  useEffect(() => {
    addBreadcrumb({
      label: `Debug`,
      to: Paths.controllerDebugPath(site.id, controller.id),
    });
  }, [breadCrumbs, site.id, controller.id, addBreadcrumb]);

  return (
    <VStack>
      <View style={styles.left}>
        <View style={styles.informationContainer}>
          <ControllerInformationPanel
            controller={controller as Controller}
            site={site}
          />
        </View>
      </View>
      <View style={styles.right}>
        <View style={styles.actionsContainer}>
          <ControllerDebugPanel
            controller={controller as Controller}
            site={site}
          />
        </View>
      </View>
    </VStack>
  );
};

// TODO: Make this not suck
const styles = StyleSheet.create({
  informationContainer: {
    padding: 16,
  },
  actionsContainer: {
    padding: 16,
  },
  left: {
    flexGrow: 0.25,
  },
  right: {
    flexGrow: 1,
  },
});
