import { VStack } from "@smartrent/ui";

import { FormField, FormSelectField } from "@smartrent/forms";

import { UseQueryResult } from "@tanstack/react-query";

import { ControllerQueries } from "@/modules/controller/queries";
import { Controller } from "@/modules/controller/types";
import { Panel } from "@/modules/panel/types";
import { Output } from "@/modules/output/types";
import { Input } from "@/modules/input/types";
import { Reader } from "@/modules/reader/types";

import { DoorConfigurations } from "../../door/types";

interface ElevatorAsyncDataFieldsProps {
  controller_id: number;
  disabled?: boolean;
}

const getReaderOptions = (
  controller: UseQueryResult<Controller | null>["data"]
) => {
  if (!controller?.panels) return [];
  return controller.panels
    .map((panel: Panel) => {
      if (!panel?.readers) return [];
      return panel.readers.map((reader: Reader) => ({
        value: reader.id,
        label: `${panel.name}: ${reader.name}`,
      }));
    })
    .flat();
};

const getOutputsOptions = (
  controller: UseQueryResult<Controller | null>["data"]
) => {
  if (!controller?.panels) return [];
  return controller.panels
    .map((panel: Panel) => {
      if (!panel?.outputs) return [];
      return panel.outputs.map((output: Output) => ({
        value: output.id,
        label: `${panel.name}: ${output.name}`,
      }));
    })
    .flat();
};

const getInputsOptions = (
  controller: UseQueryResult<Controller | null>["data"]
) => {
  if (!controller?.panels) return [];
  return controller.panels
    .map((panel: Panel) => {
      if (!panel?.inputs) return [];
      return panel.inputs.map((input: Input) => ({
        value: input.id,
        label: `${panel.name}: ${input.name}`,
      }));
    })
    .flat();
};

export const ElevatorAsyncDataFields: React.FC<
  React.PropsWithChildren<ElevatorAsyncDataFieldsProps>
> = ({ controller_id, disabled }) => {
  const { data: controller, isLoading } = ControllerQueries.useQuery({
    id: controller_id,
  });

  const readerOptions = getReaderOptions(controller);
  const inputOptions = getInputsOptions(controller);
  const outputOptions = getOutputsOptions(controller);

  return (
    <VStack spacing={8}>
      <FormSelectField
        isLoading={isLoading}
        closeOnChange={true}
        name="reader_id"
        label="Reader"
        options={readerOptions}
        disabled={!controller_id || disabled}
      />
      <FormSelectField
        isLoading={isLoading}
        closeOnChange={true}
        name="strike_id"
        label="First Output"
        options={outputOptions}
        disabled={!controller_id || disabled}
      />

      <FormField name="configuration">
        {({ value }) =>
          value == DoorConfigurations.elevatorWithFloor ? (
            <FormSelectField
              isLoading={isLoading}
              closeOnChange={true}
              name="rex1_id"
              label="First Input"
              options={inputOptions}
              disabled={!controller_id || disabled}
            />
          ) : null
        }
      </FormField>
    </VStack>
  );
};
