import { useWindowDimensionsQuery } from "@smartrent/hooks";
import { IconProps } from "@smartrent/icons";
import { Button, ButtonProps, FloatingActionButton } from "@smartrent/ui";

interface ResponsiveButtonProps extends Partial<ButtonProps> {
  icon: React.ComponentType<React.PropsWithChildren<IconProps>>;
  text: string;
}
export const ResponsiveButton: React.FC<
  React.PropsWithChildren<ResponsiveButtonProps>
> = ({ disabled, icon, onPress, text, color, size, ...props }) => {
  const { large: isDesktop } = useWindowDimensionsQuery();

  if (isDesktop) {
    return (
      <Button
        disabled={disabled}
        onPress={onPress}
        color={color}
        size={size}
        {...props}
      >
        {text}
      </Button>
    );
  }
  return (
    <FloatingActionButton
      disabled={disabled}
      size="xsmall"
      icon={icon}
      onPress={onPress}
      color={color}
    />
  );
};
