import { StyleSheet } from "react-native";
import { useParams } from "react-router-dom";

import {
  Typography,
  ColumnOptions,
  TypographyProps,
  useTableQuery,
  ListQueryResponse,
  Table,
} from "@smartrent/ui";

import { humanize } from "@/lib/helpers";

import {
  StatusDisplay,
  DeviceStatus,
} from "@/components/alloy-access/StatusDisplay";

import { DomLink } from "@/components/alloy-access/Link";

import { Paths } from "@/lib/path";

import { useAppDrawer } from "@/components/layout/AppDrawer";

import { usePermissions } from "@/context/PolicyContext";

import { TableFilters } from "@/modules/base/table/types";

import {
  ControllerProtocols,
  ControllersThatCanEditOutputs,
} from "../controller/types";

import { ControllerQueries } from "../controller/queries";

import { createActionsColumn } from "../base/table/utils";

import { PanelQueries } from "../panel/queries";

import { Panel } from "../panel/types";

import { DoorQueries } from "../door/queries";

import { OutputQueries } from "./queries";

import { Output } from "./types";

interface OutputTableFilters extends TableFilters {
  controller_id?: number;
  panel_id?: number;
}
interface OutputsTableProps {
  title?: string;
  filters: OutputTableFilters;
  numberOfLines?: TypographyProps["numberOfLines"];
}

export const OutputsTable = ({
  filters,
  numberOfLines = 2,
}: OutputsTableProps) => {
  const drawer = useAppDrawer();
  const params = useParams();
  const { canUpdate } = usePermissions(OutputQueries);

  const { site_id } = useParams<{ site_id: string }>();
  const { data: controller } = ControllerQueries.useQuery({
    id: filters?.controller_id,
  });

  const panelSelectProps = PanelQueries.tableSelectProps({
    defaultParams: { site_id, controller_id: filters?.controller_id },
  });

  const doorSelectProps = DoorQueries.tableSelectProps({
    defaultParams: {
      site_id,
      controller_id: filters?.controller_id,
      include_deleted: false,
      has_assigned_output: true,
    },
  });

  const fields: ColumnOptions<Output>[] = [
    {
      name: "name",
      header: "Name",
      style: styles.column,
      filterType: {
        type: "textInputField",
      },
      render: ({ row }) => {
        return (
          <Typography numberOfLines={numberOfLines} type="body">
            {row.name}
          </Typography>
        );
      },
    },
    {
      name: "panel_ids",
      header: "Panel",
      style: styles.column,
      render: ({ row }) => {
        return (
          <StatusDisplay
            status={
              row.panel?.online ? DeviceStatus.Online : DeviceStatus.Offline
            }
            message={row.panel?.name}
            numberOfLines={numberOfLines}
          />
        );
      },
      filterType: {
        type: "multiSelectField",
        getOptionValue: (option: Panel) => option.id.toString(),
        getOptionLabel: (option: Panel) => `${option.name}`,
        ...panelSelectProps,
      },
    },
    {
      name: "mode",
      header: "Mode",
      style: styles.column,
      render: ({ row }) => {
        return (
          <Typography numberOfLines={numberOfLines} type="body">
            {humanize(row.mode)}
          </Typography>
        );
      },
    },
    {
      name: "door_ids",
      header: "Door",
      style: styles.column,
      filterType: {
        type: "multiSelectField",
        getOptionValue: (option: Panel) => option.id.toString(),
        getOptionLabel: (option: Panel) => `${option.name}`,
        ...doorSelectProps,
      },
      render: ({ row }) => {
        if (!row.door) return null;
        return (
          <DomLink
            href={Paths.doorViewPath(site_id, row.door.id)}
            numberOfLines={numberOfLines}
          >
            {row.door.name}
          </DomLink>
        );
      },
    },
  ];

  const tableProps = useTableQuery<any, Output, ListQueryResponse<Output>>({
    fetch: OutputQueries.fetch,
    getQueryKey: ({
      filters: tableFilters,
      page,
      pageSize,
      sortColumn,
      sortDirection,
    }) => [
      OutputQueries.queryKey,
      {},
      {
        ...filters,
        page,
        per_page: pageSize,
        sort: sortColumn,
        dir: sortDirection,
        ...tableFilters,
      },
    ],
    columns: [
      ...fields,
      createActionsColumn<Output>({
        canUpdate:
          canUpdate &&
          ControllersThatCanEditOutputs.includes(
            controller?.protocol as ControllerProtocols
          ),
        onUpdate: async (row) =>
          drawer.push(OutputQueries.queryKey, { initialValues: row, params }),
      }),
    ],
    // currently, our system supports multiple doors per output, so
    // to fix any duplicate key issues, we need to add the index to the key
    keyExtractor: (value, index) =>
      `${OutputQueries.queryKey}-${value.id}-${index}`,
    defaultPageSize: 10,
  });

  return (
    <Table<Output>
      title={"Outputs"}
      noRecordsText={"No Outputs Found"}
      {...tableProps}
    />
  );
};

const styles = StyleSheet.create({
  column: {
    paddingRight: 16,
  },
});
