import { Door } from "@/modules/door/types";

import { EditFloorFormDrawer } from "./components/EditFloorFormDrawer";
import { AddFloorFormDrawer } from "./components/AddFloorFormDrawer";

interface FloorFormDrawerProps {
  initialValues?: Door;
  params: {
    door_id: number;
  };
}

export const FloorFormDrawer: React.FC<
  React.PropsWithChildren<FloorFormDrawerProps>
> = ({ initialValues, params }) => {
  return initialValues?.id ? (
    <EditFloorFormDrawer initialValues={initialValues} />
  ) : (
    <AddFloorFormDrawer door_id={params.door_id} />
  );
};
