import { Member } from "@/modules/member/types";
import { CredentialsTable } from "@/modules/credential/Table";
import { Site } from "@/modules/site/types";

interface CredentialsDisplayProps {
  site: Site;
  member: Member;
}

export const CredentialsDisplay = ({
  site,
  member,
}: CredentialsDisplayProps) => {
  return (
    <CredentialsTable
      filters={{ site_id: site.id, member_id: member.id }}
      member={member}
    />
  );
};
