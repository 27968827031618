import { useWindowDimensionsQuery } from "@smartrent/hooks";
import {
  Button,
  FilterProps,
  FormikTextInputField,
  ListQueryResponse,
  Table,
  useTableQuery,
} from "@smartrent/ui";
import { useCallback } from "react";
import { StyleSheet, View } from "react-native";
import { useHistory } from "react-router-dom";

import { useAppDrawer } from "@/components/layout/AppDrawer";
import { usePermissions } from "@/context/PolicyContext";
import { useDialog } from "@/context/dialog";
import { PathActions, Paths } from "@/lib/path";
import { TableFilters } from "@/modules/base/table/types";

import { PendingDeleteChip } from "@/components/PendingDeleteChip";

import { ToggleableTypography } from "@/components/ToggleableTypography";

import { createActionsColumn, useTableUtils } from "../base/table/utils";
import { ControllerNameAndStatusIndicator } from "../controller/components/ControllerStatusIndicator";
import { ControllerQueries } from "../controller/queries";
import { Controller } from "../controller/types";
import { DoorQueries } from "../door/queries";
import { Door, ElevatorFeedbackOptions } from "../door/types";
import { Site } from "../site/types";

import { ElevatorQueries } from "./queries";

interface ElevatorsTableProps {
  filters: Partial<TableFilters & Door>;
  defaultSortColumn?: string;
  defaultSortDirection?: string;
  site: Site;
}

export const ElevatorsTable = ({
  filters,
  defaultSortColumn,
  defaultSortDirection,
  site,
}: ElevatorsTableProps) => {
  const drawer = useAppDrawer();
  const history = useHistory();
  const { large: isDesktop } = useWindowDimensionsQuery();
  const { confirm } = useDialog();
  const [doDelete] = ElevatorQueries.useDeleteMutation();

  const { canView, canCreate, canDelete, canUpdate } =
    usePermissions(DoorQueries);

  const { relativeWidths } = useTableUtils();

  const elevatorCreateAction = useCallback(
    () =>
      drawer.push(ElevatorQueries.queryKey, {
        params: filters,
        site_id: site.id,
      }),
    [drawer, filters, site]
  );

  const controllerSelectProps = ControllerQueries.tableSelectProps({
    defaultParams: { site_id: site.id },
  });

  const tableProps = useTableQuery<any, Door, ListQueryResponse<Door>>({
    fetch: ElevatorQueries.fetch,
    getQueryKey: ({
      filters: tableFilters,
      page,
      pageSize,
      sortColumn,
      sortDirection,
    }) => [
      ElevatorQueries.queryKey,
      {},
      {
        ...filters,
        page,
        per_page: pageSize,
        sort: sortColumn || defaultSortColumn,
        dir: sortDirection || defaultSortDirection,
        ...tableFilters,
        site_id: site.id,
      },
    ],
    columns: [
      {
        name: "name",
        header: "Name",
        style: isDesktop ? styles.columnPadding : null,
        render: ({ row }) => (
          <ToggleableTypography disabled={!!row?.pending_delete}>
            {row.name}
          </ToggleableTypography>
        ),

        sortable: true,
        filter: (props: FilterProps) => <FormikTextInputField {...props} />,
      },
      {
        name: "controller",
        header: "Controller",
        style: isDesktop ? styles.columnPadding : null,
        render: ({ row }) =>
          !row.controller ? (
            <ToggleableTypography disabled={!!row?.pending_delete}>
              {" "}
              -
            </ToggleableTypography>
          ) : (
            <ControllerNameAndStatusIndicator
              disabled={!!row?.pending_delete}
              controller={row.controller}
            />
          ),

        sortable: true,
        filterType: {
          type: "selectField",
          getOptionValue: (option: Controller) => option.id.toString(),
          getOptionLabel: (option: Controller) => `${option.name}`,
          ...controllerSelectProps,
        },
      },
      {
        name: "panel",
        header: "Panel",
        style: isDesktop ? styles.columnPadding : null,
        render: ({ row }) => (
          <ToggleableTypography disabled={!!row?.pending_delete}>
            {row.reader?.panel?.name || "-"}
          </ToggleableTypography>
        ),
        sortable: true,
      },
      {
        name: "reader",
        header: "Reader",
        style: isDesktop ? styles.columnPadding : null,
        render: ({ row }) => (
          <ToggleableTypography disabled={!!row?.pending_delete}>
            {row.reader?.name || "-"}
          </ToggleableTypography>
        ),
        sortable: true,
      },
      {
        name: "number_of_floors",
        header: "# of Floors",
        style: isDesktop ? styles.columnPadding : null,
        render: ({ row }) => (
          <ToggleableTypography disabled={!!row?.pending_delete}>
            {row?.strike_mode || row?.floors?.length || "-"}
          </ToggleableTypography>
        ),
      },
      {
        name: "configuration",
        header: "Feedback",
        style: isDesktop ? styles.columnPadding : null,
        render: ({ row }) => {
          const option = ElevatorFeedbackOptions.find(
            ({ value }) => value === row?.configuration
          );

          return (
            <ToggleableTypography disabled={!!row?.pending_delete}>
              {" "}
              {option?.label || "-"}
            </ToggleableTypography>
          );
        },
        sortable: true,
      },

      createActionsColumn<Door>({
        relativeWidth: relativeWidths.columnDouble,
        customActions: (row) =>
          row.pending_delete ? <PendingDeleteChip /> : null,
        shouldAdjustForPressIcon: canView,
        canDelete: (row) => !row.pending_delete && canDelete,
        onDelete: async (row) => {
          const confirmed = await confirm({
            title: "Delete",
            description: "Are you sure you want to delete this Elevator?",
            confirmText: "Delete",
            confirmType: "destructive",
            cancelText: "Cancel",
          });
          if (confirmed) {
            doDelete({ id: row.id });
          }
        },
        canUpdate: (row) => !row.pending_delete && canUpdate,
        onUpdate: async (row) =>
          drawer.push(ElevatorQueries.queryKey, {
            initialValues: row,
            site_id: site.id,
          }),
      }),
    ],
    defaultPageSize: 10,
  });

  return (
    <Table<Door>
      title={"Elevators"}
      noRecordsText={`No Elevators Found`}
      action={
        canCreate ? (
          <View style={styles.createButtonContainer}>
            <Button onPress={elevatorCreateAction} style={styles.createButton}>
              Add Elevator
            </Button>
          </View>
        ) : null
      }
      isRowPressable={(row) => canView && !row.pending_delete}
      onRowPress={(row) =>
        history.push(
          Paths.GetPath(DoorQueries.queryKey, PathActions.View, {
            id: row.id,
            site_id: site.id,
          })
        )
      }
      {...tableProps}
    />
  );
};

const styles = StyleSheet.create({
  createButtonContainer: {
    flexDirection: "row",
  },
  createButton: {
    marginLeft: 8,
  },

  columnPadding: { paddingRight: 16 },
});
