import { Route, Switch, useRouteMatch } from "react-router-dom";

import { useContext, useEffect } from "react";

import { Site } from "@/modules/site/types";

import { PageCtx } from "@/context/PageContext";
import { Paths } from "@/lib/path";

import { EventsListPage } from "./EventsListPage";

interface EventsRouterProps {
  site: Site;
}

export const EventsRouter = ({ site }: EventsRouterProps) => {
  const { path } = useRouteMatch();
  const { addBreadcrumb } = useContext(PageCtx);

  useEffect(() => {
    addBreadcrumb({
      label: `Events`,
      to: Paths.eventsListPage(site.id),
    });
  }, [addBreadcrumb, site.id]);

  return (
    <Switch>
      <Route exact path={`${path}`}>
        <EventsListPage site={site} />
      </Route>
    </Switch>
  );
};
