import { useContext, useEffect } from "react";

import { OrganizationsTable } from "@/modules/organization/Table";
import { PageCtx } from "@/context/PageContext";
import { Paths } from "@/lib/path";

export const OrganizationListPage: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const { addBreadcrumb, breadCrumbs } = useContext(PageCtx);

  useEffect(() => {
    addBreadcrumb({
      label: `Organizations`,
      to: Paths.organizationsListPath(),
    });
  }, [breadCrumbs, addBreadcrumb]);

  return <OrganizationsTable filters={{}} />;
};
