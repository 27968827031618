import { useContext, useEffect } from "react";

import { View, StyleSheet } from "react-native";

import { ActivityIndicator } from "@smartrent/ui";

import { PageCtx } from "@/context/PageContext";
import { Paths } from "@/lib/path";

import { Site } from "@/modules/site/types";

import { FloorsTable } from "@/modules/floor/Table";

import { Door } from "@/modules/door/types";

import { ElevatorInformationPanel } from "./ElevatorInformationPanel";
import { CascadingPanelsList } from "./CascadingPanelsList";

interface ElevatorDetailPageProps {
  door: Door;
  site: Site;
}

export const ElevatorDetailPage = ({
  door: elevator,
  site,
}: ElevatorDetailPageProps) => {
  const { addBreadcrumbs } = useContext(PageCtx);

  useEffect(() => {
    addBreadcrumbs([
      {
        label: "Elevators",
        to: Paths.elevatorsListPath(site.id),
      },
      {
        label: `${elevator.name}`,
        to: Paths.doorViewPath(site.id, elevator.id),
      },
    ]);
  }, [addBreadcrumbs, elevator, site.id]);

  return (
    <View style={styles.root}>
      <View style={styles.left}>
        <View style={styles.container}>
          {elevator ? (
            <ElevatorInformationPanel site={site} elevator={elevator} />
          ) : (
            <ActivityIndicator />
          )}
        </View>
      </View>
      <View style={styles.right}>
        <View style={styles.container}>
          <CascadingPanelsList controller_id={elevator.controller_id} />
        </View>

        <View style={styles.container}>
          <FloorsTable
            filters={{ door_id: elevator.id, door_name: elevator.name }}
          />
        </View>
      </View>
    </View>
  );
};

// TODO: Make this not suck
const styles = StyleSheet.create({
  root: {
    flexDirection: "row",
    flexWrap: "wrap",
    flexGrow: 1,
  },
  container: {
    padding: 16,
  },
  left: { flexGrow: 1 },
  right: {
    flexGrow: 3,
  },
});
