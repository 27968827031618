import {
  View,
  StyleSheet,
  ActivityIndicator,
  StyleProp,
  ViewStyle,
} from "react-native";

import { useTheme } from "@smartrent/ui";

interface LoadingIndicatorProps {
  style?: StyleProp<ViewStyle>;
}

export const LoadingIndicator: React.FC<
  React.PropsWithChildren<LoadingIndicatorProps>
> = ({ style }) => {
  const { colors } = useTheme();

  return (
    <View style={[styles.root, style]}>
      <ActivityIndicator size="large" color={colors.primary} />
    </View>
  );
};

const styles = StyleSheet.create({
  root: { flex: 1, justifyContent: "center" },
});
