import { Route, Switch, useRouteMatch } from "react-router-dom";

import { Site } from "@/modules/site/types";

import { SiteImportsListPage } from "./SiteImportsListPage";

interface ImportsRouterProps {
  site: Site;
}

export const ImportsRouter = ({ site }: ImportsRouterProps) => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}`}>
        <SiteImportsListPage site={site} />
      </Route>
    </Switch>
  );
};
