import { useContext } from "react";
import {
  VStack,
  FormikTextInputField,
  FormikSelectField,
  Button,
} from "@smartrent/ui";
import { Form, FormikProps } from "formik";
import * as yup from "yup";

import { AuthContext } from "@/context/Auth";
import { SiteMultiSelectField } from "@/modules/site/components/SiteMultiSelectField";

import { FormProps, BaseForm } from "../base/Form";

import { User, UserRole, userRolesOptions } from "./types";
import { UserQueries } from "./queries";

const validationSchema = yup.object().shape({
  first_name: yup.string().required("Required").max(40),
  last_name: yup.string().required("Required").max(40),
  email: yup.string().email().required().label("Email").max(100),
  role: yup
    .string()
    .required("Required")
    .oneOf([UserRole.Admin, UserRole.Manager, UserRole.ChannelPartner]),
});

export const UserForm = ({ initialValues, params }: FormProps<User>) => {
  const { user: currentUser } = useContext(AuthContext);
  const [doResend] = UserQueries.resendInviteMutation();

  if (initialValues.sites) {
    initialValues.sites = initialValues.sites?.map((site_id) => `${site_id}`);
  } else {
    initialValues.sites = [];
  }
  if (params.organization_id) {
    initialValues.organization_id = params.organization_id;
  }

  return (
    <BaseForm<User>
      initialValues={{
        first_name: initialValues?.first_name,
        last_name: initialValues?.last_name,
        email: initialValues?.email,
        role: initialValues?.role,
        ...initialValues,
      }}
      validationSchema={validationSchema}
      QueryClient={UserQueries}
      params={params}
    >
      {({ values }: FormikProps<User>) => (
        <Form>
          <VStack spacing={16}>
            <FormikTextInputField
              name="first_name"
              label="First Name"
              required
            />
            <FormikTextInputField name="last_name" label="Last Name" required />
            <FormikTextInputField name="email" label="Email" required />
            <FormikSelectField
              name="role"
              label="Role"
              options={userRolesOptions(currentUser?.role as UserRole)}
              required
            />
            {(values.role === UserRole.Manager ||
              values.role === UserRole.ChannelPartner) && (
              <SiteMultiSelectField name="sites" label="Sites" />
            )}
            {initialValues.invitation_accepted_at === null && (
              <Button
                onPress={() => {
                  doResend(initialValues as User);
                }}
              >
                Resend Invite
              </Button>
            )}
          </VStack>
        </Form>
      )}
    </BaseForm>
  );
};
