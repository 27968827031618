import {
  FilterProps,
  FormikTextInputField,
  ListQueryResponse,
  Table,
  Typography,
  useTableQuery,
} from "@smartrent/ui";

import { useHistory, useParams } from "react-router-dom";

import { useAppDrawer } from "@/components/layout/AppDrawer";

import { usePermissions } from "@/context/PolicyContext";

import { useDialog } from "@/context/dialog";

import { PathActions, Paths } from "@/lib/path";

import { TableFilters } from "@/modules/base/table/types";

import { Site } from "../site/types";

import {
  createActionsColumn,
  CreateButton,
  useTableUtils,
} from "../base/table/utils";

import { Schedule } from "./types";
import { ScheduleQueries } from "./queries";

interface SchedulesTableProps {
  filters: Partial<TableFilters & Schedule>;
  site: Site;
}

export const SchedulesTable = ({ filters, site }: SchedulesTableProps) => {
  const drawer = useAppDrawer();
  const params = useParams();
  const history = useHistory();

  const { confirm } = useDialog();
  const [doDelete] = ScheduleQueries.useDeleteMutation();

  const { canView, canCreate, canDelete, canUpdate } =
    usePermissions(ScheduleQueries);

  const { numberOfLines } = useTableUtils();

  const tableProps = useTableQuery<any, Schedule, ListQueryResponse<Schedule>>({
    fetch: ScheduleQueries.fetch,
    getQueryKey: ({
      filters: tableFilters,
      page,
      pageSize,
      sortColumn,
      sortDirection,
    }) => [
      ScheduleQueries.queryKey,
      {},
      {
        ...filters,
        page,
        per_page: pageSize,
        sort: sortColumn,
        dir: sortDirection,
        ...tableFilters,
        site_id: site.id,
      },
    ],
    columns: [
      {
        name: "name",
        header: "Name",
        render: ({ row }) => {
          return (
            <Typography numberOfLines={numberOfLines}>{row.name}</Typography>
          );
        },
        sortable: true,
        filter: (props: FilterProps) => {
          return <FormikTextInputField {...props} />;
        },
      },
      {
        name: "access_levels",
        header: "# Access Levels",
        render: ({ row }) => {
          return (
            <Typography numberOfLines={numberOfLines}>
              {row.access_levels ? row.access_levels.length : "-"}
            </Typography>
          );
        },
      },
      createActionsColumn<Schedule>({
        shouldAdjustForPressIcon: canView,
        canDelete: (row: Schedule) =>
          canDelete && row.id !== site.always_schedule_id,
        onDelete: async (row) => {
          const confirmed = await confirm({
            title: "Delete",
            description: "Are you sure you want to delete this Schedule?",
            confirmText: "Delete",
            confirmType: "destructive",
            cancelText: "Cancel",
          });
          if (confirmed) {
            doDelete({ id: row.id });
          }
        },
        canUpdate: (row: Schedule) =>
          canUpdate && row.id !== site.always_schedule_id,
        onUpdate: async (row) =>
          drawer.push(ScheduleQueries.queryKey, { initialValues: row, params }),
      }),
    ],
    defaultPageSize: 10,
  });
  return (
    <Table<Schedule>
      title={"Schedules"}
      noRecordsText={`No Schedules Found`}
      action={
        <CreateButton
          canCreate={canCreate}
          onCreate={() => drawer.push(ScheduleQueries.queryKey, { params })}
          text={"Add Schedule"}
        />
      }
      onRowPress={(row) =>
        history.push(
          Paths.GetPath(ScheduleQueries.queryKey, PathActions.View, {
            id: row.id,
            site_id: site.id,
          })
        )
      }
      {...tableProps}
    />
  );
};
