import { useState, useEffect } from "react";
import { Label, TextInput, Button } from "badmagic";
import { Plus } from "@smartrent/icons";
import { StyleSheet, View } from "react-native";

import { ProfileProps } from "../../types";

export function CreateHmacProfile({
  setProfiles,
  profiles,
  setError,
  setSuccess,
  setActiveProfile,
}: ProfileProps) {
  const [name, setName] = useState("");
  const [key, setKey] = useState("");
  const [secret, setSecret] = useState("");
  const [collapsed, setCollapsed] = useState(true);

  // Auto-collapse if we have profiles or if a new profile is added
  useEffect(() => {
    setCollapsed(!!profiles?.length);
  }, [profiles]);

  return (
    <View style={styles.root}>
      {collapsed ? (
        <Button
          onClick={() => setCollapsed(!collapsed)}
          title="Create HMAC Auth Profile"
        >
          <div className="flex items-center justify-center">
            <Plus color="#eee" size={20} />
            <div className="ml-2">Create HMAC Profile</div>
          </div>
        </Button>
      ) : null}
      {!collapsed ? (
        <View style={styles.profileFormContainer}>
          <Label>Create HMAC Profile</Label>
          <TextInput
            type="text"
            value={name}
            placeholder="Profile Name"
            className="mb-1"
            onChange={(e) => setName(e.currentTarget.value)}
          />
          <TextInput
            type="text"
            value={key}
            placeholder="Key"
            className="mb-1"
            onChange={(e) => setKey(e.currentTarget.value)}
          />
          <TextInput
            type="password"
            value={secret}
            placeholder="Secret"
            className="mb-1"
            onChange={(e) => setSecret(e.currentTarget.value)}
          />
          <div className="flex mt-4">
            <button
              className="bg-red-500 hover:bg-red-700 text-white font-semibold py-2 px-4 rounded"
              onClick={() => {
                setError("");
                setSuccess("");
                setCollapsed(!collapsed);
              }}
            >
              Cancel
            </button>
            <Button
              className="ml-2"
              onClick={() => {
                setError("");
                setSuccess("");

                if (!key) {
                  setError("Please specify a key");
                  return;
                } else if (!secret) {
                  setError("Please specify a secret");
                  return;
                } else if (!name) {
                  setError("Please give your profile a name");
                  return;
                }

                setSuccess("Profile created");

                const newProfiles = [...profiles];
                const newProfile = {
                  name,
                  key,
                  secret,
                };

                // Checks if the profile already existed in `auth-profiles`
                // If the profile already exists, it replaces the pre-existing profile with the new profile
                // If it didn't exist, it appends to the array
                const profileIndex = newProfiles.findIndex((profile) => {
                  return profile.name === newProfile.name;
                });
                if (profileIndex !== -1) {
                  newProfiles.splice(profileIndex, 1, newProfile); // mutates array; replaces old profile with new
                } else {
                  newProfiles.push(newProfile); // appends
                }

                setProfiles(newProfiles);
                setActiveProfile(newProfile);
                setCollapsed(true);
              }}
            >
              Save Profile
            </Button>
          </div>
        </View>
      ) : null}
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    marginTop: "1rem",
    marginBottom: "1rem",
    marginLeft: "1rem",
  },
  profileFormContainer: {
    borderStyle: "dashed",
    borderWidth: 1,
    borderColor: "white",
    padding: 8,
  },
});
