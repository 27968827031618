import { Breadcrumbs, useTheme } from "@smartrent/ui";
import { useContext } from "react";
import { View, StyleSheet } from "react-native";

import { PageCtx } from "@/context/PageContext";

import { NavigationBannerActions } from "./NavigationBannerActions";

export const NavigationBanner = () => {
  const { colors } = useTheme();
  const { breadCrumbs, actions } = useContext(PageCtx);

  if (breadCrumbs.length === 0 && actions.length === 0) return null;
  return (
    <View
      style={[
        styles.root,
        {
          borderBottomColor: colors.border,
        },
      ]}
    >
      <View style={styles.breadcrumbsContainer}>
        <Breadcrumbs items={breadCrumbs} />
      </View>
      <NavigationBannerActions />
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    flexDirection: "row",
    borderBottomColor: "gray",
    borderBottomWidth: 1,
    justifyContent: "space-between",
    height: 40,
  },
  breadcrumbsContainer: {
    flexDirection: "row",
    padding: 8,
    marginLeft: 16,
  },
});
