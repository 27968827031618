import { AccessLevelsTable } from "@/modules/access-level/AccessLevelsTable";
import { Site } from "@/modules/site/types";

interface AccessLevelsListPageProps {
  site: Site;
}

export const AccessLevelsListPage = ({ site }: AccessLevelsListPageProps) => {
  return <AccessLevelsTable filters={{ site_id: site.id }} />;
};
