import { Route, Switch, useRouteMatch } from "react-router-dom";

import { FirmwareListPage } from "./FirmwareListPage";

export const FirmwareRouter: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}`}>
        <FirmwareListPage />
      </Route>
    </Switch>
  );
};
