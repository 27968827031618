import * as yup from "yup";

import { ButtonGroup, FormCheckboxGroup, FormField } from "@smartrent/forms";
import { VStack, Button } from "@smartrent/ui";

import { useState } from "react";

import { StyleSheet } from "react-native";

import { ChevronDown, ChevronRight } from "@smartrent/icons";

import { FormDrawer } from "../base/FormDrawer";

import {
  DataFormat,
  DataFormatOptions,
  Reader,
  ReaderProtocols,
  readerProtocolOptions,
  LedDriveMode,
} from "./types";
import { ReaderQueries } from "./queries";
import {
  ReaderOsdpFields,
  readerOsdpValidationSchema,
} from "./components/ReaderOsdpFields";
import {
  ReaderWiegandFields,
  readerWiegandValidationSchema,
} from "./components/ReaderWiegandFields";

interface ReaderFormDrawerProps {
  initialValues?: Reader;
}

const hasGenericDataFormats = (dt_fmt: DataFormat[]) => {
  return dt_fmt.some((fmt) => fmt !== DataFormat.wiegand_pulses);
};

// Currently, this form will only be used for updating Mercury controllers
// (kln) NOTE: We may add warblers to this flow in the future
export const ReaderFormDrawer: React.FC<
  React.PropsWithChildren<ReaderFormDrawerProps>
> = ({ initialValues }) => {
  const [advOptionsCheckboxesVisible, setAdvOptionsCheckboxesVisible] =
    useState(
      initialValues?.dt_fmt
        ? hasGenericDataFormats(initialValues.dt_fmt)
        : false
    );

  const [readerProtocol, setReaderProtocol] = useState<string>(
    initialValues?.led_drive_mode ===
      LedDriveMode.osdp_reader_with_lcd_and_keypad
      ? ReaderProtocols.osdp
      : ReaderProtocols.wiegand
  );

  const validationSchema =
    readerProtocol === ReaderProtocols.wiegand
      ? readerWiegandValidationSchema
      : readerProtocol === ReaderProtocols.osdp
        ? readerOsdpValidationSchema
        : yup.object();

  return (
    <FormDrawer<Reader>
      title={initialValues?.name}
      subtitle={"Update Reader"}
      QueryClient={ReaderQueries}
      initialValues={initialValues}
      validationSchema={validationSchema}
      beforeSubmit={async (values) =>
        readerProtocol === ReaderProtocols.osdp
          ? {
              ...values,
              // led_drive_mode can only be this value for OSDP readers
              led_drive_mode: LedDriveMode.osdp_reader_with_lcd_and_keypad,
            }
          : values
      }
    >
      <VStack spacing={16} style={styles.container}>
        <ButtonGroup
          size="large"
          options={readerProtocolOptions}
          value={readerProtocol}
          onChangeValue={setReaderProtocol}
        />

        <VStack spacing={16}>
          {readerProtocol === ReaderProtocols.wiegand ? (
            <ReaderWiegandFields />
          ) : readerProtocol === ReaderProtocols.osdp ? (
            <ReaderOsdpFields />
          ) : null}

          <FormField<Reader["dt_fmt"]> name="dt_fmt">
            {({ value }) => {
              // makes typescript happy
              const dt_fmt = value as DataFormat[];

              // check if any of the options are marked as selected
              const hasActiveCheckboxes = hasGenericDataFormats(dt_fmt);

              // menu is open if any of the options are selected
              const isMenuOpen =
                hasActiveCheckboxes || advOptionsCheckboxesVisible;
              return (
                <VStack>
                  <Button
                    style={styles.justifyLeft}
                    contentStyle={styles.hugLeft}
                    iconRight={isMenuOpen ? ChevronDown : ChevronRight}
                    variation="plain"
                    onPress={() =>
                      setAdvOptionsCheckboxesVisible(
                        !advOptionsCheckboxesVisible
                      )
                    }
                    disabled={hasActiveCheckboxes}
                  >
                    Advanced Options
                  </Button>

                  {isMenuOpen ? (
                    <FormCheckboxGroup
                      style={styles.indentRight}
                      name={"dt_fmt"}
                      // 'wiegand_pulses' has its own field, so we don't want to show it here
                      items={DataFormatOptions.filter(
                        ({ value }) => value !== DataFormat.wiegand_pulses
                      )}
                    />
                  ) : null}
                </VStack>
              );
            }}
          </FormField>
        </VStack>
      </VStack>
    </FormDrawer>
  );
};

const styles = StyleSheet.create({
  container: { maxWidth: 360 },
  justifyLeft: {
    justifyContent: "flex-start",
  },
  hugLeft: { paddingLeft: 0, alignItems: "flex-start" },
  indentRight: { marginLeft: 4 },
});
