import { BaseDrawer } from "@/modules/base/Drawer";

import { Member } from "../types";

import { MemberAccessLevelForm } from "./MemberAccessLevelForm";

interface MemberAddAccessLevelDrawerProps {
  initialValues?: Member;
  params: { site_id: number };
}

export const MemberAccessLevelDrawer: React.FC<
  React.PropsWithChildren<MemberAddAccessLevelDrawerProps>
> = ({ initialValues, params }) => {
  return (
    <BaseDrawer
      title={
        initialValues?.id
          ? `${initialValues.first_name} ${initialValues.last_name}`
          : "New Member"
      }
      subtitle={"Access Levels"}
    >
      <MemberAccessLevelForm initialValues={initialValues} params={params} />
    </BaseDrawer>
  );
};
