import * as yup from "yup";

import { SelectField, VStack, StatusBanner } from "@smartrent/ui";

import {
  FieldSeparator,
  Form,
  FormField,
  FormOnSubmit,
} from "@smartrent/forms";

import { useCallback } from "react";

import { StyleSheet } from "react-native";

import { Calendar } from "@smartrent/icons";

import { useQueryClient } from "@tanstack/react-query";

import { useAppDrawer } from "@/components/layout/AppDrawer";

import { ScheduleQueries } from "@/modules/schedule/queries";

import { Floor } from "../floor/types";

import { Schedule } from "../schedule/types";

import { Elevator } from "../elevator/types";

import { AccessLevel } from "../access-level/types";

import { Site } from "../site/types";

import { FloorQueries } from "../floor/queries";

import {
  ElevatorAccessLevelFormValues,
  ElevatorAccessLevelPayload,
  ScheduleOption,
} from "./types";
import {
  useCreateElevatorAccessLevelMutation,
  useUpdateElevatorAccessLevelsMutation,
} from "./queries";

import { SetAllFloorSchedulesAction } from "./components/SetAllFloorSchedulesAction";
import { findElevatorAccessLevel } from "./utils/find-elevator-access-level";
import { noAccessScheduleOption } from "./utils/no-access-schedule-option";
import { SubmitFormButton } from "./components/SubmitFormButton";

interface ElevatorAccessLevelForm {
  initialValues?: ElevatorAccessLevelFormValues;
  access_level: AccessLevel;
  site: Site;
  elevator?: Elevator;
  submitButtonRef?: React.RefObject<HTMLButtonElement>;
}

export const ElevatorAccessLevelForm: React.FC<
  React.PropsWithChildren<ElevatorAccessLevelForm>
> = ({ initialValues, access_level, site, elevator, submitButtonRef }) => {
  const drawer = useAppDrawer();
  const queryClient = useQueryClient();

  const [create] = useCreateElevatorAccessLevelMutation();
  const [update] = useUpdateElevatorAccessLevelsMutation();

  const { data: schedules } = ScheduleQueries.useList({ site_id: site.id });

  const handleSubmit = useCallback<FormOnSubmit<ElevatorAccessLevelFormValues>>(
    async (values) => {
      const elevatorAccessLevels = (values?.floors || []).map((floor) => {
        const elevator_access_level = findElevatorAccessLevel(
          floor,
          access_level.id
        );

        return {
          site_id: site.id,
          access_level_id: access_level.id,
          floor_id: floor?.id || null,
          schedule_id: elevator_access_level?.schedule_id || null, // schedule_id=null deletes an existing elevator_access_level
        };
      });

      const payload = {
        site_id: site.id,
        access_level_id: access_level.id,
        elevator_access_levels: elevatorAccessLevels,
      } as ElevatorAccessLevelPayload;

      initialValues
        ? await update({ values: payload })
        : await create({ values: payload });

      drawer.pop();

      // refreshes data for floors table on elevator detail page
      queryClient.invalidateQueries([FloorQueries.queryKey]);
    },
    [
      site.id,
      access_level.id,
      initialValues,
      update,
      create,
      drawer,
      queryClient,
    ]
  );

  if (!elevator) {
    return null;
  }

  return (
    <Form<ElevatorAccessLevelFormValues>
      initialValues={elevator}
      validationSchema={yup.object().shape({
        floors: yup
          .array()
          .of(
            yup.object().shape({
              name: yup.string(),
              elevator_access_levels: yup.array().of(
                yup
                  .object()
                  .shape({
                    access_level_id: yup.number(),
                    schedule_id: yup.number().nullable(),
                    schedule: yup.object().shape({ name: yup.string() }),
                  })
                  .nullable()
              ),
            })
          )
          .label("Floors")
          .min(1)
          .required(),
        floors_error: yup.bool().when(["floors"], {
          is: (floors: Floor[]) =>
            floors &&
            floors.length &&
            // error if we can't find at least 1 schedule_id on an elevator_access_level
            !floors?.some(
              (floor) =>
                findElevatorAccessLevel(floor, access_level.id)?.schedule_id
            ),
          then: yup
            .bool()
            .required("Must choose at least one schedule for a floor"),
          otherwise: yup.bool(),
        }),
      })}
      onSubmit={handleSubmit}
      key={`access-level-${access_level.id}-elevator-${elevator.id}-elevator-access-levels-form`}
    >
      <VStack spacing={16}>
        <SetAllFloorSchedulesAction
          site={site}
          access_level={access_level}
          defaultOption={noAccessScheduleOption}
        />

        <FieldSeparator style={styles.fieldSeparator} />

        <FormField name="floors_error">
          {({ error: floors_error }) => (
            <VStack spacing={16}>
              {floors_error ? (
                <StatusBanner
                  status="error"
                  title={floors_error}
                ></StatusBanner>
              ) : null}

              <FormField<Floor[]> name="floors">
                {({ value: floors, setValue: setFloors }) =>
                  floors?.map((floor, index) => (
                    <SelectField<ScheduleOption>
                      StartAdornment={
                        findElevatorAccessLevel(floor, access_level.id)
                          ?.schedule_id
                          ? Calendar
                          : undefined
                      }
                      key={`floor-${index}-id-${floor.id}`}
                      onChange={(value) => {
                        const floorsCopy = floors.slice();

                        const elevator_access_level = findElevatorAccessLevel(
                          floor,
                          access_level.id
                        );

                        floorsCopy[index] = {
                          ...floor,
                          elevator_access_levels: [
                            {
                              ...elevator_access_level,
                              access_level_id: access_level.id,
                              schedule_id: value ? value.id : null,
                              schedule: value
                                ? ({ name: value.name } as Schedule)
                                : null,
                            },
                          ],
                        };

                        setFloors(floorsCopy);
                      }}
                      name={`floor.${index}.elevator_access_levels.schedule`}
                      label={floor.name}
                      value={
                        (findElevatorAccessLevel(floor, access_level.id)
                          ?.schedule as ScheduleOption) ||
                        noAccessScheduleOption
                      }
                      options={[
                        noAccessScheduleOption,
                        ...(schedules?.records || []),
                      ]}
                      getOptionLabel={(option) => option.name as string}
                      getOptionValue={(option) => option}
                      clearable={false}
                      flatListProps={{
                        keyExtractor: (item) =>
                          `floor_id-${floor.id}-schedule_id-${item.id}-option`,
                      }}
                      // Set error to single whitespace.
                      // We want the red error borders to display.
                      // To avoid duplicate error messages, we display it in a separate component below.
                      error={floors_error ? " " : undefined}
                    />
                  ))
                }
              </FormField>
            </VStack>
          )}
        </FormField>
        <SubmitFormButton ref={submitButtonRef} />
      </VStack>
    </Form>
  );
};

const styles = StyleSheet.create({ fieldSeparator: { marginVertical: 16 } });
