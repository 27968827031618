import { useState } from "react";
import { VStack, SelectField, Skeleton } from "@smartrent/ui";

import { Firmware } from "@/modules/firmware/types";
import { FirmwareQueries } from "@/modules/firmware/queries";
import { SiteFirmwareQueries } from "@/modules/site-firmware/queries";
import { SiteFirmware, UnsetSiteFirmware } from "@/modules/site-firmware/types";

import { Site } from "../types";

interface FirmwareSettingsProps {
  site: Site;
}

export const FirmwareSettings = ({ site }: FirmwareSettingsProps) => {
  const { data: siteFirmware, isLoading } =
    SiteFirmwareQueries.useListActiveSiteFirmware(site.id);

  if (isLoading) return <Skeleton />;

  return (
    <VStack spacing={16}>
      {siteFirmware?.map((siteFirmware) => {
        return (
          <FirmwareDropdown
            key={`${siteFirmware.model}-${
              "firmware_id" in siteFirmware ? siteFirmware.firmware_id : "unset"
            }`}
            firmwareVersion={siteFirmware}
            siteId={site.id}
          />
        );
      })}
    </VStack>
  );
};

interface FirmwareDropdownProps {
  firmwareVersion: SiteFirmware | UnsetSiteFirmware;
  siteId: number;
}

const FirmwareDropdown = ({
  firmwareVersion,
  siteId,
}: FirmwareDropdownProps) => {
  const [value, setValue] = useState<Firmware | undefined>(
    "firmware_id" in firmwareVersion
      ? ({
          version: firmwareVersion.version,
          id: firmwareVersion.firmware_id,
        } as Firmware)
      : undefined
  );

  const [createOrUpdate] = SiteFirmwareQueries.useCreateMutation();

  const updateSelectedFirmwareVersion = (
    selectOption: Firmware | undefined
  ) => {
    if (!selectOption) return;

    createOrUpdate({
      values: {
        model: firmwareVersion.model,
        firmware_id: selectOption.id,
        site_id: siteId,
      },
    });
    setValue(selectOption);
  };

  const selectQueryProps = FirmwareQueries.select({
    filterParams: { model: firmwareVersion.model },
  });

  return (
    <SelectField<Firmware>
      clearable={false}
      getOptionLabel={(option) => option.version}
      getOptionValue={(option) => option.id}
      label={`${firmwareVersion.model} Model Firmware Version`}
      name={firmwareVersion.model}
      onChange={updateSelectedFirmwareVersion}
      required
      value={value}
      {...selectQueryProps}
    />
  );
};
