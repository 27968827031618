import { FormikSelectField } from "@smartrent/ui";

import { ScheduleQueries } from "@/modules/schedule/queries";
import { Schedule } from "@/modules/schedule/types";

interface ScheduleSelectFieldProps {
  site_id: number;
  name: string;
  label: string;
  filterParams?: any;
  initialValue?: string;
  required?: boolean;
}

export const ScheduleSelectField: React.FC<
  React.PropsWithChildren<ScheduleSelectFieldProps>
> = ({ site_id, label, name, filterParams, initialValue, required }) => {
  const selectQueryProps = ScheduleQueries.select({
    filterParams: { site_id, ...filterParams },
    initialValue,
  });

  return (
    <FormikSelectField<Schedule>
      name={name}
      label={label}
      getOptionValue={(option) => option.id}
      getOptionLabel={(option) => `${option.name}`}
      {...selectQueryProps}
      required={required}
    />
  );
};
