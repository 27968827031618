import { BaseDrawer } from "../base/Drawer";

import { FirmwareForm } from "./Form";
import { Firmware } from "./types";

interface FirmwareDrawerProps {
  initialValues?: Firmware;
  params?: any;
}

export const FirmwareDrawer: React.FC<
  React.PropsWithChildren<FirmwareDrawerProps>
> = ({ initialValues, params }) => {
  return (
    <BaseDrawer
      title={initialValues?.id ? "Edit Release Notes" : "Upload Firmware"}
    >
      <FirmwareForm initialValues={{ ...initialValues }} params={params} />
    </BaseDrawer>
  );
};
