import { ComponentType, ReactNode } from "react";
import { ButtonGroup, TestProps } from "@smartrent/ui";
import { StyleProp, ViewStyle } from "react-native";
import { useField } from "formik";
import { IconProps } from "@smartrent/icons";

interface FormikButtonGroupProps<T = any> extends TestProps {
  name: string;
  label?: string;
  assistiveText?: string | ReactNode;
  options: {
    label: string;
    value: T;
    Icon?: ComponentType<React.PropsWithChildren<IconProps>>;
  }[];
  required?: boolean;
  disabled?: boolean;
  style?: StyleProp<ViewStyle>;
  onChange?: (value: string) => void;
}

export function FormikButtonGroup<T = any>({
  name,
  disabled,
  testID,
  options,
  onChange,
  ...props
}: FormikButtonGroupProps<T>) {
  const [{ value }, , { setValue }] = useField(name);
  return (
    <ButtonGroup
      onChangeValue={(newValue) => {
        setValue(newValue);
        onChange && onChange(newValue);
      }}
      value={value}
      size="small"
      testID="default"
      options={options}
    />
  );
}
