import {
  FormikTextInputField,
  Typography,
  FilterProps,
  FormikSelectField,
  FormikNumberInputField,
  Table,
  useTableQuery,
  ListQueryResponse,
  ColumnOptions,
} from "@smartrent/ui";

import { formatInternationalPhone } from "@smartrent/formatters";

import { compact } from "lodash-es";

import { useHistory, useParams } from "react-router-dom";

import { useMemo } from "react";

import { booleanOptions } from "@/pages/dev/badmagic/workspaces/shared";

import { truncateString } from "@/lib/helpers";

import { usePermissions } from "@/context/PolicyContext";

import { useAppDrawer } from "@/components/layout/AppDrawer";

import { useDialog } from "@/context/dialog";

import { PathActions, Paths } from "@/lib/path";

import { TableFilters } from "@/modules/base/table/types";

import { Site } from "../site/types";

import { CardFormat } from "../card-format/types";
import { CardFormatQueries } from "../card-format/queries";

import {
  CreateButton,
  createActionsColumn,
  useTableUtils,
} from "../base/table/utils";

import { Member } from "./types";
import { MemberQueries } from "./queries";
import { useAccessLevelsColumn } from "./components/AccessLevelsColumn";

interface MembersTableProps {
  filters: Partial<TableFilters & Member>;
  site: Site;
}

export const MembersTable = ({ filters, site }: MembersTableProps) => {
  const drawer = useAppDrawer();
  const params = useParams();
  const history = useHistory();

  const { confirm } = useDialog();
  const [deleteMember] = MemberQueries.useDeleteMutation();

  const { canCreate, canDelete, canUpdate, canView } =
    usePermissions(MemberQueries);

  const accessLevelsColumn = useAccessLevelsColumn({ site });

  const cardFormatSelectProps = CardFormatQueries.tableSelectProps({
    defaultParams: { site_id: site.id },
  });

  const { numberOfLines } = useTableUtils();

  const columns = useMemo<ColumnOptions<Member>[]>(
    () =>
      compact([
        {
          name: "first_name",
          header: "First Name",
          render: ({ row }: { row: Member }) => {
            return (
              <Typography numberOfLines={numberOfLines} type="body">
                {truncateString(row.first_name)}
              </Typography>
            );
          },
          sortable: true,
          filter: (props: FilterProps) => {
            return <FormikTextInputField {...props} />;
          },
        },
        {
          name: "last_name",
          header: "Last Name",
          render: ({ row }: { row: Member }) => {
            return (
              <Typography numberOfLines={numberOfLines} type="body">
                {truncateString(row.last_name)}
              </Typography>
            );
          },
          sortable: true,
          filter: (props: FilterProps) => {
            return <FormikTextInputField {...props} />;
          },
        },
        {
          name: "nickname",
          header: "Nickname",
          filter: (props: FilterProps) => {
            return <FormikTextInputField {...props} />;
          },
          hidden: true,
        },
        {
          name: "email_address",
          header: "Email",
          render: ({ row }: { row: Member }) => {
            return (
              <Typography numberOfLines={numberOfLines} type="body">
                {truncateString(row.email_address, 25)}
              </Typography>
            );
          },
          sortable: true,
          filter: (props: FilterProps) => {
            return <FormikTextInputField {...props} />;
          },
        },
        {
          name: "phone_number",
          header: "Phone",
          render: ({ row }: { row: Member }) => {
            return (
              <Typography numberOfLines={numberOfLines} type="body">
                {formatInternationalPhone(row.phone_number as string)}
              </Typography>
            );
          },
          filter: (props: FilterProps) => {
            return <FormikTextInputField {...props} />;
          },
        },
        {
          name: "aa_member_only",
          header: "AA Member Only",
          filter: (props: FilterProps) => {
            return <FormikSelectField options={booleanOptions} {...props} />;
          },
          hidden: true,
        },
        accessLevelsColumn,
        {
          name: "has_access_levels",
          header: "Has Access Levels",
          filter: (props: FilterProps) => {
            return <FormikSelectField options={booleanOptions} {...props} />;
          },
          hidden: true,
        },
        {
          name: "has_credentials",
          header: "Has Credentials",
          filter: (props: FilterProps) => {
            return <FormikSelectField options={booleanOptions} {...props} />;
          },
          hidden: true,
        },
        {
          name: "has_ble_credentials",
          header: "Has Mobile Credentials",
          filter: (props: FilterProps) => {
            return <FormikSelectField options={booleanOptions} {...props} />;
          },
          hidden: true,
        },
        {
          name: "assigned_pin",
          header: "Assigned Pin",
          filter: (props: FilterProps) => {
            return <FormikTextInputField {...props} />;
          },
          hidden: true,
        },
        {
          name: "assigned_card",
          header: "Assigned Card",
          filter: (props: FilterProps) => <FormikNumberInputField {...props} />,
          hidden: true,
        },
        {
          name: "assigned_card_format",
          header: "Assigned Card Bank",
          filterType: {
            type: "selectField",
            getOptionValue: (option: CardFormat) => {
              return option.id.toString();
            },
            getOptionLabel: (option: CardFormat) => {
              return `${option.name} (${option.facility_code})`;
            },
            ...cardFormatSelectProps,
          },
          hidden: true,
        },
        {
          name: "synced",
          header: "Member Is Synced",
          filter: (props: FilterProps) => {
            return <FormikSelectField options={booleanOptions} {...props} />;
          },
          hidden: true,
        },
        createActionsColumn<Member>({
          maxWidth: 64,
          canDelete,
          onDelete: async (row) => {
            const confirmed = await confirm({
              title: "Delete",
              description: "Are you sure you want to delete this Member?",
              confirmText: "Delete",
              confirmType: "destructive",
              cancelText: "Cancel",
            });

            if (confirmed) {
              deleteMember(row);
            }
          },
          canUpdate,
          onUpdate: async (row) =>
            drawer.push(MemberQueries.queryKey, { initialValues: row, params }),
          shouldAdjustForPressIcon: canView,
        }),
      ]),
    [
      accessLevelsColumn,
      canDelete,
      canUpdate,
      canView,
      cardFormatSelectProps,
      confirm,
      deleteMember,
      drawer,
      numberOfLines,
      params,
    ]
  );

  const tableProps = useTableQuery<any, Member, ListQueryResponse<Member>>({
    fetch: MemberQueries.fetch,
    getQueryKey: ({
      filters: tableFilters,
      page,
      pageSize,
      sortColumn,
      sortDirection,
    }) => [
      MemberQueries.queryKey,
      {},
      {
        ...filters,
        page,
        per_page: pageSize,
        sort: sortColumn,
        dir: sortDirection,
        ...tableFilters,
      },
    ],
    columns,
    defaultPageSize: 10,
  });

  return (
    <Table<Member>
      title={"Members"}
      noRecordsText={"No Members Found"}
      action={
        <CreateButton
          canCreate={canCreate}
          onCreate={() => drawer.push(MemberQueries.queryKey, { params })}
          text={`Add Member`}
        />
      }
      onRowPress={
        canView
          ? (row) =>
              history.push(
                Paths.GetPath(MemberQueries.queryKey, PathActions.View, {
                  ...filters,
                  ...row,
                })
              )
          : undefined
      }
      {...tableProps}
    />
  );
};
