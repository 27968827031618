import {
  ListItemTouchableContainer,
  ListItemLabelValue,
  useTheme,
  ListItemLabelValueProps,
  ListItemTouchableContainerProps,
} from "@smartrent/ui";

import { StyleSheet } from "react-native";

interface UserActivityLogListItemLinkProps {
  href: ListItemTouchableContainerProps["href"];
  style?: ListItemTouchableContainerProps["style"];
  label?: ListItemLabelValueProps["label"];
  value?: ListItemLabelValueProps["value"];
  valueStyle?: ListItemLabelValueProps["valueStyle"];
  numberOfLines?: ListItemLabelValueProps["numberOfLines"];
}

export const UserActivityLogListItemLink: React.FC<
  React.PropsWithChildren<UserActivityLogListItemLinkProps>
> = ({ href, style, label, value, valueStyle, numberOfLines }) => {
  const { colors } = useTheme();

  return (
    <ListItemTouchableContainer
      href={href}
      style={style}
      touchableStyle={(state: any) =>
        state.pressed || state?.hovered
          ? { backgroundColor: colors.listBackgroundHover }
          : { backgroundColor: "inherit" }
      }
    >
      <ListItemLabelValue
        value={value || ""}
        label={label || ""}
        valueStyle={[styles.underlined, { color: colors.primary }, valueStyle]}
        numberOfLines={numberOfLines}
      />
    </ListItemTouchableContainer>
  );
};

const styles = StyleSheet.create({
  underlined: { textDecorationLine: "underline" },
});
