import { Calendar } from "@smartrent/icons";
import { Box, VStack, StatusDot, Typography } from "@smartrent/ui";
import { useState } from "react";
import { View, StyleSheet, TouchableOpacity } from "react-native";

import { formatDateTime } from "@/lib/formatters";

export interface ControllerResponse {
  path?: string;
  status?: "success" | "error" | "warning" | "info";
  body: string;
  timestamp: string;
}

export const ControllerResponseRow = ({
  path,
  body,
  status,
  timestamp,
}: ControllerResponse) => {
  const [collapsed, setCollapsed] = useState(false);

  return (
    <View>
      <TouchableOpacity
        onPress={() => {
          setCollapsed(!collapsed);
        }}
        activeOpacity={0.7}
        style={styles.reply}
      >
        <Box style={[styles.box, { borderLeftColor: status }]}>
          <VStack spacing={12}>
            <View style={styles.boxContent}>
              <View style={styles.boxPath}>
                <StatusDot color={status} />
                <Typography
                  type="title4"
                  style={styles.responseText}
                  numberOfLines={1}
                >
                  {path}
                </Typography>
              </View>
              <View style={styles.boxPath}>
                <Typography type="body" style={styles.responseText}>
                  {formatDateTime(timestamp)}
                </Typography>
                <Calendar />
              </View>
            </View>
          </VStack>
        </Box>
      </TouchableOpacity>
      {!collapsed ? (
        <View style={styles.replyBody}>
          <Typography type="caption">{body}</Typography>
        </View>
      ) : null}
    </View>
  );
};

const styles = StyleSheet.create({
  box: {
    borderLeftWidth: 3,
  },
  boxContent: {
    flexDirection: "row",
    justifyContent: "space-between",
    overflow: "hidden",
  },
  boxPath: { alignItems: "center", flexDirection: "row" },
  responseText: { marginLeft: 8, marginRight: 8 },
  reply: {},
  replyBody: {
    padding: 16,
  },
});
