import { StyleSheet, View } from "react-native";

import {
  FilterProps,
  FormikTextInputField,
  ListQueryResponse,
  Table,
  Typography,
  Tooltip,
  useTableQuery,
  HStack,
} from "@smartrent/ui";

import { useParams } from "react-router-dom";

import { InformationSolid, TrashX } from "@smartrent/icons";

import { useCallback } from "react";

import { usePermissions } from "@/context/PolicyContext";

import { useAppDrawer } from "@/components/layout/AppDrawer";

import { useDialog } from "@/context/dialog";

import { QueryKeys } from "@/types";

import { EN_DASH } from "@/utils/chars";

import { CreateButton, createActionsColumn } from "../base/table/utils";

import { ElevatorQueries } from "../elevator/queries";

import { ResponsiveButton } from "../base/table/ResponsiveButton";

import { Door } from "../door/types";

import { Floor, FloorTableFilters } from "./types";
import { FloorQueries } from "./queries";

interface FloorsTableProps {
  filters: Partial<FloorTableFilters>;
}

export const FloorsTable = ({ filters }: FloorsTableProps) => {
  const drawer = useAppDrawer();
  const params = useParams();
  const { confirm } = useDialog();
  const [doUpdate] = ElevatorQueries.useUpdateMutation();
  const [doDelete] = FloorQueries.useDeleteMutation();

  const { data: elevator } = ElevatorQueries.useQuery({ id: filters.door_id });

  const hasEals = elevator?.floors?.some(
    (floor) => floor?.elevator_access_levels?.length
  );

  const { canCreate, canDelete, canUpdate } = usePermissions(FloorQueries);

  const handleDeleteAll = useCallback(async () => {
    const confirmed = await confirm({
      title: "Delete All",
      description: "Are you sure you want to delete all Floors?",
      confirmText: "Delete All",
      confirmType: "destructive",
      cancelText: "Cancel",
    });
    if (confirmed) {
      doUpdate({
        id: filters.door_id as number,
        values: { floors: [], strike_mode: null } as unknown as Door,
      });
    }
  }, [confirm, doUpdate, filters?.door_id]);

  const tableProps = useTableQuery<any, Floor, ListQueryResponse<Floor>>({
    fetch: FloorQueries.fetch,
    getQueryKey: ({
      filters: tableFilters,
      page,
      pageSize,
      sortColumn,
      sortDirection,
    }) => [
      FloorQueries.queryKey,
      {},
      {
        ...filters,
        page,
        per_page: pageSize,
        sort: sortColumn,
        dir: sortDirection,
        ...tableFilters,
      },
    ],
    columns: [
      {
        name: "name",
        header: "Name",
        style: styles.column,
        relativeWidth: 40,
        render: ({ row }) => {
          return <Typography numberOfLines={1}>{row.name}</Typography>;
        },
        sortable: true,
        filter: (props: FilterProps) => {
          return <FormikTextInputField {...props} />;
        },
      },
      {
        name: "controller_id",
        header: "Controller",
        style: styles.column,
        relativeWidth: 48,
        render: ({ row }) => {
          return (
            <Typography numberOfLines={1}>
              {row?.controller?.name || EN_DASH}
            </Typography>
          );
        },
      },
      {
        name: "panel_id",
        header: "Panel",
        style: styles.column,
        relativeWidth: 56,
        render: ({ row }) => {
          return (
            <Typography numberOfLines={1}>
              {row?.output_panel_name || EN_DASH}
            </Typography>
          );
        },
      },
      {
        name: "elevator_access_levels",
        header: "Active Schedules Count",
        style: styles.column,
        relativeWidth: 40,
        render: ({ row }) => {
          return (
            <Typography numberOfLines={1}>
              {row?.elevator_access_levels?.length || EN_DASH}
            </Typography>
          );
        },
      },
      createActionsColumn<Floor>({
        relativeWidth: 75,
        maxWidth: 75,
        style: { flexGrow: 1 },
        canDelete: !hasEals && canDelete,
        onDelete: async (row) => {
          const confirmed = await confirm({
            title: "Delete",
            description: "Are you sure you want to delete this Floor?",
            confirmText: "Delete",
            confirmType: "destructive",
            cancelText: "Cancel",
          });
          if (confirmed) {
            doDelete({ id: row.id });
          }
        },
        canUpdate: !hasEals && canUpdate,
        onUpdate: (row) =>
          drawer.push(QueryKeys.Floors, {
            initialValues: row,
            params: {
              door_id: filters?.door_id as number,
              door_name: filters?.door_name,
            },
          }),
      }),
    ],
    defaultPageSize: 10,
  });
  return (
    <Table<Floor>
      title={"Floors"}
      noRecordsText={`No Floors Found`}
      action={
        <View style={styles.flexRow}>
          {hasEals ? (
            <Tooltip
              name="create_disabled_tooltip"
              title="To edit Floor Details, first clear all Elevator Access Levels."
            >
              <View style={styles.spacingSm}>
                <InformationSolid color={"gray"} size={24} />
              </View>
            </Tooltip>
          ) : null}
          <HStack spacing={8}>
            <CreateButton
              canCreate={canCreate}
              onCreate={() => drawer.push(FloorQueries.queryKey, { params })}
              text={"Add Floors"}
              disabled={hasEals}
            />
            {elevator?.floors?.length ? (
              <ResponsiveButton
                disabled={hasEals}
                icon={TrashX}
                iconRight={TrashX}
                onPress={handleDeleteAll}
                text={"Delete All"}
                color={"destructive"}
              />
            ) : null}
          </HStack>
        </View>
      }
      {...tableProps}
    />
  );
};

const styles = StyleSheet.create({
  column: {
    paddingRight: 16,
  },
  flexRow: {
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "wrap",
  },
  spacingSm: {
    paddingHorizontal: 4,
  },
});
