import {
  FormikTextInputField,
  Typography,
  FilterProps,
  FormikSelectField,
  Table,
  ListQueryResponse,
  useTableQuery,
} from "@smartrent/ui";

import { useHistory, useParams } from "react-router-dom";

import { useAppDrawer } from "@/components/layout/AppDrawer";

import { usePermissions } from "@/context/PolicyContext";

import { PathActions, Paths } from "@/lib/path";

import { TableFilters } from "@/modules/base/table/types";

import { createActionsColumn, CreateButton } from "../base/table/utils";

import { Site, SystemTypeOptions, getSystemFriendlyNames } from "./types";
import { SiteQueries } from "./queries";

interface SitesTableProps {
  filters: Partial<TableFilters & Site>;
}

export const SitesTable = ({ filters }: SitesTableProps) => {
  const drawer = useAppDrawer();
  const params = useParams();
  const history = useHistory();

  const { canView, canCreate, canUpdate } = usePermissions(SiteQueries);

  const tableProps = useTableQuery<any, Site, ListQueryResponse<Site>>({
    fetch: SiteQueries.fetch,
    getQueryKey: ({
      filters: tableFilters,
      page,
      pageSize,
      sortColumn,
      sortDirection,
    }) => [
      SiteQueries.queryKey,
      {},
      {
        ...filters,
        page,
        per_page: pageSize,
        sort: sortColumn,
        dir: sortDirection,
        ...tableFilters,
      },
    ],
    columns: [
      {
        name: "name",
        header: "Name",
        render: ({ row }) => {
          return <Typography>{row.name}</Typography>;
        },
        sortable: true,
        filter: (props: FilterProps) => {
          return <FormikTextInputField {...props} />;
        },
      },
      {
        name: "system_type",
        header: "System Type",
        render: ({ row }) => {
          return (
            <Typography>{getSystemFriendlyNames(row.system_type)}</Typography>
          );
        },
        sortable: true,
        filter: (props: FilterProps) => {
          return <FormikSelectField options={SystemTypeOptions} {...props} />;
        },
      },
      createActionsColumn<Site>({
        shouldAdjustForPressIcon: canView,
        canUpdate,
        onUpdate: async (row) =>
          drawer.push(SiteQueries.queryKey, { initialValues: row, params }),
      }),
    ],
    defaultPageSize: 10,
  });
  return (
    <Table<Site>
      title={"Sites"}
      noRecordsText={`No Sites Found`}
      action={
        <CreateButton
          canCreate={canCreate}
          onCreate={() => drawer.push(SiteQueries.queryKey, { params })}
          text={"Add Site"}
        />
      }
      onRowPress={
        canView
          ? (row) =>
              history.push(
                Paths.GetPath(SiteQueries.queryKey, PathActions.View, {
                  ...filters,
                  ...row,
                })
              )
          : undefined
      }
      {...tableProps}
    />
  );
};
