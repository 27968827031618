import {
  LoadingCard,
  TabContent,
  TabProvider,
  TabsNavigation,
  useQueryStringField,
  useTheme,
} from "@smartrent/ui";
import { useContext, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";

import { View, StyleSheet } from "react-native";

import { ErrorPage } from "@/components/ErrorPage";
import { PageCtx } from "@/context/PageContext";
import { Paths } from "@/lib/path";
import { AccessLevelQueries } from "@/modules/access-level/queries";
import { Site } from "@/modules/site/types";
import { AccessLevelSchedulesTable } from "@/modules/access-level-schedule/Table";
import { ElevatorAccessLevelSchedulesTable } from "@/modules/elevator-access-level/Table";

interface AccessLevelShowPageProps {
  site: Site;
}

export const AccessLevelShowPage: React.FC<
  React.PropsWithChildren<AccessLevelShowPageProps>
> = ({ site }) => {
  const { access_level_id } = useParams<{ access_level_id: string }>();
  const { addBreadcrumb } = useContext(PageCtx);
  const history = useHistory();
  const activeTab = useQueryStringField("tab");
  const { colors } = useTheme();

  const { data: access_level, isLoading } = AccessLevelQueries.useQuery({
    id: parseInt(access_level_id as string),
  });

  useEffect(() => {
    if (access_level) {
      addBreadcrumb({
        label: `${access_level?.name}`,
        to: Paths.accessLevelShowPath(site.id, access_level_id),
      });
    }
  }, [addBreadcrumb, site.id, access_level, access_level_id]);

  if (isLoading) {
    return <LoadingCard />;
  }
  if (!access_level) {
    return <ErrorPage title={"Error retrieving access level"} />;
  }

  return (
    <TabProvider>
      <View style={[styles.tabContainer, { borderColor: colors.border }]}>
        <TabsNavigation
          align="flex-start"
          initialActiveTabId={activeTab || "doors"}
          items={[
            {
              id: "doors",
              label: "Door Access",
              onPress: () =>
                history.replace({
                  pathname: window.location.pathname,
                  search: "?tab=doors",
                }),
            },
            {
              id: "elevators",
              label: "Elevator Access",
              onPress: () =>
                history.replace({
                  pathname: window.location.pathname,
                  search: "?tab=elevators",
                }),
            },
          ]}
        />
      </View>

      <TabContent id="doors">
        <AccessLevelSchedulesTable
          filters={{ access_level_id: access_level.id }}
          access_level={access_level}
        />
      </TabContent>
      <TabContent id="elevators">
        <ElevatorAccessLevelSchedulesTable
          site={site}
          access_level={access_level}
        />
      </TabContent>
    </TabProvider>
  );
};

const styles = StyleSheet.create({
  tabContainer: {
    borderWidth: 1,
    borderRadius: 4,
    borderStyle: "solid",
    paddingBottom: 8,
  },
});
