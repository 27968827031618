import { TabsNavigation } from "@smartrent/ui";

import { useRouteMatch } from "react-router-dom";

import { Paths } from "@/lib/path";

interface ScheduleQueryParams {
  site_id: string;
}
export const ScheduleNavigation = () => {
  const { params } = useRouteMatch();
  const site_id = (params as ScheduleQueryParams).site_id;

  return (
    <TabsNavigation
      testID="schedulenavigation"
      align="flex-start"
      items={[
        {
          id: "schedules",
          label: "Schedules",
          to: `${Paths.schedulesListPath(site_id)}`,
        },
        {
          id: "exceptions",
          label: "Exceptions",
          to: `${Paths.holidayGroupListPath(site_id)}`,
        },
      ]}
    />
  );
};
