import { useContext } from "react";

import {
  LoadingCard,
  Panel,
  PanelBody,
  PanelHeader,
  PanelHeaderTitle,
} from "@smartrent/ui";

import { useParams } from "react-router-dom";

import { OrganizationQueries } from "@/modules/organization/queries";
import { ErrorPage } from "@/components/ErrorPage";
import { OrganizationForm } from "@/modules/organization/Form";
import { AuthContext } from "@/context/Auth";

export const OrganizationSettingsPage = () => {
  const { user } = useContext(AuthContext);
  const { data: organization, isLoading } = OrganizationQueries.useQuery({
    id: user?.organization_id,
  });
  const params = useParams();

  if (isLoading) {
    return <LoadingCard />;
  }
  if (!organization) {
    return <ErrorPage title={"Failed To Load Organization"} />;
  }

  return (
    <Panel>
      <PanelHeader>
        <PanelHeaderTitle title="Organization Settings" />
      </PanelHeader>
      <PanelBody>
        <OrganizationForm initialValues={organization} params={params} />
      </PanelBody>
    </Panel>
  );
};
