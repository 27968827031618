import { BaseDrawer } from "../base/Drawer";

import { FeatureFlagForm } from "./Form";
import { FeatureFlag } from "./types";

interface FeatureFlagDrawerProps {
  initialValues?: FeatureFlag;
  params?: any;
}

export const FeatureFlagDrawer: React.FC<
  React.PropsWithChildren<FeatureFlagDrawerProps>
> = ({ initialValues, params }) => {
  return (
    <BaseDrawer
      title={initialValues?.id ? "Edit Feature Flag" : "Create Feature Flag"}
    >
      <FeatureFlagForm initialValues={{ ...initialValues }} params={params} />
    </BaseDrawer>
  );
};
