import { useCallback } from "react";
import { StyleSheet, View } from "react-native";
import { useHistory, useParams } from "react-router-dom";
import {
  Link,
  TextThemeColor,
  Typography,
  useTheme,
  useToast,
} from "@smartrent/ui";

import { Logo } from "@/components/alloy-access/Logo";
import {
  PasswordForm,
  PasswordParams,
} from "@/modules/user/components/PasswordForm";
import { AuthQueries } from "@/modules/auth/queries";

export const ResetPasswordPage = () => {
  const { base64Email, resetToken } = useParams<{
    base64Email: string;
    resetToken: string;
  }>();

  const { colors } = useTheme();
  const history = useHistory();
  const setToast = useToast();

  const [resetPassword] = AuthQueries.useResetPasswordMutation({
    onSuccess: () => {
      history.push("/");
      setToast({
        message: "Your password has been reset. You may now log in.",
        status: "success",
        title: "Success",
      });
    },
  });

  const handleSubmit = useCallback(
    async (values: PasswordParams) => {
      resetPassword(Object.assign({ reset_token: resetToken }, values)).catch(
        () => {
          /* No-op since the mutation handles this for us */
        }
      );
    },
    [resetPassword, resetToken]
  );

  return (
    <View style={styles.root}>
      <View style={styles.container}>
        <View style={styles.logoContainer}>
          <Logo size={28.5} />
        </View>

        <Typography
          type="title3"
          color={colors.gray700 as TextThemeColor}
          style={styles.centeredHeader}
        >
          Reset Password
        </Typography>

        <PasswordForm
          email={atob(base64Email)}
          handleSubmit={handleSubmit}
          submitText="Reset Password"
        />
      </View>

      <Typography type="bodySmall" style={styles.marginTop20}>
        Back to{" "}
        <Link type="bodySmall" href="/">
          Sign In
        </Link>
      </Typography>
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    alignItems: "center",
    justifyContent: "center",
    padding: 16,
  },
  container: {
    minWidth: 350,
  },
  logoContainer: {
    marginBottom: 45,
    minWidth: 325,
  },
  centeredHeader: {
    textAlign: "center",
    marginBottom: 40,
  },
  marginTop20: {
    marginTop: 20,
  },
});
