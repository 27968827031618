import { Form, Formik, FormikHelpers } from "formik";
import * as yup from "yup";
import { FormikSubmit, FormikTextInputField, VStack } from "@smartrent/ui";

import { ASPConfig } from "@/modules/controller/configurations/asp";

interface Props {
  handleSubmit: (
    values: ASPConfig,
    helpers: FormikHelpers<ASPConfig>
  ) => Promise<void>;
  config: ASPConfig;
}

const validationSchema = yup.object().shape({
  connection_string: yup.string().required().label("Connection String"),
});

export const ASPControllerConfigurationForm = ({
  config,
  handleSubmit,
}: Props) => {
  return (
    <Formik<ASPConfig>
      initialValues={{
        connection_string: config?.connection_string,
      }}
      enableReinitialize={true}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ dirty }) => {
        return (
          <Form>
            <VStack spacing={16}>
              <FormikTextInputField
                name="connection_string"
                label="Connection String"
                assistiveText="Format: 192.168.1.123:5000"
              />
              {dirty && <FormikSubmit label="Set" />}
            </VStack>
          </Form>
        );
      }}
    </Formik>
  );
};
