import {
  FilterProps,
  FormikTextInputField,
  ListQueryResponse,
  Table,
  Typography,
  useTableQuery,
} from "@smartrent/ui";

import { useHistory, useParams } from "react-router-dom";

import { Paths } from "@/lib/path";

import { usePermissions } from "@/context/PolicyContext";

import { useDialog } from "@/context/dialog";

import { useAppDrawer } from "@/components/layout/AppDrawer";

import { TableFilters } from "@/modules/base/table/types";

import { Site } from "../site/types";

import { CreateButton, createActionsColumn } from "../base/table/utils";

import { HolidayScheduleGroup } from "./types";

import { HolidayScheduleGroupQueries } from "./queries";

interface HolidayGroupTableProps {
  filters: Partial<TableFilters & HolidayScheduleGroup>;
  site: Site;
}

export const HolidayGroupTable = ({
  filters,
  site,
}: HolidayGroupTableProps) => {
  const drawer = useAppDrawer();
  const params = useParams();
  const history = useHistory();

  const { confirm } = useDialog();
  const [doDelete] = HolidayScheduleGroupQueries.useDeleteMutation();

  const { canView, canCreate, canDelete, canUpdate } = usePermissions(
    HolidayScheduleGroupQueries
  );

  const tableProps = useTableQuery<
    any,
    HolidayScheduleGroup,
    ListQueryResponse<HolidayScheduleGroup>
  >({
    fetch: HolidayScheduleGroupQueries.fetch,
    getQueryKey: ({
      filters: tableFilters,
      page,
      pageSize,
      sortColumn,
      sortDirection,
    }) => [
      HolidayScheduleGroupQueries.queryKey,
      {},
      {
        site_id: site.id,
        ...filters,
        page,
        per_page: pageSize,
        sort: sortColumn,
        dir: sortDirection,
        ...tableFilters,
      },
    ],
    columns: [
      {
        name: "name",
        header: "Group Name",
        render: ({ row }) => {
          return <Typography>{row.name}</Typography>;
        },
        sortable: true,
        filter: (props: FilterProps) => {
          return <FormikTextInputField {...props} />;
        },
      },
      {
        name: "holiday_count",
        header: "# of Exceptions",
        render: ({ row }) => {
          return <Typography>{row.holiday_schedule_count}</Typography>;
        },
      },
      createActionsColumn<HolidayScheduleGroup>({
        shouldAdjustForPressIcon: canView,
        canDelete,
        onDelete: async (row) => {
          const confirmed = await confirm({
            title: "Delete",
            description:
              "Are you sure you want to delete this Exception Group?",
            confirmText: "Delete",
            confirmType: "destructive",
            cancelText: "Cancel",
          });
          if (confirmed) {
            doDelete({ id: row.id });
          }
        },
        canUpdate,
        onUpdate: async (row) =>
          drawer.push(HolidayScheduleGroupQueries.queryKey, {
            initialValues: row,
            params,
          }),
      }),
    ],
    defaultPageSize: 10,
  });
  return (
    <Table<HolidayScheduleGroup>
      title={"Exception Groups"}
      noRecordsText={"No Exceptions Groups Found"}
      action={
        <CreateButton
          canCreate={canCreate}
          onCreate={() =>
            drawer.push(HolidayScheduleGroupQueries.queryKey, { params })
          }
          text={"Add Exception Group"}
        />
      }
      onRowPress={(row) =>
        history.push(
          Paths.BuildViewPath(Paths.holidayGroupViewPath, {
            ...filters,
            ...row,
          })
        )
      }
      {...tableProps}
    />
  );
};
