import { Route, Switch, useRouteMatch } from "react-router-dom";

import { Site } from "@/modules/site/types";

import { CardsListPage } from "./CardsListPage";

interface CardsRouterProps {
  site: Site;
}

export const CardsRouter = ({ site }: CardsRouterProps) => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}`}>
        <CardsListPage site={site} />
      </Route>
    </Switch>
  );
};
