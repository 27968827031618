import {
  VStack,
  FormikSelectField,
  FormikNumberInputField,
  FormikTextInputField,
} from "@smartrent/ui";
import { Form } from "formik";
import * as yup from "yup";

import { BaseForm } from "../base/Form";

import { CardFormatQueries } from "./queries";
import {
  CardFormat,
  CardFormatOptions,
  MAX_CARD_NUMBER,
  MIN_CARD_NUMBER,
  ValidCardFormats,
} from "./types";

const validationSchema = yup.object().shape({
  name: yup.string().required().label("Name").max(40),
  format: yup.string().oneOf(ValidCardFormats).required().label("Card Format"),
  first_number: yup
    .number()
    .required()
    .label("First Number")
    .min(MIN_CARD_NUMBER)
    .max(MAX_CARD_NUMBER),
  last_number: yup
    .number()
    .required()
    .label("Last Number")
    .min(MIN_CARD_NUMBER)
    .max(MAX_CARD_NUMBER)
    .min(yup.ref("first_number"), "Last Number can't be before Start Number"),
  offset: yup.number().required().min(0).max(MAX_CARD_NUMBER).label("Offset"),
  facility_code: yup
    .number()
    .required()
    .label("Facility Code")
    .min(-1)
    .max(MAX_CARD_NUMBER),
});

interface CardFormatFormProps {
  initialValues?: Partial<CardFormat>;
  params: { site_id: number };
}

export const CardFormatForm = ({
  initialValues,
  params,
}: CardFormatFormProps) => {
  return (
    <BaseForm<CardFormat>
      initialValues={{
        type: "card",
        site_id: params.site_id,
        offset: initialValues?.offset || 0,
        name: initialValues?.name || "",
        format: initialValues?.format,
        first_number: initialValues?.first_number,
        last_number: initialValues?.last_number,
        facility_code: initialValues?.facility_code,
        ...initialValues,
      }}
      validationSchema={validationSchema}
      QueryClient={CardFormatQueries}
      params={params}
    >
      {() => {
        return (
          <Form>
            <VStack spacing={16}>
              <FormikTextInputField
                name="name"
                label="Name"
                required
                assistiveText="Grey Fobs or Car Tags"
              />
              <FormikSelectField
                name="format"
                label="Format"
                options={CardFormatOptions}
                disabled={!!initialValues?.id}
                required
              />
              <FormikNumberInputField
                name="first_number"
                label="First Number"
                required
              />
              <FormikNumberInputField
                name="last_number"
                label="Last Number"
                required
              />
              <FormikNumberInputField
                name="offset"
                label="Offset"
                disabled={!!initialValues?.id}
                required
              />
              <FormikNumberInputField
                name="facility_code"
                label="Facility Code"
                required
              />
            </VStack>
          </Form>
        );
      }}
    </BaseForm>
  );
};
