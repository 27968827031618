import {
  ListQueryResponse,
  Table,
  Typography,
  useTableQuery,
} from "@smartrent/ui";

import { useParams } from "react-router-dom";

import { AccessLevel } from "@/modules/access-level/types";

import { useAppDrawer } from "@/components/layout/AppDrawer";

import { useDialog } from "@/context/dialog";

import { usePermissions } from "@/context/PolicyContext";

import { TableFilters } from "@/modules/base/table/types";

import { CreateButton, createActionsColumn } from "@/modules/base/table/utils";

import { AccessLevelScheduleQueries } from "./queries";
import { AccessLevelSchedule } from "./types";

interface AccessLevelSchedulesTableProps {
  filters: TableFilters & { access_level_id: number };
  access_level: AccessLevel;
}

export const AccessLevelSchedulesTable: React.FC<
  React.PropsWithChildren<AccessLevelSchedulesTableProps>
> = ({ filters, access_level }) => {
  const drawer = useAppDrawer();
  const params = useParams();
  const { confirm } = useDialog();

  const { queryKey, fetch, useDeleteMutation } = AccessLevelScheduleQueries;

  const { canCreate, canDelete } = usePermissions({ queryKey });

  const [doDeleteMutation] = useDeleteMutation();

  const tableProps = useTableQuery<
    any,
    AccessLevelSchedule,
    ListQueryResponse<AccessLevelSchedule>
  >({
    columns: [
      {
        name: "door",
        header: "Door",
        render: ({ row }) => {
          return <Typography>{row.door?.name}</Typography>;
        },
      },
      {
        name: "name",
        header: "Schedule",
        render: ({ row }) => {
          return <Typography>{row.schedule?.name}</Typography>;
        },
      },
      createActionsColumn<AccessLevelSchedule>({
        canDelete,
        onDelete: async (row) => {
          const confirmed = await confirm({
            title: "Delete",
            description: `Are you sure you want to delete this Access Level?`,
            confirmText: "Delete",
            confirmType: "destructive",
            cancelText: "Cancel",
          });
          if (confirmed) {
            doDeleteMutation({ id: row.id });
          }
        },
      }),
    ],
    fetch,
    getQueryKey: ({
      filters: tableFilters,
      page,
      pageSize,
      sortColumn,
      sortDirection,
    }) => [
      queryKey,
      {},
      {
        ...filters,
        door_type: "doors",
        page,
        per_page: pageSize,
        sort: sortColumn,
        dir: sortDirection,
        ...tableFilters,
      },
    ],
    defaultPageSize: 10,
  });
  return (
    <Table<AccessLevelSchedule>
      title={`${access_level.name} Door Access`}
      noRecordsText={"No Doors Found"}
      action={
        <CreateButton
          canCreate={canCreate}
          onCreate={() => drawer.push(queryKey, { params })}
          text={"Add Door"}
        />
      }
      {...tableProps}
    />
  );
};
