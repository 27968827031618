import {
  VStack,
  FormikTextInputField,
  FormikNumberInputField,
  FormikCheckbox,
} from "@smartrent/ui";
import { Form, FormikProps } from "formik";
import * as yup from "yup";

import { BaseForm, FormProps } from "../base/Form";

import { SipAccountQueries } from "./queries";
import { SipAccount } from "./types";

const validationSchema = yup.object().shape({
  controller_id: yup.number().required(),
  account_active: yup.boolean(),
  display_name: yup
    .string()
    .nullable()
    .when("account_active", {
      is: true,
      then: yup.string().required("This field is required"),
    }),
  user_name: yup
    .string()
    .nullable()
    .when("account_active", {
      is: true,
      then: yup.string().required("This field is required"),
    }),
  register_name: yup
    .string()
    .nullable()
    .when("account_active", {
      is: true,
      then: yup.string().required("This field is required"),
    }),
  password: yup
    .string()
    .nullable()
    .when("account_active", {
      is: true,
      then: yup.string().required("This field is required"),
    }),
  sip_server_host: yup
    .string()
    .nullable()
    .when("account_active", {
      is: true,
      then: yup.string().required("This field is required"),
    }),
  sip_server_port: yup.number().when("account_active", {
    is: true,
    then: yup.number().required("This field is required"),
  }),
  stun_enable: yup.boolean(),
  stun_server: yup
    .string()
    .nullable()
    .when("stun_enable", {
      is: true,
      then: yup.string().nullable().required("This field is required"),
    }),
  stun_port: yup.number().when("stun_enable", {
    is: true,
    then: yup.number().required("This field is required"),
  }),
});

export const SipAccountForm = ({
  initialValues,
  params,
}: FormProps<SipAccount>) => {
  return (
    <BaseForm<SipAccount>
      initialValues={{
        ...initialValues,
        controller_id: params.controller_id,
      }}
      validationSchema={validationSchema}
      QueryClient={SipAccountQueries}
      params={params}
    >
      {({ values }: FormikProps<SipAccount>) => (
        <Form>
          <VStack spacing={8}>
            <FormikCheckbox
              name="account_active"
              label="Sip Account Enabled?"
            />
            {values.account_active && (
              <>
                <FormikTextInputField
                  name="display_name"
                  label="Display Name"
                  required
                />
                <FormikTextInputField
                  name="register_name"
                  label="Register Name"
                  required
                />
                <FormikTextInputField
                  name="user_name"
                  label="User Name"
                  required
                />
                <FormikTextInputField
                  name="password"
                  label="Password"
                  required
                />
                <FormikTextInputField
                  name="sip_server_host"
                  label="Sip Server Host"
                  required
                />
                <FormikNumberInputField
                  name="sip_server_port"
                  label="Sip Server Port"
                  required
                />
                <FormikCheckbox
                  name="stun_enable"
                  label="Stun Server Enabled?"
                />
                {values.stun_enable && (
                  <>
                    <FormikTextInputField
                      name="stun_server"
                      label="Stun Server Host"
                      required
                    />
                    <FormikNumberInputField
                      name="stun_port"
                      label="Stun Server Port"
                      required
                    />
                  </>
                )}
              </>
            )}
          </VStack>
        </Form>
      )}
    </BaseForm>
  );
};
