import { Button, SelectField } from "@smartrent/ui";

import { useState } from "react";
import { View } from "react-native";

import { useFieldValue, useSetFieldValue } from "@smartrent/forms";

import { Calendar } from "@smartrent/icons";

import { ScheduleQueries } from "@/modules/schedule/queries";
import { Site } from "@/modules/site/types";
import { Floor } from "@/modules/floor/types";

import { AccessLevel } from "@/modules/access-level/types";

import { ScheduleOption } from "../types";
import { findElevatorAccessLevel } from "../utils/find-elevator-access-level";

export const SetAllFloorSchedulesAction: React.FC<
  React.PropsWithChildren<{
    access_level: AccessLevel;
    site: Site;
    defaultOption: ScheduleOption;
  }>
> = ({ site, access_level, defaultOption }) => {
  const [schedule, setSchedule] = useState<ScheduleOption>();
  const [isAnimating, setIsAnimating] = useState<boolean>();

  const floors = useFieldValue<Floor[]>("floors");
  const setFloors = useSetFieldValue("floors");

  const selectScheduleProps = ScheduleQueries.select({
    filterParams: { site_id: site.id },
  });

  return (
    <View>
      <SelectField<ScheduleOption>
        required={false}
        assistiveText="Choose schedule to apply to all floors"
        onChange={(value) => setSchedule(value)}
        name="schedule_override"
        label="Schedule"
        getOptionValue={(option) => option}
        getOptionLabel={(option) => `${option.name}`}
        value={schedule}
        options={[defaultOption, ...selectScheduleProps.options]}
        // set keyExtractor callback to prevent duplicate key console errors
        flatListProps={{
          keyExtractor: (item, index) =>
            `${item.id}-${index}-schedule-override-opt`,
        }}
        StartAdornment={Calendar}
      />
      <Button
        onPress={(e) => {
          setIsAnimating(true);
          const updatedFloors = floors?.map((floor) => {
            const elevator_access_level = findElevatorAccessLevel(
              floor,
              access_level.id
            );

            return {
              ...floor,
              elevator_access_levels: [
                {
                  ...elevator_access_level,
                  access_level_id: access_level.id,
                  floor_id: floor.id,
                  schedule_id: schedule ? schedule.id : null,
                  schedule: schedule ? { name: schedule.name } : null,
                },
              ],
            } as Floor;
          });
          setFloors(updatedFloors);

          return setTimeout(() => {
            setIsAnimating(false);
            setSchedule(undefined);
          }, 600);
        }}
        isLoading={isAnimating}
        disabled={!schedule}
      >
        Apply To All Floors
      </Button>
    </View>
  );
};
