import {
  Panel,
  PanelBody,
  PanelHeader,
  PanelHeaderTitle,
  ListItemContainer,
  ListItemLabelValue,
  useTheme,
  HStack,
} from "@smartrent/ui";
import { View, StyleSheet, TouchableOpacity } from "react-native";
import { PencilSolid, TrashX } from "@smartrent/icons";
import { useHistory } from "react-router-dom";

import { toTitleCase } from "@/lib/helpers";
import { Site } from "@/modules/site/types";
import { Member } from "@/modules/member/types";
import { QueryKeys } from "@/types";
import { useAppDrawer } from "@/components/layout/AppDrawer";
import { MemberQueries } from "@/modules/member/queries";
import { useDialog } from "@/context/dialog";
import { PathActions, Paths } from "@/lib/path";
import { DATE_TIME_FORMAT, formatDateTime } from "@/lib/formatters";

interface InformationPanelProps {
  site: Site;
  member: Member;
}

const InformationItem = ({
  label,
  value,
}: {
  label: string;
  value?: string | number;
}) => {
  return (
    <ListItemContainer style={styles.informationRow}>
      <ListItemLabelValue
        numberOfLines={3}
        label={label}
        value={value ? (value as string) : "-"}
      />
    </ListItemContainer>
  );
};

export const InformationPanel = ({ member, site }: InformationPanelProps) => {
  const { colors } = useTheme();
  const drawer = useAppDrawer();
  const { confirm } = useDialog();
  const [doDelete] = MemberQueries.useDeleteMutation();
  const history = useHistory();

  const PanelActions = () => {
    return (
      <HStack spacing={16} align="center">
        <TouchableOpacity
          activeOpacity={0.7}
          onPress={() => {
            drawer.push(QueryKeys.Members, {
              initialValues: member,
              params: { site_id: site.id },
            });
          }}
        >
          <PencilSolid color={colors.gray600} />
        </TouchableOpacity>
        <TouchableOpacity
          activeOpacity={0.7}
          onPress={async () => {
            const confirmed = await confirm({
              title: "Delete Member?",
              description: "Are you sure you want to delete this Member?",
              confirmText: "Delete",
              confirmType: "destructive",
            });

            if (confirmed) {
              await doDelete({ id: member.id });
              history.push(
                Paths.GetPath(QueryKeys.Members, PathActions.List, {
                  site_id: site.id,
                })
              );
            }
          }}
        >
          <TrashX color={colors.gray600} />
        </TouchableOpacity>
      </HStack>
    );
  };

  return (
    <Panel>
      <PanelHeader style={styles.panelHeader} EndAdornment={PanelActions()}>
        <View style={styles.panelHeaderTitleContainer}>
          <PanelHeaderTitle
            title={`${member.first_name} ${member.last_name}`}
          />
        </View>
      </PanelHeader>
      <PanelBody>
        <View>
          <InformationItem
            label="Created"
            value={formatDateTime(
              `${member.inserted_at}Z` as string,
              site.timezone as string,
              DATE_TIME_FORMAT
            )}
          />
          <InformationItem label="Phone Number" value={member.phone_number} />
          <InformationItem label="Email Address" value={member.email_address} />
          <InformationItem label="Nick Name" value={member.nickname} />
          <InformationItem label="Location" value={member.location} />
          <InformationItem label="Title" value={member.title} />
          <InformationItem label="Department" value={member.department} />
          <InformationItem
            label="Communication Preference"
            value={toTitleCase(member.communication_preference)}
          />
          <InformationItem label="Remote ID" value={member.remote_id} />
        </View>
      </PanelBody>
    </Panel>
  );
};

const styles = StyleSheet.create({
  panelHeader: { alignItems: "flex-start" },
  panelHeaderTitleContainer: { flex: 1, paddingRight: 16 },
  informationRow: { flex: 1 },
});
