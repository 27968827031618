import { BaseDrawer } from "../base/Drawer";

import { CardFormat } from "./types";
import { CardFormatForm } from "./Form";

interface CardFormatDrawerProps {
  initialValues?: CardFormat;
  params: {
    site_id: number;
  };
}

export const CardFormatDrawer: React.FC<
  React.PropsWithChildren<CardFormatDrawerProps>
> = ({ initialValues, params }) => {
  return (
    <BaseDrawer
      title={initialValues?.id ? initialValues.name : "New Credential"}
      subtitle={initialValues?.id ? "Edit Credential" : null}
    >
      <CardFormatForm initialValues={initialValues} params={params} />
    </BaseDrawer>
  );
};
