import { MemberQueries } from "../member/queries";

import { BaseDrawer } from "../base/Drawer";

import { CredentialForm } from "./Form";
import { Credential } from "./types";
import { CredentialSyncForm } from "./components/CredentialSyncForm";

interface CredentialDrawerProps {
  initialValues?: Credential;
  params: {
    site_id: number;
    member_id: number;
  };
}

export const CredentialDrawer: React.FC<
  React.PropsWithChildren<CredentialDrawerProps>
> = ({ initialValues, params }) => {
  const { data: member } = MemberQueries.useQuery({ id: params.member_id });
  const isExistingCredential = !!initialValues?.id;

  if (isExistingCredential) {
    return (
      <BaseDrawer
        title={`Credential ${initialValues.number}`}
        subtitle={"Sync"}
      >
        <CredentialSyncForm initialValues={initialValues} params={params} />
      </BaseDrawer>
    );
  }
  return (
    <BaseDrawer
      title={`${member?.first_name} ${member?.last_name}`}
      subtitle={"Credentials"}
    >
      <CredentialForm initialValues={initialValues} params={params} />
    </BaseDrawer>
  );
};
