import {
  VStack,
  FormikTextInputField,
  FormikPhoneInputField,
  FormikSelectField,
  LoadingCard,
} from "@smartrent/ui";
import { Form } from "formik";
import * as yup from "yup";

import { ErrorPage } from "@/components/ErrorPage";

import { BaseForm } from "../base/Form";
import { SiteQueries } from "../site/queries";

import { MemberQueries } from "./queries";
import {
  CommunicationPreference,
  CommunicationPreferenceOptions,
  Member,
  ValidCommunicationPreferences,
} from "./types";

interface MemberFormProps {
  initialValues: Partial<Member>;
  params: { site_id: number };
}

export const MemberForm = ({ initialValues, params }: MemberFormProps) => {
  const { data: site, isLoading } = SiteQueries.useQuery({
    id: params.site_id,
  });

  const validationSchema = yup.object().shape({
    first_name: yup.string().required("Required").max(40),
    last_name: yup.string().required("Required").max(40),
    email_address: yup.string().nullable().email().label("Email").max(240),
    phone_number: yup.string().phone().nullable(),
    nickname: yup.string().nullable().max(40),
    location: yup.string().nullable().max(40),
    title: yup.string().nullable().max(40),
    department: yup.string().nullable().max(40),
    communication_preference: yup
      .string()
      .nullable()
      .required("You must select a communication preference")
      .oneOf(ValidCommunicationPreferences),
  });

  if (isLoading) return <LoadingCard />;
  if (!site) return <ErrorPage title="Failed to load site." />;

  return (
    <BaseForm<Member>
      initialValues={{
        first_name: initialValues?.first_name,
        last_name: initialValues?.last_name,
        phone_number: initialValues?.phone_number,
        email_address: initialValues?.email_address,
        nickname: initialValues?.nickname,
        location: initialValues?.location,
        title: initialValues?.title,
        department: initialValues?.department,
        communication_preference:
          initialValues?.communication_preference ||
          CommunicationPreference.Both,
        site_id: params.site_id,
        ...initialValues,
      }}
      validationSchema={validationSchema}
      QueryClient={MemberQueries}
      params={params}
    >
      {() => {
        return (
          <Form>
            <VStack spacing={8}>
              <FormikTextInputField
                name="first_name"
                label="First Name"
                required
              />
              <FormikTextInputField
                name="last_name"
                label="Last Name"
                required
              />
              <FormikPhoneInputField name="phone_number" label="Phone Number" />
              <FormikTextInputField name="email_address" label="Email" />
              {initialValues?.id && (
                <>
                  <FormikTextInputField name="nickname" label="Nickname" />
                  <FormikTextInputField name="location" label="Location" />
                  <FormikTextInputField name="title" label="Title" />
                  <FormikTextInputField name="department" label="Department" />
                  <FormikSelectField
                    name="communication_preference"
                    label="Communication Preference"
                    options={CommunicationPreferenceOptions}
                    required
                  />
                </>
              )}
            </VStack>
          </Form>
        );
      }}
    </BaseForm>
  );
};
