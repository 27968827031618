import { useCallback } from "react";
import {
  useTheme,
  Panel,
  PanelHeader,
  TabsNavigation,
  PanelBody,
  TabContent,
  TabProvider,
  Typography,
} from "@smartrent/ui";
import { NonRouterTabItem } from "@smartrent/ui/dist/components/TabsNavigation/types";
import { StyleSheet } from "react-native";

import { Controller, ControllerProtocols } from "@/modules/controller/types";

import { Site } from "@/modules/site/types";
import { DelayLoad } from "@/components/DelayLoad";

import { ControllerAndHubRepliesPanel } from "../shared/ControllerAndHubRepliesPanel";

import { AkvxDebugPanel } from "./panels/AkvxDebugPanel";
import { MPLDebugPanel } from "./panels/MPLDebugPanel";
import { SMRTPDebugPanel } from "./panels/SMRTPDebugPanel";
import { SHIPDebugPanel } from "./panels/SHIPDebugPanel";

interface ControllerActionPanelProps {
  controller: Controller;
  site: Site;
}

export const ControllerDebugPanel = ({
  controller,
  site,
}: ControllerActionPanelProps) => {
  const { colors } = useTheme();

  const tabs = useCallback(() => {
    const currentTabs: Array<NonRouterTabItem> = [];

    if (controller.protocol == ControllerProtocols.akvx) {
      currentTabs.push({
        id: "controller-actions",
        label: "Controller Actions",
      });
    }

    if (controller.protocol == ControllerProtocols.mpl) {
      currentTabs.push({
        id: "controller-actions",
        label: "Controller Actions",
      });
    }

    if (controller.protocol == ControllerProtocols.smrtp) {
      currentTabs.push({
        id: "controller-actions",
        label: "Controller Actions",
      });
    }

    if (controller.protocol == ControllerProtocols.ship) {
      currentTabs.push({
        id: "controller-actions",
        label: "Controller Actions",
      });
    }

    return currentTabs;
  }, [controller.protocol]);

  const body = useCallback(() => {
    return (
      <>
        {controller.protocol === ControllerProtocols.akvx ? (
          <TabContent id="controller-actions">
            <AkvxDebugPanel controller={controller} site={site} />
          </TabContent>
        ) : null}
        {controller.protocol === ControllerProtocols.mpl ? (
          <TabContent id="controller-actions">
            <MPLDebugPanel controller={controller} site={site} />
          </TabContent>
        ) : null}
        {controller.protocol === ControllerProtocols.smrtp ? (
          <TabContent id="controller-actions">
            <SMRTPDebugPanel controller={controller} site={site} />
          </TabContent>
        ) : null}
        {controller.protocol === ControllerProtocols.ship ? (
          <TabContent id="controller-actions">
            <SHIPDebugPanel controller={controller} />
          </TabContent>
        ) : null}
      </>
    );
  }, [controller, site]);

  return (
    <TabProvider>
      <Panel>
        <PanelHeader
          style={[styles.header, { backgroundColor: colors.background }]}
        >
          <TabsNavigation
            initialActiveTabId="controller-actions"
            items={tabs()}
          />
        </PanelHeader>
        <PanelBody>{body()}</PanelBody>
      </Panel>

      <Panel>
        <PanelHeader>
          <Typography>Watch</Typography>
        </PanelHeader>
        <PanelBody>
          <DelayLoad msDelay={1000}>
            <ControllerAndHubRepliesPanel controller={controller} />
          </DelayLoad>
        </PanelBody>
      </Panel>
    </TabProvider>
  );
};

const styles = StyleSheet.create({
  header: {
    padding: 0,
  },
});
