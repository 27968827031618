import { useContext, useEffect } from "react";

import { Site } from "@/modules/site/types";
import { PageCtx } from "@/context/PageContext";
import { Paths } from "@/lib/path";
import { HubsTable } from "@/modules/hub/Table";

interface HubsListPageProps {
  site: Site;
}

export const HubsListPage = ({ site }: HubsListPageProps) => {
  const { addBreadcrumb, breadCrumbs } = useContext(PageCtx);

  useEffect(() => {
    addBreadcrumb({
      label: `Hubs`,
      to: Paths.hubsListPath(site.id),
    });
  }, [breadCrumbs, addBreadcrumb, site.id]);

  return <HubsTable filters={{ site_id: site.id }} />;
};
