import { Typography } from "@smartrent/ui";
import { Form } from "formik";

import * as yup from "yup";

import { StyleSheet, View } from "react-native";

import { Site } from "@/modules/site/types";
import { FormikFileUpload } from "@/components/form/FormikFileUpload";
import { BaseForm } from "@/modules/base/Form";

import { SiteImport } from "../types";
import { SiteImportQueries } from "../queries";

interface NewImportFormProps {
  initialValues: Partial<SiteImport>;
  params: { site_id: number };
  site: Site;
}

const validationSchema = yup.object().shape({
  site_id: yup.number().required(),
  file: yup.mixed().required(),
});

export const NewImportForm = ({
  initialValues,
  site,
  params,
}: NewImportFormProps) => (
  <BaseForm<SiteImport>
    initialValues={{ site_id: site.id, file: null }}
    validationSchema={validationSchema}
    QueryClient={SiteImportQueries}
    saveText={"Upload File"}
    params={params}
  >
    {() => {
      return (
        <Form>
          <Typography type="title4">
            In order to start a new import, please upload a csv containing the
            member data you want to import. Once the form is uploaded, there
            will be a short delay while the data is processed. You can upload
            the data now and come back later after it's finished processing.
          </Typography>
          <View style={styles.formElements}>
            <FormikFileUpload label="Import File" name="file" accept=".csv" />
          </View>
        </Form>
      );
    }}
  </BaseForm>
);

const styles = StyleSheet.create({
  formElements: {
    paddingTop: 16,
  },
});
