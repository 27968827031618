import { StyleSheet, View } from "react-native";
import {
  FilterProps,
  FormikTextInputField,
  ListQueryResponse,
  Table,
  Typography,
  useTableQuery,
} from "@smartrent/ui";

import { TableFilters } from "@/modules/base/table/types";

import { Site } from "../site/types";

import { EncoderQueries } from "./queries";
import { Encoder } from "./types";

interface EncodersTableFilters extends TableFilters {
  site_id: number;
}

interface EncodersTableProps {
  filters: EncodersTableFilters;
  site: Site;
}

export const EncodersTable = ({
  filters: filterParams,
  site,
}: EncodersTableProps) => {
  const tableProps = useTableQuery<any, Encoder, ListQueryResponse<Encoder>>({
    columns: [
      {
        name: "name",
        header: "Name",
        render: ({ row }) => {
          return <Typography>{row.name} </Typography>;
        },
        sortable: true,
        filter: (props: FilterProps) => {
          return <FormikTextInputField {...props} />;
        },
      },
      {
        name: "type",
        header: "Type",
        render: ({ row }) => {
          return <Typography>{row.type} </Typography>;
        },
        sortable: true,
        filter: (props: FilterProps) => {
          return <FormikTextInputField {...props} />;
        },
      },
      {
        name: "serial_number",
        header: "Serial Number",
        render: ({ row }) => {
          return <Typography>{row.serial_number} </Typography>;
        },
        sortable: true,
        filter: (props: FilterProps) => {
          return <FormikTextInputField {...props} />;
        },
      },
    ],
    fetch: EncoderQueries.fetch,
    getQueryKey: ({ filters, page, pageSize, sortColumn, sortDirection }) => [
      EncoderQueries.queryKey,
      {},
      {
        ...filters,
        page,
        per_page: pageSize,
        sort: sortColumn,
        dir: sortDirection,
        ...filterParams,
      },
    ],
    defaultPageSize: 10,
  });

  return (
    <View style={styles.tableContainer}>
      <Table<Encoder>
        title="Encoders"
        noRecordsText="No Encoders Found"
        style={styles.table}
        isRowPressable={() => false}
        {...tableProps}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  tableContainer: { zIndex: -500 },
  table: { zIndex: -500 },
});
