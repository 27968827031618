import { Route, Switch, useRouteMatch } from "react-router-dom";

import { FeatureFlagListPage } from "./FeatureFlagListPage";

export const FeatureFlagRouter: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}`}>
        <FeatureFlagListPage />
      </Route>
    </Switch>
  );
};
