import { LoadingCard } from "@smartrent/ui";

import { Door } from "@/modules/door/types";

import { ErrorPage } from "../../components/ErrorPage";
import { ControllerQueries } from "../controller/queries";

import { AddDoorForm } from "./components/AddDoorForm";
import { EditDoorForm } from "./components/EditDoorForm";

interface DoorFormProps {
  initialValues?: Door;
  site_id: number;
}

export const DoorForm = ({ initialValues, site_id }: DoorFormProps) => {
  const { data: controller, isLoading } = ControllerQueries.useQuery({
    id: initialValues?.controller_id,
  });

  if (!initialValues?.id) {
    return <AddDoorForm site_id={site_id} />;
  }

  if (isLoading) return <LoadingCard />;
  if (!controller) return <ErrorPage title={"Unable to fetch Controller"} />;

  return (
    <EditDoorForm
      initialValues={initialValues}
      site_id={site_id}
      controller={controller}
    />
  );
};
