import { useContext, useEffect } from "react";

import { View, StyleSheet } from "react-native";

import { PageCtx } from "@/context/PageContext";
import { Paths } from "@/lib/path";

import { Site } from "@/modules/site/types";
import { Door } from "@/modules/door/types";

import { DoorQuickActionsPanel } from "./DoorQuickActionsPanel";
import { DoorInformationPanel } from "./DoorInformationPanel";

interface DoorDetailPageProps {
  site: Site;
  door: Door;
}

export const DoorDetailPage: React.FC<
  React.PropsWithChildren<DoorDetailPageProps>
> = ({ site, door }) => {
  const { addBreadcrumbs } = useContext(PageCtx);

  useEffect(() => {
    addBreadcrumbs([
      {
        label: "Doors",
        to: Paths.doorsListPath(site.id),
      },
      {
        label: `${door?.name}`,
        to: Paths.doorViewPath(site.id, door.id),
      },
    ]);
  }, [door, site.id, addBreadcrumbs]);

  return (
    <View style={styles.root}>
      <View style={styles.left}>
        <View style={styles.informationContainer}>
          <DoorInformationPanel site={site} door={door} />
        </View>
      </View>
      <View style={styles.right}>
        <DoorQuickActionsPanel door={door} />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    flexDirection: "row",
    flexWrap: "wrap",
    flexGrow: 1,
  },
  informationContainer: {
    padding: 16,
  },
  left: { flexGrow: 1 },
  right: { flexGrow: 3 },
});
