import { VStack, FormikTextInputField } from "@smartrent/ui";
import { Form } from "formik";
import * as yup from "yup";

import { BaseForm } from "../base/Form";

import { ApiApplication } from "./types";
import { ApiApplicationQueries } from "./queries";

const webhookUrlRegex =
  process.env.NODE_ENV == "development"
    ? /^http:\/\/localhost:\.*/
    : /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;

const validationSchema = yup.object().shape({
  name: yup.string().required("Required").max(40),
  // Dev note: Set this to localhost manually. I don't want people in production to set this to localhost somehow.
  webhook_url: yup.string().nullable().matches(webhookUrlRegex, "Invalid URL"),
});

interface ApiApplicationFormProps {
  initialValues?: Partial<ApiApplication>;
  params: { site_id: number };
}

export const ApiApplicationForm = ({
  initialValues,
  params,
}: ApiApplicationFormProps) => {
  const { data } = ApiApplicationQueries.useDefaultWebhookUrl({
    site_id: params.site_id,
  });

  return (
    <BaseForm<ApiApplication>
      initialValues={{
        site_id: params.site_id,
        name: initialValues?.name,
        webhook_url: initialValues?.id
          ? initialValues?.webhook_url
          : data?.webhook_url,
        ...initialValues,
      }}
      validationSchema={validationSchema}
      QueryClient={ApiApplicationQueries}
      params={params}
    >
      {() => (
        <Form>
          <VStack spacing={16}>
            <FormikTextInputField name="name" label="Name" required />
            <FormikTextInputField
              name="webhook_url"
              label="Webhook Url"
              assistiveText="http(s)://domain.tld:7777"
            />
          </VStack>
        </Form>
      )}
    </BaseForm>
  );
};
