import {
  Button,
  FilterProps,
  FormikTextInputField,
  ListQueryResponse,
  Table,
  Typography,
  useTableQuery,
} from "@smartrent/ui";
import { StyleSheet, View } from "react-native";

import { useCallback } from "react";

import { CSVLink } from "react-csv";

import { useParams } from "react-router-dom";

import { useAppDrawer } from "@/components/layout/AppDrawer";

import { DATE_TIME_FORMAT, formatDateTime } from "@/lib/formatters";

import { useDialog } from "@/context/dialog";

import { usePermissions } from "@/context/PolicyContext";

import { TableFilters } from "@/modules/base/table/types";

import { Site } from "../site/types";

import { createActionsColumn } from "../base/table/utils";

import { SiteImport, SiteImportStatus, siteImportTemplateData } from "./types";
import { SiteImportQueries } from "./queries";

interface SiteImportsTableProps {
  site: Site;
  filters: Partial<TableFilters & SiteImport>;
}
export const SiteImportsTable = ({ site, filters }: SiteImportsTableProps) => {
  const drawer = useAppDrawer();
  const params = useParams();

  const importCreateAction = useCallback(
    () =>
      drawer.push(SiteImportQueries.queryKey, {
        params: filters,
        initialValues: {},
      }),
    [drawer, filters]
  );

  const { confirm } = useDialog();
  const [doDelete] = SiteImportQueries.useDeleteMutation();

  const { canView, canDelete } = usePermissions(SiteImportQueries);

  const tableProps = useTableQuery<
    any,
    SiteImport,
    ListQueryResponse<SiteImport>
  >({
    fetch: SiteImportQueries.fetch,
    getQueryKey: ({
      filters: tableFilters,
      page,
      pageSize,
      sortColumn,
      sortDirection,
    }) => [
      SiteImportQueries.queryKey,
      {},
      {
        ...filters,
        page,
        per_page: pageSize,
        sort: sortColumn,
        dir: sortDirection,
        ...tableFilters,
      },
    ],
    columns: [
      {
        name: "file_name",
        header: "Name",
        render: ({ row }) => {
          return <Typography>{row.file_name}</Typography>;
        },
        sortable: true,
        filter: (props: FilterProps) => {
          return <FormikTextInputField {...props} />;
        },
      },
      {
        name: "records",
        header: "Records",
        render: ({ row }) => {
          return <Typography>{row.records}</Typography>;
        },
        sortable: true,
        filter: (props: FilterProps) => {
          return <FormikTextInputField {...props} />;
        },
      },
      {
        name: "inserted_at",
        header: "Uploaded At",
        render: ({ row }) => {
          return (
            <Typography>
              {formatDateTime(
                `${row.inserted_at}Z` as string,
                site.timezone as string,
                DATE_TIME_FORMAT
              )}
            </Typography>
          );
        },
        sortable: true,
      },
      {
        name: "status",
        header: "Status",
        render: ({ row }) => {
          return <Typography>{row.status}</Typography>;
        },
        sortable: true,
        filter: (props: FilterProps) => {
          return <FormikTextInputField {...props} />;
        },
      },
      createActionsColumn<SiteImport>({
        shouldAdjustForPressIcon: canView,
        canDelete,
        onDelete: async (row) => {
          const confirmed = await confirm({
            title: "Delete",
            description: "Are you sure you want to delete this Entry?",
            confirmText: "Delete",
            confirmType: "destructive",
            cancelText: "Cancel",
          });
          if (confirmed) {
            doDelete({ id: row.id });
          }
        },
      }),
    ],
    defaultPageSize: 10,
  });
  return (
    <Table<SiteImport>
      title={"Site Imports"}
      noRecordsText={`No Site Imports Found`}
      action={
        <View style={styles.createButtonContainer}>
          <Button onPress={importCreateAction} style={styles.createButton}>
            New Import
          </Button>
          <CSVLink
            data={siteImportTemplateData}
            filename="aa-import-template.csv"
          >
            <Button onPress={() => null}>Download CSV Template</Button>
          </CSVLink>
        </View>
      }
      onRowPress={(siteImport) =>
        siteImport?.status === SiteImportStatus.new
          ? drawer.push(SiteImportQueries.queryKey, {
              initialValues: siteImport,
              params,
            })
          : undefined
      }
      {...tableProps}
    />
  );
};

const styles = StyleSheet.create({
  createButtonContainer: {
    flexDirection: "row",
  },
  createButton: {
    marginRight: 8,
  },
});
