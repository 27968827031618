import { StyleSheet, View } from "react-native";
import { useHistory } from "react-router-dom";

import {
  TabProvider,
  Panel,
  PanelHeader,
  TabsNavigation,
  TabContent,
  Table,
  Typography,
} from "@smartrent/ui";

import { History } from "history";

import { Hub, InterfaceDetails } from "@/modules/hub/types";
import { Controller } from "@/modules/controller/types";
import { Paths } from "@/lib/path";

import { ControllerNameAndStatusIndicator } from "@/modules/controller/components/ControllerStatusIndicator";

interface HubActionPanelProps {
  hub: Hub;
}

const addNetworkTypeToInterfaceDetails = (
  type: string,
  details: InterfaceDetails[]
): InterfaceDetails[] =>
  details.flatMap((detail) => ({ ...detail, network_type: type }));

const buildNetworkDetails = (hub: Hub): InterfaceDetails[] => {
  if (hub.network_details) {
    return [
      ...addNetworkTypeToInterfaceDetails(
        "ethernet",
        hub.network_details.ethernet
      ),
      ...addNetworkTypeToInterfaceDetails("mobile", hub.network_details.mobile),
      ...addNetworkTypeToInterfaceDetails("wifi", hub.network_details.wifi),
    ];
  } else {
    return [];
  }
};

const ControllersTable = ({ hub, history }: { hub: Hub; history: History }) => (
  <Table
    data={hub.controllers || []}
    title={""}
    noRecordsText={`No Controllers`}
    onRowPress={(row: Controller) =>
      history.push(Paths.controllerViewPath(hub.site_id, row.id))
    }
    columns={[
      {
        name: "name",
        header: "Name",
        render: ({ row }) => (
          <ControllerNameAndStatusIndicator controller={row} />
        ),
      },
      {
        name: "protocol",
        header: "Protocol",
        render: ({ row }) => (
          <Typography type="body">{row.protocol}</Typography>
        ),
      },
      {
        name: "model",
        header: "Model",
        render: ({ row }) => <Typography type="body">{row.model}</Typography>,
      },
    ]}
  />
);

const NetworkDetailsTable = ({ hub }: { hub: Hub }) => {
  return (
    <Table
      data={buildNetworkDetails(hub)}
      title={""}
      noRecordsText={`No Network Details`}
      isRowPressable={(_) => true}
      columns={[
        {
          name: "networkType",
          header: "Network Type",
          render: ({ row }) => (
            <Typography type="body">{row.network_type}</Typography>
          ),
        },
        {
          name: "ip",
          header: "IP",
          render: ({ row }) => <Typography type="body">{row.ip}</Typography>,
        },
        {
          name: "dns",
          header: "DNS",
          render: ({ row }) => <Typography type="body">{row.dns}</Typography>,
        },
        {
          name: "macAddress",
          header: "MAC Address",
          render: ({ row }) => (
            <Typography type="body">{row.mac_address}</Typography>
          ),
        },
        {
          name: "subnet",
          header: "Subnet",
          render: ({ row }) => (
            <Typography type="body">{row.subnet}</Typography>
          ),
        },
        {
          name: "serverID",
          header: "Server ID",
          render: ({ row }) => (
            <Typography type="body">{row.server_id}</Typography>
          ),
        },
        {
          name: "state",
          header: "State",
          render: ({ row }) => <Typography type="body">{row.state}</Typography>,
        },
        {
          name: "active",
          header: "Active",
          render: ({ row }) => (
            <Typography type="body">
              {row.name == hub.network_details?.active_interface ? "Yes" : "No"}
            </Typography>
          ),
        },
      ]}
    />
  );
};

export const HubActionPanel = ({ hub }: HubActionPanelProps) => {
  const history = useHistory();

  return (
    <View style={styles.root}>
      <TabProvider>
        <Panel>
          <PanelHeader style={styles.header}>
            <TabsNavigation
              align="flex-start"
              initialActiveTabId={"controllers"}
              items={[
                {
                  id: "controllers",
                  label: "Controllers",
                },
                {
                  id: "network-details",
                  label: "Network Details",
                },
              ]}
            />
          </PanelHeader>
          <TabContent id="controllers">
            <ControllersTable hub={hub} history={history} />
          </TabContent>
          <TabContent id="network-details">
            <NetworkDetailsTable hub={hub} />
          </TabContent>
        </Panel>
      </TabProvider>
    </View>
  );
};

const styles = StyleSheet.create({
  root: { padding: 16 },
  header: {
    padding: 0,
  },
});
