import { createContext, useState } from "react";

import { LinkGroupData } from "@/components/alloy-access/NavDrawer";

interface NavigationLinksContext {
  navigationLinks: LinkGroupData[];
  setNavigationLinks: (navigationLinks: LinkGroupData[]) => void;
}

const NavigationLinksCtx = createContext<NavigationLinksContext>({
  navigationLinks: [],
  setNavigationLinks: () => null,
});

const NavigationLinksProvider = ({ children }: { children: any }) => {
  const [navigationLinks, setNavigationLinks] = useState<LinkGroupData[] | []>(
    []
  );

  return (
    <NavigationLinksCtx.Provider
      value={{ navigationLinks, setNavigationLinks }}
    >
      {children}
    </NavigationLinksCtx.Provider>
  );
};

export { NavigationLinksCtx, NavigationLinksProvider };
