import { FormikSelectField } from "@smartrent/ui";

import { HubQueries } from "@/modules/hub/queries";
import { Hub } from "@/modules/hub/types";

interface HubSelectFieldProps {
  site_id: number;
  name: string;
  label: string;
  filterParams: any;
  initialValue?: string;
}

export const HubSelectField: React.FC<
  React.PropsWithChildren<HubSelectFieldProps>
> = ({ site_id, label, name, filterParams, initialValue }) => {
  const selectQueryProps = HubQueries.select({
    filterParams: { ...filterParams, site_id },
    initialValue,
  });

  return (
    <FormikSelectField<Hub>
      name={name}
      label={label}
      getOptionValue={(option) => option.id.toString()}
      getOptionLabel={(option) =>
        `${option.serial} ${option.name ? `(${option.name})` : ""}`
      }
      required
      {...selectQueryProps}
    />
  );
};
