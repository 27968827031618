import { Typography, TypographyProps } from "@smartrent/ui";
import { Link } from "react-router-dom";

interface LinkProps extends Omit<TypographyProps, "hrefAttrs"> {
  /**
   * @platform web
   */
  href?: string;
  /**
   * @platform web
   */
  target?: string;
  /**
   * @platform web
   */
  rel?: string;
  /**
   * @platform web
   */
  download?: string;
}

export const DomLink = ({
  href,
  style,
  children,
  type,
  color,
  numberOfLines,
}: LinkProps) => {
  color = color || "primary";
  return (
    <Link to={href ? href : "#"}>
      <Typography
        type={type}
        style={style}
        color={color}
        numberOfLines={numberOfLines}
      >
        {children}
      </Typography>
    </Link>
  );
};
