import { Controller } from "@/modules/controller/types";
import { PanelsTable } from "@/modules/panel/Table";
import { Site } from "@/modules/site/types";

interface ControllerPanelsDisplayProps {
  controller: Controller;
  site: Site;
}

export const ControllerPanelsDisplay = ({
  controller,
  site,
}: ControllerPanelsDisplayProps) => {
  return <PanelsTable site={site} filters={{ controller_id: controller.id }} />;
};
