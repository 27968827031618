import { FormikMultiSelectField, FormikSelectField } from "@smartrent/ui";

import { DoorQueries } from "@/modules/door/queries";
import { Door } from "@/modules/door/types";

interface DoorSelectFieldProps {
  site_id: number;
  name: string;
  label: string;
  filterParams?: any;
  initialValue?: string;
}

export const DoorMultiSelectField: React.FC<
  React.PropsWithChildren<DoorSelectFieldProps>
> = ({ site_id, label, name, filterParams, initialValue }) => {
  const selectQueryProps = DoorQueries.select({
    filterParams: { site_id, ...filterParams },
    initialValue,
  });

  return (
    <FormikMultiSelectField<Door>
      name={name}
      label={label}
      getOptionValue={(option) => option.id.toString()}
      getOptionLabel={(option) => `${option.name}`}
      required
      {...selectQueryProps}
    />
  );
};

export const DoorAcrSelectField: React.FC<
  React.PropsWithChildren<DoorSelectFieldProps & { controller_id: number }>
> = ({ site_id, controller_id, label, name, filterParams, initialValue }) => {
  const selectQueryProps = DoorQueries.select({
    filterParams: {
      site_id,
      controller_id,
      is_assigned: true,
      ...filterParams,
    },
    initialValue,
  });

  return (
    <FormikSelectField<Door>
      name={name}
      label={label}
      getOptionValue={(option) => option.door_number?.toString()}
      getOptionLabel={(option) => `${option.name}`}
      required
      {...selectQueryProps}
    />
  );
};
