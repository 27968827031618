import {
  Panel,
  ActivityIndicator,
  PanelHeader,
  PanelBody,
  Typography,
  VStack,
} from "@smartrent/ui";

import { StyleSheet } from "react-native";

import DraggableFlatList from "react-native-draggable-flatlist";

import { useQueryClient } from "@tanstack/react-query";

import { Panel as PanelType } from "@/modules/panel/types";

import {
  createCascadeOptionsMutation,
  useCascadingOptionsQuery,
} from "@/modules/panel/queries";
import { Controller } from "@/modules/controller/types";

import { QueryKeys } from "@/types";

import { useDialog } from "@/context/dialog";

import { ElevatorCascadingPanelListItem } from "./ElevatorCascadingPanelListItem";

interface ElevatorCascadingOptionsListProps {
  controller: Controller;
  orderField: "order_in" | "order_out";
  title: string;
}

export const ElevatorCascadingPanelList: React.FC<
  React.PropsWithChildren<ElevatorCascadingOptionsListProps>
> = ({ title, controller, orderField }) => {
  const [update] = createCascadeOptionsMutation();
  const { confirm } = useDialog();
  const queryClient = useQueryClient();

  const { data: cascadingPanelsData, isLoading } = useCascadingOptionsQuery({
    controller_id: controller.id,
    order_field: orderField,
  });

  if (!cascadingPanelsData?.total_records) return null;
  if (isLoading) return <ActivityIndicator />;

  return (
    <Panel>
      <PanelHeader>
        <VStack spacing={8} style={styles.header}>
          <Typography type="title3">{title}</Typography>
          <Typography style={styles.headerText} type="title5" numberOfLines={2}>
            Cascading SIOs refers to which SIO is next in line to continue the
            floor relays when there are not enough `
            {orderField == "order_in" ? "inputs" : "outputs"}` on a single board
            to support all of the floors in the elevator.{" "}
          </Typography>
        </VStack>
      </PanelHeader>

      <PanelBody>
        <DraggableFlatList<PanelType>
          dragItemOverflow={true}
          keyExtractor={(item, index) => `${orderField}-item-${item.id}`}
          data={cascadingPanelsData?.records || []}
          renderItem={ElevatorCascadingPanelListItem}
          onDragEnd={async ({ data }) => {
            const queryKey = [
              QueryKeys.CascadingOptions,
              { controller_id: controller.id, order_field: orderField },
            ];
            const panels = data.map((panel, index) => {
              return {
                ...panel,
                [orderField]: index,
              };
            });
            // Updates list's visual order using reactQuery cache.
            queryClient.setQueryData(queryKey, {
              ...cascadingPanelsData,
              records: panels,
            });

            const confirmed = await confirm({
              title: "Cascade Order Has Changed",
              description:
                "Ensure the elevator wiring matches the new cascade order.",
              confirmText: "Change Cascade Order",
              confirmType: "primary",
              cancelText: "Cancel",
            });

            if (confirmed) {
              update({
                order_field: orderField,
                controller_id: controller.id,
                panels,
              });
            } else {
              queryClient.invalidateQueries(queryKey);
            }
          }}
        />
      </PanelBody>
    </Panel>
  );
};

const styles = StyleSheet.create({
  header: { flex: 1 },
  headerText: { paddingHorizontal: 8 },
});
