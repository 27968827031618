import { Route, Switch, useRouteMatch } from "react-router-dom";

import { useContext, useEffect } from "react";

import { CloudSync } from "@smartrent/icons";

import { Site } from "@/modules/site/types";

import { PageCtx } from "@/context/PageContext";

import { Paths } from "@/lib/path";

import { SiteQueries } from "@/modules/site/queries";

import { AccessLevelsListPage } from "./AccessLevelsListPage";
import { AccessLevelShowPage } from "./AccessLevelShowPage";

interface AccessLevelsRouterProps {
  site: Site;
}

export const AccessLevelsRouter = ({ site }: AccessLevelsRouterProps) => {
  const { path } = useRouteMatch();
  const { addBreadcrumb, setActions } = useContext(PageCtx);
  const [startSync] = SiteQueries.useStartSyncMutation();

  useEffect(() => {
    addBreadcrumb({
      label: `Access Levels`,
      to: Paths.accessLevelsListPath(site.id),
    });
  }, [addBreadcrumb, site.id]);

  useEffect(() => {
    setActions([
      {
        label: "Sync Access Levels",
        onPress: () => {
          startSync({ site_id: site.id, force: false });
        },
        iconLeft: CloudSync,
      },
      {
        label: "Force Sync Access Levels",
        onPress: () => {
          startSync({ site_id: site.id, force: true });
        },
        iconLeft: CloudSync,
      },
    ]);
  }, [setActions, site.id, startSync]);

  return (
    <Switch>
      <Route exact path={`${path}`}>
        <AccessLevelsListPage site={site} />
      </Route>
      <Route exact path={`${path}/:access_level_id`}>
        <AccessLevelShowPage site={site} />
      </Route>
    </Switch>
  );
};
