import { Controller } from "@/modules/controller/types";
import { OutputsTable } from "@/modules/output/Table";

interface ControllerOutputsDisplayProps {
  controller: Controller;
}

export const ControllerOutputsDisplay = ({
  controller,
}: ControllerOutputsDisplayProps) => {
  return <OutputsTable filters={{ controller_id: controller.id }} />;
};
