import { LoadingCard, useTheme } from "@smartrent/ui";
import { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";

import { ErrorPage } from "@/components/ErrorPage";
import { PageCtx } from "@/context/PageContext";
import { Paths } from "@/lib/path";
import { OrganizationQueries } from "@/modules/organization/queries";
import { UsersTable } from "@/modules/user/Table";

export const OrganizationShowPage: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const { organization_id } = useParams<{ organization_id: string }>();
  const { addBreadcrumb, breadCrumbs, setActions, actions } =
    useContext(PageCtx);
  const { colors } = useTheme();

  const { data: organization, isLoading } = OrganizationQueries.useQuery({
    id: parseInt(organization_id),
  });

  useEffect(() => {
    if (!organization) return;
    addBreadcrumb({
      label: `${organization?.name}`,
      to: Paths.organizationViewPath(organization.id),
    });
    addBreadcrumb({
      label: `Organizations`,
      to: Paths.organizationsListPath(),
    });
  }, [
    breadCrumbs,
    organization,
    actions,
    addBreadcrumb,
    colors.textPrimaryInverse,
    setActions,
  ]);

  if (isLoading) {
    return <LoadingCard />;
  }
  if (!organization) {
    return <ErrorPage title={"Organization Not Found"} />;
  }
  return (
    <UsersTable
      title={`${organization.name || "Organization"} Users`}
      filters={{ organization_id: organization.id }}
    />
  );
};
