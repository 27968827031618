import {
  ListQueryResponse,
  Table,
  Typography,
  useTableQuery,
} from "@smartrent/ui";

import { formatDateTime } from "@/lib/formatters";

import { Site } from "@/modules/site/types";

import { Controller, ControllerLog } from "../types";
import { ControllerQueries } from "../queries";

interface ControllerLogsTableProps {
  controller: Controller;
  site: Site;
}

export const ControllerLogsTable = ({
  controller,
  site,
}: ControllerLogsTableProps) => {
  const tableProps = useTableQuery<
    any,
    ControllerLog,
    ListQueryResponse<ControllerLog>
  >({
    columns: [
      {
        name: "reply",
        header: "Reply",
        render: ({ row }) => {
          return (
            <Typography type="body">
              {JSON.stringify(row?.reply, null, 2)}
            </Typography>
          );
        },
      },
      {
        name: "inserted_at",
        header: "Inserted At",
        render: ({ row }) => {
          return (
            <Typography type="body">
              {formatDateTime(`${row.inserted_at}Z`, site.timezone)}
            </Typography>
          );
        },
        sortable: true,
      },
    ],
    fetch: ControllerQueries.fetchLogs,
    getQueryKey: ({ filters, page, pageSize, sortColumn, sortDirection }) => [
      "controller-logs",
      {},
      {
        ...filters,
        page,
        per_page: pageSize,
        sort: sortColumn,
        dir: sortDirection,
        controller_id: controller.id,
      },
    ],
    defaultPageSize: 10,
  });

  return (
    <Table<ControllerLog>
      title={"Controller Logs"}
      noRecordsText={`No Logs Found`}
      {...tableProps}
    />
  );
};
