import { useContext, useEffect } from "react";
import { View } from "react-native";

import { PageCtx } from "@/context/PageContext";
import { Paths } from "@/lib/path";
import { Site } from "@/modules/site/types";

import { HolidayGroupTable } from "@/modules/holiday-groups/Table";

import { ScheduleNavigation } from "../schedules/Navigation";

interface HolidayGroupListPageProps {
  site: Site;
}

export const HolidayGroupListPage = ({ site }: HolidayGroupListPageProps) => {
  const { addBreadcrumb, breadCrumbs } = useContext(PageCtx);

  useEffect(() => {
    addBreadcrumb({
      label: `Exception Groups`,
      to: Paths.holidayGroupListPath(site.id),
    });
  }, [breadCrumbs, addBreadcrumb, site.id]);

  return (
    <View>
      <View>
        <ScheduleNavigation />
      </View>
      <HolidayGroupTable filters={{ site_id: site.id }} site={site} />
    </View>
  );
};
