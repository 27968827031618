import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";

import { useCallback, useContext, useEffect } from "react";

import { View, StyleSheet } from "react-native";

import { Building, Cog, Person } from "@smartrent/icons";

import { LinkData } from "@/components/alloy-access/NavDrawer";
import { User, UserRole } from "@/modules/user/types";

import squares from "@/assets/images/squares.svg";

import { NavigationLinksCtx } from "@/context/NavigationLinksContext";

import { SiteListPage } from "./site/SiteListPage";
import { SiteAddPage } from "./site/SiteAddPage";
import { UserListPage } from "./user/UserListPage";
import { OrganizationSettingsPage } from "./organization/OrganizationSettingsPage";

interface AdminRouterProps {
  user: User;
}

export const AdminRouter: React.FC<
  React.PropsWithChildren<AdminRouterProps>
> = ({ user }) => {
  const { path } = useRouteMatch();
  const { setNavigationLinks } = useContext(NavigationLinksCtx);

  const NavLinks = useCallback(() => {
    const items: LinkData[] = [
      { url: `/admin`, label: "Sites", icon: Building },
      { url: `/admin/users`, label: "Users", icon: Person },
    ];

    if (user?.role === UserRole.Admin) {
      items.push({ url: `/admin/settings`, label: "Settings", icon: Cog });
    }

    return [
      {
        label: "Admin",
        icon: squares,
        items,
      },
    ];
  }, [user]);

  useEffect(() => {
    setNavigationLinks(NavLinks());
  }, [setNavigationLinks, NavLinks, user]);

  return (
    <View style={styles.root}>
      <Switch>
        <Route exact path={`${path}`}>
          <SiteListPage />
        </Route>
        <Route exact path={`${path}/sites/add`}>
          <SiteAddPage />
        </Route>
        <Route exact path={`${path}/users`}>
          <UserListPage />
        </Route>
        <Route path={`${path}/settings`}>
          {user?.role === UserRole.Admin ? (
            <OrganizationSettingsPage />
          ) : (
            <Redirect to="/" />
          )}
        </Route>
      </Switch>
    </View>
  );
};
const styles = StyleSheet.create({
  root: {
    padding: 16,
  },
});
