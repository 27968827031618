import { CreditCard, ChevronRight } from "@smartrent/icons";
import { Box, Form, HStack, Typography, useTheme, VStack } from "@smartrent/ui";
import { StyleSheet, TouchableOpacity, View } from "react-native";

import * as yup from "yup";

import { Site } from "@/modules/site/types";

import { BaseForm } from "@/modules/base/Form";

import { MissingCardBankInfo, SiteImport } from "../types";
import { SiteImportQueries } from "../queries";

interface ExistingImportFormProps {
  initialValues: Partial<SiteImport>;
  params: { site_id: number };
  site: Site;
}

const ImportStateMessage = ({
  initialValues,
}: {
  initialValues: SiteImport;
}) => {
  const { colors } = useTheme();

  if (
    !initialValues.missing_card_bank_info ||
    initialValues?.missing_card_bank_info?.length === 0
  ) {
    return (
      <View style={[styles.importMessage, { backgroundColor: colors.success }]}>
        <Typography color="textPrimaryInverse">
          This import is ready to go! Click the button below to start the
          import.
        </Typography>
      </View>
    );
  }

  return (
    <View>
      <View style={[styles.importMessage, { backgroundColor: colors.error }]}>
        <Typography color="textPrimaryInverse">
          You're missing necessary cards to successfully complete this import.
          You can run this import anyway, but not all members will be assigned a
          card.
        </Typography>
      </View>
    </View>
  );
};
const ImportErrorDisplay = ({
  initialValues,
}: {
  initialValues: SiteImport;
}) => {
  const { colors } = useTheme();

  if (!initialValues?.missing_card_bank_info) return null;
  return (
    <View style={styles.importErrorMessage}>
      {initialValues.missing_card_bank_info.map(
        (cardBank: MissingCardBankInfo, index: number) => {
          return (
            <TouchableOpacity activeOpacity={0.7} key={index}>
              <Box style={[styles.box, { borderColor: colors.error }]}>
                <VStack spacing={12}>
                  <View style={styles.container}>
                    <View>
                      <CreditCard color={colors.textPrimary} size={20} />
                      <Typography type="title4" numberOfLines={1}>
                        {`Facility Code ${cardBank.facility_code}`}
                      </Typography>
                    </View>
                    <ChevronRight color={colors.textPrimary} size={20} />
                  </View>

                  <VStack spacing={4}>
                    <HStack>
                      <Typography color="labelText"># Cards: </Typography>
                      <Typography>{cardBank.count}</Typography>
                    </HStack>
                    <HStack>
                      <Typography color="labelText">
                        First Card Number:
                      </Typography>
                      <Typography numberOfLines={1}>{cardBank.min}</Typography>
                    </HStack>
                    <HStack>
                      <Typography color="labelText">
                        Last Card Number:
                      </Typography>
                      <Typography numberOfLines={1}>{cardBank.max}</Typography>
                    </HStack>
                  </VStack>
                </VStack>
              </Box>
            </TouchableOpacity>
          );
        }
      )}
    </View>
  );
};

export const ExistingImportForm = ({
  initialValues,
  params,
}: ExistingImportFormProps) => {
  const validationSchema = yup.object().shape({
    site_id: yup.number().required(),
  });
  return (
    <BaseForm<SiteImport>
      initialValues={initialValues}
      validationSchema={validationSchema}
      QueryClient={SiteImportQueries}
      saveText="Start Import"
      params={params}
    >
      {() => {
        return (
          <Form>
            <ImportStateMessage initialValues={initialValues as SiteImport} />
            <ImportErrorDisplay initialValues={initialValues as SiteImport} />
          </Form>
        );
      }}
    </BaseForm>
  );
};
const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "space-between",
    overflow: "hidden",
  },
  box: {
    borderLeftWidth: 3,
  },
  importMessage: {
    marginTop: 8,
    marginBottom: 8,
    padding: 8,
    borderRadius: 8,
  },
  importErrorMessage: {
    marginTop: 8,
    marginBottom: 8,
  },
});
