import { useCallback } from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { useParams } from "react-router-dom";

import { Download } from "@smartrent/icons";

import {
  FilterProps,
  FormikSelectField,
  FormikTextInputField,
  Link,
  ListQueryResponse,
  Table,
  Typography,
  useTableQuery,
  useTheme,
} from "@smartrent/ui";

import { useAppDrawer } from "@/components/layout/AppDrawer";
import { useDialog } from "@/context/dialog";
import { usePermissions } from "@/context/PolicyContext";
import { conservativeTwoDecimalCeil } from "@/lib/helpers";

import { TableFilters } from "@/modules/base/table/types";

import { CreateButton, createActionsColumn } from "../base/table/utils";

import { ControllerModelOptions } from "../controller/types";

import { FirmwareQueries } from "./queries";
import { Firmware } from "./types";

interface FirmwareTableProps {
  filters: Partial<TableFilters & Firmware>;
}

const ONE_MB = 1000000;

export const FirmwareTable = ({ filters }: FirmwareTableProps) => {
  const drawer = useAppDrawer();
  const params = useParams();
  const { colors } = useTheme();
  const { canCreate, canDelete, canUpdate } = usePermissions(FirmwareQueries);

  const onUpdate = useCallback(
    (row: Firmware) =>
      drawer.push(FirmwareQueries.queryKey, {
        initialValues: { ...row },
        params,
      }),
    [drawer, params]
  );

  const { confirm } = useDialog();
  const [deleteQuery] = FirmwareQueries.useDeleteMutation();

  const onDelete = useCallback(
    async (row: Firmware) => {
      const confirmed = await confirm({
        title: "Delete",
        description: "Are you sure you want to delete this Entry?",
        confirmText: "Delete",
        confirmType: "destructive",
        cancelText: "Cancel",
      });

      if (confirmed) {
        await deleteQuery({ id: row.id });
      }
    },
    [confirm, deleteQuery]
  );

  const customActions = (row: Firmware) => {
    const onPress = () => window.open(row.firmware_url);

    return (
      <TouchableOpacity onPress={onPress}>
        <Download color={colors.gray400} />
      </TouchableOpacity>
    );
  };

  const tableProps = useTableQuery<any, Firmware, ListQueryResponse<Firmware>>({
    columns: [
      {
        name: "model",
        header: "Model",
        render: ({ row }) => {
          return <Typography>{row.model}</Typography>;
        },
        sortable: false,
        filter: (props: FilterProps) => {
          return (
            <FormikSelectField {...props} options={ControllerModelOptions} />
          );
        },
      },
      {
        name: "version",
        header: "Version",
        render: ({ row }) => {
          return <Typography>{row.version}</Typography>;
        },
        sortable: false,
        filter: (props: FilterProps) => {
          return <FormikTextInputField {...props} />;
        },
      },
      {
        name: "size_in_bytes",
        header: "Size",
        render: ({ row }) => {
          return (
            <Typography type="body">
              {conservativeTwoDecimalCeil(row.size_in_bytes / ONE_MB)} MB
            </Typography>
          );
        },
        sortable: false,
      },
      {
        name: "release_notes_url",
        header: "Release Notes",
        render: ({ row }) => {
          if (!row.release_notes_url) return null;

          return (
            <Typography type="body">
              <Link href={row.release_notes_url} target="_blank">
                {row.model}_{row.version}_release_notes
              </Link>
            </Typography>
          );
        },
        sortable: false,
      },
      createActionsColumn<Firmware>({
        canUpdate,
        onUpdate,
        // We're punting on deleting until we can define the side effects
        canDelete: canDelete && false,
        onDelete,
        customActions,
      }),
    ],
    fetch: FirmwareQueries.fetch,
    getQueryKey: ({ filters, page, pageSize, sortColumn, sortDirection }) => [
      FirmwareQueries.queryKey,
      {},
      {
        ...filters,
        page,
        per_page: pageSize,
        sort: sortColumn,
        dir: sortDirection,
        ...filters,
      },
    ],
    defaultPageSize: 10,
  });

  const onCreate = useCallback(
    () => drawer.push(FirmwareQueries.queryKey, { params }),
    [drawer, params]
  );

  return (
    <View style={styles.tableContainer}>
      <Table<Firmware>
        title="Firmware"
        noRecordsText="No Firmware Found"
        style={styles.table}
        action={
          <CreateButton
            canCreate={canCreate}
            onCreate={onCreate}
            text="Upload Firmware"
          />
        }
        isRowPressable={() => false}
        {...tableProps}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  tableContainer: { zIndex: -500 },
  table: { zIndex: -500 },
});
