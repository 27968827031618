import { Site } from "@/modules/site/types";
import { ControllersTable } from "@/modules/controller/Table";

interface ControllersListPageProps {
  site: Site;
}

export const ControllersListPage = ({ site }: ControllersListPageProps) => {
  return <ControllersTable filters={{ site_id: site.id }} />;
};
