import { BaseDrawer } from "../base/Drawer";

import { HolidayGroupForm } from "./Form";
import { HolidayScheduleGroup } from "./types";

interface HolidayScheduleGroupDrawerProps {
  initialValues?: HolidayScheduleGroup;
  params: { site_id: number };
}

export const HolidayScheduleGroupDrawer: React.FC<
  React.PropsWithChildren<HolidayScheduleGroupDrawerProps>
> = ({ initialValues, params }) => {
  return (
    <BaseDrawer
      title={initialValues?.id ? initialValues.name : "New Exception Group"}
      subtitle={initialValues?.id ? "Edit Exception Group" : null}
    >
      <HolidayGroupForm initialValues={initialValues} params={params} />
    </BaseDrawer>
  );
};
