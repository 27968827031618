import { useCallback } from "react";
import * as yup from "yup";

import { VStack } from "@smartrent/ui";

import {
  Form,
  FormNumberInputField,
  FormOnSubmit,
  FormSubmit,
} from "@smartrent/forms";

import { Elevator } from "@/modules/elevator/types";

import { FloorQueries } from "../../floor/queries";
import { CreateFloorFormProps } from "../../floor/components/AddFloorFormDrawer";

interface AddFloorsFormProps {
  door_id: number;
  onSuccessfulSubmit?: (response: Elevator) => void;
}

export const AddFloorsForm: React.FC<
  React.PropsWithChildren<AddFloorsFormProps>
> = ({ door_id, onSuccessfulSubmit }) => {
  const [addFloors] = FloorQueries.useAddFloorsMutation();

  const handleSubmitAddFloors = useCallback<FormOnSubmit<CreateFloorFormProps>>(
    async (values, helpers) => {
      try {
        const elevator = await addFloors({
          values: { door_id, ...values },
        });
        onSuccessfulSubmit ? onSuccessfulSubmit(elevator) : null;
      } catch (err: any) {
        const [{ description, field }] = err.response.data.errors;
        helpers.setFieldError && helpers.setFieldError(field, description);
      }
    },
    [addFloors, door_id, onSuccessfulSubmit]
  );

  return (
    <Form<CreateFloorFormProps>
      validationSchema={yup.object().shape({
        number_of_floors: yup
          .number()
          .moreThan(0)
          .required()
          .label("Number of Floors"),
      })}
      onSubmit={handleSubmitAddFloors}
    >
      <VStack spacing={16}>
        <FormNumberInputField
          name="number_of_floors"
          label="Number of floors"
          required
        ></FormNumberInputField>
        <FormSubmit />
      </VStack>
    </Form>
  );
};
