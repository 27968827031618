import { View, StyleSheet } from "react-native";

import {
  FormikTextInputFieldProps,
  FloatingActionButton,
  FormikTextInputField,
} from "@smartrent/ui";
import { Refresh } from "@smartrent/icons";
import { useField } from "formik";

import { randomPin } from "@/lib/helpers";

export const FormikPinInputField = (props: FormikTextInputFieldProps) => {
  const [, , { setValue }] = useField(props.name);

  const RandomPinButton = () => {
    return (
      <FloatingActionButton
        style={styles.button}
        icon={Refresh}
        onPress={() => setValue(randomPin())}
      />
    );
  };

  return (
    <View style={styles.root}>
      <FormikTextInputField style={styles.input} {...props} />
      <RandomPinButton />
    </View>
  );
};

const styles = StyleSheet.create({
  root: { flexDirection: "row" },
  input: {
    flexGrow: 1,
    marginRight: 8,
  },
  button: {
    borderRadius: 4,
  },
});
