import { Site } from "@/modules/site/types";
import { EncodersTable } from "@/modules/encoder/EncodersTable";

interface DoorsListPageProps {
  site: Site;
}

export const EncodersListPage = ({ site }: DoorsListPageProps) => {
  return <EncodersTable filters={{ site_id: site.id }} site={site} />;
};
