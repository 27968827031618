import { useContext } from "react";

import { useTheme } from "@smartrent/ui";
import { View, StyleSheet, TouchableOpacity } from "react-native";
import { PencilSolid, TrashX } from "@smartrent/icons";
import { useHistory } from "react-router-dom";

import { Site, SystemTypes } from "@/modules/site/types";
import { QueryKeys } from "@/types";
import { useAppDrawer } from "@/components/layout/AppDrawer";
import { useDialog } from "@/context/dialog";
import { PathActions, Paths } from "@/lib/path";
import { Door } from "@/modules/door/types";
import { DoorQueries } from "@/modules/door/queries";
import { PolicyContext } from "@/context/PolicyContext";

interface DoorInformationPanelActionsProps {
  site: Site;
  door: Door;
}

export const DoorInformationPanelActions: React.FC<
  React.PropsWithChildren<DoorInformationPanelActionsProps>
> = ({ door, site }) => {
  const { colors } = useTheme();
  const drawer = useAppDrawer();
  const { confirm } = useDialog();
  const [doDelete] = DoorQueries.useDeleteMutation();
  const history = useHistory();
  const permissions = useContext(PolicyContext);

  const canEditDoor =
    site.system_type === SystemTypes.Alloy &&
    permissions &&
    permissions[QueryKeys.Doors].update;

  const canDeleteDoor =
    site.system_type === SystemTypes.Alloy &&
    permissions &&
    permissions[QueryKeys.Doors].delete;

  return (
    <View style={styles.panelHeaderActionsContainer}>
      {canEditDoor ? (
        <TouchableOpacity
          activeOpacity={0.7}
          style={styles.panelHeaderAction}
          onPress={() => {
            drawer.push(QueryKeys.Doors, {
              initialValues: door,
              site_id: site.id,
            });
          }}
        >
          <PencilSolid color={colors.gray600} />
        </TouchableOpacity>
      ) : (
        <View />
      )}
      {canDeleteDoor ? (
        <TouchableOpacity
          activeOpacity={0.7}
          style={styles.panelHeaderAction}
          onPress={async () => {
            const confirmed = await confirm({
              title: "Delete Door?",
              description: "Are you sure you want to delete this Door?",
              confirmText: "Delete",
              confirmType: "destructive",
            });

            if (confirmed) {
              await doDelete({ id: door.id });
              history.push(
                Paths.GetPath(QueryKeys.Doors, PathActions.List, {
                  site_id: site.id,
                })
              );
            }
          }}
        >
          <TrashX color={colors.gray600} />
        </TouchableOpacity>
      ) : (
        <View />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  panelHeaderActionsContainer: {
    flexDirection: "row",
  },
  panelHeaderAction: {
    paddingLeft: 8,
    paddingRight: 8,
  },
});
