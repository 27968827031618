import { useState } from "react";

interface DelayLoadProps {
  msDelay: number;
  children: any;
}

export const DelayLoad = ({ msDelay, children }: DelayLoadProps) => {
  const [timeElapsed, setTimeElapsed] = useState(false);

  setTimeout(() => {
    setTimeElapsed(true);
  }, msDelay);

  if (timeElapsed) return children;
  return null;
};
