import { LoadingCard } from "@smartrent/ui";
import { View, StyleSheet } from "react-native";
import { useParams } from "react-router-dom";

import { useContext, useEffect } from "react";

import { useWindowDimensionsQuery } from "@smartrent/hooks";

import { ErrorPage } from "@/components/ErrorPage";
import { Site } from "@/modules/site/types";

import { MemberQueries } from "@/modules/member/queries";

import { PageCtx } from "@/context/PageContext";

import { Paths } from "@/lib/path";

import { InformationPanel } from "./components/InformationPanel";
import { MemberActionPanel } from "./components/ActionPanel";

interface MemberShowPageProps {
  site: Site;
}
export const MemberShowPage = ({ site }: MemberShowPageProps) => {
  const { large: isDesktop } = useWindowDimensionsQuery();
  const { member_id } = useParams<{ member_id: string }>();
  const { addBreadcrumb } = useContext(PageCtx);

  const { data: member, isLoading } = MemberQueries.useQuery({
    id: parseInt(member_id as string),
  });

  useEffect(() => {
    if (member) {
      addBreadcrumb({
        label: `${member?.first_name} ${member?.last_name}`,
        to: Paths.memberViewPage(site.id, member_id),
      });
    }
  }, [member, addBreadcrumb, site.id, member_id]);

  if (isLoading) {
    return <LoadingCard />;
  }
  if (!member) {
    return <ErrorPage title={"Error retrieving member"} />;
  }
  return (
    <View style={isDesktop ? styles.root : styles.rootMobile}>
      <View style={styles.left}>
        <View style={styles.informationContainer}>
          <InformationPanel site={site} member={member} />
        </View>
      </View>
      <View style={styles.right}>
        <View style={styles.actionsContainer}>
          <MemberActionPanel site={site} member={member} />
        </View>
      </View>
    </View>
  );
};

// TODO: Make this not suck
const styles = StyleSheet.create({
  root: {
    flexDirection: "row",
  },
  rootMobile: { flexDirection: "row", flexWrap: "wrap" },

  informationContainer: {
    padding: 16,
  },
  actionsContainer: {
    padding: 16,
  },
  left: { flex: 1, flexBasis: "32%", minWidth: 300 },
  right: { flex: 3, flexBasis: "66%", minWidth: 360 },
});
