import { LoadingCard } from "@smartrent/ui";

import { ErrorPage } from "@/components/ErrorPage";

import { SiteQueries } from "../site/queries";

import { SystemTypes } from "../site/types";

import { AlloyAccessCredentialForm } from "./components/AlloyAccessCredentialForm";
import { SaltoCredentialForm } from "./components/SaltoCredentialForm";
import { SchlageCredentialForm } from "./components/SchlageCredentialForm";
import { Credential } from "./types";

interface CredentialFormProps {
  initialValues?: Credential;
  params: {
    site_id: number;
    member_id: number;
  };
}

export const CredentialForm: React.FC<
  React.PropsWithChildren<CredentialFormProps>
> = ({ initialValues, params }) => {
  const { data: site, isLoading } = SiteQueries.useQuery({
    id: params.site_id,
  });

  if (isLoading) return <LoadingCard />;
  if (!site) return <ErrorPage title="Failed to load site settings" />;

  switch (site.system_type) {
    case SystemTypes.Alloy:
      return (
        <AlloyAccessCredentialForm
          initialValues={initialValues}
          params={params}
          site={site}
        />
      );

    case SystemTypes.Salto:
      return (
        <SaltoCredentialForm
          initialValues={initialValues}
          params={params}
          site={site}
        />
      );

    case SystemTypes.Schlage:
      return (
        <SchlageCredentialForm
          initialValues={initialValues}
          params={params}
          site={site}
        />
      );
  }

  return null;
};
