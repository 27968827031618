import { BaseDrawer } from "@/modules/base/Drawer";

import { Member } from "../types";

import { MemberDoorsForm } from "./MemberDoorsForm";

interface MemberAddDoorDrawerProps {
  initialValues?: Member;
  params: { site_id: number };
}

export const MemberDoorsDrawer: React.FC<
  React.PropsWithChildren<MemberAddDoorDrawerProps>
> = ({ initialValues, params }) => {
  return (
    <BaseDrawer
      title={
        initialValues?.id
          ? `${initialValues.first_name} ${initialValues.last_name}`
          : "New Member"
      }
      subtitle={"Doors"}
    >
      <MemberDoorsForm initialValues={initialValues} params={params} />
    </BaseDrawer>
  );
};
