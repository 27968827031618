import { FormikSelectField, FormikSelectFieldProps } from "@smartrent/ui";

import { CredentialQueries } from "../queries";

import { Credential, CredentialType } from "../types";

interface CredentialSelectFieldProps
  extends Omit<FormikSelectFieldProps<any>, "options"> {
  member_id: number;
  filterParams?: any;
  initialValue?: string;
  lookup_key?: string;
}

export const CredentialSelectField: React.FC<
  React.PropsWithChildren<CredentialSelectFieldProps>
> = (params) => {
  const { member_id, filterParams, initialValue } = params;
  const lookup_key = params.lookup_key || "id";

  const selectQueryProps = CredentialQueries.select({
    filterParams: { member_id, ...filterParams },
    initialValue,
  });

  return (
    <FormikSelectField<Credential>
      getOptionValue={(option) =>
        (option as any)[lookup_key as string].toString()
      }
      getOptionLabel={(option) => {
        switch (option.type) {
          case CredentialType.pin:
            return `Pin: ${option.pin}`;
          case CredentialType.card:
            return `Card: ${option.number}`;
          case CredentialType.mobile:
            return `Mobile`;
        }
        return "Credential";
      }}
      required
      {...selectQueryProps}
      {...params}
    />
  );
};
