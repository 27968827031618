import { DrawerHeader } from "@smartrent/ui";

import { AccessLevel } from "../access-level/types";

import { AccessLevelQueries } from "../access-level/queries";
import { Site } from "../site/types";
import { Elevator } from "../elevator/types";

interface ElevatorAccessLevelDrawerHeaderProps {
  initialValues?: Elevator;
  params: {
    site: Site;
    access_level: AccessLevel;
  };
}

export const ElevatorAccessLevelDrawerHeader: React.FC<
  React.PropsWithChildren<ElevatorAccessLevelDrawerHeaderProps>
> = ({ initialValues, params }) => {
  const { data } = AccessLevelQueries.useQuery({
    id: params.access_level.id,
  });

  return (
    <DrawerHeader
      title={data?.name}
      subtitle={`${initialValues?.id ? "Edit" : "Add"} Elevator Access Levels`}
    />
  );
};
