import { View, StyleSheet, Pressable } from "react-native";
import { ActionSheetAction, Chip, useActionSheet } from "@smartrent/ui";
import { useContext, useEffect } from "react";

import { useWindowDimensionsQuery } from "@smartrent/hooks";
import { Close, KabobVertical } from "@smartrent/icons";

import { PageCtx } from "@/context/PageContext";

export const NavigationBannerActions = () => {
  const { actions } = useContext(PageCtx);
  const { isDesktop } = useWindowDimensionsQuery({
    isDesktop: ({ width }) => width >= 1200,
  });
  const { buttonProps, isOpen, onClose } = useActionSheet({
    description: "Actions",
    actions,
  });

  useEffect(() => {
    if (isOpen && isDesktop) {
      onClose();
    }
  }, [isDesktop, isOpen, onClose]);

  const Action = ({ action }: { action: ActionSheetAction }) => {
    return (
      <View style={styles.actionContainer}>
        <Chip
          color={action.destructive ? "error" : "grayscale"}
          variation={"outlined"}
          iconLeft={action.iconLeft}
          iconRight={action.iconRight}
          onPress={() => {
            action.onPress({ onClose });
          }}
        >
          {action.label}
        </Chip>
      </View>
    );
  };

  if (isDesktop) {
    return (
      <View style={styles.actionsContainer}>
        {actions.map((action, index) => {
          return <Action action={action} key={index} />;
        })}
      </View>
    );
  }
  return (
    <View style={styles.actionSheetContainer}>
      <Pressable {...buttonProps} style={styles.actionSheetButton}>
        {isOpen ? <Close /> : <KabobVertical />}
      </Pressable>
    </View>
  );
};

const styles = StyleSheet.create({
  actionsContainer: {
    flexDirection: "row",
  },
  actionContainer: {
    padding: 8,
  },
  actionSheetContainer: {
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  actionSheetButton: {
    borderRadius: 4,
    padding: 8,
  },
});
