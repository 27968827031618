import { FormikSelectField, FormikSelectFieldProps } from "@smartrent/ui";

import { CardFormatQueries } from "@/modules/card-format/queries";
import { CardFormat } from "@/modules/card-format/types";

interface CardFormatSelectFieldProps
  extends Omit<FormikSelectFieldProps<any>, "options"> {
  site_id: number;
  filterParams?: any;
  initialValue?: string;
  lookup_key?: string;
}

export const CardFormatSelectField: React.FC<
  React.PropsWithChildren<CardFormatSelectFieldProps>
> = (params) => {
  const { site_id, filterParams, initialValue } = params;
  const lookup_key = params.lookup_key || "id";

  const selectQueryProps = CardFormatQueries.select({
    filterParams: { site_id, ...filterParams },
    initialValue,
  });

  return (
    <FormikSelectField<CardFormat>
      getOptionValue={(option) =>
        (option as any)[lookup_key as string].toString()
      }
      getOptionLabel={(option) => `${option.name} (${option.facility_code})`}
      {...selectQueryProps}
      {...params}
    />
  );
};
