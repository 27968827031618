import { Controller } from "@/modules/controller/types";
import { ReadersTable } from "@/modules/reader/Table";

interface ControllerReadersDisplayProps {
  controller: Controller;
}

export const ControllerReadersDisplay = ({
  controller,
}: ControllerReadersDisplayProps) => {
  return <ReadersTable filters={{ controller_id: controller.id }} />;
};
