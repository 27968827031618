import { StyleSheet } from "react-native";
import { Link } from "react-router-dom";
import {
  LoadingCard,
  Panel,
  PanelBody,
  PanelHeader,
  PanelHeaderTitleWithStatusDot,
  Typography,
} from "@smartrent/ui";

import { SystemHealthQueries } from "@/modules/system-health/queries";
import { ResponsiveGrid } from "@/components/ResponsiveGrid";
import { Site } from "@/modules/site/types";
import { PathActions, Paths } from "@/lib/path";
import { QueryKeys } from "@/types";

interface SystemHealthPageProps {
  site: Site;
}

interface SystemHealthCardProps {
  to: string;
  count: number;
  title: string;
  subtitle: string;
}

const SystemHealthCard: React.FC<
  React.PropsWithChildren<SystemHealthCardProps>
> = ({ count, title, subtitle, to }) => (
  <Link to={to}>
    <Panel>
      <PanelHeader>
        <PanelHeaderTitleWithStatusDot
          title={title}
          status={count > 0 ? "error" : "success"}
        />
      </PanelHeader>
      <PanelBody>
        <>
          <Typography type="titleBold" style={styles.textCenter}>
            {count}
          </Typography>
          <Typography style={styles.textCenter}>{subtitle}</Typography>
        </>
      </PanelBody>
    </Panel>
  </Link>
);

export const SystemHealthPage: React.FC<
  React.PropsWithChildren<SystemHealthPageProps>
> = ({ site }) => {
  const { data: healthIssues, isLoading } = SystemHealthQueries.getSummary({
    site_id: site.id,
  });

  if (isLoading || !healthIssues) {
    return <LoadingCard />;
  }

  return (
    <ResponsiveGrid>
      <SystemHealthCard
        title="Controllers"
        subtitle="Offline"
        count={healthIssues?.offline_controllers}
        to={Paths.GetPath(
          QueryKeys.Controllers,
          PathActions.List,
          { site_id: site.id },
          { online: "false" }
        )}
      />

      <SystemHealthCard
        title="Hubs"
        subtitle="Offline"
        count={healthIssues.offline_hubs}
        to={Paths.GetPath(
          QueryKeys.Hubs,
          PathActions.List,
          { site_id: site.id },
          { online: "false" }
        )}
      />

      <SystemHealthCard
        title="Members"
        subtitle="Without Access Levels"
        count={healthIssues.members_without_access_levels}
        to={Paths.GetPath(
          QueryKeys.Members,
          PathActions.List,
          { site_id: site.id },
          { has_access_levels: "false" }
        )}
      />

      <SystemHealthCard
        title="Members"
        subtitle="Without Credentials"
        count={healthIssues.members_without_credentials}
        to={Paths.GetPath(
          QueryKeys.Members,
          PathActions.List,
          { site_id: site.id },
          { has_credentials: "false" }
        )}
      />

      <SystemHealthCard
        title="Members"
        subtitle="Unsynced"
        count={healthIssues.unsynced_members}
        to={Paths.GetPath(
          QueryKeys.Members,
          PathActions.List,
          { site_id: site.id },
          { synced: "false" }
        )}
      />
    </ResponsiveGrid>
  );
};

const styles = StyleSheet.create({
  textCenter: {
    textAlign: "center",
  },
});
