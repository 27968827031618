import { Typography, TypographyProps } from "@smartrent/ui";

import { useTableUtils } from "@/modules/base/table/utils";

interface ToggleableTypographyProps extends Partial<TypographyProps> {
  disabled?: boolean;
}

export const ToggleableTypography: React.FC<
  React.PropsWithChildren<ToggleableTypographyProps>
> = ({ disabled, children, ...props }) => {
  const { typographyProps, typographyPropsDisabled } = useTableUtils();

  return (
    <Typography
      {...(disabled ? typographyPropsDisabled : typographyProps)}
      {...props}
    >
      {children}
    </Typography>
  );
};
