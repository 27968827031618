import {
  FilterProps,
  FormikTextInputField,
  ListQueryResponse,
  Table,
  Typography,
  useTableQuery,
} from "@smartrent/ui";
import { useHistory, useParams } from "react-router-dom";

import { Paths } from "@/lib/path";

import { useAppDrawer } from "@/components/layout/AppDrawer";

import { usePermissions } from "@/context/PolicyContext";

import { TableFilters } from "@/modules/base/table/types";

import { createActionsColumn, CreateButton } from "../base/table/utils";

import { OrganizationQueries } from "./queries";
import { Organization } from "./types";

interface OrganizationsTableProps {
  filters: Partial<TableFilters & Organization>;
}

export const OrganizationsTable = ({ filters }: OrganizationsTableProps) => {
  const drawer = useAppDrawer();
  const params = useParams();
  const history = useHistory();

  const { canView, canCreate, canUpdate } = usePermissions(OrganizationQueries);

  const tableProps = useTableQuery<
    any,
    Organization,
    ListQueryResponse<Organization>
  >({
    fetch: OrganizationQueries.fetch,
    getQueryKey: ({
      filters: tableFilters,
      page,
      pageSize,
      sortColumn,
      sortDirection,
    }) => [
      OrganizationQueries.queryKey,
      {},
      {
        ...filters,
        page,
        per_page: pageSize,
        sort: sortColumn,
        dir: sortDirection,
        ...tableFilters,
      },
    ],
    columns: [
      {
        name: "name",
        header: "Name",
        render: ({ row }) => {
          return <Typography>{row.name}</Typography>;
        },
        sortable: true,
        filter: (props: FilterProps) => {
          return <FormikTextInputField {...props} />;
        },
      },
      {
        name: "sites",
        header: "# Sites",
        render: ({ row }) => {
          return <Typography type="body">{row.num_sites}</Typography>;
        },
        sortable: false,
      },
      createActionsColumn<Organization>({
        shouldAdjustForPressIcon: canView,
        canUpdate,
        onUpdate: async (row) =>
          drawer.push(OrganizationQueries.queryKey, {
            initialValues: row,
            params,
          }),
      }),
    ],
    defaultPageSize: 10,
  });
  return (
    <Table<Organization>
      title={"Organizations"}
      noRecordsText={`No Organizations Found`}
      action={
        <CreateButton
          canCreate={canCreate}
          onCreate={() => drawer.push(OrganizationQueries.queryKey, { params })}
          text={"Add Organization"}
        />
      }
      onRowPress={
        canView
          ? ({ id }: Organization) =>
              history.push(Paths.organizationViewPath(id))
          : undefined
      }
      {...tableProps}
    />
  );
};
