import { Typography } from "@smartrent/ui";
import { useField } from "formik";
import { View } from "react-native";

interface FormikFileUploadProps {
  name: string;
  label: string;
  accept: ".csv";
}

export const FormikFileUpload = ({
  name,
  label,
  accept,
}: FormikFileUploadProps) => {
  const [, , { setValue }] = useField<File>(name);

  return (
    <View>
      <Typography type="body">{label}</Typography>
      <input
        name="file"
        type="file"
        accept={accept}
        onChange={(event) => {
          if (!event?.currentTarget?.files) return;
          setValue(event?.currentTarget?.files[0]);
        }}
        className="form-control"
      />
    </View>
  );
};
