import { VStack, FormikTextInputField } from "@smartrent/ui";

import { FormikProps, Form } from "formik";

import * as yup from "yup";

import { BaseForm } from "@/modules/base/Form";
import { ControllerSelectField } from "@/modules/controller/components/ControllerSelectField";

import { DoorQueries } from "../queries";
import {
  Door,
  DoorConfigurations,
  StrikeModes,
  ValidDoorConfigurations,
} from "../types";

const validationSchema = yup.object().shape({
  name: yup.string().max(40).required().label("Name"),
  controller_id: yup.number().required("You must select a controller"),
  configuration: yup
    .string()
    .required("You must select a configuration.")
    .oneOf(ValidDoorConfigurations),
});

interface AddDoorFormProps {
  initialValues?: Door;
  site_id: number;
}

export const AddDoorForm: React.FC<
  React.PropsWithChildren<AddDoorFormProps>
> = ({ initialValues, site_id }) => {
  return (
    <BaseForm<Door>
      initialValues={{
        name: initialValues?.name,
        controller_id: initialValues?.controller_id,
        configuration: DoorConfigurations.single,
        // These values were defaulted in the form in the phoenix site.
        // We should default them in phoenix world, but I don't know if there would be side effects.
        strike_min: 1,
        strike_max: 5,
        strike_mode: StrikeModes.open,
        ...initialValues,
      }}
      validationSchema={validationSchema}
      QueryClient={DoorQueries}
    >
      {({ values }: FormikProps<Door>) => {
        return (
          <Form>
            <VStack spacing={16}>
              <FormikTextInputField name="name" label="Name" required />
              <ControllerSelectField
                site_id={site_id}
                name="controller_id"
                label="Controller"
              />
            </VStack>
          </Form>
        );
      }}
    </BaseForm>
  );
};
