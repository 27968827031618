import {
  VStack,
  FormikTextInputField,
  FormikSelectField,
  Typography,
  LoadingCard,
  FormikNumberInputField,
  FormikSwitch,
  FormikStaticTextField,
} from "@smartrent/ui";
import { Form, FormikProps } from "formik";
import * as yup from "yup";

import { StyleSheet, View } from "react-native";

import { ErrorPage } from "@/components/ErrorPage";

import {
  DATE_FORMAT_SHORT,
  formatDateTime,
  TIME_FORMAT,
} from "@/lib/formatters";

import { BaseForm, FormProps } from "../base/Form";
import { Controller, ControllerProtocols } from "../controller/types";

import { ControllerQueries } from "../controller/queries";

import { SiteQueries } from "../site/queries";

import { PanelQueries } from "./queries";

import {
  ElevatorCascadingPanelModelsIn,
  ElevatorCascadingPanelModelsOut,
  InternalPanelModelOptions,
  Panel,
  PanelModelOptions,
  PanelPort,
  ValidPanelModels,
  ValidPanelPorts,
} from "./types";

interface PanelFormProps {
  initialValues: Partial<Panel>;
  params: { controller_id: number; site_id: number };
}

export const PanelForm = ({ initialValues, params }: PanelFormProps) => {
  const { data: controller, isLoading } = ControllerQueries.useQuery({
    id: params.controller_id,
  });

  if (isLoading) return <LoadingCard />;
  if (!controller) return <ErrorPage title="Failed to load Controller" />;
  if (controller?.protocol !== ControllerProtocols.mpl)
    return (
      <View style={styles.invalidControllerContainer}>
        <Typography>This controllers panels aren't modifiable</Typography>
      </View>
    );

  return (
    <MPLPanelForm
      initialValues={initialValues}
      params={params}
      controller={controller}
    />
  );
};

const MPLPanelForm: React.FC<
  React.PropsWithChildren<FormProps<Panel> & { controller: Controller }>
> = ({ initialValues, params, controller }) => {
  const { data: site } = SiteQueries.useQuery({
    id: params.site_id,
  });

  const validationSchema = yup.object().shape({
    name: yup.string().required().max(40).label("Name"),
    model: yup.string().required().oneOf(ValidPanelModels).label("Model"),
    address: yup.number().required().min(0).label("Address"),
    port: yup.number().required().oneOf(ValidPanelPorts).label("Port"),
    cascading_in: yup
      .boolean()
      .label("Include in Elevator Input Cascade Positions"),
    cascading_out: yup
      .boolean()
      .label("Include in Elevator Output Cascade Positions"),
  });

  return (
    <BaseForm<Panel>
      initialValues={{
        ...initialValues,
        address: initialValues?.address || 0,
        cascading_in: initialValues?.cascading_in
          ? initialValues.cascading_in
          : false,
        cascading_out: initialValues?.cascading_out
          ? initialValues.cascading_out
          : false,
        controller_id: params.controller_id,
        cascading_options: initialValues?.cascading_options || undefined,
        // New panels cannot be internal
        port:
          initialValues?.port != undefined ? initialValues.port : PanelPort.tb3,
      }}
      validationSchema={validationSchema}
      QueryClient={PanelQueries}
      params={params}
    >
      {(_formikProps: FormikProps<Partial<Panel>>) => {
        return (
          <Form>
            <VStack spacing={16}>
              <FormikTextInputField name="name" label="Name" required />
              <FormikSelectField
                options={
                  initialValues?.model
                    ? PanelModelOptions.concat(InternalPanelModelOptions)
                    : PanelModelOptions
                }
                name="model"
                label="Model"
                required
                disabled={!!initialValues?.id}
              />
              <FormikNumberInputField name="address" label="Address" required />
              <FormikStaticTextField
                name="port"
                label="Port"
                disabled
                onPress={() => null}
                transformValue={(port: PanelPort): string =>
                  panelNameForPort(port, !!controller.downstream_sio_support)
                }
              />

              <FormikStaticTextField
                name="inserted_at"
                label="Created At"
                disabled
                transformValue={function (inserted_at: any): string {
                  if (!inserted_at) return "";
                  const date = formatDateTime(
                    `${inserted_at}Z`,
                    site?.timezone,
                    DATE_FORMAT_SHORT
                  );
                  const time = formatDateTime(
                    `${inserted_at}Z`,
                    site?.timezone,
                    TIME_FORMAT
                  );

                  return `${date} ${time}`;
                }}
                onPress={() => null}
              />

              {ElevatorCascadingPanelModelsIn.includes(
                _formikProps.values?.model || ("" as any)
              ) ? (
                <FormikSwitch
                  name={"cascading_in"}
                  label={"Assign to Input Cascade"}
                />
              ) : null}
              {ElevatorCascadingPanelModelsOut.includes(
                _formikProps.values?.model || ("" as any)
              ) ? (
                <FormikSwitch
                  name={"cascading_out"}
                  label={"Assign to Output Cascade"}
                />
              ) : null}
            </VStack>
          </Form>
        );
      }}
    </BaseForm>
  );
};

function panelNameForPort(
  port: PanelPort,
  downstream_sio_support: boolean
): string {
  return {
    [PanelPort.internal]: "Internal",
    [PanelPort.tb3]: downstream_sio_support ? "TB2" : "TB3",
  }[port];
}

const styles = StyleSheet.create({
  invalidControllerContainer: { padding: 16, alignItems: "center" },
});
