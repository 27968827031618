import { Chip, HStack, Typography, useTheme } from "@smartrent/ui";

import { StyleSheet, TouchableOpacity, View } from "react-native";

import {
  RenderItemParams,
  ScaleDecorator,
} from "react-native-draggable-flatlist";

import { Hamburger } from "@smartrent/icons";

import { Panel } from "@/modules/panel/types";

export const ElevatorCascadingPanelListItem: React.FC<
  React.PropsWithChildren<RenderItemParams<Panel>>
> = ({ drag, isActive, item, index }) => {
  const { colors } = useTheme();

  return (
    <View style={styles.root}>
      <View style={styles.indexContainer}>
        <Typography type="title2">{(index || 0) + 1}</Typography>
      </View>
      <View style={styles.panelContainer}>
        <ScaleDecorator activeScale={0.95}>
          <TouchableOpacity
            onPressIn={drag}
            disabled={isActive}
            style={[
              styles.rowItem,
              { shadowColor: colors.mutedBackgroundGrayscale },
              {
                backgroundColor: isActive ? colors.gray200 : colors.gray075,
              },
            ]}
          >
            <View style={styles.rowItemContainer}>
              <Typography type="title4" numberOfLines={1}>
                {item?.name}
              </Typography>

              <HStack spacing={24}>
                <HStack spacing={8}>
                  {item?.model ? (
                    <Chip
                      color="grayscale"
                      size="small"
                      corners="badge"
                      variation="outlined"
                    >
                      {item.model.toUpperCase()}
                    </Chip>
                  ) : null}

                  <Chip
                    color="grayscale"
                    size="small"
                    corners="badge"
                    variation="outlined"
                  >{`Panel ${item.panel_number}`}</Chip>
                </HStack>

                <Hamburger />
              </HStack>
            </View>
          </TouchableOpacity>
        </ScaleDecorator>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    paddingVertical: 8,
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
  },
  indexContainer: {
    minWidth: 24,
    alignItems: "flex-start",
    marginHorizontal: 8,
  },
  panelContainer: { flex: 1 },
  rowItem: {
    height: 80,
    margin: 8,
    alignItems: "flex-start",
    justifyContent: "center",
    shadowOffset: { width: 0, height: 4 },
    shadowRadius: 8,
    shadowOpacity: 2,
  },
  rowItemContainer: {
    paddingHorizontal: 24,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    flexGrow: 1,
  },
});
