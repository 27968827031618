import { LoadingCard } from "@smartrent/ui";

import { useContext, useEffect } from "react";

import { useParams } from "react-router-dom";

import { ErrorPage } from "@/components/ErrorPage";

import { PageCtx } from "@/context/PageContext";
import { Paths } from "@/lib/path";
import { Site } from "@/modules/site/types";

import { HolidaySchedulesTable } from "@/modules/holiday-schedules/HolidaySchedulesTable";
import { HolidayScheduleGroupQueries } from "@/modules/holiday-groups/queries";

interface HolidayGroupViewPageProps {
  site: Site;
}

export const HolidayGroupViewPage = ({ site }: HolidayGroupViewPageProps) => {
  const { addBreadcrumb, breadCrumbs } = useContext(PageCtx);

  const { holiday_schedule_group_id } = useParams<{
    holiday_schedule_group_id: string;
  }>();

  const { data: holiday_group, isLoading } =
    HolidayScheduleGroupQueries.useQuery({
      id: parseInt(holiday_schedule_group_id as string),
    });

  const holiday_group_name = holiday_group?.name || "Exceptions";
  useEffect(() => {
    if (!isLoading) {
      addBreadcrumb({
        label: holiday_group_name,
        to: Paths.holidayGroupViewPath(site.id, holiday_schedule_group_id),
      });
    }
  }, [
    breadCrumbs,
    holiday_group,
    addBreadcrumb,
    site.id,
    holiday_schedule_group_id,
    holiday_group_name,
    isLoading,
  ]);

  if (isLoading) {
    return <LoadingCard />;
  }
  if (!holiday_group) {
    return <ErrorPage title={"Error retrieving exceptions"} />;
  }
  return (
    <HolidaySchedulesTable
      filters={{
        site_id: site.id,
        holiday_schedule_group_id: holiday_schedule_group_id,
      }}
      site={site}
      holiday_group_name={holiday_group_name}
    />
  );
};
