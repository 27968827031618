import { BaseDrawer } from "../base/Drawer";

import { FormWithAddresses } from "./components/FormWithAddress";
import { FormWithoutAddresses } from "./components/FormWithoutAddress";
import { Reader } from "./types";

export interface AddressOptions {
  label: number;
  value: number;
}

export interface ReaderDrawerProps {
  reader: Reader;
  addressOptions?: AddressOptions[];
  afterSubmit?: () => void | undefined;
}

export const ReaderDrawer: React.FC<
  React.PropsWithChildren<ReaderDrawerProps>
> = ({ reader, addressOptions, afterSubmit }) => {
  return (
    <BaseDrawer
      title={reader?.id ? reader?.name : "New Reader"}
      subtitle={reader?.id ? "Edit Reader" : null}
    >
      {addressOptions ? (
        <FormWithAddresses
          reader={reader}
          addressOptions={addressOptions}
          afterSubmit={afterSubmit}
        />
      ) : (
        <FormWithoutAddresses reader={reader} />
      )}
    </BaseDrawer>
  );
};
