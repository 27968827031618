import { Form } from "formik";
import * as yup from "yup";
import { VStack } from "@smartrent/ui";

import { ScheduleSelectField } from "@/modules/schedule/components/ScheduleSelectField";
import { DoorMultiSelectField } from "@/modules/door/components/DoorSelectField";

import { BaseForm } from "../base/Form";

import { AccessLevelScheduleQueries } from "./queries";
import { AccessLevelSchedule } from "./types";

const validationSchema = yup.object().shape({
  access_level_id: yup.number().required(),
  schedule_id: yup.number().nullable().required().label("Schedule"),
  door_ids: yup
    .array()
    .of(yup.number())
    .min(1, "You must select at least one door")
    .required()
    .label("Doors"),
});

interface AccessLevelScheduleFormProps {
  initialValues?: any;
  params: {
    site_id: number;
    access_level_id: number;
  };
}

export const AccessLevelScheduleForm = ({
  initialValues,
  params,
}: AccessLevelScheduleFormProps) => {
  return (
    <BaseForm<AccessLevelSchedule>
      initialValues={{
        door_ids: [],
        schedule_id: null,
        access_level_id: params.access_level_id,
        ...initialValues,
      }}
      validationSchema={validationSchema}
      QueryClient={AccessLevelScheduleQueries}
      params={params}
    >
      {() => {
        return (
          <Form>
            <VStack spacing={8}>
              <DoorMultiSelectField
                name={"door_ids"}
                label={"Door"}
                site_id={params.site_id}
                filterParams={{
                  not_in_access_level: params.access_level_id,
                }}
              />
              <ScheduleSelectField
                name={"schedule_id"}
                label={"Schedule"}
                site_id={params.site_id}
                required
              />
            </VStack>
          </Form>
        );
      }}
    </BaseForm>
  );
};
