import {
  AssistiveText,
  Button,
  FormikSelectField,
  FormikSubmit,
  HStack,
  Typography,
  VStack,
} from "@smartrent/ui";
import { Form, Formik, FormikHelpers } from "formik";
import * as yup from "yup";

import { View, StyleSheet } from "react-native";

import {
  Door,
  DoorModeOptions,
  ValidDoorModes,
  DoorModeRequest,
  TempModeDurationOptions,
  DoorModeAssistiveTextMap,
  getDoorModeAssistiveText,
} from "@/modules/door/types";

import { DoorQueries } from "../queries";

const validationSchema = yup.object().shape({
  id: yup.number().required(),
  mode: yup
    .string()
    .oneOf(ValidDoorModes)
    .required()
    .nullable()
    .label("Door Mode"),
  duration: yup.number().required().label("Duration"),
});

interface DoorModeFormProps {
  initialValues: Door;
}

export const DoorModeForm = ({ initialValues }: DoorModeFormProps) => {
  const [setTemporaryMode] = DoorQueries.useSetTemporaryModeMutation();

  return (
    <View style={styles.root}>
      <View style={styles.textContainer}>
        <Typography type="body" style={styles.text} numberOfLines={3}>
          Changing this value will temporarily set the mode of the door. The
          mode will remain set for the duration you select, or until another
          event changes the mode.
        </Typography>
      </View>
      <Formik<DoorModeRequest>
        initialValues={{
          ...initialValues,
          duration: 60,
          mode: undefined,
        }}
        validationSchema={validationSchema}
        onSubmit={async function (
          values: DoorModeRequest,
          formikHelpers: FormikHelpers<DoorModeRequest>
        ): Promise<any> {
          try {
            await setTemporaryMode(values);
          } catch (e) {
            console.error(e);
          }
          formikHelpers.setSubmitting(false);
        }}
      >
        {({ values }) => (
          <Form>
            <HStack spacing={8} style={styles.formElementContainer}>
              <VStack>
                <FormikSelectField
                  name="mode"
                  label="Temporary Door Mode"
                  options={DoorModeOptions.map((o) => {
                    if (o.value == initialValues.mode) {
                      return {
                        label: `${o.label} (Current)`,
                        value: o.value,
                      };
                    } else {
                      return o;
                    }
                  })}
                />
                {/* TODO: We want the assistive text to render 'beneath' the select field, which js-shared does not currently support. */}
                {values?.mode && DoorModeAssistiveTextMap[values.mode] ? (
                  <AssistiveText>
                    {getDoorModeAssistiveText(values.mode)}
                  </AssistiveText>
                ) : null}
              </VStack>
              <FormikSelectField
                name="duration"
                label="Duration"
                options={TempModeDurationOptions}
                assistiveText=""
              />
              <FormikSubmit size="large" />

              {initialValues.mode != initialValues.default_mode ? (
                <Button
                  size="large"
                  onPress={() => {
                    // Reset temp door mode by setting duration to 0
                    setTemporaryMode({
                      id: initialValues.id,
                      mode: initialValues.default_mode,
                      duration: 0,
                    });
                  }}
                >
                  Reset
                </Button>
              ) : null}
            </HStack>
          </Form>
        )}
      </Formik>
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    padding: 16,
  },
  textContainer: {
    alignItems: "center",
    paddingTop: 16,
    paddingBottom: 32,
  },
  text: {
    maxWidth: 600,
  },
  formElementContainer: {
    justifyContent: "center",
  },
});
