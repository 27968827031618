import { useContext, useEffect } from "react";

import { FeatureFlagsTable } from "@/modules/feature-flags/Table";
import { PageCtx } from "@/context/PageContext";
import { Paths } from "@/lib/path";

export const FeatureFlagListPage: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const { addBreadcrumb, breadCrumbs } = useContext(PageCtx);

  useEffect(() => {
    addBreadcrumb({
      label: `Feature Flags`,
      to: Paths.featureFlagListPath(),
    });
  }, [breadCrumbs, addBreadcrumb]);

  return <FeatureFlagsTable filters={{}} />;
};
