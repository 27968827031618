import { BaseDrawer } from "../base/Drawer";

import { Controller } from "./types";
import { ControllerForm } from "./Form";

interface ControllerDrawerProps {
  initialValues?: Controller;
  params: {
    site_id: number;
  };
}

export const ControllerDrawer: React.FC<
  React.PropsWithChildren<ControllerDrawerProps>
> = ({ initialValues, params }) => {
  return (
    <BaseDrawer
      title={initialValues?.id ? initialValues.name : "New Controller"}
      subtitle={initialValues?.id ? "Edit Controller" : null}
    >
      <ControllerForm initialValues={initialValues} params={params} />
    </BaseDrawer>
  );
};
