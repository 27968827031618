import { ListQueryResponse, Table, useTableQuery } from "@smartrent/ui";

import { AccessLevel } from "@/modules/access-level/types";

import { useAppDrawer } from "@/components/layout/AppDrawer";

import { useDialog } from "@/context/dialog";

import { usePermissions } from "@/context/PolicyContext";

import { QueryKeys } from "@/types";

import { PendingDeleteChip } from "@/components/PendingDeleteChip";

import { ToggleableTypography } from "@/components/ToggleableTypography";

import { CreateButton, createActionsColumn } from "../base/table/utils";

import { Elevator } from "../elevator/types";

import { ElevatorQueries } from "../elevator/queries";

import { Site } from "../site/types";

import { useDeleteElevatorAccessLevelQuery } from "./queries";
import { findElevatorAccessLevel } from "./utils/find-elevator-access-level";

interface ElevatorAccessLevelSchedulesTableProps {
  access_level: AccessLevel;
  site: Site;
  pending_delete?: string;
}

const isDisabled = (access_level_id: AccessLevel["id"], row: Elevator) =>
  row?.floors?.some(
    (f) => findElevatorAccessLevel(f, access_level_id)?.pending_delete
  );

export const ElevatorAccessLevelSchedulesTable: React.FC<
  React.PropsWithChildren<ElevatorAccessLevelSchedulesTableProps>
> = ({ access_level, site }) => {
  const drawer = useAppDrawer();
  const { confirm } = useDialog();

  const [doDeleteMutation] = useDeleteElevatorAccessLevelQuery();

  const { canCreate } = usePermissions({
    queryKey: QueryKeys.AccessLevels,
  });

  const tableProps = useTableQuery<any, Elevator, ListQueryResponse<Elevator>>({
    columns: [
      {
        name: "door",
        header: "Elevator",
        render: ({ row }) => {
          return (
            <ToggleableTypography disabled={isDisabled(access_level.id, row)}>
              {row.name}
            </ToggleableTypography>
          );
        },
      },
      {
        name: "overview",
        header: "Has Access",
        render: ({ row }) => {
          const totalFloorCount = row?.floors?.length || 0;
          const accessibleFloorsCount = row?.floors?.reduce((acc, floor) => {
            const hasAccess = floor?.elevator_access_levels?.some(
              (eal) => eal.access_level_id === access_level.id
            );
            return acc + (hasAccess ? 1 : 0);
          }, 0);

          return (
            <ToggleableTypography disabled={isDisabled(access_level.id, row)}>
              {`${accessibleFloorsCount}/${totalFloorCount} Floors`}
            </ToggleableTypography>
          );
        },
      },
      createActionsColumn<Elevator>({
        customActions: (row) =>
          isDisabled(access_level.id, row) ? <PendingDeleteChip /> : null,
        canUpdate: (row) => !isDisabled(access_level.id, row),
        onUpdate: async (row) => {
          drawer.push(QueryKeys.ElevatorAccessLevels, {
            initialValues: row,
            params: {
              access_level,
              site,
            },
          });
        },
        canDelete: (row) => !isDisabled(access_level.id, row),
        onDelete: async (row) => {
          const confirmed = await confirm({
            title: "Delete",
            description: `Are you sure you want to delete this Access Level?`,
            confirmText: "Delete",
            confirmType: "destructive",
            cancelText: "Cancel",
          });
          if (confirmed) {
            doDeleteMutation({
              access_level_id: access_level.id,
              door_id: row.id,
            });
          }
        },
      }),
    ],
    fetch: ElevatorQueries.fetch,
    getQueryKey: ({
      filters: tableFilters,
      page,
      pageSize,
      sortColumn,
      sortDirection,
    }) => [
      QueryKeys.ElevatorAccessLevels,
      {},
      {
        access_level_id: access_level.id,
        site_id: access_level.site_id as number,
        page,
        per_page: pageSize,
        sort: sortColumn,
        dir: sortDirection,
        ...tableFilters,
      },
    ],
    defaultPageSize: 10,
  });
  return (
    <Table<Elevator>
      title={`${access_level.name} Elevator Access`}
      noRecordsText={"No Elevators Found"}
      action={
        <CreateButton
          canCreate={true || canCreate}
          onCreate={() =>
            drawer.push(QueryKeys.ElevatorAccessLevels, {
              params: { access_level, site },
            })
          }
          text={"Add Elevator"}
        />
      }
      {...tableProps}
    />
  );
};
