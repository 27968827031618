import { StyleProp, StyleSheet, ViewStyle } from "react-native";

import { NoRecords } from "./NoRecords";

interface ErrorPageProps {
  title: string;
  style?: StyleProp<ViewStyle>;
}

export const ErrorPage: React.FC<React.PropsWithChildren<ErrorPageProps>> = ({
  title,
  style,
}) => {
  return <NoRecords style={[styles.error, style]} title={title} />;
};

const styles = StyleSheet.create({
  error: {
    flex: 1,
    padding: 24,
    textAlign: "center",
  },
});
