import { View } from "react-native";

import {
  FilterProps,
  FormikTextInputField,
  ListQueryResponse,
  Table,
  Typography,
  useTableQuery,
  Link,
} from "@smartrent/ui";

import { useParams } from "react-router-dom";

import { useState } from "react";

import { useAppDrawer } from "@/components/layout/AppDrawer";

import { useDialog } from "@/context/dialog";

import { usePermissions } from "@/context/PolicyContext";

import { TableFilters } from "@/modules/base/table/types";

import { CreateButton, createActionsColumn } from "../base/table/utils";

import { ApiApplicationQueries } from "./queries";
import { ApiApplication } from "./types";

interface ApiApplicationsTableProps {
  filters: Partial<TableFilters & ApiApplication>;
}

const ApiSecretDisplay = ({
  apiApplicationId,
}: {
  apiApplicationId: number;
}) => {
  const VisibleApiSecret = () => {
    const { data: apiApplication, isLoading } = ApiApplicationQueries.useQuery({
      id: apiApplicationId,
    });
    return (
      <View>
        <Typography>{isLoading ? "..." : apiApplication?.secret}</Typography>
      </View>
    );
  };

  const [visible, setVisible] = useState<boolean>(false);

  const showSecret = async () => {
    setVisible(true);
    setTimeout(() => {
      setVisible(false);
    }, 10000);
  };

  if (visible) {
    return <VisibleApiSecret />;
  } else {
    return (
      <View>
        <Link onPress={showSecret}>Show Secret</Link>
      </View>
    );
  }
};

export const ApiApplicationsTable = ({
  filters,
}: ApiApplicationsTableProps) => {
  const drawer = useAppDrawer();
  const params = useParams();
  const { confirm } = useDialog();

  const { queryKey, useDeleteMutation, fetch } = ApiApplicationQueries;
  const { canCreate, canDelete, canUpdate } = usePermissions({ queryKey });

  const [doDelete] = useDeleteMutation();

  const tableProps = useTableQuery<
    any,
    ApiApplication,
    ListQueryResponse<ApiApplication>
  >({
    fetch,
    getQueryKey: ({
      filters: tableFilters,
      page,
      pageSize,
      sortColumn,
      sortDirection,
    }) => [
      queryKey,
      {},
      {
        ...filters,
        page,
        per_page: pageSize,
        sort: sortColumn,
        dir: sortDirection,
        ...tableFilters,
      },
    ],

    columns: [
      {
        name: "name",
        header: "Name",
        render: ({ row }) => {
          return <Typography>{row.name}</Typography>;
        },
        sortable: true,
        filter: (props: FilterProps) => {
          return <FormikTextInputField {...props} />;
        },
      },
      {
        name: "key",
        header: "Key",
        render: ({ row }) => {
          return <Typography type="body">{row.key}</Typography>;
        },
        sortable: true,
        filter: (props: FilterProps) => {
          return <FormikTextInputField {...props} />;
        },
      },
      {
        name: "secret",
        header: "Secret",
        render: ({ row }) => {
          return <ApiSecretDisplay apiApplicationId={row.id} />;
        },
        sortable: false,
      },
      {
        name: "webhook_url",
        header: "Webhook Url",
        hidden: true,
        filter: function UrlFilter(props: FilterProps) {
          return <FormikTextInputField {...props} />;
        },
      },
      createActionsColumn<ApiApplication>({
        maxWidth: 120,
        canDelete,
        onDelete: async (row) => {
          const confirmed = await confirm({
            title: "Delete",
            description: `Are you sure you want to delete this Api Application?`,
            confirmText: "Delete",
            confirmType: "destructive",
            cancelText: "Cancel",
          });
          if (confirmed) {
            doDelete({ id: row.id });
          }
        },
        canUpdate,
        onUpdate: async (row) =>
          drawer.push(queryKey, { initialValues: row, params }),
      }),
    ],
    defaultPageSize: 10,
  });

  return (
    <Table<ApiApplication>
      title={"Api Applications"}
      noRecordsText={"No Api Applications Found"}
      action={
        <CreateButton
          canCreate={canCreate}
          onCreate={() => drawer.push(queryKey, { params })}
          text={"Add Api Application"}
        />
      }
      {...tableProps}
    />
  );
};
