import { MemberAccessLevelsTable } from "@/modules/member/components/MemberAccessLevelTable";
import { Member } from "@/modules/member/types";
import { Site } from "@/modules/site/types";

interface AccessLevelsDisplayProps {
  member: Member;
  site: Site;
}

export const AccessLevelsDisplay = ({
  member,
  site,
}: AccessLevelsDisplayProps) => {
  return (
    <MemberAccessLevelsTable
      filters={{ member_id: member.id }}
      params={{ site_id: site.id }}
      member={member}
    />
  );
};
