import * as yup from "yup";
import { Form, Formik } from "formik";
import {
  Button,
  FormikPasswordInputField,
  FormikTextInputField,
  LoadingCard,
  Typography,
  useTheme,
  VStack,
} from "@smartrent/ui";
import { password as passwordValidators } from "@smartrent/utils";
import { CheckCircleSolid, ExclamationCircleSolid } from "@smartrent/icons";
import { StyleSheet, View } from "react-native";

const validationSchema = yup.object().shape({
  password: yup.string().required("Required"),
  password_confirmation: yup.string().required("Required"),
});

export interface PasswordParams {
  password: string;
  password_confirmation: string;
}
interface Props {
  email: string;
  handleSubmit: ({
    password,
    password_confirmation,
  }: PasswordParams) => Promise<void>;
  submitText: string;
}

/**
 * Used for both resetting passwords and accepting the initial invite
 */
export const PasswordForm: React.FC<React.PropsWithChildren<Props>> = ({
  email,
  handleSubmit,
  submitText,
}: Props) => {
  const { colors } = useTheme();

  return (
    <Formik<PasswordParams & { email: string }>
      initialValues={{ email, password: "", password_confirmation: "" }}
      validationSchema={validationSchema}
      onSubmit={(
        { password, password_confirmation },
        { setSubmitting, setFieldError }
      ) => {
        setSubmitting(true);
        if (passwordValidators.validateForm(password, password_confirmation)) {
          try {
            handleSubmit({
              password,
              password_confirmation,
            }).then(() => setSubmitting(false));
          } catch (error: any) {
            setFieldError("password", "Something went wrong, please try again");
            setSubmitting(false);
          }
        } else {
          setFieldError("password", "Invalid password");
          setSubmitting(false);
        }
      }}
    >
      {({
        isSubmitting,
        isValid,
        submitForm,
        values: { password, password_confirmation },
      }) =>
        isSubmitting ? (
          <LoadingCard />
        ) : (
          <Form>
            <View>
              <FormikTextInputField
                name="email"
                label="Email"
                disabled={true}
              />
              <View style={styles.marginBottom16}>
                <FormikPasswordInputField
                  name="password"
                  label="Password"
                  required
                />
              </View>
              <View style={styles.marginBottom16}>
                <FormikPasswordInputField
                  name="password_confirmation"
                  label="Confirm Password"
                  required
                  submitOnEnter={true}
                />
              </View>

              <VStack
                spacing={4}
                style={[styles.marginTop24, styles.marginBottom40]}
              >
                <View style={styles.validation}>
                  {password && password.length >= 12 ? (
                    <CheckCircleSolid color={colors.success} />
                  ) : (
                    <ExclamationCircleSolid color={colors.error} />
                  )}
                  <Typography style={styles.marginLeft8}>
                    Minimum of 12 characters
                  </Typography>
                </View>
                <View style={styles.validation}>
                  {passwordValidators.checkLowerCase(password) ? (
                    <CheckCircleSolid color={colors.success} />
                  ) : (
                    <ExclamationCircleSolid color={colors.error} />
                  )}
                  <Typography style={styles.marginLeft8}>
                    1 lowercase letter
                  </Typography>
                </View>
                <View style={styles.validation}>
                  {passwordValidators.checkUpperCase(password) ? (
                    <CheckCircleSolid color={colors.success} />
                  ) : (
                    <ExclamationCircleSolid color={colors.error} />
                  )}
                  <Typography style={styles.marginLeft8}>
                    1 uppercase letter
                  </Typography>
                </View>
                <View style={styles.validation}>
                  {passwordValidators.checkSpecialChar(password) ? (
                    <CheckCircleSolid color={colors.success} />
                  ) : (
                    <ExclamationCircleSolid color={colors.error} />
                  )}
                  <Typography style={styles.marginLeft8}>
                    1 special character
                  </Typography>
                </View>
                <View style={styles.validation}>
                  {passwordValidators.checkHasNumber(password) ? (
                    <CheckCircleSolid color={colors.success} />
                  ) : (
                    <ExclamationCircleSolid color={colors.error} />
                  )}
                  <Typography style={styles.marginLeft8}>1 number</Typography>
                </View>
                <View style={styles.validation}>
                  {passwordValidators.checkSamePasswords(
                    password,
                    password_confirmation
                  ) ? (
                    <CheckCircleSolid color={colors.success} />
                  ) : (
                    <ExclamationCircleSolid color={colors.error} />
                  )}
                  <Typography style={styles.marginLeft8}>
                    Passwords Match
                  </Typography>
                </View>
              </VStack>
            </View>
            <Button
              disabled={!isValid || isSubmitting}
              onPress={submitForm}
              style={styles.submit}
            >
              {submitText}
            </Button>
          </Form>
        )
      }
    </Formik>
  );
};

const styles = StyleSheet.create({
  marginLeft8: {
    marginLeft: 8,
  },
  marginTop24: {
    marginTop: 24,
  },
  marginBottom16: {
    marginBottom: 16,
  },
  marginBottom40: {
    marginBottom: 40,
  },
  submit: {
    flexGrow: 0,
  },
  validation: {
    flexDirection: "row",
    alignItems: "center",
  },
});
