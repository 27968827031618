import { StyleSheet, View } from "react-native";

import {
  Panel,
  PanelHeader,
  TabContent,
  TabProvider,
  TabsNavigation,
  useTheme,
} from "@smartrent/ui";

import { Door } from "@/modules/door/types";

import { DoorModeForm } from "@/modules/door/components/DoorModeForm";
import { ControllerProtocols } from "@/modules/controller/types";

interface DoorQuickActionsPanelProps {
  door: Door;
}

export const DoorQuickActionsPanel: React.FC<
  React.PropsWithChildren<DoorQuickActionsPanelProps>
> = ({ door }) => {
  const { colors } = useTheme();

  if (
    !door?.controller?.protocol ||
    ![ControllerProtocols.mpl, ControllerProtocols.smrtp].includes(
      door.controller.protocol
    )
  ) {
    return null;
  }

  return (
    <View style={styles.root}>
      <TabProvider>
        <Panel>
          <PanelHeader
            style={[styles.header, { backgroundColor: colors.background }]}
          >
            <TabsNavigation
              initialActiveTabId={"quick-actions"}
              items={[
                {
                  id: "quick-actions",
                  label: `Quick Actions (${door?.controller?.protocol})`,
                },
              ]}
            />
          </PanelHeader>
          <TabContent id="quick-actions">
            <DoorModeForm initialValues={door} />
          </TabContent>
        </Panel>
      </TabProvider>
    </View>
  );
};

const styles = StyleSheet.create({
  root: { padding: 16 },
  header: {
    padding: 0,
  },
});
