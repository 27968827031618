import { BaseDrawer } from "../base/Drawer";

import { Door } from "./types";
import { DoorForm } from "./Form";

interface DoorDrawerProps {
  initialValues?: Door;
  site_id: number;
}

export const DoorDrawer: React.FC<React.PropsWithChildren<DoorDrawerProps>> = ({
  initialValues,
  site_id,
}) => {
  return (
    <BaseDrawer
      title={initialValues?.id ? initialValues?.name : `New Door`}
      subtitle={initialValues?.id ? `Edit Door` : null}
    >
      <DoorForm initialValues={initialValues} site_id={site_id} />
    </BaseDrawer>
  );
};
