import {
  FilterProps,
  FormikTextInputField,
  ListQueryResponse,
  Table,
  Typography,
  useTableQuery,
} from "@smartrent/ui";

import { DATE_TIME_FORMAT, formatDateTime } from "@/lib/formatters";

import { TableFilters } from "@/modules/base/table/types";

import { ControllerNameAndStatusIndicator } from "../controller/components/ControllerStatusIndicator";

import { ControllerQueries } from "../controller/queries";
import { Controller } from "../controller/types";
import { Site } from "../site/types";

import { Door } from "./types";
import { DoorQueries } from "./queries";

interface SaltoDoorsTableProps {
  filters: Partial<TableFilters & Door>;
  defaultSortColumn?: string;
  defaultSortDirection?: string;
  site: Site;
}
export const SaltoDoorsTable = ({
  filters,
  defaultSortColumn,
  defaultSortDirection,
  site,
}: SaltoDoorsTableProps) => {
  const controllerSelectProps = ControllerQueries.tableSelectProps({
    defaultParams: { site_id: site.id },
  });

  const tableProps = useTableQuery<any, Door, ListQueryResponse<Door>>({
    fetch: DoorQueries.fetch,
    getQueryKey: ({
      filters: tableFilters,
      page,
      pageSize,
      sortColumn,
      sortDirection,
    }) => [
      DoorQueries.queryKey,
      {},
      {
        ...filters,
        page,
        per_page: pageSize,
        sort: sortColumn || defaultSortColumn,
        dir: sortDirection || defaultSortDirection,
        ...tableFilters,
        site_id: site.id,
      },
    ],

    columns: [
      {
        name: "name",
        header: "Name",
        render: ({ row }) => {
          return <Typography>{row.name}</Typography>;
        },
        sortable: true,
        filter: (props: FilterProps) => {
          return <FormikTextInputField {...props} />;
        },
      },
      {
        name: "controller_id",
        header: "Controller",
        render: ({ row }) => {
          if (!row.controller) return <Typography>-</Typography>;
          return (
            <ControllerNameAndStatusIndicator controller={row.controller} />
          );
        },
        sortable: true,
        filterType: {
          type: "selectField",
          getOptionValue: (option: Controller) => {
            return option.id.toString();
          },
          getOptionLabel: (option: Controller) => {
            return `${option.name}`;
          },
          ...controllerSelectProps,
        },
      },
      {
        name: "inserted_at",
        header: "Created At",
        render: ({ row }) => {
          return (
            <Typography type="body">
              {formatDateTime(
                `${row.inserted_at}Z`,
                site?.timezone,
                DATE_TIME_FORMAT
              )}
            </Typography>
          );
        },
      },
    ],
  });

  return (
    <Table<Door>
      title={"Doors"}
      noRecordsText={"No Doors Found"}
      {...tableProps}
    />
  );
};
