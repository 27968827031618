import { FormikMultiSelectField } from "@smartrent/ui";

import { Site } from "@/modules/site/types";
import { SiteQueries } from "@/modules/site/queries";

interface UnitGroupMultiSelectFieldProps {
  label: string;
  name: string;
}

export const SiteMultiSelectField: React.FC<
  React.PropsWithChildren<UnitGroupMultiSelectFieldProps>
> = ({ label, name }) => {
  const selectQueryProps = SiteQueries.select({});

  return (
    <FormikMultiSelectField<Site>
      label={label}
      name={name}
      getOptionValue={(option) => option.id.toString()}
      getOptionLabel={(option) => option?.name ?? ""}
      {...selectQueryProps}
    />
  );
};
