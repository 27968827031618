import { VStack, FormikMultiSelectField } from "@smartrent/ui";
import { Form } from "formik";
import * as yup from "yup";

import { BaseForm } from "../base/Form";

import { HolidayScheduleGroupQueries } from "../holiday-groups/queries";

import { HolidayScheduleGroup } from "../holiday-groups/types";

import { ScheduleHolidayGroupsFormValues } from "./types";

import { ScheduleHolidayGroupQueryClient } from "./queries";

interface ScheduleHolidayGroupProps {
  params: { site_id: number; schedule_id: number };
}

const validationSchema = yup.object().shape({});

export const ScheduleHolidayGroupsForm = ({
  params,
}: ScheduleHolidayGroupProps) => {
  const queryClient = new ScheduleHolidayGroupQueryClient(params.schedule_id);

  const { data: initialData, isLoading } = HolidayScheduleGroupQueries.useList({
    site_id: params.site_id,
    schedule_id: params.schedule_id,
  });
  const { data, isLoading: optionsIsLoading } =
    HolidayScheduleGroupQueries.useList({ site_id: params.site_id });

  const showLoading = isLoading || optionsIsLoading;

  const groups = data?.records || [];
  const groupOptions = buildMultiSelectOptions(groups);

  const initialGroups = initialData?.records || [];
  const initialValues = {
    holiday_schedule_group_ids: initialGroups.map((group) => group.id),
    id: -1,
  };

  return (
    <BaseForm<ScheduleHolidayGroupsFormValues>
      initialValues={initialValues}
      validationSchema={validationSchema}
      params={params}
      QueryClient={queryClient}
    >
      {() => (
        <Form>
          <VStack>
            <FormikMultiSelectField
              name="holiday_schedule_group_ids"
              label="Exceptions"
              isLoading={showLoading}
              options={groupOptions}
            />
          </VStack>
        </Form>
      )}
    </BaseForm>
  );
};

const buildMultiSelectOptions = (holidayGroups: HolidayScheduleGroup[]) =>
  holidayGroups.map((group) => ({ label: group.name, value: group.id }));
