import { View, StyleSheet } from "react-native";

import { useWindowDimensionsQuery } from "@smartrent/hooks";

import { Site, SystemTypes } from "@/modules/site/types";
import { Controller, ControllerProtocols } from "@/modules/controller/types";

import { ControllerActionPanel } from "./show/ActionPanel";
import { ControllerInformationPanel } from "./shared/InformationPanel";
import { ControllerConfigurationPanel } from "./show/ControllerConfigurationPanel";
import { ElevatorCascadingPanelList } from "./show/elevator/ElevatorCascadingPanelList";

interface ControllerShowPageProps {
  site: Site;
  controller: Controller;
}

export const ControllerShowPage: React.FC<
  React.PropsWithChildren<ControllerShowPageProps>
> = ({ site, controller }) => {
  const { large: isDesktop } = useWindowDimensionsQuery();

  return (
    <View style={isDesktop ? styles.root : styles.rootMobile}>
      <View style={styles.left}>
        <View style={styles.container}>
          <ControllerInformationPanel site={site} controller={controller} />
        </View>
        {site.system_type !== SystemTypes.Schlage &&
        controller.protocol !== ControllerProtocols.whoo ? (
          <View style={styles.container}>
            <ControllerConfigurationPanel site={site} controller={controller} />
          </View>
        ) : null}
      </View>
      <View style={styles.right}>
        <View style={styles.container}>
          <ControllerActionPanel site={site} controller={controller} />
        </View>

        <View style={styles.container}>
          <ElevatorCascadingPanelList
            controller={controller}
            title="Input Cascade Positions"
            orderField="order_in"
          />
        </View>

        <View style={styles.container}>
          <ElevatorCascadingPanelList
            controller={controller}
            title="Output Cascade Positions"
            orderField="order_out"
          />
        </View>
      </View>
    </View>
  );
};

// TODO: Make this not suck
const styles = StyleSheet.create({
  root: { flexDirection: "row" },
  rootMobile: { flexDirection: "row", flexWrap: "wrap" },
  container: { padding: 16 },
  left: { flex: 1, flexBasis: "32%", minWidth: 420 },
  right: { flex: 3, flexBasis: "66%", minWidth: 360 },
});
