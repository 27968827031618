import { Form, Formik, FormikHelpers } from "formik";
import * as yup from "yup";
import {
  FormikNumberInputField,
  FormikSelectField,
  FormikSubmit,
  FormikTextInputField,
  VStack,
} from "@smartrent/ui";

import { View } from "react-native";

import {
  AKVXConfig,
  AuthMode,
  AuthModeOptions,
  ValidAuthModes,
} from "@/modules/controller/configurations/akvx";

interface Props {
  handleSubmit: (
    values: AKVXConfig,
    helpers: FormikHelpers<AKVXConfig>
  ) => Promise<void>;
  config: AKVXConfig;
}

const validationSchema = yup.object().shape({
  host: yup.string().required().label("Host"),
  port: yup.number().required().label("Port"),
  auth_mode: yup.string().oneOf(ValidAuthModes),
  username: yup
    .string()
    .nullable()
    .when("auth_mode", {
      is: (val: AuthMode) => [AuthMode.basic, AuthMode.digest].includes(val),
      then: yup.string().nullable().required().label("Username"),
    }),
  password: yup
    .string()
    .nullable()
    .when("auth_mode", {
      is: (val: AuthMode) => [AuthMode.basic, AuthMode.digest].includes(val),
      then: yup.string().nullable().required().label("Password"),
    }),
});

export const AKVXControllerConfigurationForm = ({
  config,
  handleSubmit,
}: Props) => {
  return (
    <Formik<AKVXConfig>
      initialValues={{
        host: config?.host,
        port: config?.port,
        auth_mode: config?.auth_mode,
        username: config?.username,
        password: config?.password,
      }}
      enableReinitialize={true}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ values, dirty }) => {
        return (
          <Form>
            <VStack spacing={16}>
              <FormikSelectField
                name="auth_mode"
                label={"Auth Mode"}
                options={AuthModeOptions}
                required
              />
              <FormikTextInputField name="host" label={"Host"} required />
              <FormikNumberInputField name="port" label="Port" required />
              {values.auth_mode === AuthMode.basic ||
              values.auth_mode === AuthMode.digest ? (
                <View>
                  <FormikTextInputField name="username" label={"Username"} />
                  <FormikTextInputField name="password" label={"Password"} />
                </View>
              ) : null}
              {dirty && <FormikSubmit label="Set" />}
            </VStack>
          </Form>
        );
      }}
    </Formik>
  );
};
