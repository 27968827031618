import { useState } from "react";
import { View, StyleSheet } from "react-native";

import {
  ColumnOptions,
  DirectionStackProps,
  HStack,
  Pill,
  VStack,
} from "@smartrent/ui";

import { useWindowDimensionsQuery } from "@smartrent/hooks";

import { AccessLevelQueries } from "@/modules/access-level/queries";

import { Site } from "@/modules/site/types";
import { AccessLevel } from "@/modules/access-level/types";

import { Member } from "../types";

interface UseAccessLevelsColumnProps extends Partial<ColumnOptions<any>> {
  site: Site;
}
export const useAccessLevelsColumn = ({
  site: { system_type, id },
  ...props
}: UseAccessLevelsColumnProps): ColumnOptions<Member> | null => {
  const { large: isDesktop } = useWindowDimensionsQuery();

  const accessLevelSelectProps = AccessLevelQueries.tableSelectProps({
    defaultParams: { site_id: id },
  });

  return {
    name: "access_level_id",
    header: "Access Level",
    render: ({ row }: { row: Member }) => {
      return (
        <ExpandableAccessLevelsDisplay
          accessLevels={row.access_levels as AccessLevel[]}
          style={isDesktop ? styles.root : null}
        />
      );
    },
    filterType: {
      type: "selectField",
      getOptionValue: (option: AccessLevel) => {
        return option.id.toString();
      },
      getOptionLabel: (option: AccessLevel) => {
        return option.name;
      },
      ...accessLevelSelectProps,
    },
    ...props,
  };
};

const ExpandableAccessLevelsDisplay = ({
  accessLevels,
  align = "start",
  spacing = 4,
  style,
}: {
  accessLevels: AccessLevel[];
  align?: DirectionStackProps["align"];
  spacing?: DirectionStackProps["spacing"];
  style?: DirectionStackProps["style"];
}) => {
  const [expanded, setExpanded] = useState(false);

  if (accessLevels.length === 0) {
    return <View></View>;
  }

  if (!expanded) {
    return (
      <HStack style={style} spacing={spacing} align={align} wrap>
        <View style={styles.pill}>
          <Pill onPress={undefined}>{accessLevels[0]?.name || "-"}</Pill>
        </View>
        {accessLevels.length > 1 && (
          <Pill onPress={() => setExpanded(!expanded)}>{`...`}</Pill>
        )}
      </HStack>
    );
  }
  return (
    <VStack style={style} spacing={spacing} align={align}>
      {accessLevels.map((access_level: AccessLevel, index: number) => {
        return (
          <Pill onPress={() => setExpanded(!expanded)} key={index}>
            {access_level?.name || "-"}
          </Pill>
        );
      })}
    </VStack>
  );
};

const styles = StyleSheet.create({
  root: { flex: 1 },
  pill: { paddingBottom: 4 },
});
