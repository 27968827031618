import { StyleSheet, View } from "react-native";
import {
  CheckboxBase,
  FilterProps,
  FormikSelectField,
  FormikTextInputField,
  ListQueryResponse,
  Table,
  Typography,
  useTableQuery,
} from "@smartrent/ui";

import { useParams } from "react-router-dom";

import { booleanOptions } from "@/pages/dev/badmagic/workspaces/shared";

import { useAppDrawer } from "@/components/layout/AppDrawer";
import { useDialog } from "@/context/dialog";
import { usePermissions } from "@/context/PolicyContext";

import { TableFilters } from "@/modules/base/table/types";

import { createActionsColumn, CreateButton } from "../base/table/utils";

import { FeatureFlagQueries } from "./queries";
import { FeatureFlag } from "./types";

interface FeatureFlagsTableProps {
  filters: Partial<TableFilters & FeatureFlag>;
}

export const FeatureFlagsTable = ({ filters }: FeatureFlagsTableProps) => {
  const drawer = useAppDrawer();
  const params = useParams();

  const { confirm } = useDialog();
  const [doDelete] = FeatureFlagQueries.useDeleteMutation();
  const [doUpdate] = FeatureFlagQueries.useUpdateMutation();

  const { canCreate, canDelete, canUpdate } =
    usePermissions(FeatureFlagQueries);

  const tableProps = useTableQuery<
    any,
    FeatureFlag,
    ListQueryResponse<FeatureFlag>
  >({
    fetch: FeatureFlagQueries.fetch,
    getQueryKey: ({
      filters: tableFilters,
      page,
      pageSize,
      sortColumn,
      sortDirection,
    }) => [
      FeatureFlagQueries.queryKey,
      {},
      {
        ...filters,
        page,
        per_page: pageSize,
        sort: sortColumn,
        dir: sortDirection,
        ...tableFilters,
      },
    ],
    columns: [
      {
        name: "name",
        header: "Name",
        render: ({ row }) => {
          return <Typography>{row.name}</Typography>;
        },
        sortable: true,
        filter: (props: FilterProps) => {
          return <FormikTextInputField {...props} />;
        },
      },
      {
        name: "type",
        header: "Type",
        render: ({ row }) => {
          return <Typography type="body">{row.type}</Typography>;
        },
        sortable: true,
      },
      {
        name: "enabled",
        header: "Enabled",
        render: ({ row }) => {
          return (
            <CheckboxBase
              ariaRequired={false}
              ariaInvalid={true}
              checked={row.enabled}
              name="enabled"
              onChangeChecked={async () => {
                const confirmed = await confirm({
                  title: row.enabled ? "Disable" : "Enable",
                  description: `Are you sure you want to ${
                    row.enabled ? "disable" : "enable"
                  } this feature flag?`,
                  confirmText: row.enabled
                    ? "Disable Feature Flag"
                    : "Enable Feature Flag",
                  confirmType: "primary",
                  cancelText: "Cancel",
                });
                if (confirmed) {
                  row.enabled = !row.enabled;
                  doUpdate({ id: row.id, values: row });
                }
              }}
            />
          );
        },
        sortable: true,
        filter: (props: FilterProps) => {
          return <FormikSelectField {...props} options={booleanOptions} />;
        },
      },
      createActionsColumn<FeatureFlag>({
        canDelete: (row) => canDelete,
        onDelete: async (row) => {
          const confirmed = await confirm({
            title: "Delete",
            description: "Are you sure you want to delete this feature flag?",
            confirmText: "Delete",
            confirmType: "destructive",
            cancelText: "Cancel",
          });
          if (confirmed) {
            doDelete({ id: row.id });
          }
        },
        canUpdate,
        onUpdate: async (row) =>
          drawer.push(FeatureFlagQueries.queryKey, {
            initialValues: row,
            params,
          }),
      }),
    ],
    defaultPageSize: 10,
  });

  return (
    <View style={styles.tableContainer}>
      <Table<FeatureFlag>
        title="Feature Flags"
        noRecordsText="No Feature Flags Found"
        style={styles.table}
        action={
          <CreateButton
            canCreate={canCreate}
            onCreate={() =>
              drawer.push(FeatureFlagQueries.queryKey, { params })
            }
            text={"Add Feature Flag"}
          />
        }
        {...tableProps}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  tableContainer: { zIndex: -500 },
  table: { zIndex: -500 },
});
