import { Route, Switch, useRouteMatch, useParams } from "react-router-dom";
import { LoadingCard } from "@smartrent/ui";

import { DoorQueries } from "@/modules/door/queries";

import { ErrorPage } from "@/components/ErrorPage";
import { isElevator } from "@/modules/door/types";

import { Site } from "@/modules/site/types";

import { DoorsListPage } from "./DoorsListPage";

import { DoorDetailPage } from "./components/doors/DoorDetailPage";
import { ElevatorDetailPage } from "./components/elevators/ElevatorDetailPage";

interface DoorsRouterProps {
  site: Site;
}

export const DoorsRouter = ({ site }: DoorsRouterProps) => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}`}>
        <DoorsListPage site={site} />
      </Route>
      <Route path={`${path}/:door_id`}>
        <DoorSubRouter site={site} />
      </Route>
    </Switch>
  );
};

const DoorSubRouter = ({ site }: DoorsRouterProps) => {
  const { path } = useRouteMatch();

  const { door_id } = useParams<{ door_id: string }>();
  const { data: door, isLoading } = DoorQueries.useQuery({
    id: parseInt(door_id as string),
  });

  if (isLoading) {
    return <LoadingCard />;
  }
  if (!door) {
    return <ErrorPage title={`Error Not found`} />;
  }

  const DoorComponent = isElevator(door) ? ElevatorDetailPage : DoorDetailPage;

  return (
    <Switch>
      <Route exact path={`${path}`}>
        <DoorComponent door={door} site={site} />
      </Route>
    </Switch>
  );
};
