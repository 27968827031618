import { Route, Switch, useRouteMatch } from "react-router-dom";

import { Site } from "@/modules/site/types";

import { EncodersListPage } from "./EncodersListPage";

interface CardsRouterProps {
  site: Site;
}

export const EncodersRouter = ({ site }: CardsRouterProps) => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}`}>
        <EncodersListPage site={site} />
      </Route>
    </Switch>
  );
};
