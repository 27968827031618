import {
  ColumnOptions,
  DirectionStackProps,
  HStack,
  TypographyProps,
  useTheme,
} from "@smartrent/ui";
import { PencilSolid, Plus, TrashX } from "@smartrent/icons";
import { TouchableOpacity, StyleSheet } from "react-native";

import { ResponsiveButton } from "./ResponsiveButton";

interface CreateActionsColumnProps<T> {
  canUpdate?: boolean | ((row: T) => boolean);
  onUpdate?: (row: T) => void;
  canDelete?: boolean | ((row: T) => boolean);
  onDelete?: (row: T) => void;
  maxWidth?: ColumnOptions<any>["maxWidth"];
  style?: ColumnOptions<any>["style"];
  relativeWidth?: ColumnOptions<any>["relativeWidth"];
  spacing?: DirectionStackProps["spacing"];
  customActions?: (row: T) => any;
  shouldAdjustForPressIcon?: boolean;
}

export function createActionsColumn<T>({
  canUpdate,
  onUpdate,
  canDelete,
  onDelete,
  shouldAdjustForPressIcon,
  maxWidth = 50,
  style,
  spacing = 16,
  relativeWidth,
  customActions,
}: CreateActionsColumnProps<T>) {
  const jsSharedDefaultGap = 12;
  const maxWidthCalculated = shouldAdjustForPressIcon
    ? maxWidth + jsSharedDefaultGap
    : maxWidth;

  return {
    name: "actions",
    header: "",
    maxWidth: maxWidthCalculated,
    relativeWidth,
    style: [
      styles.actionsColumn,
      shouldAdjustForPressIcon ? styles.viewColumnOffset : null,
      style,
    ],
    render: ({ row }: { row: T }) => {
      const isEditVisible =
        onUpdate &&
        (canUpdate === true ||
          (typeof canUpdate === "function" && canUpdate(row)));

      const isDeleteVisible =
        onDelete &&
        (canDelete === true ||
          (typeof canDelete === "function" && canDelete(row)));

      return (
        <HStack spacing={spacing} align="end">
          {customActions && customActions(row)}
          {isEditVisible ? <EditButton onEdit={() => onUpdate(row)} /> : null}
          {isDeleteVisible ? (
            <DeleteButton onDelete={() => onDelete(row)} />
          ) : null}
        </HStack>
      );
    },
    sortable: false,
  };
}

const EditButton = ({ onEdit }: { onEdit: () => void }) => {
  const { colors } = useTheme();

  return (
    <TouchableOpacity onPress={onEdit}>
      <PencilSolid color={colors.gray400} />
    </TouchableOpacity>
  );
};

const DeleteButton = ({ onDelete }: { onDelete: () => void }) => {
  const { colors } = useTheme();

  return (
    <TouchableOpacity onPress={onDelete}>
      <TrashX color={colors.gray400} />
    </TouchableOpacity>
  );
};

export const CreateButton = ({
  canCreate,
  disabled,
  onCreate,
  text,
}: {
  canCreate: boolean;
  disabled?: boolean;
  onCreate: (arg0: any) => void;
  text: string;
}) => {
  if (!canCreate) return null;

  return (
    <ResponsiveButton
      disabled={disabled}
      icon={Plus}
      onPress={onCreate}
      text={text}
    />
  );
};

interface UseTableUtils {
  relativeWidths: Record<string, ColumnOptions["relativeWidth"]>;
  numberOfLines: TypographyProps["numberOfLines"];
  typographyProps: Partial<TypographyProps>;
  typographyPropsDisabled: Partial<TypographyProps>;
}
export const useTableUtils = (): UseTableUtils => {
  return {
    relativeWidths: {
      sm: 80,
      md: 100,
      lg: 120,
      xl: 140,
      columnHalf: 75,
      columnSingle: 150, // js-shared default
      columnDouble: 300,
    },
    numberOfLines: 2,
    typographyProps: {
      numberOfLines: 2,
    },

    typographyPropsDisabled: {
      numberOfLines: 2,
      color: "textDisabled", // "textDisabled" is gray500
    },
  };
};

const styles = StyleSheet.create({
  actionsColumn: { justifyContent: "flex-end" },
  viewColumnOffset: { paddingRight: 12 },
});
