import { Controller } from "@/modules/controller/types";
import { InputsTable } from "@/modules/input/Table";

interface ControllerInputsDisplayProps {
  controller: Controller;
}

export const ControllerInputsDisplay = ({
  controller,
}: ControllerInputsDisplayProps) => {
  return <InputsTable filters={{ controller_id: controller.id }} />;
};
