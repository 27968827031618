import { BaseDrawer } from "../base/Drawer";

import { SiteForm } from "./Form";
import { Site } from "./types";

interface SiteDrawerProps {
  initialValues?: Site;
  params?: any;
}

export const SiteDrawer: React.FC<React.PropsWithChildren<SiteDrawerProps>> = ({
  initialValues,
  params,
}) => {
  return (
    <BaseDrawer
      title={initialValues?.id ? initialValues.name : "New Site"}
      subtitle={initialValues?.id ? "Edit Site" : null}
    >
      <SiteForm initialValues={{ ...initialValues }} params={params} />
    </BaseDrawer>
  );
};
