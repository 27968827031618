import { createContext, useState } from "react";

type Active = null | string;

interface NavigationState {
  active: Active;
  setNavigationState: (arg0: Active) => void;
}

const NavigationStateCtx = createContext<NavigationState>({
  active: null,
  setNavigationState: () => null,
});

const NavigationStateProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [active, setNavigationState] = useState<Active>(null);

  return (
    <NavigationStateCtx.Provider value={{ active, setNavigationState }}>
      {children}
    </NavigationStateCtx.Provider>
  );
};

export { NavigationStateCtx, NavigationStateProvider };
