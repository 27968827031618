import { BaseDrawer } from "../base/Drawer";
import { ScheduleQueries } from "../schedule/queries";

import { ScheduleForm } from "./Form";
import { ScheduleInterval } from "./types";

interface ScheduleIntervalDrawerProps {
  initialValues?: ScheduleInterval;
  params: { schedule_id: number };
}

export const ScheduleIntervalDrawer: React.FC<
  React.PropsWithChildren<ScheduleIntervalDrawerProps>
> = ({ initialValues, params }) => {
  const { data: schedule } = ScheduleQueries.useQuery({
    id: params.schedule_id,
  });

  return (
    <BaseDrawer
      title={
        initialValues?.id ? "Edit Schedule Interval" : "New Schedule Interval"
      }
      subtitle={
        initialValues?.id
          ? `${schedule?.name + " " || ""}Business Hours Schedule`
          : schedule?.name || ""
      }
    >
      <ScheduleForm initialValues={initialValues} params={params} />
    </BaseDrawer>
  );
};
