import { BaseDrawer } from "../base/Drawer";

import { SipAccount } from "./types";
import { SipAccountForm } from "./Form";

interface SipAccountDrawerProps {
  initialValues: SipAccount;
  params: {
    controller_id: number;
  };
}

export const SipAccountDrawer: React.FC<
  React.PropsWithChildren<SipAccountDrawerProps>
> = ({ initialValues, ...props }) => {
  return (
    <BaseDrawer
      title={
        initialValues?.account_number
          ? `Sip account ${initialValues.account_number}`
          : "New Sip Account"
      }
      subtitle={initialValues?.account_number ? "Edit Sip Account" : null}
    >
      <SipAccountForm initialValues={initialValues} {...props} />
    </BaseDrawer>
  );
};
