import { Form, Formik, FormikHelpers } from "formik";
import * as yup from "yup";
import {
  FormikCheckbox,
  FormikNumberInputField,
  FormikSelectField,
  FormikSubmit,
  FormikTextInputField,
  VStack,
} from "@smartrent/ui";

import {
  DataStorageOptions,
  GmtOffsetOptions,
  MPLConfig,
} from "@/modules/controller/configurations/mpl";

interface Props {
  handleSubmit: (
    values: MPLConfig,
    helpers: FormikHelpers<MPLConfig>
  ) => Promise<void>;
  config: MPLConfig;
}

const validationSchema = yup.object().shape({
  num_cardholders: yup.number().label("Num Cardholders"),
  connection_string: yup.string().required().label("Connection String"),
  daylight_savings: yup.bool().label("Daylight Savings"),
  gmt_offset: yup.number().required().label("GMT Offset"),
  transactions: yup.number().label("Transactions"),
  activation_date: yup.string().label("Activation Date"),
  deactivation_date: yup.string().label("Deactivation Date"),
  use_limit: yup.bool().label("Use Limit"),
});

export const MPLControllerConfigurationForm = ({
  config,
  handleSubmit,
}: Props) => {
  return (
    <Formik<MPLConfig>
      initialValues={{
        num_cardholders: config?.num_cardholders,
        connection_string: config?.connection_string,
        daylight_savings: config?.daylight_savings,
        gmt_offset: config?.gmt_offset,
        transactions: config?.transactions,
        activation_date: config?.activation_date,
        deactivation_date: config?.deactivation_date,
        use_limit: config?.use_limit,
      }}
      enableReinitialize={true}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ dirty }) => {
        return (
          <Form>
            <VStack spacing={16}>
              <FormikTextInputField
                name="connection_string"
                label={"Connection String"}
              />
              <FormikCheckbox
                name="daylight_savings"
                label="Daylight Savings"
              />
              <FormikSelectField
                name="gmt_offset"
                label="Gmt Offset"
                options={GmtOffsetOptions}
                required
              />
              <FormikNumberInputField
                name="num_cardholders"
                label="Num Cardholders"
              />
              <FormikNumberInputField
                name="transactions"
                label="Transactions"
              />
              <FormikCheckbox name="use_limit" label="Use Limit" />
              <FormikSelectField
                name="activation_date"
                label="Activation Date"
                options={DataStorageOptions}
                required
              />
              <FormikSelectField
                name="deactivation_date"
                label="Deactivation Date"
                options={DataStorageOptions}
                required
              />
              {dirty && <FormikSubmit label="Set" />}
            </VStack>
          </Form>
        );
      }}
    </Formik>
  );
};
