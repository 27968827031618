import {
  Box,
  Drawer,
  DrawerContent,
  DrawerHeader,
  FlatListItemSeparator,
  ListItemLabelValue,
  ListItemTouchableContainer,
  Typography,
  useTheme,
} from "@smartrent/ui";

import { useState } from "react";

import { ArrowDown, ArrowRight } from "@smartrent/icons";

import { useAppDrawer } from "@/components/layout/AppDrawer";

import { formatDateTime, DATE_TIME_FORMAT } from "@/lib/formatters";

import { Paths } from "@/lib/path";

import { UserActivityLogListItemLink } from "./components/UserActivityLogListItemLink";

import { UserActivityLog } from "./types";
import { UserActivityLogListItem } from "./components/UserActivityLogListItem";

interface UserActivityLogDrawerProps {
  initialValues: UserActivityLog;
  params: {
    site_id: number;
  };
}

export const UserActivityLogDrawer: React.FC<
  React.PropsWithChildren<UserActivityLogDrawerProps>
> = ({ initialValues: userActivityLog }) => {
  const { activeRoute, reset } = useAppDrawer();
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);
  const { colors } = useTheme();

  return (
    <Drawer
      testID={`ual-${userActivityLog.id}-drawer`}
      anchor="right"
      open={!!activeRoute}
      onClose={reset}
    >
      <>
        <DrawerHeader testID="drawer-header" title="User Activity Details" />

        <DrawerContent>
          <UserActivityLogListItem
            label="User"
            value={userActivityLog.user.name}
          />
          <FlatListItemSeparator />

          <UserActivityLogListItem
            label="Action"
            value={userActivityLog.action}
          />
          <FlatListItemSeparator />

          <UserActivityLogListItem
            label="Category"
            value={userActivityLog.category}
          />
          <FlatListItemSeparator />

          {userActivityLog?.schedule?.name ? (
            <>
              <UserActivityLogListItemLink
                href={Paths.scheduleViewPage(
                  userActivityLog.site.id,
                  userActivityLog.schedule.id
                )}
                label="Schedule"
                value={userActivityLog.schedule.name}
                numberOfLines={2}
              />
              <FlatListItemSeparator />
            </>
          ) : null}

          <UserActivityLogListItem
            label="Date"
            value={formatDateTime(
              `${userActivityLog.inserted_at}` as string,
              userActivityLog.site?.timezone || "",
              DATE_TIME_FORMAT
            )}
          />
          <FlatListItemSeparator />

          <ListItemTouchableContainer
            onPress={() => setIsDetailsOpen(!isDetailsOpen)}
            rightDetail={
              isDetailsOpen ? (
                <ArrowDown color={colors.primary} />
              ) : (
                <ArrowRight color={colors.primary} />
              )
            }
          >
            <ListItemLabelValue
              value={""}
              label={"Details"}
              numberOfLines={2}
            />
          </ListItemTouchableContainer>

          {isDetailsOpen ? (
            <Box>
              <Typography>
                {JSON.stringify(userActivityLog.details, null, 2)}
              </Typography>
            </Box>
          ) : null}
          <FlatListItemSeparator />
        </DrawerContent>
      </>
    </Drawer>
  );
};
