import { FormSelectField, FormSelectFieldProps } from "@smartrent/forms";

import { ControllerQueries } from "@/modules/controller/queries";
import { Controller } from "@/modules/controller/types";

interface ControllerFormSelectFieldProps
  extends Partial<FormSelectFieldProps<Controller>> {
  site_id: number;
  name: string;
  label: string;
  filterParams?: any;
  initialValue?: string;
}

export const ControllerFormSelectField: React.FC<
  React.PropsWithChildren<ControllerFormSelectFieldProps>
> = ({ site_id, filterParams, initialValue, ...formSelectFieldProps }) => {
  const selectQueryProps = ControllerQueries.select({
    filterParams: { site_id, ...filterParams },
    initialValue,
  });

  return (
    <FormSelectField<Controller>
      {...selectQueryProps}
      {...formSelectFieldProps}
    />
  );
};
