import { VStack, FormikTextInputField } from "@smartrent/ui";
import { Form } from "formik";
import * as yup from "yup";

import { BaseForm } from "../base/Form";

import { Schedule } from "./types";
import { ScheduleQueries } from "./queries";

const validationSchema = yup.object().shape({
  name: yup.string().required("Required").max(40),
});

interface ScheduleProps {
  initialValues?: Partial<Schedule>;
  params: { site_id: number };
}

export const ScheduleForm = ({ initialValues, params }: ScheduleProps) => {
  return (
    <BaseForm<Schedule & { site_id: number }>
      initialValues={{
        ...initialValues,
        site_id: params.site_id,
      }}
      validationSchema={validationSchema}
      QueryClient={ScheduleQueries}
      params={params}
    >
      {() => (
        <Form>
          <VStack spacing={16}>
            <FormikTextInputField name="name" label="Name" required />
          </VStack>
        </Form>
      )}
    </BaseForm>
  );
};
