import {
  FilterProps,
  FormikNumberInputField,
  FormikSelectField,
  FormikTextInputField,
  ListQueryResponse,
  Table,
  Typography,
  useTableQuery,
} from "@smartrent/ui";

import { useCallback } from "react";

import { useHistory, useParams } from "react-router-dom";

import { toTitleCase } from "@/lib/helpers";

import {
  StatusDisplay,
  SyncStatus,
} from "@/components/alloy-access/StatusDisplay";

import { useDialog } from "@/context/dialog";

import { Paths } from "@/lib/path";

import { useAppDrawer } from "@/components/layout/AppDrawer";

import { usePermissions } from "@/context/PolicyContext";

import { TableFilters } from "@/modules/base/table/types";

import { Site } from "../site/types";

import { CreateButton, createActionsColumn } from "../base/table/utils";

import { CardFormat, CardFormatOptions } from "./types";
import { CardFormatQueries } from "./queries";

interface CardFormatTableFilters extends TableFilters {
  include_deleted?: boolean;
  site_id: number;
}

interface CardFormatsTableProps {
  site: Site;
  filters: CardFormatTableFilters;
}

export const CardFormatsTable = ({ site, filters }: CardFormatsTableProps) => {
  const drawer = useAppDrawer();
  const params = useParams();

  const { queryKey, useDeleteMutation, fetch } = CardFormatQueries;
  const { canCreate, canDelete, canUpdate } = usePermissions({ queryKey });

  const [deleteCardFormat] = useDeleteMutation();
  const { confirm } = useDialog();
  const history = useHistory();

  const onDelete = useCallback(
    async (cardFormat: CardFormat) => {
      if (cardFormat?.card_count && cardFormat?.card_count > 0) {
        const confirmed = await confirm({
          title: "Delete",
          description:
            "You cannot delete this Card Format until all credentials that use it have been deleted. Please delete the associated credentials first before attempting to delete this Card Format.",
          confirmText: "View Members with Assigned Cards",
          confirmType: "destructive",
          cancelText: "Cancel",
        });

        if (confirmed) {
          history.push(
            `${Paths.membersListPage(site.id)}?assigned_card_format=${
              cardFormat.id
            }`
          );
        }
      } else {
        const confirmed = await confirm({
          title: "Delete",
          description: "Are you sure you want to delete this Card Format?",
          confirmText: "Delete",
          confirmType: "destructive",
          cancelText: "Cancel",
        });

        if (confirmed) {
          await deleteCardFormat({ id: cardFormat.id });
        }
      }
    },
    [confirm, deleteCardFormat, history, site.id]
  );

  const tableProps = useTableQuery<
    any,
    CardFormat,
    ListQueryResponse<CardFormat>
  >({
    fetch,
    getQueryKey: ({
      filters: tableFilters,
      page,
      pageSize,
      sortColumn,
      sortDirection,
    }) => [
      queryKey,
      {},
      {
        ...filters,
        page,
        per_page: pageSize,
        sort: sortColumn,
        dir: sortDirection,
        ...tableFilters,
      },
    ],
    columns: [
      {
        name: "name_like",
        header: "Name",
        render: ({ row }) => {
          return <Typography>{toTitleCase(row.name)}</Typography>;
        },
        filter: (props: FilterProps) => {
          return <FormikTextInputField {...props} />;
        },
      },
      {
        name: "type",
        header: "Type",
        render: ({ row }) => {
          return <Typography>{toTitleCase(row.type)}</Typography>;
        },
      },
      {
        name: "number_range",
        header: "Number Range",
        render: ({ row }) => {
          return (
            <Typography>
              {row.first_number}-{row.last_number}
            </Typography>
          );
        },
        filter: (props: FilterProps) => {
          return (
            <FormikNumberInputField {...props} label="Ranges contains number" />
          );
        },
      },
      {
        name: "facility_code",
        header: "Facility Code",
        render: ({ row }) => {
          return <Typography>{row.facility_code}</Typography>;
        },
        sortable: true,
        filter: (props: FilterProps) => {
          return <FormikTextInputField {...props} />;
        },
      },
      {
        name: "format",
        header: "Format",
        render: ({ row }) => {
          return <Typography>{row.format}</Typography>;
        },
        sortable: true,
        filter: (props: FilterProps) => {
          return <FormikSelectField options={CardFormatOptions} {...props} />;
        },
      },
      {
        name: "format_number",
        header: "Format Number",
        render: ({ row }) => {
          return <Typography>{row.format_number}</Typography>;
        },
        sortable: true,
        filter: (props: FilterProps) => {
          return <FormikTextInputField {...props} />;
        },
      },
      {
        name: "offset",
        header: "Offset",
        render: ({ row }) => {
          return <Typography>{row.offset}</Typography>;
        },
      },
      {
        name: "status",
        header: "Sync Status",
        render: ({ row }) => {
          const unsyncedControllers = (row.controllers || []).filter(
            (cardFormatController) => !cardFormatController.synced_at
          );
          return (
            <StatusDisplay
              status={
                unsyncedControllers.length == 0
                  ? SyncStatus.Synced
                  : SyncStatus.NotSynced
              }
              message={`${unsyncedControllers.length} Unsynced`}
            />
          );
        },
        sortable: false,
      },
      {
        name: "pending_actions",
        header: "Pending Actions",
        render: ({ row }) => {
          return <Typography>{row.pending_action}</Typography>;
        },
      },
      createActionsColumn<CardFormat>({
        canDelete,
        onDelete,
        canUpdate,
        onUpdate: async (row) =>
          drawer.push(queryKey, { initialValues: row, params }),
      }),
    ],
    defaultPageSize: 10,
  });
  return (
    <Table<CardFormat>
      title={"Card Formats"}
      noRecordsText={"No Card Formats Found"}
      action={
        <CreateButton
          canCreate={canCreate}
          onCreate={() => drawer.push(queryKey, { params })}
          text={"Add Card Format"}
        />
      }
      {...tableProps}
    />
  );
};
