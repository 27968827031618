import { BaseDrawer } from "../base/Drawer";

import { OutputForm } from "./Form";
import { Output } from "./types";

interface OutputDrawerProps {
  initialValues?: Output;
  params?: any;
}

export const OutputDrawer: React.FC<
  React.PropsWithChildren<OutputDrawerProps>
> = ({ initialValues, params }) => {
  return (
    <BaseDrawer
      title={initialValues?.id ? initialValues?.name : "New Output"}
      subtitle={initialValues?.id ? "Edit Output" : null}
    >
      <OutputForm initialValues={{ ...initialValues }} params={params} />
    </BaseDrawer>
  );
};
