import { StyleSheet, View } from "react-native";

import { Logo } from "@/components/alloy-access/Logo";
import { LoginForm } from "@/modules/auth/LoginForm";

export const LoginPage = () => {
  return (
    <View style={styles.root}>
      <View style={styles.container}>
        <Logo />
        <LoginForm />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  container: {
    padding: 16,
    minWidth: 350,
  },
});
