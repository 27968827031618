import { useState, useContext, useCallback } from "react";
import { View, StyleSheet, Image, TouchableOpacity } from "react-native";
import { Typography, useTheme, VStack } from "@smartrent/ui";
import { ChevronRight, ChevronDown } from "@smartrent/icons";

import { NavigationStateCtx } from "@/context/NavigationStateContext";

import { LinkGroupData, LinkData } from "./NavDrawer";
import { NavItem } from "./NavItem";

interface NavGroupProps extends LinkGroupData {
  close?: VoidFunction;
}

export const NavGroup = ({ icon, label, items, close }: NavGroupProps) => {
  const { colors } = useTheme();
  const [showItems, setShowItems] = useState<boolean>(false);
  const { active, setNavigationState } = useContext(NavigationStateCtx);

  const setNavigationStateCallback = useCallback(
    () => setNavigationState(label),
    [label, setNavigationState]
  );

  return (
    <VStack spacing={4} style={styles.navGroup}>
      <TouchableOpacity
        onPress={setShowItems.bind(null, !showItems)}
        activeOpacity={1}
      >
        <View style={styles.headerContainer}>
          <Image
            style={[
              styles.headerImage,
              active == label
                ? { tintColor: colors.brand400 }
                : { tintColor: colors.gray700 },
            ]}
            resizeMode="contain"
            source={icon}
          />
          <Typography
            style={[
              styles.headerText,
              {
                color: colors.gray200,
              },
            ]}
          >
            {label}
          </Typography>
          <View style={styles.chevronContainer}>
            {showItems ? (
              <ChevronDown style={styles.chevron} color={colors.gray200} />
            ) : (
              <ChevronRight style={styles.chevron} color={colors.gray200} />
            )}
          </View>
        </View>
      </TouchableOpacity>
      <View style={showItems ? styles.itemsShown : styles.itemsHidden}>
        {items.map((linkData: LinkData, index: number) => {
          return (
            <NavItem
              close={close}
              key={`${linkData?.url}-${index}`}
              setNavigationState={setNavigationStateCallback}
              {...linkData}
            />
          );
        })}
      </View>
    </VStack>
  );
};

const styles = StyleSheet.create({
  headerContainer: {
    flexDirection: "row",
    marginLeft: 16,
    marginTop: 4,
  },
  navGroup: {
    padding: 0,
  },
  headerText: {
    flexGrow: 1,
    paddingLeft: 8,
  },
  headerImage: {
    marginTop: 4,
    height: 16,
    width: 16,
  },
  itemsHidden: {
    height: 0,
    overflow: "hidden",
  },
  itemsShown: {
    height: "auto",
  },
  chevronContainer: { marginRight: 16 },
  chevron: {
    height: 16,
    marginTop: 4,
  },
});
