import { StyleSheet, View } from "react-native";
import { useParams } from "react-router-dom";

import {
  Typography,
  useTableQuery,
  ListQueryResponse,
  Table,
} from "@smartrent/ui";

import { useAppDrawer } from "@/components/layout/AppDrawer";

import { useDialog } from "@/context/dialog";

import { usePermissions } from "@/context/PolicyContext";

import { TableFilters } from "@/modules/base/table/types";

import { QueryKeys } from "@/types";

import { humanize } from "@/lib/helpers";

import { ControllerProtocols } from "../controller/types";

import { ControllerQueries } from "../controller/queries";

import { Site } from "../site/types";

import {
  CreateButton,
  createActionsColumn,
  useTableUtils,
} from "../base/table/utils";

import { PanelQueries } from "./queries";
import { Panel, PanelModels, UndeletablePanels } from "./types";
import { WebsocketViewPanelStatus } from "./components/WebsocketViewPanelStatus";

interface PanelTableFilters extends TableFilters {
  controller_id: number;
}
interface PanelsTableProps {
  title?: string;
  filters: PanelTableFilters;
  site: Site;
  minWidth?: number;
}

export const PanelsTable = ({ filters, site }: PanelsTableProps) => {
  const drawer = useAppDrawer();
  const params = useParams();
  const { confirm } = useDialog();
  const { canCreate, canDelete, canUpdate } = usePermissions(PanelQueries);

  const { data: controller } = ControllerQueries.useQuery({
    id: filters?.controller_id,
  });

  const [deletePanelMutation] = PanelQueries.useDeleteMutation({
    onSuccess: (queryClient, data, variables, context) => {
      queryClient.invalidateQueries([QueryKeys.CascadingOptions]);
    },
  });

  const { relativeWidths, numberOfLines } = useTableUtils();

  const tableProps = useTableQuery<any, Panel, ListQueryResponse<Panel>>({
    fetch: PanelQueries.fetch,
    getQueryKey: ({
      filters: tableFilters,
      page,
      pageSize,
      sortColumn,
      sortDirection,
    }) => [
      QueryKeys.Panels,
      {},
      {
        ...filters,
        page,
        per_page: pageSize,
        // default sort helps prevent table rows moving whenever we invalidate the fetch query in the update panel mutations
        sort: sortColumn ? sortColumn : "inserted_at",
        dir: sortDirection,
        ...tableFilters,
      },
    ],
    columns: [
      {
        name: "name",
        header: "Name",
        style: [styles.column, { flexGrow: 48 }],
        render: ({ row }) => {
          // This component is crucial for maintaining the real-time status of the table data.
          // It contains a hook that connects to the 'panel:status' topic on the websocket channel.
          // Whenever the backend pushes a message to this topic, the hook invalidates the React Query cache for the table,
          // signaling that the data needs to be updated.
          // The reason this logic is encapsulated within this component is because we need the panel.id to open the websocket channel.
          // This id is only available once the table query has fetched the records.
          // Therefore, our only entry point to the records is via this 'columns' property on the useTableQuery hook.
          // TODO: Request js-shared to provide a way to update the query with new data from websockets.

          return (
            <WebsocketViewPanelStatus
              panel={row}
              typographyProps={{ numberOfLines }}
            />
          );
        },
      },

      {
        name: "model",
        header: "Model",
        style: [styles.column, { flexGrow: 0 }],
        render: ({ row }) => {
          return (
            <Typography numberOfLines={numberOfLines} type="body">
              {row.model}
            </Typography>
          );
        },
      },
      {
        name: "encryption",
        header: "Encryption",
        style: [styles.column, { flexGrow: 300 }],
        render: ({ row }) => (
          <View style={styles.columnContainer}>
            <Typography numberOfLines={1} type="body">
              {humanize(row.encryption_key_status)}
            </Typography>

            <Typography numberOfLines={1} type="body">
              {row.encryption_config}
            </Typography>
          </View>
        ),
      },
      {
        name: "firmware",
        header: "Firmware",
        style: [styles.column, { flexGrow: 0 }],
        render: ({ row }) => (
          <Typography numberOfLines={1} type="body">
            {row.firmware_version}
          </Typography>
        ),
      },
      {
        name: "address",
        header: "Address",
        style: [styles.column, { flexGrow: 0 }],
        render: ({ row }) => {
          return (
            <Typography numberOfLines={numberOfLines} type="body">
              {row.address}
            </Typography>
          );
        },
      },

      createActionsColumn<Panel>({
        maxWidth: relativeWidths.columnHalf,
        canDelete: (row) =>
          canDelete && !UndeletablePanels.includes(row.model as PanelModels),
        onDelete: async (row) => {
          const confirmed = await confirm({
            title: "Delete",
            description: "Are you sure you want to delete this Panel?",
            confirmText: "Delete",
            confirmType: "destructive",
            cancelText: "Cancel",
          });

          if (confirmed) {
            deletePanelMutation({
              id: row.id,
            });
          }
        },
        canUpdate:
          canUpdate && controller?.protocol === ControllerProtocols.mpl,
        onUpdate: async (row) =>
          drawer.push(PanelQueries.queryKey, { initialValues: row, params }),
      }),
    ],
    defaultPageSize: 10,
  });

  return (
    <Table<Panel>
      title={"Panels"}
      noRecordsText={"No Panels Found"}
      action={
        <CreateButton
          canCreate={
            canCreate && controller?.protocol === ControllerProtocols.mpl
          }
          onCreate={() => drawer.push(PanelQueries.queryKey, { params })}
          text={`Add Panel`}
        />
      }
      {...tableProps}
    />
  );
};

const styles = StyleSheet.create({
  column: {
    paddingRight: 16,
  },
  columnContainer: { flexShrink: 1 },
});
