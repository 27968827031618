import { useContext, useEffect } from "react";

import { Site } from "@/modules/site/types";
import { CardFormatsTable } from "@/modules/card-format/Table";
import { PageCtx } from "@/context/PageContext";
import { Paths } from "@/lib/path";

interface CardsListPageProps {
  site: Site;
}

export const CardsListPage = ({ site }: CardsListPageProps) => {
  const { addBreadcrumb, breadCrumbs } = useContext(PageCtx);

  useEffect(() => {
    addBreadcrumb({
      label: `Cards`,
      to: Paths.cardsListPath(site.id),
    });
  }, [breadCrumbs, addBreadcrumb, site.id]);

  return (
    <CardFormatsTable
      filters={{ site_id: site.id, include_deleted: true }}
      site={site}
    />
  );
};
