import { BaseDrawer } from "../base/Drawer";

import { SiteImportForm } from "./Form";
import { SiteImport, SiteImportStatus } from "./types";

interface SiteImportDrawerProps {
  initialValues?: SiteImport;
  params: { site_id: number };
}

const getImportStep = (siteImport?: SiteImport) => {
  if (!siteImport?.id) {
    return "Step 1";
  }
  if (siteImport?.status === SiteImportStatus.new) {
    return "Step 2";
  }
  return "Import Complete";
};

export const SiteImportDrawer: React.FC<
  React.PropsWithChildren<SiteImportDrawerProps>
> = ({ initialValues, params }) => {
  return (
    <BaseDrawer title={"Site Import"} subtitle={getImportStep(initialValues)}>
      <SiteImportForm initialValues={{ ...initialValues }} params={params} />
    </BaseDrawer>
  );
};
