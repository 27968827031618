import { useState } from "react";
import { History, Location } from "history";

import { endOfDay } from "date-fns";

import { DatePickerField } from "@smartrent/forms";
import { HStack } from "@smartrent/ui";
import { Calendar } from "@smartrent/icons";

import { qsFromLocation, updateQS } from "@/lib/helpers";

type Props = {
  location: Location;
  history: History;
};

export default ({ location, history }: Props) => {
  const queryString = qsFromLocation(location);

  const [minDate, setMinDate] = useState<string | null>(
    (queryString.start_datetime as string) || null
  );
  const [maxDate, setMaxDate] = useState<string | null>(
    (queryString.end_datetime as string) || null
  );

  const updateQueryString = (
    minDate: string | null,
    maxDate: string | null
  ) => {
    updateQS({
      history,
      update: {
        ...queryString,
        start_datetime: minDate,
        end_datetime: maxDate,
      },
      location,
      omitEmpty: true,
    });
  };
  return (
    <HStack spacing={8}>
      <DatePickerField
        label="Start Date"
        value={minDate ? new Date(minDate) : undefined}
        onChangeDate={(date) => {
          if (date) {
            setMinDate(date.toISOString());
            updateQueryString(date.toISOString(), maxDate);
          } else {
            setMinDate(null);
            updateQueryString(null, maxDate);
          }
        }}
        StartAdornment={Calendar}
      />
      <DatePickerField
        label="End Date"
        value={maxDate ? new Date(maxDate) : undefined}
        onChangeDate={(date) => {
          if (date) {
            setMaxDate(endOfDay(date).toISOString());
            updateQueryString(minDate, endOfDay(date).toISOString());
          } else {
            setMaxDate(null);
            updateQueryString(minDate, null);
          }
        }}
        StartAdornment={Calendar}
      />
    </HStack>
  );
};
