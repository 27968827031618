import { ActionSheetActions } from "@smartrent/ui";
import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useRouteMatch } from "react-router-dom";

import { LinkGroupData } from "@/components/alloy-access/NavDrawer";
import { SystemHealthIssue } from "@/modules/system-health/types";
import { Site } from "@/modules/site/types";

interface PageContext {
  addBreadcrumb: (breadcrumb: Breadcrumb) => void;
  addBreadcrumbs: (breadcrumbs: Breadcrumb[]) => void;
  breadCrumbs: Breadcrumb[];
  actions: ActionSheetActions;
  setActions: (actions: ActionSheetActions) => void;
  setBreadcrumbs: (breadcrumbs: Breadcrumb[]) => void;
  navigationLinks: LinkGroupData[];
  setNavigationLinks: (navigationLinks: LinkGroupData[]) => void;
  systemIssues: SystemHealthIssue[] | null;
  setSystemIssues: (issues: SystemHealthIssue[] | null) => void;
  site: Site | null;
  setSite: (site: Site | null) => void;
}

const PageCtx = createContext<PageContext>({
  addBreadcrumb: () => null,
  addBreadcrumbs: () => null,
  breadCrumbs: [],
  setActions: () => null,
  setBreadcrumbs: () => null,
  navigationLinks: [],
  setNavigationLinks: () => null,
  actions: [],
  systemIssues: [],
  setSystemIssues: () => null,
  site: null,
  setSite: () => null,
});

interface Breadcrumb {
  label: string;
  to: string;
}

const PageContextProvider = ({ children }: { children: React.ReactNode }) => {
  const { params } = useRouteMatch();
  const [breadCrumbs, setBreadcrumbs] = useState<Breadcrumb[]>([]);
  const [actions, setActions] = useState<ActionSheetActions>([]);
  const [navigationLinks, setNavigationLinks] = useState<LinkGroupData[]>([]);
  const [systemIssues, setSystemIssues] = useState<SystemHealthIssue[] | null>(
    null
  );
  const [site, setSite] = useState<Site | null>(null);

  const uniqueToValues = useMemo(
    () => new Set(breadCrumbs.map((breadcrumb) => breadcrumb.to)),
    [breadCrumbs]
  );

  const addBreadcrumb = useCallback(
    (breadcrumb: Breadcrumb) => {
      if (uniqueToValues.has(breadcrumb.to)) return;
      setBreadcrumbs([...breadCrumbs, breadcrumb]);
    },
    [breadCrumbs, uniqueToValues]
  );

  const addBreadcrumbs = useCallback(
    (newBreadcrumbs: Breadcrumb[]) => {
      const filteredBreadCrumbs = newBreadcrumbs.filter(
        (b) => !uniqueToValues.has(b.to)
      );
      if (!filteredBreadCrumbs?.length) return;
      setBreadcrumbs([...breadCrumbs, ...filteredBreadCrumbs]);
    },
    [breadCrumbs, uniqueToValues]
  );

  const setActionSheetActions = (newActions: ActionSheetActions) => {
    if (actions.length > 0) return;
    setActions(newActions);
  };

  useEffect(() => {
    setBreadcrumbs([]);
    setActions([]);
  }, [params]);

  return (
    <PageCtx.Provider
      value={{
        addBreadcrumb,
        addBreadcrumbs,
        breadCrumbs,
        setBreadcrumbs,
        actions,
        navigationLinks,
        setNavigationLinks,
        setActions: setActionSheetActions,
        systemIssues,
        setSystemIssues,
        site,
        setSite,
      }}
    >
      {children}
    </PageCtx.Provider>
  );
};

export { PageCtx, PageContextProvider };
