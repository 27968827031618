import { VStack, FormikTextInputField } from "@smartrent/ui";
import { Form } from "formik";
import * as yup from "yup";

import { BaseForm } from "../base/Form";

import { AccessLevel } from "./types";
import { AccessLevelQueries } from "./queries";

const validationSchema = yup.object().shape({
  name: yup.string().required("Required").max(40),
  remote_id: yup.string().optional().nullable().max(40),
});

interface ApiApplicationFormProps {
  initialValues?: Partial<AccessLevel>;
  params: { site_id: number };
}

export const AccessLevelForm = ({
  initialValues,
  params,
}: ApiApplicationFormProps) => {
  return (
    <BaseForm<AccessLevel>
      initialValues={{
        site_id: params.site_id,
        name: initialValues?.name,
        remote_id: initialValues?.remote_id,
        ...initialValues,
      }}
      validationSchema={validationSchema}
      QueryClient={AccessLevelQueries}
      params={params}
    >
      {() => (
        <Form>
          <VStack spacing={16}>
            <FormikTextInputField name="name" label="Name" required />
            <FormikTextInputField name="remote_id" label="Remote ID" />
          </VStack>
        </Form>
      )}
    </BaseForm>
  );
};
