import { VStack, FormikSelectField, Typography } from "@smartrent/ui";
import { Form } from "formik";
import * as yup from "yup";

import { StyleSheet, View } from "react-native";

import { BaseForm, FormProps } from "../base/Form";
import { ControllerProtocols } from "../controller/types";

import { OutputQueries } from "./queries";
import { Output, OutputModeOptions, ValidOutputModes } from "./types";

export const OutputForm = ({ initialValues, params }: FormProps<Output>) => {
  switch (initialValues.panel?.controller?.protocol) {
    case ControllerProtocols.mpl:
      return <MPLOutputForm initialValues={initialValues} params={params} />;
    case ControllerProtocols.smrtp:
      return <SMRTPOutputForm initialValues={initialValues} params={params} />;
    default:
      return (
        <View style={styles.invalidControllerContainer}>
          <Typography>This controllers outputs aren't modifyable</Typography>
        </View>
      );
  }
};

const MPLOutputForm = ({ initialValues, params }: FormProps<Output>) => {
  const validationSchema = yup.object().shape({
    mode: yup.string().required().oneOf(ValidOutputModes),
  });

  return (
    <BaseForm<Output>
      initialValues={{
        ...initialValues,
      }}
      validationSchema={validationSchema}
      QueryClient={OutputQueries}
      params={params}
    >
      {() => (
        <Form>
          <VStack spacing={16}>
            <FormikSelectField
              name={"mode"}
              label={"Mode"}
              options={OutputModeOptions}
            />
          </VStack>
        </Form>
      )}
    </BaseForm>
  );
};

const SMRTPOutputForm = ({ initialValues, params }: FormProps<Output>) => {
  const validationSchema = yup.object().shape({});

  return (
    <BaseForm<Output>
      initialValues={{
        ...initialValues,
      }}
      validationSchema={validationSchema}
      QueryClient={OutputQueries}
      params={params}
    >
      {() => (
        <Form>
          <VStack spacing={16}>
            <FormikSelectField
              name={"mode"}
              label={"Mode"}
              options={OutputModeOptions}
            />
          </VStack>
        </Form>
      )}
    </BaseForm>
  );
};

const styles = StyleSheet.create({
  invalidControllerContainer: { padding: 16, alignItems: "center" },
});
