import { truncateString } from "@/lib/helpers";

import { BaseDrawer } from "../base/Drawer";

import { Member } from "./types";
import { MemberForm } from "./Form";

interface MemberDrawerProps {
  initialValues: Member;
  params: { site_id: number };
}

export const MemberDrawer: React.FC<
  React.PropsWithChildren<MemberDrawerProps>
> = ({ initialValues, params }) => {
  return (
    <BaseDrawer
      title={
        initialValues?.id
          ? truncateString(
              `${initialValues.first_name} ${initialValues.last_name}`
            )
          : "Add Member"
      }
      subtitle={initialValues?.id ? "Edit Member" : null}
    >
      <MemberForm initialValues={initialValues} params={params} />
    </BaseDrawer>
  );
};
