import { VStack, FormikTextInputField, FormikCheckbox } from "@smartrent/ui";
import { Form } from "formik";
import * as yup from "yup";

import { FormProps, BaseForm } from "../base/Form";

import { FeatureFlagQueries } from "./queries";
import { FeatureFlag } from "./types";

const validationSchema = yup.object().shape({
  name: yup.string().required("Required"),
  enabled: yup.boolean().required("Required"),
});

export const FeatureFlagForm = ({
  initialValues,
  params,
}: FormProps<FeatureFlag>) => {
  if (initialValues.enabled === undefined) {
    initialValues.enabled = false;
  }

  return (
    <BaseForm<FeatureFlag>
      initialValues={{
        ...initialValues,
      }}
      validationSchema={validationSchema}
      QueryClient={FeatureFlagQueries}
      params={params}
      saveText="Save"
    >
      {() => (
        <Form>
          <VStack spacing={16}>
            <FormikTextInputField name="name" label="Name" required />
            <FormikCheckbox name="enabled" label="Enabled" required />
          </VStack>
        </Form>
      )}
    </BaseForm>
  );
};
