import { useCallback, useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";

import { useHistory, useParams } from "react-router-dom";

import {
  FilterProps,
  FormikTextInputField,
  ListQueryResponse,
  Table,
  Typography,
  useTableQuery,
} from "@smartrent/ui";

import {
  StatusDisplay,
  SyncStatus,
} from "@/components/alloy-access/StatusDisplay";
import { useAppDrawer } from "@/components/layout/AppDrawer";

import { useDialog } from "@/context/dialog";
import { usePermissions } from "@/context/PolicyContext";
import { PathActions, Paths } from "@/lib/path";

import { TableFilters } from "@/modules/base/table/types";

import { createActionsColumn, CreateButton } from "../base/table/utils";

import { SiteQueries } from "../site/queries";
import { SystemTypes } from "../site/types";

import { AccessLevelQueries } from "./queries";
import { AccessLevel } from "./types";

interface AccessLevelSiteFilters extends Partial<AccessLevel> {
  site_id: number;
}

type AccessLevelFilters = TableFilters & AccessLevelSiteFilters;

interface AccessLevelsTableProps {
  filters: AccessLevelFilters;
}

export const AccessLevelsTable = ({
  filters: filterParams,
}: AccessLevelsTableProps) => {
  const { data: site } = SiteQueries.useQuery({
    id: Number(filterParams.site_id),
  });

  const [isSalto, setIsSalto] = useState(
    site?.system_type === SystemTypes.Salto
  );
  const [isSchlage, setIsSchlage] = useState(
    site?.system_type === SystemTypes.Schlage
  );

  useEffect(() => {
    setIsSalto(site?.system_type === SystemTypes.Salto);
    setIsSchlage(site?.system_type === SystemTypes.Schlage);
  }, [site]);

  const [isAtMaxForSchlage, setIsAtMaxForSchlage] = useState(false);

  const drawer = useAppDrawer();
  const history = useHistory();
  const params = useParams();

  const { canCreate, canDelete, canUpdate, canView } =
    usePermissions(AccessLevelQueries);

  const onCreate = useCallback(
    () => drawer.push(AccessLevelQueries.queryKey, { params }),
    [drawer, params]
  );

  const { confirm } = useDialog();
  const [deleteQuery] = AccessLevelQueries.useDeleteMutation();

  const onDelete = useCallback(
    async (row: AccessLevel) => {
      const confirmed = await confirm({
        title: "Delete",
        description: "Are you sure you want to delete this Entry?",
        confirmText: "Delete",
        confirmType: "destructive",
        cancelText: "Cancel",
      });

      if (confirmed) {
        await deleteQuery({ id: row.id });
      }
    },
    [confirm, deleteQuery]
  );

  const onUpdate = useCallback(
    (row: AccessLevel) =>
      drawer.push(AccessLevelQueries.queryKey, {
        initialValues: { ...row },
        params,
      }),
    [drawer, params]
  );

  const onView = useCallback(
    (accessLevel: AccessLevel) => {
      history.push(
        Paths.GetPath(AccessLevelQueries.queryKey, PathActions.View, {
          ...filterParams,
          ...accessLevel,
        })
      );
    },
    [filterParams, history]
  );

  const customFetch = useCallback(
    async (params: any) => {
      const result = await AccessLevelQueries.fetch(params);

      if (isSchlage) {
        setIsAtMaxForSchlage(result.total_records >= 16);
      }

      return result;
    },
    [isSchlage]
  );

  const tableProps = useTableQuery<
    any,
    AccessLevel,
    ListQueryResponse<AccessLevel>
  >({
    columns: [
      {
        name: "name",
        header: "Name",
        render: ({ row }) => {
          return <Typography>{row.name}</Typography>;
        },
        sortable: true,
        filter: (props: FilterProps) => {
          return <FormikTextInputField {...props} />;
        },
      },
      {
        name: "status",
        header: "Sync Status",
        render: ({ row }) => {
          const unsyncedControllers = (row.controllers || []).filter(
            (accessLevelController) => !accessLevelController.synced_at
          );
          return (
            <StatusDisplay
              status={
                unsyncedControllers.length == 0
                  ? SyncStatus.Synced
                  : SyncStatus.NotSynced
              }
              message={`${unsyncedControllers.length} Unsynced`}
            />
          );
        },
        sortable: false,
      },
      createActionsColumn<AccessLevel>({
        canUpdate: canUpdate && !isSalto,
        onUpdate,
        canDelete: canDelete && !isSalto,
        onDelete,
      }),
    ],
    fetch: customFetch,
    getQueryKey: ({ filters, page, pageSize, sortColumn, sortDirection }) => [
      AccessLevelQueries.queryKey,
      {},
      {
        ...filters,
        page,
        per_page: pageSize,
        sort: sortColumn,
        dir: sortDirection,
        ...filterParams,
      },
    ],
    defaultPageSize: 10,
  });

  return (
    <View style={styles.tableContainer}>
      <Table<AccessLevel>
        title="Access Levels"
        noRecordsText="No Access Levels Found"
        style={styles.table}
        action={
          <CreateButton
            canCreate={canCreate && !isSalto}
            disabled={isAtMaxForSchlage}
            onCreate={onCreate}
            text={
              isAtMaxForSchlage
                ? "Max Access Levels Reached"
                : "Add Access Level"
            }
          />
        }
        isRowPressable={() => !isSalto && canView}
        onRowPress={onView}
        {...tableProps}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  tableContainer: { zIndex: -500 },
  table: { zIndex: -500 },
});
