import { useState } from "react";
import { View, StyleSheet } from "react-native";

import { useWindowDimensionsQuery } from "@smartrent/hooks";
import {
  VStack,
  Panel,
  PanelBody,
  ListItemContainer,
  ListItemLabelValue,
  ListItemTouchableContainer,
  PanelHeader,
  PanelHeaderTitleWithStatusBadge,
  useToast,
  ActivityIndicator,
  useTheme,
} from "@smartrent/ui";

import { Refresh } from "@smartrent/icons";

import { formatDateTime, DATE_TIME_FORMAT } from "@/lib/formatters";

import { Hub } from "@/modules/hub/types";

import { Site } from "@/modules/site/types";

import { getErrorMessage } from "@/lib/axios-helpers";

import { apiClient } from "@/lib/api";

import { HubActionPanel } from "./show/ActionPanel";

interface HubShowPageProps {
  hub: Hub;
  site: Site;
}

const InformationItem = ({
  label,
  value,
}: {
  label: string;
  value?: string | number | JSX.Element;
}) => {
  if (!value) return null;
  return (
    <ListItemContainer style={styles.informationRow}>
      <ListItemLabelValue
        label={label}
        value={value ? (value as string) : "-"}
      />
    </ListItemContainer>
  );
};

const FirmwareVersion = ({ hub }: { hub: Hub }) => {
  const { colors } = useTheme();
  const setToast = useToast();
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [firmwareVersion, setFirmwareVersion] = useState(hub.firmware_version);
  const refreshFirmwareVersion = () => {
    setIsRefreshing(true);
    apiClient
      .get(`v1/hubs/${hub.id}/refresh_firmware_version`)
      .then((response) => {
        setIsRefreshing(false);
        if (response.data.firmware_version == firmwareVersion) {
          setToast({
            status: "knowledge",
            message: "Hub firmware version up to date.",
            title: "",
          });
        } else {
          setFirmwareVersion(response.data.firmware_version);
          setToast({
            status: "success",
            message: "Hub firmware version updated!",
            title: "",
          });
        }
      })
      .catch((error) => {
        setIsRefreshing(false);
        setToast({
          message: `Something went wrong: ${getErrorMessage(
            error
          )}. Please try again`,
          status: "error",
          title: "Error",
        });
      });
  };
  return (
    <ListItemTouchableContainer
      style={styles.informationRow}
      rightDetail={
        <View>
          {isRefreshing ? (
            <ActivityIndicator color={colors.black} size={24} />
          ) : (
            <Refresh />
          )}
        </View>
      }
      onPress={refreshFirmwareVersion}
    >
      <ListItemLabelValue
        label="Firmware Version"
        value={firmwareVersion ?? ""}
      />
    </ListItemTouchableContainer>
  );
};

export const HubsShowPage: React.FC<
  React.PropsWithChildren<HubShowPageProps>
> = ({ hub, site }) => {
  const { large: isDesktop } = useWindowDimensionsQuery();

  return (
    <View style={isDesktop ? styles.root : styles.rootMobile}>
      <View style={styles.left}>
        <View style={styles.container}>
          <Panel>
            <PanelHeader>
              <PanelHeaderTitleWithStatusBadge
                title={`${hub.name}`}
                status={hub.online ? "success" : "error"}
                statusLabel={hub.online ? "online" : "offline"}
              />
            </PanelHeader>

            <PanelBody>
              <VStack>
                <InformationItem label="Serial" value={hub.serial} />
                {hub.last_communication && (
                  <InformationItem
                    label="Last Communication"
                    value={formatDateTime(
                      hub.last_communication,
                      site.timezone,
                      DATE_TIME_FORMAT
                    )}
                  />
                )}
                {/* Show firmware version for hubs that aren't virtual. Warblers are still
                    marked as virtual (even though they aren't IRL) but we still want to display
                    the firmware version UI for them. */}
                {(!hub.virtual || hub.serial?.startsWith("AAW")) && (
                  <FirmwareVersion hub={hub} />
                )}
              </VStack>
            </PanelBody>
          </Panel>
        </View>
      </View>
      <View style={styles.right}>
        <HubActionPanel hub={hub} />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: { padding: 16 },
  root: { flexDirection: "row" },
  rootMobile: { flexDirection: "row", flexWrap: "wrap" },
  left: { flex: 1, flexBasis: "32%", minWidth: 420 },
  right: { flex: 3, flexBasis: "66%", minWidth: 360 },
  informationRow: {
    justifyContent: "space-between",
    flexDirection: "row",
  },
});
