import {
  Button,
  FilterProps,
  FormikSelectField,
  FormikTextInputField,
  HStack,
  ListQueryResponse,
  Table,
  useTableQuery,
  useTheme,
  ActivityIndicator,
} from "@smartrent/ui";
import { StyleSheet, View } from "react-native";

import { useCallback } from "react";

import { TimerRecurring } from "@smartrent/icons";

import { formatRelative } from "date-fns";

import { useHistory, useParams } from "react-router-dom";

import { useWindowDimensionsQuery } from "@smartrent/hooks";

import { humanize } from "@/lib/helpers";
import { useAppDrawer } from "@/components/layout/AppDrawer";

import { booleanOptions } from "@/pages/dev/badmagic/workspaces/shared";

import { usePermissions } from "@/context/PolicyContext";

import { useDialog } from "@/context/dialog";

import { PathActions, Paths } from "@/lib/path";

import { TableFilters } from "@/modules/base/table/types";

import { DomLink } from "@/components/alloy-access/Link";

import { DateTimeTypography } from "@/components/DateTimeTypography";

import { PendingDeleteChip } from "@/components/PendingDeleteChip";

import { ToggleableTypography } from "@/components/ToggleableTypography";

import { ControllerNameAndStatusIndicator } from "../controller/components/ControllerStatusIndicator";

import { ControllerQueries } from "../controller/queries";
import { Controller } from "../controller/types";
import { Site } from "../site/types";

import { createActionsColumn, useTableUtils } from "../base/table/utils";

import { Door, DoorConfigurations, DoorModeOptions } from "./types";
import { DoorQueries } from "./queries";

interface DoorsTableProps {
  filters: Partial<TableFilters & Door>;
  defaultSortColumn?: string;
  defaultSortDirection?: string;
  site: Site;
}

export const DoorsTable = ({
  filters,
  defaultSortColumn,
  defaultSortDirection,
  site,
}: DoorsTableProps) => {
  const drawer = useAppDrawer();
  const { colors } = useTheme();
  const history = useHistory();
  const { large: isDesktop } = useWindowDimensionsQuery();

  const params = useParams();
  const { confirm } = useDialog();
  const [doDelete] = DoorQueries.useDeleteMutation();

  const { canView, canCreate, canDelete, canUpdate } =
    usePermissions(DoorQueries);

  const { relativeWidths } = useTableUtils();

  const doorCreateAction = useCallback(
    () =>
      drawer.push(DoorQueries.queryKey, {
        site_id: site.id,
        initialValues: { id: 0, configuration: DoorConfigurations.single },
      }),
    [drawer, site.id]
  );

  const controllerSelectProps = ControllerQueries.tableSelectProps({
    defaultParams: { site_id: site.id },
  });

  const tableProps = useTableQuery<any, Door, ListQueryResponse<Door>>({
    fetch: DoorQueries.fetch,
    getQueryKey: ({
      filters: tableFilters,
      page,
      pageSize,
      sortColumn,
      sortDirection,
    }) => [
      DoorQueries.queryKey,
      {},
      {
        door_type: "doors",
        ...filters,
        page,
        per_page: pageSize,
        sort: sortColumn || defaultSortColumn,
        dir: sortDirection || defaultSortDirection,
        ...tableFilters,
        site_id: site.id,
      },
    ],
    columns: [
      {
        name: "name",
        header: "Name",
        style: isDesktop ? styles.columnPadding : null,
        render: ({ row }) => (
          <View>
            <HStack>
              {!row.synced_at && <ActivityIndicator size={24} />}
              <ToggleableTypography
                disabled={!!row?.pending_delete}
                style={row.synced_at ? null : styles.nameWithLoadingSpinner}
              >
                {row.name}
              </ToggleableTypography>
            </HStack>
          </View>
        ),
        sortable: true,
        filter: (props: FilterProps) => <FormikTextInputField {...props} />,
      },
      {
        name: "mode",
        header: "Mode",
        style: isDesktop ? styles.columnPadding : null,
        render: ({ row }) => {
          if (!row?.mode)
            return (
              <ToggleableTypography disabled={!!row?.pending_delete}>
                -
              </ToggleableTypography>
            );
          return (
            <View style={styles.modeContainer}>
              <View style={styles.modeTextContainer}>
                {row.mode_exp && new Date(`${row.mode_exp}Z`) > new Date() ? (
                  <TimerRecurring
                    style={styles.modeClock}
                    color={colors.warning}
                  />
                ) : null}
                <ToggleableTypography disabled={!!row?.pending_delete}>
                  {humanize(row.mode)}
                </ToggleableTypography>
              </View>
              <View style={styles.modeExpContainer}>
                {!!row.mode_exp && new Date(`${row.mode_exp}Z`) > new Date() ? (
                  <ToggleableTypography
                    disabled={!!row?.pending_delete}
                    color={"textSecondary"}
                  >
                    {`Resets ${formatRelative(
                      new Date(`${row.mode_exp}Z`),
                      new Date()
                    )}`}
                  </ToggleableTypography>
                ) : null}
              </View>
            </View>
          );
        },
        sortable: true,
        filter: (props: FilterProps) => (
          <FormikSelectField options={DoorModeOptions} {...props} />
        ),
      },
      {
        name: "controller_id",
        header: "Controller",
        style: isDesktop ? styles.columnPadding : null,
        render: ({ row }) => {
          if (!row.controller)
            return (
              <ToggleableTypography disabled={!!row?.pending_delete}>
                -
              </ToggleableTypography>
            );
          return (
            <ControllerNameAndStatusIndicator
              disabled={!!row?.pending_delete}
              controller={row.controller}
            />
          );
        },
        sortable: true,
        filterType: {
          type: "selectField",
          getOptionValue: (option: Controller) => option.id.toString(),
          getOptionLabel: (option: Controller) => `${option.name}`,
          ...controllerSelectProps,
        },
      },
      {
        name: "panel",
        header: "Panel",
        style: isDesktop ? styles.columnPadding : null,
        render: ({ row }) => {
          return (
            <ToggleableTypography disabled={!!row?.pending_delete}>
              {row.reader?.panel ? row.reader.panel.name : "-"}
            </ToggleableTypography>
          );
        },
        sortable: false,
      },
      {
        name: "reader",
        header: "Reader",
        style: isDesktop ? styles.columnPadding : null,
        render: ({ row }) => {
          return (
            <ToggleableTypography disabled={!!row?.pending_delete}>
              {row.reader ? row.reader?.name : "-"}
            </ToggleableTypography>
          );
        },
        sortable: false,
      },
      {
        name: "unlock_schedule",
        header: "Unlock Schedule",
        render: ({ row }) => {
          if (!row.unlocked_schedule) {
            return (
              <ToggleableTypography disabled={!!row?.pending_delete}>
                {" "}
                None{" "}
              </ToggleableTypography>
            );
          }
          return (
            <DomLink
              href={Paths.schedulesViewPath(
                site.id,
                row.unlocked_schedule.id as number
              )}
            >
              {row.unlocked_schedule.name}
            </DomLink>
          );
        },
      },
      {
        name: "synced_at",
        header: "Synced At",
        style: isDesktop ? styles.columnPadding : null,
        render: ({ row }) => {
          if (!row.synced_at) {
            return (
              <ToggleableTypography disabled={!!row?.pending_delete}>
                {" "}
                -{" "}
              </ToggleableTypography>
            );
          }
          return (
            <DateTimeTypography
              dateTime={`${row.synced_at}Z`}
              timezone={site?.timezone ? site.timezone : ""}
              disabled={!!row?.pending_delete}
            />
          );
        },
      },
      {
        name: "include_deleted",
        hidden: true,
        header: "Include Deleted",
        style: isDesktop ? styles.columnPadding : null,
        filter: (props: FilterProps) => (
          <FormikSelectField {...props} options={booleanOptions} />
        ),
      },
      createActionsColumn<Door>({
        relativeWidth: relativeWidths.columnSingle,
        customActions: (row) =>
          row.pending_delete ? <PendingDeleteChip /> : null,
        shouldAdjustForPressIcon: canView,
        canDelete: (row) => !row.pending_delete && canDelete,
        onDelete: async (row) => {
          const confirmed = await confirm({
            title: "Delete",
            description: "Are you sure you want to delete this Door?",
            confirmText: "Delete",
            confirmType: "destructive",
            cancelText: "Cancel",
          });
          if (confirmed) {
            doDelete({ id: row.id });
          }
        },
        canUpdate: (row) => !row.pending_delete && canUpdate,
        onUpdate: async (row) =>
          drawer.push(DoorQueries.queryKey, { initialValues: row, params }),
      }),
    ],
    defaultPageSize: 10,
  });
  return (
    <Table<Door>
      title={"Doors"}
      noRecordsText={`No Doors Found`}
      action={
        canCreate ? (
          <View style={styles.createButtonContainer}>
            <Button onPress={doorCreateAction} style={styles.createButton}>
              Add Door
            </Button>
          </View>
        ) : null
      }
      isRowPressable={(row) => canView && !row.pending_delete}
      onRowPress={
        canView
          ? (row) => {
              const unlockedSchedulesClicked =
                history.location.pathname.includes("schedules");
              if (!unlockedSchedulesClicked) {
                return history.push(
                  Paths.GetPath(DoorQueries.queryKey, PathActions.View, {
                    id: row.id,
                    site_id: site.id,
                  })
                );
              }
            }
          : undefined
      }
      refreshInterval={5000}
      {...tableProps}
      // `refreshInterval` prop causes table to flash every time we
      // refresh the door query (to display door not-synced indicator),
      // so we force table to never show loading state
      fetching={false}
    />
  );
};

const styles = StyleSheet.create({
  createButtonContainer: {
    flexDirection: "row",
  },
  createButton: {
    marginLeft: 8,
  },
  modeContainer: {
    flexDirection: "column",
  },
  modeTextContainer: {
    flexDirection: "row",
  },
  modeExpContainer: {},
  modeClock: {
    marginRight: 4,
  },
  columnPadding: { paddingRight: 16 },
  nameWithLoadingSpinner: { marginLeft: 4 },
});
