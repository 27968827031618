import {
  ListQueryResponse,
  Table,
  Typography,
  useTableQuery,
} from "@smartrent/ui";

import { useParams } from "react-router-dom";

import { toTitleCase } from "@/lib/helpers";

import {
  DeviceStatus,
  StatusDisplay,
} from "@/components/alloy-access/StatusDisplay";

import { DomLink } from "@/components/alloy-access/Link";

import { Paths, PathActions } from "@/lib/path";

import { QueryKeys } from "@/types";

import { useAppDrawer } from "@/components/layout/AppDrawer";
import { usePermissions } from "@/context/PolicyContext";
import { useDialog } from "@/context/dialog";

import { TableFilters } from "@/modules/base/table/types";

import { createActionsColumn, CreateButton } from "../base/table/utils";

import { OperatorQueries } from "./queries";
import { Operator } from "./types";

interface OperatorTableFilters extends TableFilters {
  site_id: number;
}
interface OperatorsTableProps {
  filters: OperatorTableFilters;
}
export const OperatorsTable = ({ filters }: OperatorsTableProps) => {
  // add + update
  const drawer = useAppDrawer();
  const params = useParams();

  // delete
  const { confirm } = useDialog();
  const [doDelete] = OperatorQueries.useDeleteMutation();

  const { canCreate, canDelete } = usePermissions(OperatorQueries);

  const tableProps = useTableQuery<any, Operator, ListQueryResponse<Operator>>({
    fetch: OperatorQueries.fetch,
    getQueryKey: ({
      filters: tableFilters,
      page,
      pageSize,
      sortColumn,
      sortDirection,
    }) => [
      OperatorQueries.queryKey,
      {},
      {
        ...filters,
        page,
        per_page: pageSize,
        sort: sortColumn,
        dir: sortDirection,
        ...tableFilters,
      },
    ],
    columns: [
      {
        name: "name",
        header: "Name",
        render: function NameRow({ row }) {
          return (
            <DomLink
              href={Paths.GetPath(QueryKeys.Members, PathActions.View, {
                site_id: filters.site_id,
                id: Number(row.member.id),
              })}
            >
              {toTitleCase(row.member.first_name)}{" "}
              {toTitleCase(row.member.last_name)}
            </DomLink>
          );
        },
      },
      {
        name: "email",
        header: "Email",
        render: function NameRow({ row }) {
          return <Typography>{row.member.email_address}</Typography>;
        },
      },
      {
        name: "status",
        header: "Status",
        render: function NameRow({ row }) {
          return (
            <StatusDisplay
              status={
                row.status === "active"
                  ? DeviceStatus.Online
                  : DeviceStatus.Pending
              }
              message={row.status}
            />
          );
        },
      },
      createActionsColumn<Operator>({
        canDelete,
        onDelete: async (row) => {
          const confirmed = await confirm({
            title: "Delete",
            description: "Are you sure you want to delete this Operator?",
            confirmText: "Delete",
            confirmType: "destructive",
            cancelText: "Cancel",
          });
          if (confirmed) {
            doDelete({ id: row.id });
          }
        },
      }),
    ],
    defaultPageSize: 10,
  });
  return (
    <Table<Operator>
      title={"Operators"}
      noRecordsText={`No Operators Found`}
      action={
        <CreateButton
          canCreate={canCreate}
          onCreate={() => drawer.push(OperatorQueries.queryKey, { params })}
          text={"Add Operator"}
        />
      }
      {...tableProps}
    />
  );
};
