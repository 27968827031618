import {
  ListItemContainer,
  ListItemLabelValue,
  ListItemLabelValueProps,
} from "@smartrent/ui";

interface UserActivityLogListItemProps {
  label: ListItemLabelValueProps["label"];
  value: ListItemLabelValueProps["value"];
}
export const UserActivityLogListItem: React.FC<
  React.PropsWithChildren<UserActivityLogListItemProps>
> = ({ label, value }) => (
  <ListItemContainer>
    <ListItemLabelValue label={label} value={value} numberOfLines={2} />
  </ListItemContainer>
);
