import {
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import { useContext, useEffect } from "react";
import { LoadingCard } from "@smartrent/ui";
import { CloudSync, List, TrashX } from "@smartrent/icons";

import { Site } from "@/modules/site/types";
import { Paths } from "@/lib/path";
import { PageCtx } from "@/context/PageContext";
import { ControllerQueries } from "@/modules/controller/queries";
import { ErrorPage } from "@/components/ErrorPage";

import { useDialog } from "@/context/dialog";

import { ControllersListPage } from "./ControllerListPage";
import { ControllerShowPage } from "./ControllerShowPage";
import { ControllerDebugPage } from "./ControllerDebugPage";
import { ControllerLogsPage } from "./ControllerLogsPage";

interface ControllersRouterProps {
  site: Site;
}

export const ControllersRouter = ({ site }: ControllersRouterProps) => {
  const { path } = useRouteMatch();
  const { addBreadcrumb, breadCrumbs } = useContext(PageCtx);

  useEffect(() => {
    addBreadcrumb({
      label: `Controllers`,
      to: Paths.controllersListPath(site.id),
    });
  }, [breadCrumbs, addBreadcrumb, site.id]);

  return (
    <Switch>
      <Route exact path={`${path}`}>
        <ControllersListPage site={site} />
      </Route>
      <Route path={`${path}/:controller_id`}>
        <ControllersSubRouter site={site} />
      </Route>
    </Switch>
  );
};

const ControllersSubRouter = ({ site }: ControllersRouterProps) => {
  const { path } = useRouteMatch();
  const history = useHistory();
  const { controller_id } = useParams<{ controller_id: string }>();
  const { addBreadcrumb, breadCrumbs, setActions } = useContext(PageCtx);
  const { data: controller, isLoading } = ControllerQueries.useQuery({
    id: parseInt(controller_id as string),
  });
  const [startSync] = ControllerQueries.useStartSyncMutation();
  const [deleteController] = ControllerQueries.useDeleteMutation();
  const { confirm } = useDialog();

  useEffect(() => {
    if (!controller) return;

    addBreadcrumb({
      label: `${controller?.name}`,
      to: Paths.controllerViewPath(site.id, controller.id),
    });
  }, [breadCrumbs, site.id, controller, addBreadcrumb, controller_id]);

  useEffect(() => {
    if (!controller) return;
    setActions([
      {
        label: "Logs",
        onPress: () => {
          history.push(Paths.controllerLogsPath(site.id, controller.id));
        },
        iconLeft: List,
      },
      {
        label: "Sync",
        onPress: () => {
          startSync({ controller_id: controller.id, force: false });
        },
        iconLeft: CloudSync,
      },
      {
        label: "Force Sync",
        onPress: () => {
          startSync({ controller_id: controller.id, force: true });
        },
        iconLeft: CloudSync,
      },
      {
        label: "Delete",
        onPress: async () => {
          const confirmed = await confirm({
            title: "Delete Controller?",
            description: "Are you sure you want to delete this controller?",
            confirmText: "Delete",
            confirmType: "destructive",
          });
          if (confirmed) {
            try {
              await deleteController({ id: controller.id });
              history.push(Paths.controllersListPath(site.id));
            } catch (err) {
              //no-op
            }
          }
        },
        iconLeft: TrashX,
        destructive: true,
      },
    ]);
  }, [
    setActions,
    controller,
    history,
    site.id,
    startSync,
    confirm,
    deleteController,
  ]);

  if (isLoading) return <LoadingCard />;
  if (!controller) return <ErrorPage title={"Failed to load controller"} />;

  return (
    <Switch>
      <Route exact path={`${path}`}>
        <ControllerShowPage site={site} controller={controller} />
      </Route>
      <Route path={`${path}/debug`}>
        <ControllerDebugPage site={site} controller={controller} />
      </Route>
      <Route path={`${path}/logs`}>
        <ControllerLogsPage site={site} controller={controller} />
      </Route>
    </Switch>
  );
};
