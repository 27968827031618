import { StyleSheet, View } from "react-native";
import {
  DatepickerField,
  TimeInputField,
  Typography,
  useTheme,
} from "@smartrent/ui";
import { useEffect, useState } from "react";
import { parse, isValid } from "date-fns";
import { useField } from "formik";

import {
  DATE_FORMAT,
  DATE_TIME_FORMAT,
  formatDateFromDateTime,
  formatTimeFromDateTime,
  TIME_FORMAT,
} from "@/lib/formatters";

interface DateTimePickerProps {
  name: string;
  labelPrefix: string;
  required?: boolean;
}

export const FormikDateTimePicker = ({
  name,
  labelPrefix,
  required = false,
}: DateTimePickerProps) => {
  const { colors } = useTheme();
  const [, { error, initialValue, touched }, { setValue }] = useField(name);

  const [date, setDate] = useState(formatDateFromDateTime(initialValue));
  const [dateError, setDateError] = useState("");
  const [time, setTime] = useState(formatTimeFromDateTime(initialValue));
  const [timeError, setTimeError] = useState("");

  useEffect(() => {
    setDateError("");
    if (!date) return;

    const parsedDate = parse(date, DATE_FORMAT, Date.now());
    if (!isValid(parsedDate)) {
      setDateError("Invalid Date");
    }
  }, [date]);

  useEffect(() => {
    setTimeError("");
    if (!time) return;

    const parsedTime = parse(time, TIME_FORMAT, Date.now());
    if (!isValid(parsedTime)) {
      setTimeError("Invalid Time");
    }
  }, [time]);

  useEffect(() => {
    const combinedDate = `${date} ${time}`;
    const newDate = parse(combinedDate, DATE_TIME_FORMAT, Date.now());
    if (time && date && isValid(newDate)) {
      setValue(newDate.toISOString());
    } else {
      setValue(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Creates an inf loop if you include setValue
  }, [date, time]);

  return (
    <View style={styles.datePickerContainer}>
      <View style={styles.inputContainer}>
        <View style={styles.datePickerDateContainer}>
          <DatepickerField
            label={`${labelPrefix} Date`}
            error={dateError}
            textInputProps={{
              value: date,
              onChangeText: setDate,
            }}
            required={required}
          />
        </View>
        <View style={styles.datePickerTimeContainer}>
          <TimeInputField
            label={`${labelPrefix} Time`}
            error={timeError}
            textInputProps={{
              value: time,
              onChangeText: setTime,
            }}
            required={required}
          />
        </View>
      </View>
      {error && touched ? (
        <View style={styles.errorContainer}>
          <Typography
            style={[
              styles.error,
              {
                color: colors.error,
              },
            ]}
          >
            You must provide a valid time and date
          </Typography>
        </View>
      ) : null}
    </View>
  );
};

const styles = StyleSheet.create({
  datePickerContainer: {
    flexDirection: "column",
  },
  inputContainer: {
    flexDirection: "row",
  },
  datePickerDateContainer: {
    flexGrow: 0.5,
    paddingLeft: 4,
    paddingRight: 4,
    flexBasis: "50%",
  },
  datePickerTimeContainer: {
    flexGrow: 0.5,
    paddingLeft: 4,
    paddingRight: 4,
    flexBasis: "50%",
  },
  errorContainer: {
    alignContent: "center",
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: 8,
  },
  error: {},
});
