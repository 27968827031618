import { VStack, FormikTextInputField } from "@smartrent/ui";
import { Form } from "formik";
import * as yup from "yup";

import { BaseForm } from "../base/Form";

import { Hub } from "./types";
import { HubQueries } from "./queries";

const validationSchema = yup.object().shape({
  name: yup.string().required("Required").max(50),
  serial: yup.string().required("Required").max(40),
  virtual: yup.bool(),
});

interface HubFormProps {
  initialValues?: Hub;
  params: {
    site_id: number;
  };
}

export const HubForm = ({ params, initialValues }: HubFormProps) => {
  return (
    <BaseForm<Hub>
      initialValues={{
        name: initialValues?.name,
        serial: initialValues?.serial,
        virtual: initialValues?.virtual,
        site_id: params.site_id,
        ...initialValues,
      }}
      validationSchema={validationSchema}
      QueryClient={HubQueries}
      params={params}
    >
      {() => (
        <Form>
          <VStack spacing={16}>
            <FormikTextInputField name="name" label="Name" required />
            <FormikTextInputField
              name="serial"
              label="Serial"
              required
              disabled={!!initialValues?.id}
              transformValue={(value) => value?.trim()}
            />
          </VStack>
        </Form>
      )}
    </BaseForm>
  );
};
