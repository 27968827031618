import { Route, Switch, useRouteMatch } from "react-router-dom";

import { useContext, useEffect } from "react";

import { Site } from "@/modules/site/types";
import { Paths } from "@/lib/path";

import { PageCtx } from "@/context/PageContext";

import { SystemHealthPage } from "./SystemHealthPage";

interface SystemHealthRouterProps {
  site: Site;
}

export const SystemHealthRouter = ({ site }: SystemHealthRouterProps) => {
  const { path } = useRouteMatch();
  const { addBreadcrumb } = useContext(PageCtx);

  useEffect(() => {
    addBreadcrumb({
      label: `System Health`,
      to: Paths.systemHealthPath(site.id),
    });
  }, [addBreadcrumb, site.id]);

  return (
    <Switch>
      <Route exact path={`${path}`}>
        <SystemHealthPage site={site} />
      </Route>
    </Switch>
  );
};
