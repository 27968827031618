import { Site } from "@/modules/site/types";
import { EventsTable } from "@/modules/event/Table";

interface EventsListPageProps {
  site: Site;
}

export const EventsListPage: React.FC<
  React.PropsWithChildren<EventsListPageProps>
> = ({ site }) => {
  return <EventsTable filters={{ site_id: site.id }} site={site} />;
};
