import { useContext, useEffect } from "react";

import { Site } from "@/modules/site/types";
import { ApiApplicationsTable } from "@/modules/api-applications/Table";
import { Paths } from "@/lib/path";
import { PageCtx } from "@/context/PageContext";

interface ApiClientsListPageProps {
  site: Site;
}

export const ApiClientsListPage = ({ site }: ApiClientsListPageProps) => {
  const { addBreadcrumb, breadCrumbs } = useContext(PageCtx);

  useEffect(() => {
    addBreadcrumb({
      label: `API Clients`,
      to: Paths.apiApplicationsListPath(site.id),
    });
  }, [breadCrumbs, addBreadcrumb, site.id]);

  return <ApiApplicationsTable filters={{ site_id: site.id }} />;
};
