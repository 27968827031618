import { QueryKeys } from "@/types";

import { BaseQueryClient } from "../base/queries";

import { Encoder } from "./types";

const queryKey = QueryKeys.Encoders;

class EncoderQueryClient extends BaseQueryClient<Encoder> {
  constructor() {
    super({ queryKey });
  }
}
export const EncoderQueries = new EncoderQueryClient();
